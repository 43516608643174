import React from 'react'

import { zIndices } from 'config/zIndices'
import { withPane } from 'lib/leaflet'

import { useEditZoneStore } from './Store'
import { EditCircleLayer } from './EditCircleLayer'
import { EditFzrLayer } from './EditFzrLayer'
import { EditDrawLayer } from './EditDrawLayer'
import { EditSelectionLayer } from './EditSelectionLayer'

export const EditLayers = () => {
  const [{ zone }] = useEditZoneStore()
  return (
    <>
      {zone?.properties?.creationMethod === 'circle' && <EditCircleLayer />}
      {zone?.properties?.creationMethod === 'fzr' && <EditFzrLayer />}
      {zone?.properties?.creationMethod === 'draw' && <EditDrawLayer />}
      {zone?.properties?.creationMethod === 'select' && <EditSelectionLayer />}
    </>
  )
}

export const EditPane = withPane(EditLayers, 'editZones', zIndices.editZone)
