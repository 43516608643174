import { createDataCache, validators } from 'lib/cache'
import api from 'stores/api'

const conditionals = [{ name: 'gac', validators: [validators.isType('string')] }]

function getter(gac) {
  return api.Geometry.actuallyAreas(queryObj(gac)).then((res) => res.data.data.features[0])
}

export const [getGacFeature] = createDataCache(conditionals, getter, { name: 'gacFeature' })

const queryObj = (gac) => ({
  type: 'area',
  gacsUseWildcard: false,
  precision: '00',
  gacs: [gac],
})
