import React from 'react'

import { useAppStore } from 'stores/AppStore'

import { ColorPicker } from './ColorPicker'

/**
 * A HOC for the ColorPicker to persist history and favorites
 *
 * ColorPickers of this type share history and favorites through the AppStore
 */
export const ColorPickerPersistent = (props) => {
  const [{ colorHistory, colorFavorites }, { setColorHistory, setColorFavorites }] = useAppStore()
  return (
    <ColorPicker
      history={colorHistory}
      onHistoryChange={setColorHistory}
      favorites={colorFavorites}
      onFavoritesChange={setColorFavorites}
      {...props}
    />
  )
}
