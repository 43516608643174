import React, { useEffect } from 'react'

import { usePromiseHandle, ignoreCanceled } from 'lib/hooks'
import { GeoJSON, Control } from 'lib/leaflet'
import Spinner from 'components/atoms/Spinner'

import { useMethodStore } from './Store'
import { getDrivingdistance } from './DrivingdistanceCache'

export const NewFzrLayer = () => {
  const [{ center, range, rangetype, mode, localFeatureCollection, isLoading }, setOpts] =
    useMethodStore('fzr')
  const [register, cancleAll] = usePromiseHandle()

  useEffect(() => {
    if (center === null) return
    cancleAll()
    setOpts({ isLoading: true })
    register(getDrivingdistance(center, [0, ...range], rangetype, mode))
      .then((localFeatureCollection) => setOpts({ localFeatureCollection, isLoading: false }))
      .catch((err) => {
        try {
          ignoreCanceled(err)
        } catch (err) {
          setOpts({ localFeatureCollection: null, isLoading: false })
        }
      })
    return () => {
      setOpts({ isLoading: false, localFeatureCollection: null })
    }
  }, [center, range, rangetype, mode, cancleAll, register, setOpts])

  return (
    <>
      {isLoading && (
        <Control position="bottomright">
          <Spinner inline />
        </Control>
      )}
      <GeoJSON data={localFeatureCollection} />
    </>
  )
}
