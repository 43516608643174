import React, { useCallback } from 'react'

import { makeIcon } from 'components/MapHelpers'
import { withPane, BgagVectorTileLayer } from 'lib/leaflet'
import { zIndices } from 'config/zIndices'
import { HAHNIMMO_PORTFOLIOS } from 'config/retailMarkets'
import { bgagServicesUrl } from 'config/appConfig'

const colorMap = Object.fromEntries(HAHNIMMO_PORTFOLIOS.map(({ value, color }) => [value, color]))

export const HahnimmoPortfoliosLayer = ({ selectedPortfolios }) => {
  const filter = useCallback(
    (feature) => selectedPortfolios.includes(feature.properties.category),
    [selectedPortfolios]
  )
  if (selectedPortfolios.length === 0) {
    return null
  }
  return (
    <BgagVectorTileLayer
      url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=clientHahnimmo&v=1`}
      auth
      maxNativeZoom={14}
      useMarker={true}
      style={style}
      filter={filter}
      storeAllLayers={true}
      onEachFeature={tooltip}
    />
  )
}
export const HahnimmoPortfolios = withPane(HahnimmoPortfoliosLayer, 'hahnimmo', zIndices.portfolios)

const style = (feature) => {
  const { category } = feature.properties
  return { icon: makeIcon(colorMap[category]) }
}

const tooltip = (feature) => {
  if (feature.properties.name) {
    feature.bindTooltip(feature.properties.name, { offset: [10, -25] })
    patchTooltipZoom(feature)
  }
}

/**
 * Close tooltips when map zooms
 *
 * This prevents leftover tooltips in vector tile layers
 */
const patchTooltipZoom = (layer) => {
  const closeTooltip = () => layer.closeTooltip()
  layer.on('tooltipopen', () => {
    layer._map.on('zoomend', closeTooltip)
  })
  layer.on('tooltipclose', () => {
    layer._map.off('zoomend', closeTooltip)
  })
}
