import React, { useEffect } from 'react'

import { isUndefined } from 'lib/util'
import { usePromiseHandle, ignoreCanceled, usePrevious } from 'lib/hooks'
import { Control } from 'lib/leaflet'
import Spinner from 'components/atoms/Spinner'

import { getDrivingdistance } from '../Drafts/DrivingdistanceCache'
import { useEditZoneStore } from './Store'
import { EditDrawLayer } from './EditDrawLayer'

export const EditFzrLayer = () => {
  const [{ zone, isLoading }, { setGeometry, setLoading }] = useEditZoneStore()
  const [register, cancleAll] = usePromiseHandle()

  const { creationParams } = zone.properties
  const prevParams = usePrevious(creationParams)

  useEffect(() => {
    if (isUndefined(prevParams) || prevParams === creationParams) return
    const { center, range, rangetype, mode } = creationParams
    if (center === null) return
    cancleAll()
    setLoading(true)
    register(getDrivingdistance(center, range, rangetype, mode))
      .then((collection) => setGeometry(collection.features[0].geometry))
      .then(() => setLoading(false))
      .catch((err) => {
        try {
          ignoreCanceled(err)
        } catch (err) {
          setLoading(false)
        }
      })
  }, [creationParams, prevParams, cancleAll, register, setLoading, setGeometry])

  useEffect(() => {
    return () => {
      setLoading(false)
    }
  }, [setLoading])

  return (
    <>
      {isLoading && (
        <Control position="bottomright">
          <Spinner inline />
        </Control>
      )}
      <EditDrawLayer />
    </>
  )
}
