import createStore from 'lib/flux-store'

const initialState = {
  topic: 'data_de.virtual.st_einw_prog_2035',
  year: 2019,
  displayCityLabels: true,
}

const actions = {
  setTopic: (topic) => ({ topic }),
  setYear: (year) => ({ year }),
  setCityLabels: (displayCityLabels) => ({ displayCityLabels }),
}

const reducer = {
  // set to maximum to let the yearSlider decide the max Year
  setTopic: (state, { topic }) => (state.topic === topic ? state : { ...state, topic, year: 2060 }),
  setYear: (state, { year }) => ({ ...state, year }),
  setCityLabels: (state, { displayCityLabels }) => ({ ...state, displayCityLabels }),
}

export const [ThematicMapsContext, ThematicMapsProvider, useThematicMapsStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'ThematicMapsStore'
)
