import React, { memo, useState, useRef, forwardRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Table, ColumnGroup, Column, HeaderCell, Cell } from 'rsuite-table'
import styled, { css, useTheme } from 'styled-components'
import Input from './atoms/Input'

/**
 * Override rsuite-tables classes. Will be injected in GlobalStyle.js.
 * Take care when updating library!
 */
export const rstableStylesheetOverrides = css`
  /** Override default background colors */
  .rs-table-row-header,
  .rs-table-hover .rs-table-row-header:hover {
    background: ${(props) => props.theme.table.headerBg};
  }
  .rs-table-cell-group-fixed-left,
  .rs-table-cell-group-fixed-right {
    background: ${(props) => props.theme.bodyBackground};
  }
  /** Override standard bottom border of rs-table-row in case of tree for top level rows */
  .rs-table-row[depth='0'] {
    border-bottom: 1px solid #ddd;
  }

  /** Flex-Positionierung für Zelleninhalt */
  .rs-table-cell-content {
    display: flex;
    align-items: center;
    padding: 0px;
  }
  .rs-table-cell-content:first-child {
    padding-left: 10px;
  }

  /** Ein-/Ausklapp-Button rechts von Zelleninhalt */
  .rs-table-cell-expand-wrapper {
    order: 3;
  }

  /** Show scrollbar only on table hover */
  .rs-table-scrollbar {
    opacity: 0;
    transition: opacity ease-out 0.5s;
  }
  .rs-table:hover .rs-table-scrollbar {
    opacity: 1;
  }
`

/**
 * Basic component styling starts here
 */
const StyledCell = styled(Cell).attrs({ className: 'figures-tabular' })`
  align-items: left;
  white-space: normal;
  word-break: normal;
  word-wrap: normal;
  ${(props) =>
    typeof props.bgcolor !== 'undefined' &&
    css`
      background: ${(props) => props.bgcolor};
    `}
  border-bottom: 1px solid #eee;
  border-bottom: ${(props) =>
    typeof props.depth === 'undefined' || props.depth === 0 ? '1px solid #ddd' : 'none'};
`

const styleLeft = { justifyContent: 'flex-start' }
const styleRight = { justifyContent: 'flex-end' }
const styleCenter = { justifyContent: 'center' }

const ellipsisPadding = 10
const styleEllipsis = {
  overflowX: 'hidden',
  lineHeight: 'normal',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  paddingRight: ellipsisPadding + 'px',
}

const getStyleHeader = (themeTable) => {
  return {
    backgroundColor: themeTable.headerBg,
    color: themeTable.headerColor,
    fontWeight: 'bold',
    fontSize: '0.9em',
    paddingRight: ellipsisPadding + 'px',
  }
}

let mouseOverTimeout = null
let overlay = null

const getAnchor = () => {
  const createAnchor = () => {
    const rootContainer = document.createElement('div')
    rootContainer.style.position = 'absolute'
    rootContainer.style.top = 0
    rootContainer.style.left = 0
    rootContainer.style.zIndex = 30000000
    rootContainer.setAttribute('id', 'TableEllipsisOverlay')
    document.body.insertBefore(rootContainer, document.body.lastElementChild.nextElementSibling)
    return rootContainer
  }
  return document.querySelector('#TableEllipsisOverlay') || createAnchor()
}

const reposOverlay = (cell) => {
  if (overlay === null) {
    return
  }

  const expanderRect = cell.getBoundingClientRect()
  const innerWidth = document.documentElement.clientWidth
  const overlayWidth = overlay.getBoundingClientRect().width

  let newLeft = expanderRect.left
  if (newLeft + overlayWidth > innerWidth) {
    newLeft = innerWidth - overlayWidth - 5
  }
  const newTop = expanderRect.top

  overlay.style.left = (newLeft < 0 ? 0 : newLeft) + 'px'
  overlay.style.top = (newTop < 0 ? 0 : newTop) + 'px'
  overlay.style.visibility = 'visible'
}

const buildCellClone = (source) => {
  let cellContent = source
  while (cellContent !== null && !cellContent.className.match(/rs-table-cell-content( |$)/)) {
    cellContent = cellContent.parentNode
  }
  let cell = cellContent
  while (cell !== null && !cell.className.match(/rs-table-cell( |$)/)) {
    cell = cell.parentNode
  }
  let row = cell
  while (row !== null && !row.className.match(/rs-table-row( |$)/)) {
    row = row.parentNode
  }
  if (cell === null || cellContent === null) {
    return null
  } else {
    overlay = row.cloneNode(false)
    overlay.style.boxShadow = '0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22)'
    overlay.style.minWidth = '0px'
    overlay.style.width = 'auto'
    overlay.style.transform = 'none'
    overlay.style.visibility = 'hidden'
    overlay.style.pointerEvents = 'none'

    const style = cell.currentStyle || window.getComputedStyle(cell)
    const styleContent = cellContent.currentStyle || window.getComputedStyle(cellContent)
    cell.addEventListener('mouseout', removeOverlay)
    const cellOl = cell.cloneNode(false)
    cellOl.style.backgroundColor = style.backgroundColor
    cellOl.style.width = 'auto'
    cellOl.style.paddingRight = '10px'
    cellOl.style.paddingLeft =
      parseInt(styleContent.paddingLeft) > 0 ? parseInt(styleContent.paddingLeft) + 10 + 'px' : '10px'
    cellOl.style.position = 'static'
    cellOl.style.pointerEvents = 'none'

    const cellContentOl = cellContent.cloneNode(false)
    cellContentOl.style.width = 'auto'
    cellContentOl.style.paddingLeft = '0px'
    cellContentOl.innerHTML = source.innerHTML
    cellContentOl.style.pointerEvents = 'none'

    cellOl.appendChild(cellContentOl)
    overlay.appendChild(cellOl)
    setTimeout(() => {
      reposOverlay(cell)
    }, 0)
  }
}

const addOverlay = (anchor, source) => {
  mouseOverTimeout = null
  buildCellClone(source)
  anchor.appendChild(overlay)
}

const removeOverlay = () => {
  if (overlay && overlay.parentNode) {
    overlay.parentNode.removeChild(overlay)
  }
  overlay = null
}

const mouseOutEllipsis = (evt) => {
  if (mouseOverTimeout !== null) {
    clearTimeout(mouseOverTimeout)
    mouseOverTimeout = null
  }
}
const mouseOverEllipsis = (evt) => {
  const anchor = getAnchor()
  removeOverlay()

  let measureSpan = evt.target
  while (
    measureSpan !== null &&
    (typeof measureSpan.className !== 'string' || measureSpan.className.indexOf('mouseOverSelector') < 0)
  ) {
    measureSpan = measureSpan.parentNode
  }
  if (measureSpan === null) {
    return
  }
  const source = measureSpan.parentNode
  if (source.getBoundingClientRect().width < measureSpan.getBoundingClientRect().width + ellipsisPadding) {
    if (mouseOverTimeout !== null) {
      clearTimeout(mouseOverTimeout)
    }
    mouseOverTimeout = setTimeout(() => {
      addOverlay(anchor, source)
    }, 200)
  }
}

const WrappedTable = forwardRef((props = {}, ref) => {
  const { t } = useTranslation()
  const {
    calculateHeight,
    forceRecalculateHeight,
    sort,
    setSort,
    onSortColumn,
    onScroll,
    scrollRightOnInit = false,
    onRowClick,
    defaultSortType,
    children,
    scrollTo = null,
    scrollToPx = null,
    height,
    minHeight = 0,
    rowHeight = 46,
    headerHeight = 40,
    ...rest
  } = props

  const [calculatedHeight, setHeight] = useState()

  const localTable = useRef()
  const table = ref || localTable

  const lastSort = useRef({ key: null, dir: null })

  const localOnSortColumn = (key) => {
    const lastKey = lastSort.current.key
    const lastDir = lastSort.current.dir
    let dir = 'asc'

    if (key === lastKey) {
      dir = lastDir === null ? 'asc' : lastDir === 'asc' ? 'desc' : null
      if (dir === null) {
        key = null
      }
    }
    lastSort.current = { key, dir }
    setSort({ key, dir })
    if (typeof onSortColumn === 'function') {
      onSortColumn(key)
    }
  }

  const localOnRowClick = (data) => {
    removeOverlay()
    if (typeof onRowClick === 'function') {
      onRowClick(data)
    }
  }

  const localOnScroll = (scrollX, scrollY) => {
    if (!table.current) {
      return
    }
    if (mouseOverTimeout !== null) {
      clearTimeout(mouseOverTimeout)
    }
    removeOverlay()
    /* fix for blurry text not possible like this after rsuite-table upgrade:
    // blurry text, if scrollY or scrollX gets not rounded
    const newScrollX = Math.round(scrollX * window.devicePixelRatio) / window.devicePixelRatio
    const newScrollY = Math.round(scrollY * window.devicePixelRatio) / window.devicePixelRatio
    // table.current.scrollX = scrollX
    // table.current.scrollY = scrollY
    // table.current.setState({ scrollX, scrollY })
    */
    if (typeof onScroll === 'function') {
      onScroll(scrollX, scrollY)
    }
  }

  const sortColumn = sort && sort.key ? sort.key : null
  const sortType = sort && sort.dir ? sort.dir : null

  useEffect(() => {
    if (sort && sort.key) {
      lastSort.current = sort
    }
  }, [sort])

  // calculate table height, if calculateHeight is true, else set on prop height
  useEffect(() => {
    if (!calculateHeight) {
      if (height) {
        setHeight(height)
      }
      return
    }

    const container = table.current.root.parentNode
    const resetHeight = () => {
      table.current.root.style.display = 'none'
      setHeight(container.clientHeight > minHeight ? container.clientHeight : minHeight)
      table.current.root.style.display = ''
    }

    resetHeight()
    window.addEventListener('resize', resetHeight)

    return () => {
      window.removeEventListener('resize', resetHeight)
    }
  }, [calculateHeight, height, minHeight, table, forceRecalculateHeight])

  // react on scrollTo
  useEffect(() => {
    if (scrollTo === null && scrollToPx === null) {
      return
    }

    const maxScroll = rest.data.length * rowHeight - calculatedHeight + headerHeight

    let scrollY = scrollTo === null ? scrollToPx : scrollTo * rowHeight
    scrollY = Math.max(0, Math.min(scrollY, maxScroll))
    table.current.scrollTop(scrollY)
  }, [table, scrollTo, scrollToPx, rowHeight, headerHeight, calculatedHeight, rest.data])

  useEffect(() => {
    let timeout

    const setScrollLeft = () => {
      if (!table.current || !scrollRightOnInit) {
        return
      }
      table.current.scrollLeft(Infinity)
    }

    timeout = setTimeout(setScrollLeft, 0)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [table, rest.data, scrollRightOnInit])

  return (
    <Table
      onSortColumn={localOnSortColumn}
      onScroll={localOnScroll}
      onRowClick={localOnRowClick}
      defaultSortType={defaultSortType || 'asc'}
      height={calculatedHeight}
      rowHeight={rowHeight}
      headerHeight={headerHeight}
      ref={table}
      sortColumn={sortColumn}
      sortType={sortType}
      renderEmpty={() => {
        return (
          <div className="rs-table-body-info">
            {t('table.noResult')}
            {rest.no_data_tip && <div>{t(rest.no_data_tip)}</div>}
          </div>
        )
      }}
      {...rest}
    >
      {children}
    </Table>
  )
})

/*
 * column not wrappable, since rsuite table just takes properties and children from the children of rsuites table component,
 * so no rendering taking place
 *
const WrappedColumn = props => {
  return <Column {...props}/>
}
*/

const WrappedHeaderCell = memo((props) => {
  const { style = {}, children, ...rest } = props

  const currentTheme = useTheme()

  const styleAlign =
    rest.align === 'right'
      ? { ...styleRight }
      : rest.align === 'center'
      ? { ...styleCenter }
      : { ...styleLeft }

  const styleHeader = {
    ...getStyleHeader(currentTheme.table),
    ...styleAlign,
    ...style,
  }

  return (
    <HeaderCell style={styleHeader} {...rest}>
      {children}
    </HeaderCell>
  )
})

const DataCell = memo((props) => {
  const currentTheme = useTheme()
  const [editMode, setEditMode] = useState(false)
  const inputRef = useRef()
  const {
    render,
    onMouseEnter,
    onMouseLeave,
    outsideListenerElement = 'body',
    applyKey = 'Enter',
    discardKey = 'Esc',
    CustomInput,
    ...rest
  } = props
  const computedProps = { style: {}, ellipsis: true, ...rest, ...render?.(rest) }
  const value =
    computedProps.value ??
    props.children?.(props.rowData[props.dataKey], props.rowData) ??
    props.rowData[props.dataKey]
  const ellipsis = computedProps.ellipsis
  delete computedProps.ellipsis
  delete computedProps.onChange

  const styleAlign =
    computedProps.align === 'right' ? styleRight : computedProps.align === 'center' ? styleCenter : styleLeft
  const styleHeader = props.rowData.isHeaderCell ? getStyleHeader(currentTheme.table) : {}
  computedProps.style = { ...styleHeader, ...styleAlign, ...computedProps.style }

  if (!computedProps.onMouseEnter && onMouseEnter) {
    computedProps.onMouseEnter = (evt) => onMouseEnter(rest, evt)
  }
  if (!computedProps.onMouseLeave && onMouseLeave) {
    computedProps.onMouseLeave = (evt) => onMouseLeave(rest, evt)
  }

  const enableEditMode = useCallback(() => {
    if (rest.onChange && typeof rest.onChange === 'function') {
      console.log('editmode true')
      setEditMode(true)
    }
  }, [rest.onChange])

  const onClose = useCallback((value) => {
    setEditMode(false)
  }, [])

  const onOutsideClick = useCallback(
    (evt) => {
      if (
        (editMode && evt.target.id !== 'cellInputChange') ||
        (computedProps.inputId && evt.target.id !== computedProps.inputId)
      ) {
        setEditMode(false)
      }
    },
    [editMode, computedProps.inputId]
  )

  useEffect(() => {
    if (editMode === true) {
      inputRef.current.focus()
      let listener = inputRef.current
      while (listener.parentNode && (!listener.id || !['modal', 'root'].includes(listener.id))) {
        listener = listener.parentNode
      }
      listener.addEventListener('click', onOutsideClick)
      return () => {
        listener.removeEventListener('click', onOutsideClick)
      }
    }
  }, [editMode, onOutsideClick, outsideListenerElement, inputRef])

  return (
    <StyledCell {...computedProps} onClick={enableEditMode}>
      {editMode ? (
        CustomInput ? (
          <CustomInput {...rest} ref={inputRef} />
        ) : (
          <CellInput
            initValue={value}
            cellData={rest}
            applyKey={applyKey}
            discardKey={discardKey}
            onClose={onClose}
            id={computedProps.inputId}
            ref={inputRef}
            {...rest}
          />
        )
      ) : ellipsis ? (
        <span style={styleEllipsis}>
          <div>{/* empty div prevents auto hover on elipsis of safari browser */}</div>
          <span
            className="mouseOverSelector"
            onMouseOver={mouseOverEllipsis}
            onMouseOut={mouseOutEllipsis}
            style={{ maxWidth: 'max-content' }}
          >
            {value}
          </span>
        </span>
      ) : (
        value
      )}
    </StyledCell>
  )
})

const CellInput = forwardRef(
  ({ initValue, applyKey, discardKey, onClose, inputId = 'cellInputChange', onChange, cellData }, ref) => {
    const [value, setValue] = useState(initValue)

    const onInputChange = useCallback((evt) => {
      setValue(evt.target.value)
    }, [])

    const applyOrDiscard = useCallback(
      (evt) => {
        if (evt.key === applyKey) {
          onClose()
          onChange({ target: { value, id: inputId, cellData } })
        }
        if (evt.key === discardKey) {
          onClose()
        }
      },
      [applyKey, discardKey, onClose, value, onChange, inputId, cellData]
    )

    return (
      <Input
        ref={ref}
        mb={0}
        value={value}
        onChange={onInputChange}
        onKeyDown={applyOrDiscard}
        id={inputId}
        label=""
      />
    )
  }
)

export { WrappedTable as Table, ColumnGroup, Column, WrappedHeaderCell as HeaderCell, DataCell }
