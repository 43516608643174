import { createDataTreeCache, createDataCache, validators } from 'lib/cache'

import { transformQuarterResponse } from './helper'

import api from 'stores/api'

const conditionalsTopicData = [
  { name: 'gacs' },
  {
    name: 'time',
    argToKeys: (arg, args) => {
      const isQuarter = args[3]
      const keys = []
      for (let i = arg.from; i <= arg.to; i++) {
        if (!isQuarter) {
          keys.push(i)
        } else {
          for (let quarter = 1; quarter <= 4; quarter++) {
            keys.push(i + '-' + quarter)
          }
        }
      }
      return keys
    },
    keysToArg: (keys, args) => {
      const isQuarter = args[3]
      if (isQuarter) {
        keys = keys.reduce((keys, key) => {
          if (key.indexOf('-1') >= 0) {
            keys.push(key.replace('-1', ''))
          }
          return keys
        }, [])
      }
      return {
        from: Math.min(...keys),
        to: Math.max(...keys),
      }
    },
    validators: [
      validators.isType('object'),
      (val) => validators.isType('number')(val.from),
      (val) => validators.isType('number')(val.to),
    ],
  },
  { name: 'dataSources' },
  {
    name: 'isQuarter',
    ignoreOnCaching: true,
    validators: [validators.isType('boolean')],
  },
]

const getterTopicData = (gacs, time, dataSources, isQuarter) => {
  const params = {
    format: 'tree',
    failOnAuthz: false,
    dataSources,
    gacs,
    time,
  }

  return api.Data.getData(params).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return isQuarter ? transformQuarterResponse(result.data.data) : result.data.data
  })
}

export const [getTopicData, bustTopicData] = createDataTreeCache(conditionalsTopicData, getterTopicData, {
  name: 'MarketDataCacheTopicData',
  logPerformance: false,
  maxSize: 25000,
})

const conditionalsTopicReferenceData = [
  {
    name: 'referenceCurve',
    validators: [(val) => val.match(/^[A-D]$/)],
    argToKeys: (arg) => [arg],
    keysToArg: (keys) => keys[0],
  },
  {
    name: 'time',
    argToKeys: (arg, args) => {
      const isQuarter = args[3]
      const keys = []
      for (let i = arg.from; i <= arg.to; i++) {
        if (!isQuarter) {
          keys.push(i)
        } else {
          for (let quarter = 1; quarter <= 4; quarter++) {
            keys.push(i + '-' + quarter)
          }
        }
      }
      return keys
    },
    keysToArg: (keys, args) => {
      const isQuarter = args[3]
      if (isQuarter) {
        keys = keys.reduce((keys, key) => {
          if (key.indexOf('-1') >= 0) {
            keys.push(key.replace('-1', ''))
          }
          return keys
        }, [])
      }
      return {
        from: Math.min(...keys),
        to: Math.max(...keys),
      }
    },
    validators: [
      validators.isType('object'),
      (val) => validators.isType('number')(val.from),
      (val) => validators.isType('number')(val.to),
    ],
  },
  { name: 'dataSources' },
  {
    name: 'isQuarter',
    ignoreOnCaching: true,
    validators: [validators.isType('boolean')],
  },
]

const getterTopicReferenceData = (referenceCurve, time, dataSources, isQuarter) => {
  const params = {
    format: 'tree',
    failOnAuthz: false,
    cityType: referenceCurve,
    dataSources,
    time,
  }

  return api.Data.getDataAverageByCityType(params).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return isQuarter ? transformQuarterResponse(result.data.data) : result.data.data
  })
}

export const [getTopicReferenceData, bustTopicReferenceData] = createDataTreeCache(
  conditionalsTopicReferenceData,
  getterTopicReferenceData,
  {
    name: 'MarketDataCacheTopicReferenceData',
    maxSize: 25000,
  }
)

const conditionalsMinMaxYears = [{ name: 'areaTypes' }, { name: 'dataSources' }]

const getterMinMaxYears = (areaTypes, dataSources) => {
  return api.Config.dataSourcesYearsBySources(dataSources, areaTypes).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getMinMaxYears, bustMinMaxYears] = createDataTreeCache(
  conditionalsMinMaxYears,
  getterMinMaxYears,
  {
    name: 'MarketDataCacheMinMaxYears',
  }
)

const getterMarketDataTopics = () => {
  return api.Config.topics(true).then((result) => {
    if (!(result.data.status && result.data.status === 'success')) {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getMarketDataTopics, bustMarketDataTopics] = createDataCache([], getterMarketDataTopics, {
  name: 'MarketDataTopics',
})
