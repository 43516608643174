import { createGlobalStyle, css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import typography from './shared/typography'
import { rstableStylesheetOverrides } from 'components/Table'
import themeOne from './themeOne'

// Allow accessing font settings but control how/where to load
export const googleFontsUrl =
  'https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap'

export const modalStyles = css`
  body.ReactModal__Body--open {
    overflow: hidden;
    &.hide-intercom #intercom-container {
      display: none;
    }
  }

  .ReactModalPortal > div {
    opacity: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    transition: all 200ms ease-in;

    &--after-open {
      opacity: 1;
    }
    &--before-close {
      opacity: 0;
    }
  }
`

export const bodyStyles = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root,
  html {
    font-size: 16px;
  }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    background: ${themeGet('bodyBackground', themeOne.bodyBackground)};
    margin: 0;
    padding: 0;
    font-family: ${typography.fonts.sansSerif};
    font-weight: normal;
    font-size: ${typography.fontSizes.md};
    color: ${themeGet('colors.darkest', themeOne.colors.darkest)};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-overflow-scrolling: touch;
    -webkit-text-size-adjust: 100%;
    /* TODO background-color */
    /* overflow-y: auto;
  overflow-x: hidden; */
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-family: ${typography.fonts.heading};
    font-weight: normal;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 1.2rem;
  }

  h5 {
    font-size: 1rem;
  }

  h6 {
    font-size: 0.85rem;
  }

  p {
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  a {
    /* font-size: 1em; */
    color: ${themeGet('colors.dark', themeOne.colors.dark)};
    text-decoration: none;
    background-color: transparent;
    &:hover {
      color: ${themeGet('colors.darkest', themeOne.colors.darkest)};
    }
  }
  a:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none;
  }

  hr {
    /* BOOTSTRAP
    box-sizing: content-box;
    height: 0;
    overflow: visible; */
    border: none;
    border-top: 1px solid ${themeGet('colors.border', themeOne.colors.border)};
    clear: both;
    margin-bottom: 1.25rem;
  }

  code,
  pre {
    font-family: ${typography.fonts.mono};
    font-size: ${typography.fontSizes.sm};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [tabindex='-1']:focus:not(.focus-visible) {
    outline: 0 !important;
  }

  [tabindex='-1']:focus:not(:focus-visible) {
    outline: 0 !important;
  }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none;
  }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  dt {
    font-weight: 700;
  }

  dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
  }

  blockquote {
    margin: 0 0 1rem;
  }

  b,
  em,
  strong {
    font-weight: bold;
  }

  small {
    font-size: 0.8em;
  }

  sub,
  sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
  }

  sub {
    bottom: -0.25em;
  }

  sup {
    top: -0.5em;
  }

  pre,
  code,
  kbd,
  samp {
    font-family: ${typography.fonts.sansSerif};
    font-size: 1em;
  }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
  }

  figure {
    margin: 0 0 1rem;
  }

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    vertical-align: middle;
    border-style: none;
  }

  svg {
    overflow: hidden;
    vertical-align: middle;
  }

  table {
    border-collapse: collapse;
  }

  th {
    text-align: inherit;
  }

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
  }

  button {
    border-radius: 0;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button,
  input {
    overflow: visible;
  }

  button,
  select {
    text-transform: none;
  }

  select {
    word-wrap: normal;
  }

  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }

  button:not(:disabled),
  [type='button']:not(:disabled),
  [type='reset']:not(:disabled),
  [type='submit']:not(:disabled) {
    cursor: pointer;
  }

  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }

  input[type='radio'],
  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }

  input[type='date'],
  input[type='time'],
  input[type='datetime-local'],
  input[type='month'] {
    -webkit-appearance: listbox;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }

  progress {
    vertical-align: baseline;
  }

  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    height: auto;
  }

  [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }

  [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }

  output {
    display: inline-block;
  }

  summary {
    display: list-item;
    cursor: pointer;
  }

  template {
    display: none;
  }

  [hidden] {
    display: none !important;
  }
`

export const GlobalStyle = createGlobalStyle`
  ${bodyStyles}
  ${modalStyles}
  ${rstableStylesheetOverrides}
`
