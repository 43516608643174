import React, { useEffect } from 'react'

import { makePolygonFeature } from 'lib/geojson'
import { pointBuffer } from 'lib/geo/pointBuffer'
import { GeoJSON } from 'lib/leaflet'
import { useMethodStore } from './Store'

export const NewCircleLayer = () => {
  const [{ center, radius, feature }, setOpts] = useMethodStore('circle')

  useEffect(() => {
    if (center === null) return
    const { coordinates } = center
    const polygon = pointBuffer(coordinates, radius)
    const feature = makePolygonFeature(polygon)
    setOpts({ feature })
    return () => {
      setOpts({ feature: null })
    }
  }, [center, radius, setOpts])

  return <GeoJSON data={feature} />
}
