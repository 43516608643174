import { useEffect } from 'react'

export default (onEscape) => {
  const testEscape = (evt) => {
    if (evt.keyCode === 27) {
      onEscape()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', testEscape, false)
    return () => {
      document.removeEventListener('keydown', testEscape, false)
    }
  })
}
