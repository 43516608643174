export default {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: { name: 'Düsseldorf' },
      geometry: { type: 'Point', coordinates: [6.792018, 51.222217] },
    },
    // { "type": "Feature", "properties": { "Name": "Bonn" }, "geometry": { "type": "Point", "coordinates": [ 7.07858, 50.729884 ] } },
    {
      type: 'Feature',
      properties: { name: 'Köln' },
      geometry: { type: 'Point', coordinates: [6.956998, 50.935558] },
    },
    {
      type: 'Feature',
      properties: { name: 'Dortmund' },
      geometry: { type: 'Point', coordinates: [7.47178, 51.511103] },
    },
    {
      type: 'Feature',
      properties: { name: 'Frankfurt' },
      geometry: { type: 'Point', coordinates: [8.672934, 50.114721] },
    },
    {
      type: 'Feature',
      properties: { name: 'Stuttgart' },
      geometry: { type: 'Point', coordinates: [9.184403, 48.80653] },
    },
    // { "type": "Feature", "properties": { "Name": "Karlsruhe" }, "geometry": { "type": "Point", "coordinates": [ 8.407321, 49.023033 ] } },
    {
      type: 'Feature',
      properties: { name: 'Nürnberg' },
      geometry: { type: 'Point', coordinates: [11.093541, 49.448897] },
    },
    {
      type: 'Feature',
      properties: { name: 'Bremen' },
      geometry: { type: 'Point', coordinates: [8.797618, 53.081867] },
    },
    // { "type": "Feature", "properties": { "Name": "Münster" }, "geometry": { "type": "Point", "coordinates": [ 7.612614, 51.955046 ] } },
    // { "type": "Feature", "properties": { "Name": "Mannheim" }, "geometry": { "type": "Point", "coordinates": [ 8.500491, 49.50604 ] } },
    {
      type: 'Feature',
      properties: { name: 'München' },
      geometry: { type: 'Point', coordinates: [11.577491, 48.14116] },
    },
    {
      type: 'Feature',
      properties: { name: 'Berlin' },
      geometry: { type: 'Point', coordinates: [13.411219, 52.504997] },
    },
    {
      type: 'Feature',
      properties: { name: 'Hamburg' },
      geometry: { type: 'Point', coordinates: [10.00275, 53.57292] },
    },
    {
      type: 'Feature',
      properties: { name: 'Hannover' },
      geometry: { type: 'Point', coordinates: [9.773687, 52.39126] },
    },
    {
      type: 'Feature',
      properties: { name: 'Dresden' },
      geometry: { type: 'Point', coordinates: [13.742388, 51.058678] },
    },
    {
      type: 'Feature',
      properties: { name: 'Leipzig' },
      geometry: { type: 'Point', coordinates: [12.373376, 51.336278] },
    },
  ],
}
