import React, { useMemo } from 'react'
import polygonClipping from 'polygon-clipping'

import { GeoJSON, withPane } from 'lib/leaflet'
import { makeMultiPolygonFeature } from 'lib/geojson'
import { polyFromBounds } from 'lib/bounds-cache/polyutils'
import { zIndices } from 'config/zIndices'

import { useGeotoolsStore } from 'components/geotools'
import { useGacFeature } from './useGacFeature'
import { useMarketDataStateStore, MarketDataSelectors } from 'pages/marketData/stateStore'

const backdropBorders = polyFromBounds([3, 45, 18, 57])
const DEFAULT_STYLE = () => ({
  color: null,
  fillColor: '#ffffff',
  fillOpacity: 0.4,
  className: 'bg-backdrop',
})

export const BackdropLayer = ({ style = DEFAULT_STYLE }) => {
  const projectFeature = useGeotoolsStore((state) => state.project?.union)
  const curGac = useMarketDataStateStore(MarketDataSelectors.$curGac)
  const gacFeature = useGacFeature(curGac)

  const diff = useMemo(() => {
    if (projectFeature && gacFeature) {
      const hole = polygonClipping.union(projectFeature.geometry.coordinates, gacFeature.geometry.coordinates)
      return polygonClipping.difference(backdropBorders, hole)
    }
    if (projectFeature) {
      return polygonClipping.difference(backdropBorders, projectFeature.geometry.coordinates)
    }
    if (gacFeature) {
      return polygonClipping.difference(backdropBorders, gacFeature.geometry.coordinates)
    }
    return [backdropBorders]
  }, [projectFeature, gacFeature])

  const feature = useMemo(() => makeMultiPolygonFeature(diff), [diff])
  return <GeoJSON data={feature} style={style} />
}

export const BackdropPane = withPane(BackdropLayer, 'backdrop', zIndices.backdrop)
