import { DivIcon } from 'leaflet'

import poiSbahnPath from 'assets/poi-sbahn.svg'
import poiBusPath from 'assets/poi-bus.svg'
import poiTramPath from 'assets/poi-tram.png'
import poiUBahnPath from 'assets/poi-ubahn.svg'
import poiBahnPath from 'assets/poi-bahn.svg'
import poiShipPath from 'assets/poi-ship.svg'
import poiCarPath from 'assets/poi-car.svg'

export function transportIcon(feature) {
  const types = makeOepnvTypeList(feature)
  const imgs = types.sort(transportSorter).map(getTransportImg).join('')
  const html = wrapTransportImgs(imgs)

  return new DivIcon({
    html,
    iconSize: [10 * types.length, 10],
    iconAnchor: [5 * types.length, 5],
    className: '',
  })
}

function makeOepnvTypeList(feature) {
  return Object.keys(feature.properties).reduce((types, key) => {
    if (feature.properties[key] === true) types.push(key)
    return types
  }, [])
}

const imageStyle = `width: 10px;`
const wrapperStyle = `transform: translateY(-4px)`
const wrapTransportImgs = (imgs) => `<div style="${wrapperStyle}">${imgs}</div>`

const imgHTML = (path) => `<img src="${path}" style="${imageStyle}" />`
export function getTransportImg(type) {
  switch (type) {
    case 'bus':
      return imgHTML(poiBusPath)
    case 'carTraffic':
      return imgHTML(poiCarPath)
    case 'metro':
      return imgHTML(poiUBahnPath)
    case 'sBahn':
      return imgHTML(poiSbahnPath)
    case 'ship':
      return imgHTML(poiShipPath)
    case 'train':
      return imgHTML(poiBahnPath)
    case 'tram':
      return imgHTML(poiTramPath)
    default:
      throw Error(`Public transport type ${type} not known`)
  }
}

const prioList = ['ship', 'train', 'sBahn', 'metro', 'tram', 'carTraffic', 'bus']
export function transportSorter(first, second) {
  return prioList.indexOf(first) - prioList.indexOf(second)
}

const transportTypes = ['ship', 'train', 'sBahn', 'metro', 'tram', 'carTraffic', 'bus']
export function isBusOnly(feature) {
  const otherTypes = transportTypes.filter((type) => type !== 'bus')
  for (const type of otherTypes) {
    if (feature.properties[type]) {
      return false
    }
  }
  return true
}
