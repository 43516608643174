import createStore from 'lib/flux-store'
import api from './api'

const initialState = {
  currentUser: 'init',
}

const actions = {
  pullCurrentUser: () => (dispatch) => {
    return Promise.all([
      api.Auth.currentUser(),
      api.Auth.currentPermissions(),
      api.Config.byType('dataSourcesCompact'),
      api.Auth.availableAreas({ addNames: false }),
    ])
      .then((res) => {
        dispatch({
          type: 'setCurrentUser',
          currentUser: {
            data: res[0].data.data,
            permissions: res[1].data.data,
            dataSources: res[2].data.data.reduce((dataSources, source) => {
              dataSources[source.key] = source.entries
              return dataSources
            }, {}),
            availableCountries: Array.from(
              Object.values(res[3].data.data).reduce((set, module) => {
                module.forEach((entry) => {
                  const country = entry.gac.match(/^([a-zA-Z]{2})\d{6}/)
                  const germany = entry.gac.match(/^[^a-zA-Z]\d.*/)
                  if (country && country[1]) {
                    set.add(country[1].toUpperCase())
                  } else if (germany) {
                    set.add('DE')
                  }
                })
                return set
              }, new Set())
            ),
          },
        })
      })
      .catch(() => {
        dispatch({ type: 'setCurrentUser', currentUser: false })
      })
  },
  resetCurrentUser: () => ({ type: 'setCurrentUser', currentUser: false }),
}

const reducer = {
  setCurrentUser: (state, { currentUser }) => ({ ...state, currentUser }),
}

export const [AuthStoreContext, AuthStoreProvider, useAuthStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'AuthStore'
)
