import { useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { isNullOrUndefined } from 'lib/util'
import { usePrevious } from 'lib/hooks'

import api from 'stores/api'
import { useGeotoolsStore } from 'components/geotools'
import { useMarketDataStateStore, MarketDataSelectors } from 'pages/marketData/stateStore'

export const useSyncLocationProject = () => {
  const { t } = useTranslation()
  const [, { setLocationsGeotoolsProject }] = useMarketDataStateStore()
  const locationsGeotoolsProject = useMarketDataStateStore(
    MarketDataSelectors.$curCountryLocationsGeotoolsProject
  )
  const mainLocation = useMarketDataStateStore(MarketDataSelectors.$curCountryMainLocation)
  const curLocation = useMarketDataStateStore(MarketDataSelectors.$curCountryLocation)
  const prevLocationsGeotoolsProject = usePrevious(locationsGeotoolsProject)
  const [{ isFailure, err }, { createCollection, loadCollection, addZone }] = useGeotoolsStore()

  const curTmpProject = locationsGeotoolsProject[mainLocation]

  const createTmpProject = useCallback(() => {
    const locationGeometry = curLocation.geometry
    const locationName = curLocation.displayedName
    let newProjectId = null
    createCollection({
      locationName,
      locationGeometry,
    })
      .then((project) => {
        newProjectId = project.id
        setLocationsGeotoolsProject(mainLocation, newProjectId)
      })
      .then(() => {
        if (curLocation.addressLevel === '5' && curLocation.submarketType) {
          const area = curLocation.areas[curLocation.submarketType === 'office' ? '107' : '108']
          return api.Geometry.areas(curLocation.submarketType + 'Area', { gacs: [area.gac] })
        } else {
          return {}
        }
      })
      .then((areas) => {
        if (Array.isArray(areas?.data?.data?.features)) {
          const ids = areas.data.data.features.map((area) => area.properties.gid)
          const layer = curLocation.submarketType + 'Area'
          addZone({
            type: 'select',
            opts: { layer, ids },
            name: `${ids.length} features ${t(`geotools.select.layers.${layer}`)}`,
          })
        }
      })
  }, [curLocation, addZone, mainLocation, createCollection, setLocationsGeotoolsProject, t])

  // create a new temporary project if none exists for location
  useEffect(() => {
    if (isNullOrUndefined(curLocation)) {
      return
    }

    if (isNullOrUndefined(curTmpProject)) {
      createTmpProject()
    }
  }, [curTmpProject, curLocation, createTmpProject])

  /** fix state:
   * if there is project in AppState that has been deleted from the database
   * create a new one
   */
  useEffect(() => {
    if (isFailure && err?.response?.status === 404) {
      createTmpProject()
    }
  }, [isFailure, err, createTmpProject])

  // switch to temporary project when it changes (e.g. location change)
  useEffect(() => {
    if (!isNullOrUndefined(curTmpProject)) {
      setTimeout(() => loadCollection(curTmpProject, { noRefresh: true }))
    }
  }, [curTmpProject, loadCollection])

  // Remove deleted projects from geotools store
  useEffect(() => {
    for (const prevLocId in prevLocationsGeotoolsProject) {
      const prevProjectId = prevLocationsGeotoolsProject[prevLocId]
      const curProjectId = locationsGeotoolsProject[prevLocId]
      if (!isNullOrUndefined(prevProjectId) && isNullOrUndefined(curProjectId)) {
        loadCollection(null)
      }
    }
  }, [locationsGeotoolsProject, prevLocationsGeotoolsProject, loadCollection])

  /** Move project out of store so other pages do not have it loaded initially */
  useEffect(() => {
    return () => loadCollection(null)
  }, [loadCollection])
}
