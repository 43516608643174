/**
 * Stores state about "add zone"-functionality
 */

import { useCallback } from 'react'
import createStore from 'lib/flux-store'

const initialState = {
  popupsOpen: [],
  opts: {
    circle: { type: 'circle', center: null, radius: 1500, feature: null },
    fzr: {
      type: 'fzr',
      center: null,
      range: [300],
      rangetype: 'time',
      mode: 'car',
      isLoading: false,
      localFeatureCollection: null,
    },
    select: { type: 'select', layer: '_init', ids: [], names: [] },
    draw: { type: 'draw' },
  },
}

const actions = {
  setPopups: (popupsOpen) => ({ popupsOpen }),
  setOpts: (method, opts) => ({ method, opts }),
  toggleSelectedId: (gid, name) => ({ gid, name }),
}

const reducer = {
  setPopups: (state, { popupsOpen }) => ({ ...state, popupsOpen }),
  setOpts: (state, { method, opts }) => ({
    ...state,
    opts: { ...state.opts, [method]: { ...state.opts[method], ...opts } },
  }),
  toggleSelectedId: (state, { gid, name }) => {
    const oldIds = state.opts.select.ids
    const oldNames = state.opts.select.names

    const isActive = oldIds.includes(gid)

    let newIds
    let newNames
    if (!isActive) {
      newIds = [...oldIds, gid]
      newNames = [...oldNames, name]
    } else {
      const idx = oldIds.findIndex((el) => el === gid)
      newIds = [...oldIds.slice(0, idx), ...oldIds.slice(idx + 1)]
      newNames = [...oldNames.slice(0, idx), ...oldNames.slice(idx + 1)]
    }
    return {
      ...state,
      opts: {
        ...state.opts,
        select: {
          ...state.opts.select,
          ids: newIds,
          names: newNames,
        },
      },
    }
  },
}

const [GeotoolsDraftContext, GeotoolsDraftProvider, useGeotoolsDraftStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'GeotoolsDraftStore'
)

export { GeotoolsDraftContext, GeotoolsDraftProvider, useGeotoolsDraftStore }

/** like useGeotoolsDraftStore, but scoped to the opts of a single method */
export const useMethodStore = (method) => {
  const [state, actions] = useGeotoolsDraftStore()
  const setOpts = useCallback(
    (opts) => {
      actions.setOpts(method, opts)
    },
    [actions, method]
  )
  if (!state.opts[method]) {
    throw Error(`Geotools type "${method}" is not implemented`)
  }
  const close = useCallback(() => {
    actions.setPopups([])
  }, [actions])

  return [state.opts[method], setOpts, close]
}
