import { useCallback, useMemo } from 'react'
import { useAuthStore } from 'stores/AuthStore'

const possibleDefaultRoutes = [
  '/home',
  '/market-analysis/market-data',
  '/market-analysis/objects',
  '/market-analysis/city-structure',
  '/market-analysis/market-text',
  '/objects/comparables',
  '/objects/pipeline',
  '/makro/konjunktur',
  '/makro/propertyindices',
  '/makro/thematic-maps',
  '/apps/disco',
  '/apps/l3plus',
  '/apps/retail-markets',
  '/apps/highstreet-report',
  '/download/documents',
  '/download/raw-data',
  '/download/reports',
]

export const useAuthzChecks = () => {
  const [currentUser] = useAuthStore((state) => [state.currentUser])
  const hasLicence = useCallback(
    (key, subModule = null) => {
      const licences = (currentUser.permissions?.licence?.modules || []).concat(
        subModule !== null && currentUser.permissions?.licence?.assetClassesByModule?.[subModule]?.length
          ? currentUser.permissions.licence.assetClassesByModule[subModule]
          : currentUser.permissions?.licence?.assetClasses || []
      )
      return (
        licences.findIndex(
          (licence) =>
            licence === key || (typeof licence === 'object' && licence.key === key && licence.value)
        ) >= 0
      )
    },
    [currentUser]
  )

  const hasAdminKey = useCallback(
    (key) => {
      const adminKeys = currentUser.permissions?.admin || {}
      return adminKeys[key] || false
    },
    [currentUser]
  )

  // to open routes only for testing purposes, test for hasAdminKey('testModules')
  const routes = useMemo(() => {
    return [
      { route: '/home', access: hasLicence('marketData_small') },
      { route: '/market-analysis/key-facts', access: hasLicence('keyFacts') },
      {
        route: '/market-analysis/market-data',
        access: hasLicence('marketData_small'),
      },
      {
        route: '/market-analysis/objects',
        access: hasLicence('pipeline'),
      },
      {
        route: '/market-analysis/city-structure',
        access: hasLicence('situationalLayer'),
      },
      {
        route: '/market-analysis/market-text',
        access: hasLicence('marketText'),
      },
      {
        route: '/objects',
        access: hasLicence('comparablesSales') || hasLicence('comparablesTenant'),
      },
      {
        route: '/objects/comparables',
        access: hasLicence('comparablesSales') || hasLicence('comparablesTenant'),
      },
      {
        route: '/objects/pipeline',
        access: hasLicence('pipeline'),
      },
      {
        route: '/makro/economy',
        access: hasLicence('economy'),
      },
      {
        route: '/makro/propertyindices',
        access: hasLicence('immoIndex'),
      },
      {
        route: '/makro/thematic-maps',
        access: hasLicence('thematicMaps'),
      },
      {
        route: '/makro',
        access: hasLicence('thematicMaps'),
      },
      {
        route: '/apps/hedonic',
        access: hasLicence('prospect'),
      },
      {
        route: '/download/documents',
        access: true,
      },
      {
        route: '/download/raw-data',
        access: hasLicence('rawData'),
      },
      {
        route: '/download/reports',
        access: hasLicence('profile'),
      },
      {
        route: '/apps/disco',
        access: hasLicence('disco'),
      },
      {
        route: '/apps/l3plus',
        access: hasLicence('l3plus'),
      },
      {
        route: '/apps/retail-markets',
        access: hasLicence('retailMarkets'),
      },
      {
        route: '/apps/highstreet-report',
        access: hasLicence('highstreetReport'),
      },
      {
        route: '/apps/developer',
        access: hasLicence('developer') && hasAdminKey('testModules'),
      },
      { route: '/admin/user', access: hasAdminKey('isAdmin') },
      { route: '/admin', access: hasAdminKey('isAdmin') },
      {
        route: '/admin/group',
        access: hasAdminKey('isAdmin') && hasAdminKey('isSuperAdmin') && hasAdminKey('editGroup'),
      },
      {
        route: '/admin/role',
        access: hasAdminKey('isAdmin') && hasAdminKey('isSuperAdmin') && hasAdminKey('editRole'),
      },
      { route: '/sysadmin/i18n', access: hasAdminKey('i18nRoute') && hasAdminKey('testModules') },
      { route: '/sysadmin/error-log', access: hasAdminKey('errorLog') && hasAdminKey('testModules') },
      { route: '/sysadmin/matviews', access: hasAdminKey('isSuperAdmin') && hasAdminKey('testModules') },
      { route: '/logout', access: true },
      { route: '/export', access: true },
    ]
  }, [hasLicence, hasAdminKey])

  const hasRouteAccess = useCallback(
    (pathname) => {
      return routes.reduce(
        (access, def) => access || (pathname.indexOf(def.route) === 0 && def.access),
        false
      )
    },
    [routes]
  )

  const getDefaultRoute = useCallback(() => {
    let defaultRoute = null
    for (let i = 0; i < possibleDefaultRoutes.length && defaultRoute === null; i++) {
      const route = possibleDefaultRoutes[i]
      if (hasRouteAccess(route)) {
        defaultRoute = route
      }
    }
    return defaultRoute
  }, [hasRouteAccess])

  return { hasLicence, hasAdminKey, hasRouteAccess, getDefaultRoute }
}
