import bgagColors from 'config/colors/bgag-colors.json'

import { submarketEquivalents, submarketChartAlternative } from 'config/marketDataTopics'
// import numbersFormats from 'config/numbersFormats'

import { isRIWISCity, findAreaByMarketCategory } from 'components/controls/locationHelpers'
import {
  arraySum,
  numbersRange,
  numbersRangeQuarter,
  ColorAllocator,
  ColorCalculator,
  DataColorAllocator,
  formatQuarterTitle,
  unflattenTree,
} from 'components/UtilHelper'

const GLOBAL_ACCUMULATION_RANGE = 5
const dataSourcesCache = {}

const dashStyles = ['Solid', 'ShortDash', 'ShortDot', 'ShortDashDot', 'ShortDashDotDot']

export const getCountryCodeByGac = (gac) => {
  return typeof gac === 'undefined' || gac.match(/^[0-9]/) || gac.length < 8
    ? 'DE'
    : gac.substring(0, 2).toUpperCase()
}
// from column line combo fiddle
//   http://jsfiddle.net/BlackLabel/t6gzd8u7
const calculateColumnMetrics = (numColumns) => {
  const metrics = []
  const categoryWidth = 1
  const groupPadding = categoryWidth * 0.2
  const groupWidth = categoryWidth - 2 * groupPadding
  const pointOffsetWidth = groupWidth / numColumns

  for (let colIndex = 0; colIndex < numColumns; colIndex++) {
    const pointXOffset = groupPadding + colIndex * pointOffsetWidth - categoryWidth / 2
    metrics.push({
      width: pointOffsetWidth,
      offset: pointXOffset,
      center: Math.round((pointXOffset + pointOffsetWidth / 2.0) * 1000) / 1000,
    })
  }

  return metrics
}

export const extractDataSources = (
  topicKey,
  topicConfig,
  permissions = null,
  dataSourcesUser = null,
  countryCodes
) => {
  const includeSubmarkets = permissions === null || permissions?.licence?.modules?.includes('submarket')
  const cashKey = topicKey + countryCodes.join('-')

  if (typeof dataSourcesCache[cashKey] === 'undefined') {
    dataSourcesCache[cashKey] = topicConfig.dataSources.reduce((acc, sourceObject) => {
      const addValues = new Set()
      for (const [countryCode, source] of Object.entries(sourceObject.source)) {
        if (source === null || !countryCodes.includes(countryCode)) {
          continue
        }
        if (source?.type === 'key') {
          addValues.add(source.key)
        } else {
          if (source.type !== 'submarketOnly' || includeSubmarkets) {
            Object.entries(source).forEach(([key, value]) => {
              if (key !== 'name' && key !== 'type' && typeof value === 'string') {
                addValues.add(value)
              }
            })
          }
        }
      }
      for (const countryCode of countryCodes) {
        if (!countryCodes.includes(countryCode)) {
          continue
        }
        addValues.forEach((value) => {
          if (dataSourcesUser.includes(value)) {
            acc.push(value)
          }
          if (includeSubmarkets) {
            if (
              typeof submarketEquivalents[countryCode][value] !== 'undefined' &&
              !acc.includes(submarketEquivalents[countryCode][value]) &&
              dataSourcesUser.includes(submarketEquivalents[countryCode][value])
            ) {
              acc.push(submarketEquivalents[countryCode][value])
            }
            if (
              typeof submarketChartAlternative[countryCode][value] !== 'undefined' &&
              !acc.includes(submarketChartAlternative[countryCode][value]) &&
              dataSourcesUser.includes(submarketChartAlternative[countryCode][value])
            ) {
              acc.push(submarketChartAlternative[countryCode][value])
            }
          }
        })
      }
      return acc
    }, [])
  }
  return dataSourcesCache[cashKey]
}

const testGacs = (gac, gacs, includeSubmarkets) => {
  if (gacs === null) {
    return true
  }
  // data requested for gac of federalState is accessible if any location in this federalState is authorized!
  // Hamburg 02, Bremen 04 and Berlin 11 have to be tested on complete gac
  if (gac.length === 8 && gac.match(/0{6}$/) && !['02', '04', '11'].includes(gac.substring(0, 2))) {
    return testGacs(gac.substring(0, 2), gacs, includeSubmarkets)
  }
  const isSubmarketGac = gac.length > 8
  return gacs.some((testGac) => {
    const testIsSubmarketGac = testGac.startsWith('sub')
    if (isSubmarketGac !== testIsSubmarketGac || (isSubmarketGac && !includeSubmarkets)) {
      return false
    }
    if (typeof testGac === 'string') {
      if (testIsSubmarketGac) {
        testGac = testGac.substring(3)
      }
      testGac = gac.length === 2 ? testGac.substring(0, 2) : testGac
      return gac.startsWith(testGac)
    }
    return false
  })
}

export const findFittingType = (feature, topicKey, topicConfig, permissions = null, availableGacs = null) => {
  let fittingType = null

  const includeSubmarkets = permissions === null || permissions?.licence?.modules?.includes('submarket')
  const availableAreas = Object.values(feature.areas).filter((area) =>
    testGacs(area.gac, availableGacs, includeSubmarkets)
  )

  if (!Array.isArray(topicConfig.availableAreaTypes)) {
    console.error('Property availableAreaTypes is missing on topic: ', topicKey)
    return fittingType
  }
  if (!Array.isArray(topicConfig.availableAreaFlags)) {
    console.error('Property availableAreaFlags is missing on topic: ', topicKey)
    return fittingType
  }

  const topicsAreaTypes = topicConfig.availableAreaTypes.map((type) => Number(type))
  const topicsAreaFlags = [...topicConfig.availableAreaFlags]
  const featuresAreaTypes = availableAreas.map((area) => Number(area.type))

  if (topicConfig.isQuarter) {
    // Quarter
    const foundACity = availableAreas.find((area) => area.market_category === 'A')
    if (foundACity) fittingType = foundACity.type
  } else if (topicConfig.isForecast) {
    // Forecast
    if (topicConfig.availableForCityTypes.length) {
      const foundResult = availableAreas.find((area) =>
        topicConfig.availableForCityTypes.includes(area.market_category)
      )
      if (foundResult) fittingType = foundResult.type
    } else if (topicConfig.availableForAreaTags.includes('office_forecast_available')) {
      const foundResult = availableAreas.find((area) => area.office_forecast_available === true)
      if (foundResult) fittingType = foundResult.type
    } else if (topicConfig.availableForAreaTags.includes('retail_forecast_available')) {
      const foundResult = availableAreas.find((area) => area.retail_forecast_available === true)
      if (foundResult) fittingType = foundResult.type
    } else {
      const availableTypes = featuresAreaTypes.filter((type) => topicsAreaTypes.includes(type))
      fittingType = !availableTypes.length ? null : String(Math.min(...availableTypes))
    }
  } else {
    // Normal (not quarter or forecast)
    const identifier = feature.identifier
    const addressLevel = feature.addressLevel || identifier
    const availableTypes = featuresAreaTypes.filter((type) => {
      let available
      if (Array.isArray(topicConfig.availableForAreaTags) && topicConfig.availableForAreaTags.length) {
        available =
          topicConfig.availableForAreaTags.some((tag) => {
            return availableAreas.some((area) => Number(area.type) === type && area[tag])
          }) ||
          (type > 100 && topicsAreaTypes.includes(type))
      } else {
        available = topicsAreaTypes.includes(type)
      }
      return available
    })

    if (parseInt(addressLevel) < 10 && includeSubmarkets && availableTypes.some((type) => type > 100)) {
      fittingType = !availableTypes.length ? null : String(Math.max(...availableTypes))
    } else if (
      (feature.districtRelatedRiwisCity &&
        topicsAreaFlags.includes('districtRelatedRiwisCity' + feature.properties.countryCode)) ||
      (topicConfig.regionalDataGranular &&
        feature.properties.countryCode === 'DE' &&
        featuresAreaTypes.includes(10))
    ) {
      fittingType = '10'
    } else if (topicConfig.regionalDataAvailable && findAreaByMarketCategory(feature, 'R')) {
      // feature includes an area, which is NOT a riwis city (a|b|c|d) and has flag market_data_regional or type 30
      const type = findAreaByMarketCategory(feature, 'R').type
      if (availableTypes.includes(Number(type))) {
        fittingType = type
      }
    } else if (topicsAreaTypes.includes(Number(identifier)) && availableTypes.includes(Number(identifier))) {
      fittingType = identifier
    } else {
      fittingType = !availableTypes.length ? null : String(Math.min(...availableTypes))
    }
  }
  return fittingType
}

export const findFittingAreaType = (location, fittingType) => {
  const area = location?.areas?.[fittingType]
  if (area) {
    if (area.gac.match(/^at/)) {
      if (area.market_category === 'AT') {
        return 'riwisCitiesAustria'
      }
      return 'districtWithoutRiwisCitiesAustria'
    } else {
      if (parseInt(fittingType) > 100) {
        return 'riwisCityA'
      }
      if (area.market_data_regional === true && !isRIWISCity(area.market_category)) {
        return 'riwisCitiesRegio'
      }
      if (area.market_category !== null && area.market_category.match(/^[A-D]$/)) {
        return 'riwisCity' + area.market_category
      }
      return 'districtWithoutRiwisCities'
    }
  }
  return null
}

export const transformQuarterResponse = (resData) => {
  const testDate = new RegExp(/^[0-9]+$/)
  return Object.entries(resData).reduce((acc, [gac, data]) => {
    const transformedData = Object.entries(data).reduce((acc, [date, rows]) => {
      let key
      if (testDate.exec(date)) {
        key = date
      } else {
        date = new Date(date)
        key = date.getFullYear() + '-' + Math.ceil((date.getMonth() + 1) / 3)
      }
      acc[key] = rows
      return acc
    }, {})
    acc[gac] = transformedData
    return acc
  }, {})
}

export const setTopicColors = ({ config, locations = null, locationData = {} }) => {
  const colors = []
  if (locations) {
    const countryCodes = Array.from(new Set(Object.values(locations).map((gac) => getCountryCodeByGac(gac))))
    if (countryCodes.length > 1) {
      console.warn('Patch neededed to support multiple countries for topicColors')
    }
    config.chartViews[countryCodes[0]].forEach((chartView, chartViewIndex) => {
      let indexLine = 0
      chartView.yAxis.forEach((yAxisObject, yAxisIndex) => {
        let indexColumn = 0
        let colorAllocatorIndex = null
        let colorLineIndex = null

        const series = yAxisObject.series || [
          {
            seriesType: yAxisObject.seriesType || 'column',
            sources: yAxisObject.axisSources,
          },
        ]

        series.forEach((serie, serieIndex) => {
          if (serie.seriesType === 'columnrange') {
            colorAllocatorIndex = indexColumn
            colorLineIndex = null
            indexColumn++
          }
          serie.sources.forEach((dataSourceID, dataSourceIndex) => {
            if (serie.seriesType === 'line') {
              colorAllocatorIndex = 2
              colorLineIndex = indexLine
              indexLine++
            } else if (
              serie.seriesType === 'column' ||
              (serie.seriesType === 'scatter' && !serie.recalcXPositions)
            ) {
              colorAllocatorIndex = indexColumn
              colorLineIndex = null
              indexColumn++
            }
            Object.entries(locations).forEach(([locationID, gac]) => {
              const isSubmarketGac = typeof gac === 'string' && gac.length > 8
              const countryCode = getCountryCodeByGac(gac)
              let altId = -1
              if (isSubmarketGac) {
                // the given dataSourceId for this chart might be replaced in the table by an alternative
                // if so, here we find out and set the appropriate source id for the topic-colors:
                const source = config.dataSources[dataSourceID].source
                const altSource = submarketChartAlternative[countryCode][source]
                if (altSource) {
                  altId = config.dataSources.findIndex((item) => {
                    let source = item.source
                    if (typeof source === 'object' && source.type === 'submarketOnly') {
                      source = source.source
                    }
                    return source === altSource
                  })
                }
              }
              colors.push({
                chartViewIndex,
                locationID: parseInt(locationID),
                dataSourceID: dataSourceID,
                allocatorIndex: colorAllocatorIndex,
                lineIndex: colorLineIndex,
                altDataSourceID: altId >= 0 ? altId : null,
                color: yAxisObject.dataColors
                  ? DataColorAllocator(dataSourceIndex, yAxisObject.dataColors)
                  : locationData[locationID].baseColor !== null
                  ? ColorCalculator(locationData[locationID].baseColor, colorAllocatorIndex)
                  : ColorAllocator(locationID, colorAllocatorIndex),
              })
            })
          })
        })
      })
    })
  }
  return colors
}

const numDigits = (x) => (Math.log10((x ^ (x >> 31)) - (x >> 31)) | 0) + 1
const getDivider = (val) => {
  const length = numDigits(val)
  return length > 1 ? 10 ** (length - 1) : 1
}

export const calcAxisBorders = (min, max) => {
  const minDivisor = getDivider(min)
  const maxDivisor = getDivider(max)

  let bottom = Math.floor(min / minDivisor) * minDivisor
  let top = Math.ceil(max / maxDivisor) * maxDivisor

  if (min / bottom < 1.1) bottom = Math.floor(bottom * 0.9)
  if (top / max < 1.06) top = Math.ceil(top * 1.06)

  return [bottom, top]
}

const getValue = (gac, year, choosenSource, cluster) => {
  if (!cluster[gac] || !cluster[gac][year]) return null

  return typeof cluster[gac][year][choosenSource] === 'undefined' ? null : cluster[gac][year][choosenSource]
}

const getSubmarketEquivalent = (isSubmarketGac, source, countryCode) => {
  if (!isSubmarketGac) {
    return source
  }
  return submarketEquivalents[countryCode][source]
}

const computeNotPossible = (parts) => {
  return parts.some((part) => part === null || isNaN(part))
}
const compute = (isSubmarketGac, dataYear, computationObject, dataSources, countryCode) => {
  if (computationObject.type === 'addition') {
    const summand1 = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.summand1 === 'number'
        ? dataSources[computationObject.summand1].source[countryCode].key
        : computationObject.summand1,
      countryCode
    )
    const summand2 = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.summand2 === 'number'
        ? dataSources[computationObject.summand2].source[countryCode].key
        : computationObject.summand2,
      countryCode
    )
    if (computeNotPossible([dataYear[summand1] === null, dataYear[summand2] === null])) return null
    return dataYear[summand1] + dataYear[summand2]
  }
  if (computationObject.type === 'subtraction') {
    const minuend = getSubmarketEquivalent(
      isSubmarketGac,
      dataSources[computationObject.minuend].source[countryCode].key,
      countryCode
    )
    const substrahend = getSubmarketEquivalent(
      isSubmarketGac,
      dataSources[computationObject.substrahend].source[countryCode].key,
      countryCode
    )
    if (computeNotPossible([dataYear[minuend] === null, dataYear[substrahend] === null])) return null
    return dataYear[minuend] - dataYear[substrahend]
  }
  if (computationObject.type === 'presummed-division') {
    const summand1 = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.summand1 === 'number'
        ? dataSources[computationObject.summand1].source[countryCode].key
        : computationObject.summand1,
      countryCode
    )
    const summand2 = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.summand2 === 'number'
        ? dataSources[computationObject.summand2].source[countryCode].key
        : computationObject.summand2,
      countryCode
    )
    const divisor = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.divisor === 'number'
        ? dataSources[computationObject.divisor].source[countryCode].key
        : computationObject.divisor,
      countryCode
    )

    if ((dataYear[summand1] === 0 && dataYear[summand2]) === 0 || dataYear[divisor] === 0) return 0
    if (
      (computeNotPossible([dataYear[summand1]]) && computeNotPossible([dataYear[summand2]])) ||
      computeNotPossible([dataYear[divisor]])
    )
      return null

    let result = (dataYear[summand1] + dataYear[summand2]) / dataYear[divisor]
    if (computationObject.multiplicator) result *= computationObject.multiplicator
    return Math.round(result * 10) / 10 // 1 Nachkommastelle
  }
  if (computationObject.type === 'division') {
    const dividend = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.dividend === 'number'
        ? dataSources[computationObject.dividend].source[countryCode].key
        : computationObject.dividend,
      countryCode
    )
    const divisor = getSubmarketEquivalent(
      isSubmarketGac,
      typeof computationObject.divisor === 'number'
        ? dataSources[computationObject.divisor].source[countryCode].key
        : computationObject.divisor,
      countryCode
    )
    if (dataYear[dividend] === 0 || dataYear[divisor] === 0) return 0
    if (computeNotPossible([dataYear[dividend], dataYear[divisor]])) return null

    let result = dataYear[dividend] / dataYear[divisor]
    if (computationObject.multiplicator) result *= computationObject.multiplicator
    return Math.round(result * 10) / 10 // 1 Nachkommastelle
  }
}

export const setClusterComputedValues = ({
  key,
  dataSources,
  cluster,
  forcedIsSubmarket = null,
  time,
  topicConfig,
}) => {
  if (key === 'office_space_by_yr_of_completion') {
    const [timeUnits] = getTimeUnits(topicConfig, time)
    Object.keys(cluster).forEach((gac) => {
      const countryCode = getCountryCodeByGac(gac)
      const schema = `data_${gac.length >= 8 ? countryCode.toLowerCase() : 'de'}`
      const clusterKeyAbsolut = `computed.${schema}.virtual.officeSpaceByYearOfComplAbsolut`
      const clusterKeyPercent = `computed.${schema}.virtual.officeSpaceByYearOfComplPercent`
      let sourceNet = `${schema}.md_office_market.space_net`
      let sourceTotal = `${schema}.md_office_market.space_new_total`

      if (forcedIsSubmarket || (forcedIsSubmarket === null && gac.length > 8)) {
        sourceNet = submarketEquivalents[countryCode][sourceNet]
        sourceTotal = submarketEquivalents[countryCode][sourceTotal]
      }

      const sumSpaceNewTotal = Object.entries(cluster[gac]).reduce((sum, [year, values]) => {
        sum += values?.[sourceTotal] ?? 0
        return sum
      }, 0)

      let locationMaxYear = time.maxYear
      while (
        computeNotPossible([cluster?.[gac]?.[locationMaxYear]?.[sourceNet]]) &&
        locationMaxYear >= parseInt(Object.keys(cluster[gac])[0])
      ) {
        locationMaxYear--
      }
      const spaceAbsolutOlder1990 = computeNotPossible([cluster?.[gac]?.[locationMaxYear]?.[sourceNet]])
        ? null
        : cluster[gac][locationMaxYear][sourceNet] - sumSpaceNewTotal

      timeUnits.forEach((unit, index) => {
        const [from, to] =
          index === 0 ? [null, unit] : index === timeUnits.length - 1 ? [unit, time.maxYear] : unit.split('-')

        if (typeof cluster[gac][unit] === 'undefined') {
          cluster[gac][unit] = {}
        }
        cluster[gac][unit][clusterKeyAbsolut] =
          from === null
            ? spaceAbsolutOlder1990
            : numbersRange(parseInt(from), parseInt(to)).reduce((sum, year) => {
                if (computeNotPossible([sum, cluster[gac][year][sourceTotal]])) {
                  return null
                }
                return sum + cluster[gac][year][sourceTotal]
              }, 0)

        if (
          computeNotPossible([
            cluster[gac][unit][clusterKeyAbsolut],
            cluster?.[gac]?.[locationMaxYear]?.[sourceNet],
          ])
        ) {
          cluster[gac][unit][clusterKeyPercent] = null
        } else {
          cluster[gac][unit][clusterKeyPercent] =
            (cluster[gac][unit][clusterKeyAbsolut] / cluster[gac][locationMaxYear][sourceNet]) * 100
        }
      })
    })
  } else {
    dataSources.forEach((dataSource) => {
      Object.keys(cluster).forEach((gac) => {
        const countryCode = gac.length >= 8 ? getCountryCodeByGac(gac) : 'DE'
        const source = dataSource.source[countryCode]
        if (source !== null && source.type !== 'key' && source.type !== 'submarketOnly') {
          Object.keys(cluster[gac]).forEach((year) => {
            cluster[gac][year]['computed.' + source.name] = compute(
              forcedIsSubmarket !== null ? forcedIsSubmarket : gac.length > 8,
              cluster[gac][year],
              source,
              dataSources,
              countryCode
            )
          })
        }
      })
    })
  }
}

const hasSubmarketEquivalents = (source, dataSources, countryCode) => {
  let hasEquivalents = true
  for (let key in source) {
    if (key !== 'type' && key !== 'multiplicator' && key !== 'name') {
      const testKey =
        typeof source[key] === 'string' ? source[key] : dataSources[source[key]].source[countryCode]
      hasEquivalents =
        hasEquivalents &&
        ((typeof testKey === 'object' && hasSubmarketEquivalents(testKey, dataSources, countryCode)) ||
          (typeof testKey === 'string' && typeof submarketEquivalents[countryCode][testKey] !== 'undefined'))
    }
  }
  return hasEquivalents
}

export const getTimeUnits = (topicConfig, time) => {
  let timeUnits = []
  let timeUnitTitles
  if (topicConfig.key === 'office_space_by_yr_of_completion') {
    timeUnits.push(time.from.toString())
    let cur = time.from - 1
    while (cur + 5 <= time.to) {
      timeUnits.push(cur + 1 + '-' + (cur + 5))
      cur += 5
    }
    timeUnits.push((cur + 1).toString())
    timeUnitTitles = timeUnits.map((unit, index) =>
      index === 0 ? 'vor ' + unit : index === timeUnits.length - 1 ? 'ab ' + unit : unit
    )
  } else if (topicConfig.isQuarter) {
    timeUnits = numbersRangeQuarter(time.from, time.to, time.maxMonth, time.maxYear)
    timeUnitTitles = formatQuarterTitle(timeUnits, true)
  } else {
    timeUnits = numbersRange(time.from, time.to)
    timeUnitTitles = timeUnits
  }
  return [timeUnits, timeUnitTitles]
}

export const generateChartData = ({
  topicConfig,
  locations,
  time,
  cluster,
  clusterReference,
  colors,
  chartView,
  t,
}) => {
  const [years] = getTimeUnits(topicConfig, time)

  const countryCodes = Array.from(
    Object.values(locations).reduce((set, gac) => {
      set.add(getCountryCodeByGac(gac))
      return set
    }, new Set())
  )

  let newChartData = []
  if (chartView === undefined) return newChartData

  if (countryCodes.length > 1) {
    console.warn('Patch neededed to support multiple countries in one Chart')
    return newChartData
  }

  const chartViewConfig = topicConfig.chartViews[countryCodes[0]][chartView]
  const showReferenceCurve = chartViewConfig?.showReferenceCurve ?? true

  chartViewConfig.yAxis.forEach((yAxisObject, yAxisIndex) => {
    let newYAxis = {
      id: `yAxis${chartView}_${yAxisIndex}`,
      title: yAxisObject.axisLabel ? t(`marketData:axisLabels.${yAxisObject.axisLabel}`) : '',
      opposite: yAxisIndex === 0 ? false : true,
      labels: {
        format: yAxisObject.pointLabel || '{value}',
      },
      series: [],
    }
    let yAxisMinValue = undefined
    let yAxisMaxValue = undefined

    const series = yAxisObject.series || [
      {
        seriesType: yAxisObject.seriesType,
        sources: yAxisObject.axisSources,
      },
    ]
    const recalcPositionSeriesLength = series.reduce((length, serie) => {
      if (serie.seriesType !== 'scatter' || !serie.recalcXPositions) {
        length++
      }
      return length
    }, 0)

    let columnMetrics = []
    const locationEntries = Object.entries(locations)

    locationEntries.forEach(([locationID, gac], locationIndex) => {
      const countryCode = getCountryCodeByGac(gac)
      let recalcPositionSeriesIndex = 0
      series.forEach((serie) => {
        serie.sources.forEach((dataSourceID, dataSourceIndex) => {
          const source = topicConfig.dataSources[dataSourceID].source[countryCode]
          if (source === null) {
            return
          }
          const sourceName = source.key || source.name
          const { multiplicator } = topicConfig.dataSources[dataSourceID]

          const shiftValue = (val) => (multiplicator ? (val *= multiplicator) : val)

          const topicGacsLength = locationEntries.length

          // Check if gac is submarket because of length > 8 ...
          const isSubmarketGac = gac.length > 8
          let choosenSource
          if (isSubmarketGac && typeof submarketEquivalents[countryCode][source.key] !== 'undefined') {
            choosenSource = submarketEquivalents[countryCode][source.key]
          } else if (
            isSubmarketGac &&
            typeof submarketChartAlternative[countryCode][source.key] !== 'undefined'
          ) {
            choosenSource = submarketChartAlternative[countryCode][source.key]
          } else if (typeof source.key === 'string') {
            choosenSource = source.key
          } else {
            choosenSource = 'computed.' + source.name
          }

          if (
            isSubmarketGac &&
            !hasSubmarketEquivalents(source, topicConfig.dataSources, countryCode) &&
            typeof submarketChartAlternative[countryCode][source.key] === 'undefined'
          ) {
            // .... and avoid empty rows where dataSource hasnt a submarket flag
            return
          } else {
            const firstLocationSeries = newYAxis.series.find((serie) =>
              serie.id.includes(`location${locationID}`)
            )
            const columnrangeParent = newYAxis.series.find(
              (serie) =>
                serie.id.includes(`location${locationID}`) &&
                serie.type === 'columnrange' &&
                serie.dataSources.length === 1
            )

            const color = colors.find((color) => {
              return (
                color.chartViewIndex === parseInt(chartView) &&
                color.locationID === parseInt(locationID) &&
                color.dataSourceID === parseInt(dataSourceID)
              )
            })
            let newSerie = {}
            if (serie.seriesType === 'columnrange' && columnrangeParent) {
              columnrangeParent.data = years.map((year, index) => {
                const value = shiftValue(getValue(gac, year, choosenSource, cluster))
                const parentValue = columnrangeParent.data[index]

                // if any value is null, the tooltip gets not positioned properly
                let first = parentValue
                let second = value
                if (typeof first !== 'number') {
                  columnrangeParent.zeroToNull = true
                  first = 0
                }
                if (typeof second !== 'number') {
                  columnrangeParent.zeroToNull = true
                  second = 0
                }

                return [first, second]
              })
              columnrangeParent.dataSources.push(dataSourceID)
              columnrangeParent.dataSourcesKeys.push(sourceName)
            } else {
              newSerie = {
                id: `chartView${chartView}-dataSource${dataSourceID}-location${locationID}`,
                yAxis: `yAxis${chartView}_${yAxisIndex}`,
                type: serie.seriesType || 'column',
                dashStyle: dashStyles[color?.lineIndex] || 'Solid',
                zIndex:
                  serie.seriesType === 'line'
                    ? dataSourceIndex + 10
                    : serie.seriesType === 'scatter'
                    ? dataSourceIndex + 20
                    : dataSourceIndex,
                linkedTo: topicGacsLength > 0 && firstLocationSeries ? firstLocationSeries.id : undefined,
                color: color ? color.color : null,
                gac: gac,
                locationID: locationID,
                data: years.map((year, index) => {
                  let value
                  let reassign
                  if (
                    yAxisObject.sourcesDisplayFirstOnlyIfSecondIsNull &&
                    (reassign = yAxisObject.sourcesDisplayFirstOnlyIfSecondIsNull.find(
                      (item) => item[0] === dataSourceID
                    )) &&
                    reassign
                  ) {
                    // exception to allow proper display of percentage columns for gross_value_added
                    // some locations (e.g. Bremen) only have "dienstleistungsgewerbe" for some years, whereas most have 3 subcategories of "dienstleistungsgewerbe"
                    const isNull = reassign[1].some((sourceId) => {
                      const source = topicConfig.dataSources[sourceId].source[countryCode]
                      const sourceName = source.key || source.name
                      return (cluster?.[gac]?.[year]?.[sourceName] ?? null) === null
                    })
                    value = isNull ? shiftValue(getValue(gac, year, choosenSource, cluster)) : null
                  } else {
                    value = shiftValue(getValue(gac, year, choosenSource, cluster))
                  }
                  if (serie.recalcXPositions) {
                    if (!columnMetrics.length) {
                      columnMetrics = calculateColumnMetrics(topicGacsLength * recalcPositionSeriesLength)
                    }
                    return {
                      x:
                        index +
                        columnMetrics[locationIndex * recalcPositionSeriesLength + recalcPositionSeriesIndex]
                          .center,
                      y: value,
                      year,
                    }
                  } else {
                    return value
                  }
                }),
                dataSources: [dataSourceID],
                dataSourcesKeys: [sourceName],
                isSubmarketGac,
              }
              if (yAxisObject.yAxisAutoShrink === true) {
                if (typeof yAxisMinValue === 'undefined' || Math.min(...newSerie.data) < yAxisMinValue) {
                  yAxisMinValue = Math.min(...newSerie.data)
                }
                if (typeof yAxisMaxValue === 'undefined' || Math.max(...newSerie.data) > yAxisMaxValue) {
                  yAxisMaxValue = Math.max(...newSerie.data)
                }
              }
              newYAxis.series.push(newSerie)
            }
          }
        })
        if (serie.seriesType === 'scatter' && serie.recalcXPositions) {
          recalcPositionSeriesIndex++
        }
      })
    })

    showReferenceCurve &&
      Object.keys(clusterReference).forEach((cityType) => {
        yAxisObject.axisSources.forEach((dataSourceID, dataSourceIndex) => {
          // Patch needed: Reference Curve right now only available für ABCD city types
          const source = topicConfig.dataSources[dataSourceID].source['DE']
          const { multiplicator } = topicConfig.dataSources[dataSourceID]

          const shiftValue = (val) => (multiplicator ? (val *= multiplicator) : val)

          const firstLocationSeries = newYAxis.series.find((serie) =>
            serie.id.includes(`location${cityType}`)
          )

          const colorObj = colors.find((color) => {
            return (
              color.chartViewIndex === parseInt(chartView) && color.dataSourceID === parseInt(dataSourceID)
            )
          })

          const colorKeys = ['main', 'dark', 'bright']
          let color
          let dashStyle

          if (colorObj.lineIndex !== null) {
            color = bgagColors['bgag-orange'].main
            dashStyle = dashStyles[colorObj.lineIndex]
          } else {
            color = bgagColors['bgag-orange'][colorKeys[colorObj.allocatorIndex]]
            dashStyle = dashStyles[0]
          }

          const choosenSource = source.key || 'computed.' + source.name

          const newSerie = {
            id: `chartView${chartView}-dataSource${dataSourceID}-location${cityType}`,
            yAxis: `yAxis${chartView}_${yAxisIndex}`,
            type: 'line',
            dashStyle,
            zIndex: dataSourceIndex + 10,
            linkedTo: firstLocationSeries ? firstLocationSeries.id : undefined,
            color,
            locationID: cityType,
            cityType,
            data: years.map(
              (year, index) => shiftValue(getValue(cityType, year, choosenSource, clusterReference)) || null
            ),
            dataSources: [dataSourceID],
            dataSourcesKeys: [choosenSource],
          }
          if (yAxisObject.yAxisAutoShrink === true) {
            if (typeof yAxisMinValue === 'undefined' || Math.min(...newSerie.data) < yAxisMinValue) {
              yAxisMinValue = Math.min(...newSerie.data)
            }
            if (typeof yAxisMaxValue === 'undefined' || Math.max(...newSerie.data) > yAxisMaxValue) {
              yAxisMaxValue = Math.max(...newSerie.data)
            }
          }
          newYAxis.series.push(newSerie)
        })
      })

    if (yAxisObject.yAxisAutoShrink === true) {
      if (yAxisMinValue || yAxisMaxValue) {
        const [axisBottom] = calcAxisBorders(yAxisMinValue, yAxisMaxValue)
        if (typeof axisBottom === 'number') {
          newYAxis.min = axisBottom
        }
        // newYAxis.max = axisTop
      }
    } else {
      newYAxis.maxPadding = 0.2
    }
    newChartData.push(newYAxis)
  })

  return newChartData
}

const generateEmptyRowDataSources = (dataSourcesFiltered, countryCodes) => {
  if (countryCodes.length > 1) {
    console.warn('Patch neededed to support multiple countries in one Table with "locations inside"')
  }

  return dataSourcesFiltered.map((dataSource) => ({
    key: `dataSource${dataSource.sourceId}`,
    locationID: null,
    dataSourceID: dataSource.sourceId,
    parentId: null,
    rowHeader: countryCodes
      .filter((countryCode) => typeof dataSource.source[countryCode] !== 'undefined')
      .map((countryCode) => dataSource.source[countryCode].key || dataSource.source[countryCode].name)
      .join('|'),
  }))
}

const generateEmptyRowLocations = (locations) =>
  Object.entries(locations).map(([id, gac]) => ({
    key: `location${id}`,
    color: ColorAllocator(id),
    locationID: id,
    dataSourceID: null,
    parentId: null,
    rowHeader: gac,
  }))

const generateEmptyRowReferences = (references) =>
  references.map((cityType) => ({
    key: `location${cityType}`,
    color: bgagColors['bgag-orange'].main,
    locationID: cityType,
    dataSourceID: null,
    parentId: null,
    rowHeader: cityType,
  }))

export const generateTableData = ({
  topicConfig,
  locations,
  time,
  cluster,
  clusterReference,
  colors,
  chartView,
  axisSource,
  locationsInside,
  numbersFormats,
}) => {
  const countryCodes = Array.from(new Set(Object.values(locations).map((gac) => getCountryCodeByGac(gac))))
  const dataSources = topicConfig.dataSources
  const dataSourcesFiltered = dataSources
    .map((dataSource, index) => {
      dataSource.sourceId = index
      return dataSource
    })
    .filter((dataSource) => !dataSource.tableHidden)

  let timeIdentifier = dataSources.xAxis || 'year'

  const [timeUnits, timeUnitTitles] = getTimeUnits(topicConfig, time)

  const timeColumns = timeUnits.map((timeUnit, idx) => ({
    key: `${timeIdentifier}-${timeUnit}`,
    dataKey: `${timeIdentifier}-${timeUnit}`,
    title: timeUnitTitles[idx],
    fixed: idx === timeUnits.length - 1 ? 'right' : idx === 0 ? true : false,
    align: 'center',
    flexGrow: 1,
  }))
  const columns = [
    {
      key: `rowHeader`,
      dataKey: `rowHeader`,
      title: '',
      fixed: true,
      colSpan: 2,
      flexGrow: 3,
      minWidth: 240,
    },
    ...timeColumns,
  ]

  // Add accumulation column  if any dataSource has flag
  if (dataSourcesFiltered.some((sourceObject) => sourceObject.accumulation))
    columns.push({
      key: `accumulation`,
      dataKey: `accumulation`,
      title: `5J-Entw.`,
      align: 'center',
      minWidth: 90,
      fixed: 'right',
      flexGrow: 1,
    })

  // Generate empty rows for headline rows (e.g. "Nürnberg -")
  const emptyRows = locationsInside
    ? generateEmptyRowDataSources(dataSourcesFiltered, countryCodes)
    : generateEmptyRowLocations(locations).concat(generateEmptyRowReferences(Object.keys(clusterReference)))

  const dataRows = dataSourcesFiltered.flatMap((dataSource) => {
    const { multiplicator, numbersFormat, accumulation } = dataSource

    const formatValueNew = (val) => {
      if (val === null) return null
      if (multiplicator) val *= multiplicator
      if (numbersFormat?.includes('percentage')) val = val / 100
      if (numbersFormat) val = numbersFormats[numbersFormat].format(val)
      return val
    }

    const calcAccumulationQuarter = (key, choosenSource, cluster) => {
      if (!accumulation.type || !accumulation.type) return null
      if (accumulation.type === 'average') {
        let values = timeUnits
          .slice(-(GLOBAL_ACCUMULATION_RANGE * 4))
          .map((year) => getValue(key, year, choosenSource, cluster))
        return values.length > 0 && 'Ø ' + formatValueNew(arraySum(values) / values.length)
      } else if (accumulation.type === 'delta') {
        const startKey = timeUnits[timeUnits.length - 1 - GLOBAL_ACCUMULATION_RANGE * 4]
        const endKey = timeUnits[timeUnits.length - 1]
        let startValue = getValue(key, startKey, choosenSource, cluster)
        let endValue = getValue(key, endKey, choosenSource, cluster)
        if (!startValue || !endValue) return null
        return 'Δ ' + numbersFormats['percentageFraction1'].format(endValue / startValue - 1)
      }
    }

    const calcAccumulation = (key, endYear, choosenSource, cluster) => {
      if (!accumulation.type || !accumulation.type) return null
      if (accumulation.type === 'average') {
        let values = timeUnits
          .slice(-GLOBAL_ACCUMULATION_RANGE)
          .map((year) => getValue(key, year, choosenSource, cluster))
        return values.length > 0 && 'Ø ' + formatValueNew(arraySum(values) / values.length)
      } else if (accumulation.type === 'delta') {
        let startValue = getValue(key, endYear - GLOBAL_ACCUMULATION_RANGE, choosenSource, cluster)
        let endValue = getValue(key, endYear, choosenSource, cluster)
        if (!startValue || !endValue) return null
        return 'Δ ' + numbersFormats['percentageFraction1'].format(endValue / startValue - 1)
      }
    }

    return (
      Object.entries(locations)
        .map(([id, gac]) => {
          const countryCode = getCountryCodeByGac(gac)
          const source = dataSource.source[countryCode]
          if (source === null) {
            return null
          }
          // Check if gac is submarket because of length > 8 ...
          const isSubmarketGac = gac.length > 8
          if (
            isSubmarketGac &&
            !hasSubmarketEquivalents(dataSource.source[countryCode], dataSources, countryCode)
          ) {
            // .... and avoid empty rows where dataSource hasnt a submarket flag
            return null
          } else {
            let choosenSource
            if (isSubmarketGac && typeof submarketEquivalents[countryCode][source.key] !== 'undefined') {
              choosenSource = submarketEquivalents[countryCode][source.key]
            } else if (source.type === 'key' || source.type === 'submarketOnly') {
              choosenSource = source.key
            } else {
              choosenSource = 'computed.' + source.name
            }
            const titleSource =
              isSubmarketGac && typeof submarketEquivalents[countryCode][source.key] !== 'undefined'
                ? submarketEquivalents[countryCode][source.key]
                : source.key || source.name

            const useSourceSelector =
              topicConfig.chartViews[countryCode][chartView].useAxisSourceSelector || false
            let color = colors.find((color) => {
              return (
                color.chartViewIndex === parseInt(chartView) &&
                (!useSourceSelector || color.dataSourceID === axisSource) &&
                color.locationID === parseInt(id) &&
                ((color.altDataSourceID === null && color.dataSourceID === parseInt(dataSource.sourceId)) ||
                  (color.altDataSourceID !== null && color.altDataSourceID === parseInt(dataSource.sourceId)))
              )
            })
            color = color ? color.color : 'initial'

            return timeUnits.reduce(
              (rowObject, timeUnit) => {
                let value = getValue(gac, timeUnit, choosenSource, cluster)
                rowObject[`${timeIdentifier}-${timeUnit}`] = formatValueNew(value)
                return rowObject
              },
              {
                key: locationsInside
                  ? `dataSource${dataSource.sourceId}-location${id}`
                  : `location${id}-dataSource${dataSource.sourceId}`,
                locationID: id,
                dataSourceID: dataSource.sourceId,
                parentId: locationsInside ? `dataSource${dataSource.sourceId}` : `location${id}`,
                rowHeader: locationsInside ? gac : titleSource,
                accumulation: accumulation
                  ? topicConfig.isQuarter
                    ? calcAccumulationQuarter(gac, choosenSource, cluster)
                    : calcAccumulation(gac, time.to, choosenSource, cluster)
                  : null,
                color: color,
              }
            )
          }
        })
        .concat(
          Object.keys(clusterReference).map((cityType) => {
            const countryCode = 'DE'
            // Patch needed: Reference Curve right now only available für ABCD city types
            let hasColor = colors.find((color) => {
              return (
                color.chartViewIndex === parseInt(chartView) &&
                color.dataSourceID === parseInt(dataSource.sourceId)
              )
            })
            let color
            if (hasColor) {
              color =
                hasColor.allocatorIndex === 0
                  ? bgagColors['bgag-orange'].main
                  : bgagColors['bgag-orange'].dark
            } else {
              color = 'initial'
            }

            const source = dataSource.source[countryCode]
            const sourceKey = source.key || 'computed.' + source.name

            return timeUnits.reduce(
              (rowObject, timeUnit) => {
                let value = getValue(cityType, timeUnit, sourceKey, clusterReference)
                rowObject[`${timeIdentifier}-${timeUnit}`] = formatValueNew(value)
                return rowObject
              },
              {
                key: locationsInside
                  ? `dataSource${dataSource.sourceId}-location${cityType}`
                  : `location${cityType}-dataSource${dataSource.sourceId}`,
                locationID: cityType,
                dataSourceID: dataSource.sourceId,
                parentId: locationsInside ? `dataSource${dataSource.sourceId}` : `location${cityType}`,
                rowHeader: locationsInside ? cityType : source.key || source.name,
                accumulation: accumulation
                  ? topicConfig.isQuarter
                    ? calcAccumulationQuarter(cityType, sourceKey, clusterReference)
                    : calcAccumulation(cityType, time.to, sourceKey, clusterReference)
                  : null,
                color,
              }
            )
          })
        )
        // Filter completely empty row objects:
        .filter((rowObject) => {
          return rowObject !== null
        })
        // Filter row objects with only null values:
        .filter((rowObject) =>
          Object.entries(rowObject).some(
            ([key, value]) => timeUnits.some((unit) => `${timeIdentifier}-${unit}` === key) && value !== null
          )
        )
    )
  })
  return {
    columns: columns,
    data: unflattenTree([...emptyRows, ...dataRows]),
    defaultExpanded: emptyRows.map((row) => row.key),
  }
}
