import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Box, { Flex } from 'components/atoms/Box'
import Spinner from 'components/atoms/Spinner'
import { TextHeader } from 'components/atoms/Text'
import Button from 'components/atoms/Button'

import { useGeotoolsStore } from '../Zones/Store'
import { generateZoneName } from '../Drafts/generateZoneName'
import { useEditZoneStore } from './Store'

import { EditCircleControl } from './EditCircleControl'
import { EditFzrControl } from './EditFzrControl'
import { EditDrawControl } from './EditDrawControl'
import { EditSelectionControl } from './EditSelectionControl'

export const EditZoneControl = ({ zoneId, close }) => {
  const { t } = useTranslation()
  const [{ collection }, { updateZoneGeometry }] = useGeotoolsStore()
  const [{ zone, isLoading }, { setZone }] = useEditZoneStore()

  useEffect(() => {
    const zone = collection.features.find((f) => f.id === zoneId)
    setZone(zone)
    return () => {
      setZone(null)
    }
  }, [collection, zoneId, setZone])

  const handleReset = () => {
    const zone = collection.features.find((f) => f.id === zoneId)
    // clone zone since there might be have been mutable changes to it
    setZone({ ...zone })
    // setZone(JSON.parse(JSON.stringify(zone)))
  }

  const handleSave = async () => {
    const polygon = zone.geometry
    const { creationMethod, creationParams } = zone.properties

    if (typeof creationParams.zoneNum === 'undefined') {
      creationParams.zoneNum = collection.meta.featureCount + 1
    }

    const name = generateZoneName(t, creationMethod, creationParams)

    if (creationMethod === 'select') {
      await updateZoneGeometry(zone.id, { name, creationMethod, creationParams })
    } else {
      await updateZoneGeometry(zone.id, { name, polygon, creationMethod, creationParams })
    }
    close?.()
  }

  if (!zone) return null
  return (
    <Box>
      <Flex mb={2}>
        <Box flex="1">
          <TextHeader>{t(`geotools.${zone.properties.creationMethod}.title`)}</TextHeader>
        </Box>
        <Button ml={2} onClick={handleReset} appearance="secondary">
          {t('actions.actionReset')}
        </Button>
      </Flex>
      {zone.properties.creationMethod === 'circle' && <EditCircleControl />}
      {zone.properties.creationMethod === 'fzr' && <EditFzrControl />}
      {zone.properties.creationMethod === 'draw' && <EditDrawControl />}
      {zone.properties.creationMethod === 'select' && <EditSelectionControl />}
      <Flex justifyContent="center">
        <Button onClick={handleSave} disabled={isLoading} width="100px">
          {t('actions.actionSave')} {isLoading && <Spinner inline appearance="tertiary" />}
        </Button>
        <Button ml={2} onClick={close} appearance="secondary">
          {t('actions.actionCancel')}
        </Button>
      </Flex>
    </Box>
  )
}
