import createStore from 'lib/flux-store'

import api from 'stores/api'

import { l3plusTypeOfGoodsToMain } from 'config/l3plusTypeOfGoods'

import { getZonelist, getMaxYears, getMunicipalityInfo, getSubmarkets, getRetailUnits } from './Cache'

import { getTogId, areaSizeMapping } from './helper'

const initialState = {
  isLoading: false,
  isFailure: false,
  loading: {
    initialData: false,
    municipalityInfo_current: false,
    municipalityInfo_compare: false,
    submarkets_current: false,
    submarkets_compare: false,
    retailUnits_current: false,
    retailUnits_compare: false,
  },
  messages: null,
  zonelist: [],
  maxYear: null,
  municipalityInfo: {},
  municipalityInfoCompare: {},
  submarkets: {},
  submarketsCompare: {},
  submarketsLayer: null,
  favorites: {},
  retailUnits: {},
  filteredRetailUnits: [],
  retailUnitsCompare: {},
  filteredRetailUnitsCompare: [],
  availableTypeOfGoodsCurrent: [],
  availableTypeOfGoodsCompare: [],
  filter: {},
  highlightTog: null,
}

const filterRetailUnits = ({ retailUnits, filter }) => {
  const filtered = {}
  const unitIdsDone = {}
  Object.entries(retailUnits).forEach(([zoneId, units]) => {
    filtered[zoneId] = units.features.reduce((filtered, feature, index) => {
      if (feature.properties.typeOfGoods === null) {
        return filtered
      }
      if (unitIdsDone[feature.properties.id]) {
        return filtered
      }
      unitIdsDone[feature.properties.id] = true

      const byTog = filter.typeOfGoods.includes(
        l3plusTypeOfGoodsToMain[getTogId(feature.properties.typeOfGoods)]
      )
      const byAreaSize =
        filter.areaSizes.length === 0 ||
        filter.areaSizes.includes(areaSizeMapping[feature.properties.sizeCategory])
      const byFloors =
        filter.floors.length === 0 ||
        filter.floors.reduce((byFloors, floor) => {
          return byFloors || feature.properties[floor] >= 1
        }, false)
      if (byTog && byAreaSize && byFloors) {
        filtered.push(index)
      }
      return filtered
    }, [])
  })
  return filtered
}

const getAvailableTypeOfGoods = ({ retailUnits }) => {
  const togs = []
  Object.entries(retailUnits).forEach(([zoneId, units]) => {
    units.features.forEach((unit) => {
      let tog = getTogId(unit.properties.typeOfGoods)
      if (!togs.includes(tog)) {
        togs.push(tog)
      }
    })
  })
  togs.sort((a, b) => b - a)
  return togs
}

const actions = {
  fetchInitialData: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: 'initialData' })
    const promises = [getZonelist(), getMaxYears(), api.L3Plus.favorites()]
    Promise.all(promises)
      .then((results) => {
        const [zonelist, maxYears, { data: { data: favorites } } = []] = results

        const maxYear = maxYears.reduce((max, year) => {
          if (
            (year.key === 'data_de.st_purchasing_power.purchasing_power_index' ||
              year.key === 'data_de.st_purchasing_power.purchasing_power_total' ||
              year.key === 'data_de.st_purchasing_power.centrality') &&
            (max === null || year.max < max)
          ) {
            max = year.max
          }
          return max
        }, null)

        dispatch({
          type: 'setInitialData',
          payload: {
            zonelist,
            maxYear,
            favorites,
          },
        })
      })
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
  fetchMunicipalityInfo:
    (gac, year, target = 'current') =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: 'municipalityInfo_' + target })
      getMunicipalityInfo(gac, year)
        .then((municipalityInfo) => {
          dispatch({
            type: target === 'current' ? 'setMunicipalityInfo' : 'setMunicipalityInfoCompare',
            payload: {
              [target === 'current' ? 'municipalityInfo' : 'municipalityInfoCompare']: municipalityInfo,
            },
          })
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
          throw err
        })
    },
  fetchSubmarkets:
    (city, zone, target = 'current') =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: 'submarkets_' + target })
      getSubmarkets(city, zone)
        .then((submarkets) => {
          dispatch({
            type: target === 'current' ? 'setSubmarkets' : 'setSubmarketsCompare',
            payload: {
              [target === 'current' ? 'submarkets' : 'submarketsCompare']: submarkets,
            },
          })
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
          throw err
        })
    },
  fetchRetailUnits:
    (zoneIds, target = 'current') =>
    (dispatch) => {
      if (!Array.isArray(zoneIds)) {
        zoneIds = [zoneIds]
      }
      dispatch({ type: 'setLoading', payload: 'retailUnits_' + target })
      const promises = zoneIds.map((id) => getRetailUnits(id))
      Promise.all(promises)
        .then((results) => {
          dispatch({
            type: target === 'current' ? 'setRetailUnits' : 'setRetailUnitsCompare',
            payload: results.reduce((units, result, index) => {
              units[zoneIds[index]] = result
              return units
            }, {}),
          })
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
          throw err
        })
    },
  setFilter: (filter) => ({ filter }),
  setSubmarketsLayer: (submarketsLayer) => ({ submarketsLayer }),
  setHighlightTog: (highlightTog) => ({ highlightTog }),
  setFavorite: (id, stars) => (dispatch) => {
    api.L3Plus.setFavorite(id, stars)
      .then(() =>
        dispatch({
          type: 'setFavorite',
          payload: { id, stars },
        })
      )
      .catch((err) => {
        dispatch({ type: 'setFailure', payload: err })
        throw err
      })
  },
}
const reducer = {
  setLoading: (state, { payload }) => {
    const loading = { ...state.loading, [payload]: true }
    return { ...state, isLoading: true, loading, isFailure: false }
  },
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setInitialData: (state, { payload }) => {
    const loading = { ...state.loading, initialData: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    let { favorites, ...other } = payload
    favorites = favorites.reduce((favorites, unit) => {
      favorites[unit.id] = unit.stars
      return favorites
    }, {})
    return {
      ...state,
      isLoading,
      loading,
      favorites,
      ...other,
    }
  },
  setFavorite: (state, { payload: { id, stars } }) => {
    const favorites = { ...state.favorites }
    if (stars === 0 || stars === null) {
      delete favorites[id]
    } else {
      favorites[id] = stars
    }
    return {
      ...state,
      favorites,
    }
  },
  setMunicipalityInfo: (state, { payload }) => {
    const loading = { ...state.loading, municipalityInfo_current: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      ...payload,
    }
  },
  setMunicipalityInfoCompare: (state, { payload }) => {
    const loading = { ...state.loading, municipalityInfo_compare: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      ...payload,
    }
  },
  setSubmarkets: (state, { payload }) => {
    const loading = { ...state.loading, submarkets_current: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      ...payload,
    }
  },
  setSubmarketsCompare: (state, { payload }) => {
    const loading = { ...state.loading, submarkets_compare: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      ...payload,
    }
  },
  setRetailUnits: (state, { payload }) => {
    const loading = { ...state.loading, retailUnits_current: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      filteredRetailUnits: filterRetailUnits({ retailUnits: payload, filter: state.filter }),
      availableTypeOfGoodsCurrent: getAvailableTypeOfGoods({ retailUnits: payload }),
      retailUnits: payload,
    }
  },
  setRetailUnitsCompare: (state, { payload }) => {
    const loading = { ...state.loading, retailUnits_compare: false }
    const isLoading = Object.values(loading).reduce((isLoading, subLoader) => isLoading || subLoader, false)
    return {
      ...state,
      isLoading,
      loading,
      filteredRetailUnitsCompare: filterRetailUnits({ retailUnits: payload, filter: state.filter }),
      availableTypeOfGoodsCompare: getAvailableTypeOfGoods({ retailUnits: payload }),
      retailUnitsCompare: payload,
    }
  },
  setFilter: (state, { filter }) => {
    return {
      ...state,
      filter,
      filteredRetailUnits: filterRetailUnits({ retailUnits: state.retailUnits, filter }),
      filteredRetailUnitsCompare: filterRetailUnits({
        retailUnits: state.retailUnitsCompare,
        filter,
      }),
    }
  },
  setSubmarketsLayer: (state, { submarketsLayer }) => {
    return {
      ...state,
      submarketsLayer,
    }
  },
  setHighlightTog: (state, { highlightTog }) => {
    return {
      ...state,
      highlightTog,
    }
  },
}

export const [L3PlusDataContext, L3PlusDataProvider, useL3PlusDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'L3PlusDataStore'
)
