import { useState, useEffect, useRef } from 'react'

function useDebounce(value, delay = 100) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const handler = useRef(null)

  useEffect(() => {
    if (handler.current) {
      clearTimeout(handler.current)
    }
    handler.current = setTimeout(() => {
      setDebouncedValue(value)
      handler.current = null
    }, delay)

    return () => {
      clearTimeout(handler.current)
      handler.current = null
    }
  }, [delay, value])

  return debouncedValue
}

export default useDebounce
