import Select from 'components/atoms/Select'
import React from 'react'

export const CountrySelection = ({ country, setCountry, countries }) => {
  return (
    <Select
      id="objectFilter.type"
      label={'Land'}
      hideLabel={true}
      value={country}
      options={countries}
      onChange={(event) => setCountry(event.target.value)}
      orientation="horizontal"
    />
  )
}
