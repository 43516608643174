import { wohn_lagen, buero_lagen, eh_lagen } from './colorSchemes'

export default [
  { key: 'residentialArea', name: 'Wohnlagen (Teilraum)', colors: wohn_lagen, strokeColor: '#da6b19' },
  {
    key: 'officeArea',
    name: 'Bürolagen',
    colors: buero_lagen,
    strokeColor: '#005b9a',
  },
  { key: 'retailArea', name: 'Einzelhandelslagen', colors: eh_lagen, strokeColor: '#749b2e' },
]
