import React, { useEffect, useState, createContext, useContext, useMemo } from 'react'
import { uniqueName } from 'lib/unique-name'

import { useLeaflet } from './LeafletMap'

const paneContext = createContext(null)

/** Creates a pane that layers can be part of
 *
 * Panes are used for layer ordering by z-index.
 * See https://leafletjs.com/examples/map-panes/
 *
 * Default panes (z-index, pane name):
 * auto: mapPane
 * 200:  tilePane
 * 400:  overlayPane
 * 500:  shadowPane
 * 600:  markerPane
 * 650:  tooltipPane
 * 700:  popupPane
 *
 * When using a GeoJSON layer markers are not placed in the defined pane.
 * This behavior is due to how leaflet handles markers. See https://github.com/Leaflet/Leaflet/issues/4279
 * Polygons work. Polylines are untested.
 * If you want markers in the same pane use the paneOptions hook below.
 */
export const Pane = ({ name, zIndex, ...props }) => {
  const map = useLeaflet()
  const [pane, setPane] = useState()
  const [paneName, setPaneName] = useState()

  // set the pane name initially
  useEffect(() => {
    setPaneName(uniqueName(name))
  }, [name])

  // create a pane instace
  useEffect(() => {
    if (paneName == null) return
    const paneInstance = map.createPane(paneName)
    setPane(paneInstance)
    return () => {
      paneInstance.remove()
    }
  }, [map, paneName])

  // update the zIndex
  useEffect(() => {
    if (!pane) return
    pane.style.zIndex = zIndex
  }, [pane, zIndex])

  return <paneContext.Provider value={paneName}>{pane && props.children}</paneContext.Provider>
}

export const usePane = () => useContext(paneContext)

/** Returns always a destructurable object.
 * Layers can destructure it for their Options object in any case.
 * This is needed because { pane: undefined/null } is not valid for layers.
 */
export const usePaneOptions = () => {
  const pane = usePane()
  const options = useMemo(() => (pane ? { pane } : {}), [pane])
  return options
}

export const withPane =
  (Component, defaultName, defaultIndex) =>
  ({ zIndex = defaultIndex, name = defaultName, ...other }) =>
    (
      <Pane zIndex={zIndex} name={name}>
        <Component {...other} />
      </Pane>
    )
