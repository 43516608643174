import React from 'react'
import { useTranslation } from 'react-i18next'

import { BudiconEarth } from 'bgag-budicons'
import { languages } from 'i18n'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { TextHighlight } from 'components/atoms/Text'
import Box, { Flex } from 'components/atoms/Box'

import MainMenuItem, { ItemText } from './MainMenuItem'

export const MainMenuLanguageSelect = () => {
  const { t, i18n } = useTranslation()
  const { hasAdminKey } = useAuthzChecks()

  const allowedLanguages = [...languages, hasAdminKey('i18nCiMode') && 'cimode'].filter(Boolean)

  return (
    <MainMenuItem Icon={BudiconEarth}>
      <Flex flexDirection="column" p={2}>
        <TextHighlight mb={1}>{t(`languages.title`)}</TextHighlight>
        {allowedLanguages.map((lang) => (
          <Box key={lang} cursor="pointer" mb={1} onClick={() => i18n.changeLanguage(lang)}>
            <ItemText ml={1}>{t(`languages.${lang}`)}</ItemText>
          </Box>
        ))}
      </Flex>
    </MainMenuItem>
  )
}
