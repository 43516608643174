import React, { useEffect, useState, useCallback } from 'react'

import { GeoJSON, withPane } from 'lib/leaflet'

import api from 'stores/api'
import { zIndices } from 'config/zIndices'

const defaultStyle = {
  color: '#FFFFFF',
  weight: 1,
  dashArray: '',
  lineCap: 'butt',
  lineJoin: 'miter',
  fillColor: '#eeeeee',
  opacity: 1,
  fillOpacity: 1,
}

export const Districts = ({ style = noop, ...rest }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    api.Geometry.area('district')
      .then((res) => {
        setData(res.data.data.features)
      })
      .catch((err) => console.log(err))
  }, [])

  const memoStyle = useCallback((feature) => ({ ...defaultStyle, ...style(feature) }), [style])

  return <GeoJSON data={data} style={memoStyle} {...rest} />
}

const noop = () => {}

export const DistrictsPane = withPane(Districts, 'districts', zIndices.districts)
