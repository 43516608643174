// fix default icons
// see: https://github.com/Leaflet/Leaflet/issues/4968

import L from 'leaflet'

import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

delete L.Icon.Default.prototype._getIconUrl
const options = L.Icon.Default.prototype.options
options.iconUrl = markerIcon
options.iconRetinaUrl = markerIcon2x
options.shadowUrl = markerShadow
