import React, { useMemo } from 'react'
import { createGlobalStyle } from 'styled-components'
import { DivIcon } from 'leaflet'

import typography from 'components/theming/shared/typography'
import { VectorTileLayer, BgagVectorTileLayer, Pane, withPane } from 'lib/leaflet'
import { bgagServicesUrl } from 'config/appConfig'
import { zIndices } from 'config/zIndices'

export const RetailMarketsAdditionalLayers = ({ layers = [] }) => {
  const style = useMemo(
    () => layers.reduce((acc, value) => ({ ...acc, [value]: defaultStyle }), {}),
    [layers]
  )
  return layers.map((layer) => {
    return (
      <React.Fragment key={layer}>
        <VectorTileLayer
          url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${layer}`}
          auth
          maxNativeZoom={14}
          style={style}
        />
        {labelLayers[layer] && (
          <>
            <LabelsStyleCSS />
            <Pane
              name="RetailMarketsAdditionalLayersLabels"
              zIndex={zIndices.retailMarketsAdditionalLayers + 1}
            >
              <BgagVectorTileLayer
                url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${labelLayers[layer]}`}
                auth
                maxNativeZoom={14}
                style={labelStyle}
                useMarker={true}
              />
            </Pane>
          </>
        )}
      </React.Fragment>
    )
  })
}

export const RetailMarketsAdditionalLayersPane = withPane(
  RetailMarketsAdditionalLayers,
  'RetailMarketsAdditionalLayers',
  zIndices.retailMarketsAdditionalLayers
)

const defaultStyle = {
  stroke: true,
  weight: 1,
  color: '#000000',
  opacity: 0.5,
  fill: true,
  fillColor: '#eeeeee',
  fillOpacity: 0.0,
}

const labelStyle = (layer) => {
  if (!layer.properties.name) {
    return null
  }
  return {
    icon: new DivIcon({
      html: `<span>${layer.properties.name}</span>`,
      className: 'geotools-new-selection-labels',
      iconSize: 'auto',
    }),
  }
}

const labelLayers = {
  residentialArea: 'residentialAreaLabels',
  officeArea: 'officeAreaLabels',
  logisticsArea: 'logisticsAreaLabels',
  retailArea: 'retailAreaLabels',
  exurbs: 'exurbsLabels',
  stadtteile: 'stadtteileLabels',
  stadtbezirke: 'stadtbezirkeLabels',
  ortsteile: 'ortsteileLabels',
  gemeinden: 'gemeindenLabels',
  landkreise: 'landkreiseLabels',
  postleitzahlen: 'postleitzahlenLabels',
}

const LabelsStyleCSS = createGlobalStyle`
.geotools-new-selection-labels{
  background: initial;
  pointer-events: none;
}

.geotools-new-selection-labels span {
  display:inline-block;
  transform:translate(-50%, -50%);

  padding: 2px 6px;

  font-weight: bold;
  font-family: ${typography.fonts.sansSerif};
  font-size: 10px;
  white-space: nowrap;
  color: #222;

  background: white;
  border: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  
  pointer-events: none;
}
`
