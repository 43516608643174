/**
 * These Layers wrap existing Leaflet layers and replace internal functionality
 * to make them work with Tiles.
 */

import { Util, point, Path, Polygon, Polyline, Marker, CircleMarker, Bounds } from 'leaflet'

export class TileMarker extends Marker {
  initialize(coordinates, options) {
    Util.setOptions(this, options)
    this._coordinates = coordinates
    // Fix: error on click events. See Map._fireDOMEvent() how Markers are handled
    this.getLatLng = null
  }
  update() {
    const scale = this.options.gridLayer.getScale()
    const pos = point(this._coordinates[0][0])
      .scaleBy(this.options.pxPerExtent)
      .add(this.options.tilePos)
      .scaleBy(point(scale, scale))
      .add(this.options.gridLayer.getTranslate())
    this._pos = pos
    this._setPos(pos)
    return this
  }

  /** Will be triggered by VectorLayer*/
  setStyle(style) {
    Util.setOptions(this, style)
  }

  /** Needed for tooltips */
  getCenter() {
    return this._map.layerPointToLatLng(this._pos)
  }
}

export class TileCircleMarker extends CircleMarker {
  initialize(coordinates, options) {
    Util.setOptions(this, options)
    this._coordinates = coordinates
    this._empty = () => false
    this._radius = this.options.radius
    // Fix: error on click events. See Map._fireDOMEvent() how Markers are handled
    this.getLatLng = null
  }
  /* needs to be overwritten because there are no latlngs */
  _project() {}

  /* Original implementation clips and simplifies */
  _update() {
    const scale = this.options.gridLayer.getScale()
    this._point = point(this._coordinates[0][0])
      .scaleBy(this.options.pxPerExtent)
      .add(this.options.tilePos)
      .scaleBy(point(scale, scale))
      .add(this.options.gridLayer.getTranslate())
    this._updateBounds()
    this._updatePath()
  }

  /** Needed for tooltips */
  getCenter() {
    return this._map.layerPointToLatLng(this._point)
  }
}

export class TilePolyline extends Polyline {
  initialize(coordinates, options) {
    Util.setOptions(this, Path.prototype.options)
    Util.setOptions(this, Polyline.prototype.options)
    Util.setOptions(this, options)
    this._coordinates = coordinates
  }

  /* needs to be overwritten because there are no latlngs */
  _project() {}

  /* bounds will be calculated on when needed */
  _updateBounds() {}

  /* Original implementation clips and simplifies */
  _update() {
    this._rings = getParts(
      this._coordinates,
      this.options.pxPerExtent,
      this.options.tilePos,
      this.options.gridLayer.getTranslate(),
      this.options.gridLayer.getScale()
    )
    this._parts = this._rings
    this._updatePath()

    if (this._path) {
      const clipPathId = this.options.gridLayer.getClipId(this.options.coords)
      this._path.setAttribute('clip-path', `url(#${clipPathId})`)
    }
  }

  _containsPoint() {
    this._rawPxBounds = getBounds(this._parts)
    this._updateBounds()
    return super._containsPoint(arguments)
  }
}

export class TilePolygon extends Polygon {
  initialize(coordinates, options) {
    Util.setOptions(this, Path.prototype.options)
    Util.setOptions(this, Polyline.prototype.options)
    Util.setOptions(this, Polygon.prototype.options)
    Util.setOptions(this, options)
    this._coordinates = coordinates
  }

  /* needs to be overwritten because there are no latlngs */
  _project() {}

  /* bounds will be calculated on when needed */
  _updateBounds() {}

  /* Original implementation clips and simplifies */
  _update() {
    this._rings = getParts(
      this._coordinates,
      this.options.pxPerExtent,
      this.options.tilePos,
      this.options.gridLayer.getTranslate(),
      this.options.gridLayer.getScale()
    )
    this._parts = this._rings
    this._updatePath()

    if (this._path) {
      const clipPathId = this.options.gridLayer.getClipId(this.options.coords)
      this._path.setAttribute('clip-path', `url(#${clipPathId})`)
    }
  }

  /** Canvas renderer needs the bounds for interactivity*/
  _containsPoint() {
    this._rawPxBounds = getBounds(this._parts)
    this._updateBounds()
    return super._containsPoint(arguments)
  }
}

function getParts(coordinates, pxPerExtent, tilePos, originOffset, scale) {
  const parts = []
  for (const ring of coordinates) {
    const part = []
    for (const coordinate of ring) {
      // coordinate between 0 - extent (e.g 4096)
      const p = point(coordinate)
        // coordinate between 0 - tileSize (e.g. 265)
        .scaleBy(pxPerExtent)
        // set the global position in the grid
        .add(tilePos)
        // scale whole grid (e.g. for fractional zoom)
        .scaleBy(point(scale, scale))
        // position the scaled grid correctly
        .add(originOffset)
      part.push(p)
    }
    parts.push(part)
  }
  return parts
}

function getBounds(parts) {
  const bounds = new Bounds([])
  for (const part of parts) {
    for (const coordinate of part) {
      bounds.extend(coordinate)
    }
  }
  return bounds
}
