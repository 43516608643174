import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import appConfig from 'config/appConfig'
import api from 'stores/api'
import { useAuthStore } from 'stores/AuthStore'
import { useAppStore } from 'stores/AppStore'

import Box from 'components/atoms/Box'
import { TextBanner, TextHeader, TextParagraph } from 'components/atoms/Text'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { BudiconUserCard, BudiconUnlock, BudiconEmail } from 'bgag-budicons'

const emailSubject = `Account-Anfrage Highstreet-App`

const emailBody = `Sehr geehrte Damen und Herren,
%0D%0A
%0D%0A
bitte richten Sie mir einen Account für die Highstreet-App von ein.
%0D%0A
%0D%0A
Meine Kontaktdaten lauten:
%0D%0A
%0D%0A
Mit besten Grüßen,
%0D%0A
 `

const LoginForm = ({ setRedirectToReferrer, showMessage = '' }) => {
  const { t } = useTranslation(['highstreetReport', 'translation'])
  const [currentUser, actions] = useAuthStore((state, actions) => [state.currentUser, actions])
  const [loadAppState] = useAppStore((state, actions) => [actions.loadState])
  const [loginError, setLoginError] = useState(null)
  const [loginLoading, setLoginLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })

  useEffect(() => {
    if (typeof currentUser === 'object') {
      setRedirectToReferrer(true)
    }
  }, [currentUser, setRedirectToReferrer])

  const login = async (e) => {
    e.preventDefault()
    setLoginError(null)
    setLoginLoading(true)
    try {
      await api.Auth.login(credentials)
      actions.pullCurrentUser()
      loadAppState()
    } catch (error) {
      console.error('error', error)
      if (error?.response?.data?.messages?.[0] === 'User expired') {
        setLoginError(t('login.accountExpired'))
      } else {
        setLoginError(t('login.authenticationError'))
      }
      setLoginLoading(false)
    }
  }

  return (
    <Box width="100%" textAlign="center">
      <TextBanner mt={3} mb={2}>
        {t('login.header')}
      </TextBanner>
      <TextHeader fontSize={'1.125rem'}>{t('login.subHeader')}</TextHeader>
      <Box mt={3} mb={6}>
        <BudiconUserCard size="40px" />
      </Box>
      <Box width="100%">
        <form onSubmit={login}>
          <Input
            id="username"
            value={credentials.username}
            placeholder={t('login.username')}
            label={t('login.username')}
            hideLabel
            Icon={BudiconEmail}
            appearance="default"
            onChange={(e) => {
              setCredentials({ ...credentials, username: e.target.value })
            }}
          />
          <Input
            id="password"
            type="password"
            value={credentials.password}
            label={t('login.password')}
            hideLabel
            placeholder={t('login.password')}
            Icon={BudiconUnlock}
            appearance="default"
            onChange={(e) => {
              setCredentials({ ...credentials, password: e.target.value })
            }}
          />
          <Button type="submit" mt={5} mb={5} appearance="darker" isLoading={loginLoading} minWidth="90px">
            {t('login.loginButton')}
          </Button>
        </form>
        {loginError && (
          <TextParagraph mt={2} color="danger">
            {loginError}
          </TextParagraph>
        )}
        {showMessage === 'passwordSet' && (
          <TextParagraph mt={2} color="success">
            {t('login.passwordSet')}
          </TextParagraph>
        )}
        {showMessage === 'mailSent' && (
          <TextParagraph mt={2} color="success">
            {t('login.mailSent')}
          </TextParagraph>
        )}
        <TextParagraph>
          {t('login.accountRequest.text')}
          <Box mt={1}>
            <a
              href={`mailto:jbaer@bmorep.com?subject=${emailSubject}&body=${emailBody}`}
              style={{ fontWeight: 'bold', textDecoration: 'underline' }}
            >
              {t('login.accountRequest.button')}
            </a>
          </Box>
        </TextParagraph>
        <TextParagraph>
          <Link to="/forgotPassword">{t('login.forgotPassword')}</Link>
        </TextParagraph>
        <TextParagraph>
          <a
            href={`${appConfig.appBaseUrl}/RIWIS-Datenschutzerklaerung.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('login.privacyPolicy')}
          </a>
        </TextParagraph>
      </Box>
    </Box>
  )
}

export default LoginForm
