import React from 'react'
import { TextSectionHeader } from 'components/atoms/Text'
import { Flex } from 'components/atoms/Box'

const ControlSection = ({ title, children, TitleActionArea, titleActionAreaProperties }) => (
  <Flex width="100%" flexDirection="column" mb={5} flex="0 0 auto">
    {/* "flex=" needed for safari, without this - seemingly unneeded - property safari renders the whole controlbar unstable */}
    <Flex mb={1} justifyContent="space-between" alignItems="center">
      <TextSectionHeader>{title}</TextSectionHeader>
      {TitleActionArea && <TitleActionArea {...titleActionAreaProperties} />}
    </Flex>
    {children}
  </Flex>
)

export default ControlSection
