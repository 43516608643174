/**
 * Fixes: https://github.com/Leaflet/Leaflet/issues/7466
 */

import L from 'leaflet'

const originalOnAdd = L.Renderer.prototype.onAdd
L.Renderer.prototype.onAdd = function () {
  originalOnAdd.call(this)
  if (!this._zoomAnimated) {
    L.DomUtil.addClass(this._container, 'leaflet-zoom-animated')
  }
}
