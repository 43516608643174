import React from 'react'
import { useNavigate } from 'react-router-dom'
import ForgotPasswordForm from 'components/ForgotPasswordForm'
import LoginCardWrapper from 'components/LoginCardWrapper'
import Layout from 'components/Layout'

const ForgotPasswordModule = () => {
  const navigate = useNavigate()
  const onMailSent = () => {
    navigate('/', { showMessage: 'mailSent' })
  }

  return (
    <Layout showMainMenu={false}>
      <LoginCardWrapper logoTop="riwis">
        <ForgotPasswordForm onMailSent={onMailSent} />
      </LoginCardWrapper>
    </Layout>
  )
}

export default ForgotPasswordModule
