import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { variant } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { TextFormLabel } from './Text'
import { BudiconChevronBottom } from 'bgag-budicons'
import Spinner from './Spinner'
import typography from 'components/theming/shared/typography'
import sizes from 'components/theming/shared/sizes'
import { radii } from 'components/theming/shared/misc'
import { jiggle } from 'components/theming/shared/animation'

const Selector = styled.select`
  appearance: none;
  border: 0;
  border-radius: ${radii['sm']};
  font-family: ${typography.fonts.sansSerif};
  font-size: ${typography.fontSizes.sm};
  line-height: ${typography.lineHeights.base};
  padding: ${sizes['1']} ${sizes['3']} ${sizes['1']} ${sizes['2']};
  position: relative;
  outline: none;
  width: 100%;

  ${(props) =>
    // placeholder:
    props.value === '' &&
    css`
      opacity: 0.7;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}

  ${(props) =>
    props.inProgress &&
    css`
      cursor: progress;
    `}

  ${variant({
    prop: 'sizeVariant',
    variants: {
      small: {
        fontSize: 'sm',
        padding: `0.0625rem ${sizes['3']} 0 ${sizes['2']}`,
      },
      medium: {
        fontSize: 'sm',
        padding: `${sizes['1']} ${sizes['3']} ${sizes['1']} ${sizes['2']}`,
      },
      large: {
        fontSize: 'md',
        padding: `${sizes['2']} ${sizes['3']} ${sizes['1']} ${sizes['3']}`,
      },
    },
  })}
`

const Arrow = styled(BudiconChevronBottom).attrs((props) => ({
  size: '1.1em',
  type: 'outline',
  color: themeGet('colors.dark', '#666')(props),
}))``

const SelectSpinner = styled(Spinner)`
  right: 16px;
  left: auto;
  z-index: 1;
`

const SelectError = styled.div`
  position: absolute;
  right: 0;
  top: 30%;

  transition: all 300ms cubic-bezier(0.175, 0.885, 0.335, 1.05);
  color: ${themeGet('colors.negative', 'red')};
  padding-right: ${sizes['10']};
  font-family: ${typography.fonts.sansSerif};
  font-size: ${typography.fontSizes.xs};
  line-height: ${typography.lineHeights.shorter};
`

const Container = styled.div`
  ${(props) =>
    props.flexed &&
    css`
      flex: 1 1 auto;
    `}
`

const SelectWrapper = styled.span`
  display: inline-block;
  line-height: ${typography.lineHeights.normal};
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: 100%;
  margin-bottom: ${sizes['2']};

  transition: all 150ms ease-out;
  transform: translate3d(0, 0, 0);

  &:hover {
    transform: translate3d(0, -1px, 0);
  }

  &:active {
    transform: translate3d(0, 0, 0);
  }

  &:before {
    content: '';
    bottom: 1px;
    right: 1px;
    top: 1px;
    width: 2em;
    margin-left: 1px;
    position: absolute;
    z-index: 1;
    pointer-events: none;
    border-radius: 4px;
  }

  ${Arrow} {
    position: absolute;
    z-index: 1;
    pointer-events: none;
    /* height: ${sizes['2']}; */
    margin-top: -0.65em;
    right: 12px;
    top: 50%;
    ${variant({
      prop: 'sizeVariant',
      variants: {
        small: {
          top: '45%',
          right: '5px',
        },
        medium: {
          right: '12px',
        },
        large: {
          right: '12px',
        },
      },
    })}
    ${variant({
      prop: 'arrowRightPos',
      variants: {
        small: {
          right: '5px',
        },
        medium: {
          right: '12px',
        },
        large: {
          right: '12px',
        },
      },
    })}
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}

  ${Selector} {
    background-color: ${themeGet('colors.lightest', '#666')};
    color: ${themeGet('colors.darker', '#999')};
    box-shadow: ${themeGet('colors.mediumdark', '#ccc')} 0 0 0 1px inset;
  }
  ${Selector}:focus {
    box-shadow: ${themeGet('colors.primary', 'darkblue')} 0 0 0 1px inset;
  }

  ${(props) =>
    props.appearance === 'embedded' &&
    css`
      width: auto;
      height: auto;

      ${Selector} {
        color: ${themeGet('colors.dark', '#666')};
        background-color: transparent;
        /* font-weight: ${typography.fontWeights.bold}; */
        padding-right: ${sizes['6']};
        text-decoration: none;
        box-shadow: none !important;

        &:focus {
          box-shadow: none !important;
        }
      }
      &:before {
        content: none;
      }
      ${Arrow} {
        right: 0;
      }
    `}

  ${(props) =>
    props.iconExists &&
    css`
      ${Selector} {
        padding-left: ${sizes['10']};
      }

      ${Selector} + svg {
        transition: all 150ms ease-out;
        position: absolute;
        top: 50%;
        left: ${sizes['3']};
        height: 1.1em;
        width: 1.1em;
        margin-top: -0.9em;
        z-index: 1;

        path {
          fill: ${themeGet('colors.mediumdark', '#444')};
        }
      }
      ${Selector}:focus + svg path {
        fill: ${themeGet('colors.darker', '#666')};
      }
    `}

  ${(props) =>
    props.error &&
    css`
      ${Selector} {
        box-shadow: ${themeGet('colors.negative', 'red')} 0 0 0 1px inset;
        &:focus {
          box-shadow: ${themeGet('colors.negative', 'red')} 0 0 0 1px inset !important;
        }
      }

      ${Selector} + svg {
        animation: ${jiggle} 700ms ease-out;
        path {
          fill: ${themeGet('colors.negative', 'red')};
        }
      }
    `}
`

const Option = ({ label, value, isDisabled = false, disabled = false }) => (
  <option value={value} disabled={isDisabled || disabled}>
    {label}
  </option>
)

Option.propTypes = {
  label: PropTypes.string.isRequired,
  // value: PropTypes.any.isRequired,
}

const Select = forwardRef(
  (
    {
      id,
      options,
      value,
      appearance,
      sizeVariant,
      arrowRightPos,
      flexed,
      label,
      hideLabel,
      placeholder,
      error,
      Icon,
      inProgress,
      disabled,
      ...other
    },
    ref
  ) => {
    let spinnerId
    let errorId
    let ariaDescribedBy
    if (inProgress) {
      spinnerId = `${id}-in-progress`
      ariaDescribedBy = spinnerId
    }
    if (error) {
      errorId = `${id}-error`
      ariaDescribedBy = `${ariaDescribedBy || ''} ${errorId}`
    }

    return (
      <Container flexed={flexed}>
        {label && !hideLabel && (
          <TextFormLabel htmlFor={id} ml={1} mb={1}>
            {label}
          </TextFormLabel>
        )}
        <SelectWrapper
          appearance={appearance}
          iconExists={Icon ? true : false}
          error={error}
          data-error={error}
          disabled={disabled}
          sizeVariant={sizeVariant}
          arrowRightPos={arrowRightPos}
        >
          {!inProgress && <Arrow />}
          <Selector
            id={id}
            value={value}
            sizeVariant={sizeVariant}
            {...other}
            disabled={disabled || inProgress}
            inProgress={inProgress}
            aria-describedby={ariaDescribedBy}
            aria-invalid={!!error}
            aria-busy={inProgress}
          >
            {placeholder.length > 0 && (
              <option value="" disabled={true} hidden={true}>
                {placeholder}
              </option>
            )}
            {options.map((option) => (
              <Option {...option} key={`${id}-${option.label}-${option.value}`} />
            ))}
          </Selector>
          {Icon && <Icon size="1.1em" type="outline" aria-hidden />}
          {error && <SelectError id={errorId}>{error}</SelectError>}
          {inProgress && <SelectSpinner id={spinnerId} aria-label="Loading" inForm />}
        </SelectWrapper>
      </Container>
    )
  }
)

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape(Option.propTypes)),
  flexed: PropTypes.bool,
  value: PropTypes.any,
  appearance: PropTypes.oneOf(['default', 'embedded']),
  arrowRightPos: PropTypes.oneOf(['default', 'small', 'medium', 'large']),
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hideLabel: PropTypes.bool,
  error: PropTypes.string,
  Icon: PropTypes.elementType,
  className: PropTypes.string,
  inProgress: PropTypes.bool,
  disabled: PropTypes.bool,
}

Select.defaultProps = {
  value: 'loading',
  options: [{ label: 'Loading', value: 'loading' }],
  placeholder: '',
  appearance: 'default',
  arrowRightPos: 'default',
  flexed: false,
  hideLabel: false,
  error: null,
  Icon: null,
  className: null,
  inProgress: false,
  disabled: false,
}

export default Select
