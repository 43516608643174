import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const getNumbersFormats = function (lng) {
  return {
    number: new Intl.NumberFormat(lng),
    percentageFractionVariable: (decimals) =>
      new Intl.NumberFormat(lng, {
        style: 'percent',
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      }),
    percentageFraction0: new Intl.NumberFormat(lng, {
      style: 'percent',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
    percentageFraction1: new Intl.NumberFormat(lng, {
      style: 'percent',
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    }),
    roundedFraction0: new Intl.NumberFormat(lng, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    roundedFraction1: new Intl.NumberFormat(lng, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }),
    roundedFraction2: new Intl.NumberFormat(lng, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    percentageFraction0Chart: new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }),
    percentageFraction1Chart: new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    }),
    roundedFraction0Chart: new Intl.NumberFormat({
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }),
    roundedFraction1Chart: new Intl.NumberFormat({
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }),
    roundedFraction2Chart: new Intl.NumberFormat({
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
  }
}

export function useNumbersFormats() {
  const { i18n } = useTranslation()

  const numbersFormats = useMemo(() => {
    return getNumbersFormats(i18n.language)
  }, [i18n.language])

  return numbersFormats
}

/*  this is just for backwards compatibility
 *  delete after all usages of numbersFormats has been switched to use above hook
 */
const numbersFormats = getNumbersFormats('de')

export default numbersFormats
