import React, { useRef, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import appConfig from 'config/appConfig'
import api from 'stores/api'
import useApi from 'stores/useApi'

import Box from 'components/atoms/Box'
import { TextBanner, TextParagraph } from 'components/atoms/Text'
import Input from 'components/atoms/Input'
import Select from 'components/atoms/Select'
import Checkbox from 'components/atoms/Checkbox'
import Button from 'components/atoms/Button'

const dataKeys = [
  { key: 'email', defValue: '' },
  { key: 'firstname', defValue: '' },
  { key: 'name', defValue: '' },
  { key: 'phone', defValue: '' },
  { key: 'company', defValue: '' },
  { key: 'companyRole', defValue: '' },
  { key: 'acceptTermsOfUse', defValue: false },
  { key: 'newsletter', defValue: false },
  { key: 'properties', defValue: { language: 'de' } },
]

const FrmInput = ({ fld, focusedFld, data, error, ...rest }) => {
  const { t } = useTranslation()
  const focused = focusedFld === fld ? true : null
  return (
    <Input
      id={`registerTestAccount.${fld}`}
      name={fld}
      label={t('registerTestAccount.' + fld)}
      placeholder={t('registerTestAccount.' + fld)}
      value={data[fld]}
      error={error[fld]}
      focused={focused}
      autoFocus={focused}
      {...rest}
    />
  )
}

const FrmSelect = ({ fld, focusedFld, data, error, ...rest }) => {
  const { t } = useTranslation()
  const focused = focusedFld === fld ? true : null
  return (
    <Select
      id={`registerTestAccount.${fld}`}
      name={fld}
      label={t('registerTestAccount.' + fld)}
      placeholder={t('registerTestAccount.' + fld)}
      value={data[fld]}
      error={error[fld]}
      focused={focused}
      autoFocus={focused}
      {...rest}
    />
  )
}

const RegisterTestAccountForm = () => {
  const { t } = useTranslation()

  const companyRoles = useMemo(
    () => [
      { label: t('editUser.companyRoles.transactionManager'), value: 'transactionManager' },
      { label: t('editUser.companyRoles.researcher'), value: 'researcher' },
      { label: t('editUser.companyRoles.assetManager'), value: 'assetManager' },
      { label: t('editUser.companyRoles.controller'), value: 'controller' },
      { label: t('editUser.companyRoles.evaluator'), value: 'evaluator' },
      { label: t('editUser.companyRoles.journalist'), value: 'journalist' },
      { label: t('editUser.companyRoles.student'), value: 'student' },
      { label: t('editUser.companyRoles.private'), value: 'private' },
      { label: t('editUser.companyRoles.other'), value: 'other' },
    ],
    [t]
  )

  const [sendForm, formSent, isSubmitting, failedSubmitting] = useApi(api.Auth.registerTestAccount, null)

  const [showMessage, setShowMessage] = useState(null)
  const [data, setData] = useState(
    dataKeys.reduce((keys, { key, defValue }) => {
      keys[key] = defValue
      return keys
    }, {})
  )

  const [error, setError] = useState({})

  const currentFld = useRef(Object.keys(data)[0])

  const onSubmit = (evt) => {
    evt.preventDefault()
    sendForm(data)
  }

  const frmChange = (evt) => {
    currentFld.current = null
    const value = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value
    setData({ ...data, [evt.target.name]: value })
  }

  useEffect(() => {
    if (formSent === true) {
      setShowMessage('testAccountRegistered')
    }
  }, [formSent])

  useEffect(() => {
    const error = dataKeys.reduce((error, { key }) => {
      error[key] = null
      return error
    }, {})

    if (
      failedSubmitting &&
      typeof formSent === 'object' &&
      Array.isArray(formSent.messages) &&
      formSent.messages.length
    ) {
      currentFld.current = null
      if (formSent.statusCode === 500) {
        error.serverError = true
      } else {
        formSent.messages.forEach((message) => {
          error[message.param] = t(`validations.${message.param}.${message.msg}`)
        })
      }
    }
    setError(error)
  }, [formSent, failedSubmitting, t])

  const inputProperties = {
    disabled: isSubmitting,
    isDisabled: isSubmitting,
    onChange: frmChange,
    appearance: 'default',
    hideLabel: true,
    focusedFld: currentFld.current,
    data,
    error,
  }

  return (
    <Box width="100%" textAlign="center">
      {error.serverError === true && (
        <TextParagraph color="danger" mt={2}>
          {t('errorCodes.serverError')}
        </TextParagraph>
      )}
      {(showMessage === 'testAccountRegistered' && (
        <TextParagraph color="success">{t('registerTestAccount.testAccountRegistered')}</TextParagraph>
      )) || (
        <>
          <TextBanner mt={3}>{t('registerTestAccount.header')}</TextBanner>
          <TextParagraph mt={3} mb={6}>
            {t('registerTestAccount.intro')}
          </TextParagraph>
          <form onSubmit={onSubmit}>
            <FrmInput fld="email" {...inputProperties} />
            <FrmInput fld="firstname" {...inputProperties} autoComplete="given-name" />
            <FrmInput fld="name" {...inputProperties} autoComplete="family-name" />
            <FrmInput fld="phone" {...inputProperties} />
            <FrmInput fld="company" {...inputProperties} />
            <FrmSelect fld="companyRole" {...inputProperties} options={companyRoles} />
            <Box width="100%" textAlign="left">
              <Checkbox
                id="registerTestAccount.acceptTermsOfUse"
                name="acceptTermsOfUse"
                checked={data.acceptTermsOfUse}
                error={error.acceptTermsOfUse}
                onChange={frmChange}
              >
                {t('registerTestAccount.acceptTermsOfUse')}{' '}
                <a
                  href={`${appConfig.appBaseUrl}/RIWIS-Nutzungsbedingungen-Test-Zugang.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('registerTestAccount.acceptTermsOfUseLink')}
                </a>
              </Checkbox>
              <Checkbox
                id="registerTestAccount.newsletter"
                name="newsletter"
                checked={data.newsletter}
                error={error.newsletter}
                onChange={frmChange}
              >
                {t('registerTestAccount.newsletter')}
              </Checkbox>
            </Box>
            <Box width="100%">
              <Button
                type="submit"
                mt={6}
                mb={8}
                appearance="darker"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
                disabled={isSubmitting}
              >
                {t('registerTestAccount.submit')}
              </Button>
            </Box>
          </form>
        </>
      )}
    </Box>
  )
}

export default RegisterTestAccountForm
