import React from 'react'
import PropTypes from 'prop-types'
import { darken, getLuminance } from 'polished'

const CheckboxIcon = ({
  size = '1em',
  isChecked = false,
  activeColor = '#002940',
  inactiveColor = '#d7d7d7',
  strokeWidth = '2',
  ...other
}) => {
  const activeStroke = getLuminance(activeColor) > 0.6 ? darken(1, activeColor) : 'white'
  return (
    <svg
      viewBox="0 0 16 16"
      height={size}
      width={size}
      {...other}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.41421"
    >
      {isChecked ? (
        <>
          <rect width="16" height="16" fill={activeColor} />
          <path
            d="M11.7333 5.20001L6.6 10.3333L4.26667 8.00001"
            fill="none"
            stroke={activeStroke}
            strokeWidth={Math.min(2, Number(strokeWidth) * 2)}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      ) : (
        <rect
          x="1"
          y="1"
          width="14"
          height="14"
          fill="transparent"
          stroke={inactiveColor}
          strokeWidth={strokeWidth}
        />
      )}
    </svg>
  )
}

CheckboxIcon.propTypes = {
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default CheckboxIcon
