import React from 'react'
import MainMenu from './MainMenu'

import 'rsuite-table/dist/css/rsuite-table.css'
import { GlobalStyle } from 'components/theming/GlobalStyle'
import { SvgFilterShady, BudiconContext } from 'bgag-budicons'
import { Flex } from 'components/atoms/Box'

const Layout = ({ children, showMainMenu = true }) => {
  return (
    <>
      <GlobalStyle />
      <SvgFilterShady />
      <BudiconContext.Provider value={{ type: 'outline' }}>
        <Flex width="100%" height="100%">
          {showMainMenu && <MainMenu />}
          {children}
        </Flex>
        <div id="modal"></div> {/* Placeholder for modal */}
      </BudiconContext.Provider>
    </>
  )
}

export default Layout
