import createStore from 'lib/flux-store'

const initialState = {
  messages: {},
}

const actions = {
  addMessage: (message, onlyNew) => ({ message, onlyNew }),
  closeMessage: (id) => ({ id }),
}
const reducer = {
  addMessage: (prevState, { message }) => {
    if (!message.id) {
      return prevState
    }

    const state = { ...prevState, messages: { ...prevState.messages } }

    state.messages[message.id] = {
      ...prevState.messages?.[message.id],
      ...message,
      flags: { ...message.flags }, // defaults flags to empty object
      meta: {
        ...(prevState.messages?.[message.id]?.meta || { closed: false }),
        ...(message?.meta || {}),
      },
    }

    return state
  },
  closeMessage: (prevState, { id }) => {
    if (!prevState.messages[id]) {
      return prevState
    }

    const state = { ...prevState, messages: { ...prevState.messages } }

    state.messages[id] = { ...prevState.messages[id], meta: { ...prevState.messages[id].meta, closed: true } }

    return state
  },
}

export const [QueueUpdatesContext, QueueUpdatesProvider, useQueueUpdatesStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'QueueUpdatesStore'
)
