import React from 'react'
import PropTypes from 'prop-types'

const CityCategoryIcon = ({ size = '1em', letter = '', ...other }) => {
  const color = letter === 'R' ? '#41B59E' : '#00697C'
  return (
    <svg
      viewBox="0 0 14 15"
      height={size}
      // width={size}
      fill="transparent"
      strokeWidth="0.5"
      {...other}
    >
      {letter === 'A' && (
        <>
          <circle cx="7" cy="8" r="6.75" stroke={color} />
          <path
            d="M3.67297 11L6.19297 4.33H7.96297L10.323 11H8.66297L6.53297 4.71L7.32297 4.64L5.20297 11H3.67297ZM5.09297 9.56L5.42297 8.37H8.45297L8.60297 9.56H5.09297Z"
            fill={color}
          />
        </>
      )}
      {letter === 'B' && (
        <>
          <circle cx="7" cy="8" r="6.75" stroke={color} />
          <path
            d="M4.35551 11V4.33H7.18551C7.67217 4.33 8.09217 4.39333 8.44551 4.52C8.80551 4.64 9.08551 4.83667 9.28551 5.11C9.48551 5.37667 9.58551 5.73333 9.58551 6.18C9.58551 6.48 9.51217 6.72667 9.36551 6.92C9.21884 7.11333 9.04217 7.27 8.83551 7.39C8.62884 7.50333 8.43551 7.59333 8.25551 7.66L8.07551 7.31C8.44217 7.32333 8.76217 7.4 9.03551 7.54C9.31551 7.67333 9.53217 7.86333 9.68551 8.11C9.84551 8.35 9.92551 8.64 9.92551 8.98C9.92551 9.44667 9.81217 9.83 9.58551 10.13C9.36551 10.4233 9.06884 10.6433 8.69551 10.79C8.32217 10.93 7.90884 11 7.45551 11H4.35551ZM5.97551 9.75H7.33551C7.50217 9.75 7.65551 9.72667 7.79551 9.68C7.93551 9.62667 8.04884 9.54 8.13551 9.42C8.22217 9.29333 8.26551 9.12667 8.26551 8.92C8.26551 8.76667 8.23884 8.64 8.18551 8.54C8.13217 8.44 8.05884 8.36333 7.96551 8.31C7.87884 8.25 7.77884 8.20667 7.66551 8.18C7.55217 8.15333 7.43217 8.14 7.30551 8.14H5.97551V9.75ZM5.97551 6.95H7.27551C7.38884 6.95 7.49551 6.93 7.59551 6.89C7.69551 6.84333 7.77551 6.77333 7.83551 6.68C7.90217 6.58667 7.93551 6.46333 7.93551 6.31C7.93551 6.03667 7.86217 5.85 7.71551 5.75C7.56884 5.64333 7.38884 5.59 7.17551 5.59H5.97551V6.95Z"
            fill={color}
          />
        </>
      )}
      {letter === 'C' && (
        <>
          <circle cx="7" cy="8" r="6.75" stroke={color} />
          <path
            d="M7.51059 11.13C6.44392 11.13 5.62725 10.85 5.06059 10.29C4.50059 9.72333 4.22059 8.85 4.22059 7.67C4.22059 6.52333 4.49059 5.65667 5.03059 5.07C5.57059 4.47667 6.39725 4.18 7.51059 4.18C7.86392 4.18 8.22059 4.22333 8.58059 4.31C8.94059 4.39667 9.29059 4.51667 9.63059 4.67V6.08C9.28392 5.85333 8.95059 5.69 8.63059 5.59C8.31059 5.49 8.01059 5.44 7.73059 5.44C7.33725 5.44 7.00392 5.51 6.73059 5.65C6.46392 5.79 6.25725 6.02333 6.11059 6.35C5.97059 6.67667 5.90059 7.11667 5.90059 7.67C5.90059 8.22333 5.97059 8.66 6.11059 8.98C6.25725 9.3 6.47059 9.53 6.75059 9.67C7.03725 9.80333 7.38725 9.87 7.80059 9.87C8.04725 9.87 8.32725 9.82333 8.64059 9.73C8.95392 9.63667 9.28392 9.47667 9.63059 9.25V10.66C9.44392 10.7533 9.23059 10.8367 8.99059 10.91C8.75725 10.9767 8.51059 11.03 8.25059 11.07C7.99725 11.11 7.75059 11.13 7.51059 11.13Z"
            fill={color}
          />
        </>
      )}
      {letter === 'D' && (
        <>
          <circle cx="7" cy="8" r="6.75" stroke={color} />
          <path
            d="M4.15531 11V4.33H6.83531C7.96865 4.33 8.80198 4.61333 9.33531 5.18C9.86865 5.74 10.1353 6.57 10.1353 7.67C10.1353 8.76333 9.86865 9.59333 9.33531 10.16C8.80198 10.72 7.96865 11 6.83531 11H4.15531ZM5.77531 9.75H6.64531C7.24531 9.75 7.69865 9.58333 8.00531 9.25C8.31865 8.91667 8.47531 8.39 8.47531 7.67C8.47531 6.93667 8.31865 6.40667 8.00531 6.08C7.69865 5.74667 7.24531 5.58 6.64531 5.58H5.77531V9.75Z"
            fill={color}
          />
        </>
      )}
      {letter === 'R' && (
        <>
          <circle cx="7" cy="8" r="6.75" stroke={color} />
          <path
            d="M4.16508 11V4.33H7.41508C8.16841 4.33 8.75841 4.50667 9.18508 4.86C9.61174 5.20667 9.82508 5.73667 9.82508 6.45C9.82508 6.74333 9.76508 7.03333 9.64508 7.32C9.52508 7.6 9.34841 7.84333 9.11508 8.05C8.88175 8.25 8.58841 8.38 8.23508 8.44L8.13508 7.89L10.1651 11H8.10508L6.43508 8.09L7.15508 8.7H5.78508V11H4.16508ZM5.78508 7.51H7.14508C7.43175 7.51 7.65841 7.43333 7.82508 7.28C7.99174 7.12667 8.07508 6.87333 8.07508 6.52C8.07508 6.21333 7.99841 5.98 7.84508 5.82C7.69841 5.66 7.48841 5.58 7.21508 5.58H5.78508V7.51Z"
            fill={color}
          />
        </>
      )}
    </svg>
  )
}

CityCategoryIcon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letter: PropTypes.oneOf(['A', 'B', 'C', 'D', 'R']).isRequired,
}

export default CityCategoryIcon
