import React, { useCallback, useMemo, useState } from 'react'

import { useControllableState } from 'lib/hooks'
import { difference } from 'lib/util'

import { InputGroup, withInputGroup } from './InputGroup'

export const CheckboxGroupComponent = ({
  value,
  onChange,
  defaultValue,
  children,
  allValues,
  allowMultiple = true,
  ...props
}) => {
  const [initialValue] = useState(defaultValue ?? value ?? [])
  allValues = allValues ?? initialValue ?? []
  const [checked, setChecked] = useControllableState(initialValue, value, onChange)
  const formState = useMemo(() => valueToGroupState(checked, allValues), [checked, allValues])
  const handleChange = useCallback(
    (value) => {
      const nextChecked = groupStateToValue(value)
      setChecked(allowMultiple ? nextChecked : difference(nextChecked, checked))
    },
    [checked, setChecked, allowMultiple]
  )

  return (
    <InputGroup {...props} value={formState} onChange={handleChange}>
      {children}
    </InputGroup>
  )
}

export const CheckboxGroup = withInputGroup(CheckboxGroupComponent)

const groupStateToValue = (state) =>
  Object.entries(state)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
const valueToGroupState = (checked, allValues) =>
  Object.fromEntries(allValues.map((key) => [key, checked.includes(key)]))
