import { useEffect, useCallback } from 'react'

const useInitialfocus = (ref) => {
  // reference given, so focus that reference
  useEffect(() => {
    if (ref) {
      ref.current.focus()
    }
  }, [ref])

  // no reference given, so create callback ref and return it
  const refCB = useCallback(
    (node) => {
      if (!ref && node) {
        node.focus()
      }
    },
    [ref]
  )

  return [refCB]
}

export default useInitialfocus
