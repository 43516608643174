import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { withPane, BgagVectorTileLayer } from 'lib/leaflet'
import { bgagServicesUrl } from 'config/appConfig'
import areaTypes from 'config/areaTypes'

import { zIndices } from 'config/zIndices'

export const AreaLayer = ({ topic }) => {
  const { t } = useTranslation('cityStructure')

  const onEachFeature = useCallback(
    (feature) => {
      let name = feature.properties.name || ''
      // get translation of quality (funktionale Lage)
      const legendId = `legendCategories.area.${topic}.${feature.properties.quality}`
      let fktLage = t(legendId)
      // if name is part of fktLage do not display name
      fktLage =
        name.length && name.replace('-', '').toLowerCase().startsWith(fktLage.replace('-', '').toLowerCase())
          ? ''
          : topic === 'officeArea'
          ? `(${fktLage})`
          : fktLage

      /**
       * Temporary fixes for austria specific edge cases
       * Should be fixed via database entries
       */

      // search for 1a or 1b matches
      const matchedString = name.match(/^\d[ab]\W/)
      if (matchedString) {
        name = name.replace(matchedString[0], matchedString[0].toUpperCase())
      }

      // search for 'A-Lage:' || 'B-Lage:' || 'A-Lage ' || 'B-Lage '
      if (name.match(/^[abAB][-].*?\W/)) {
        fktLage = ''
      }

      // prevent labels like '1B-Lage B-Lage'
      if (fktLage.match(/^\d[AB]\W/) && name.match(/[AB]\W/)) {
        name = ''
      }

      // change labels like '1B-Lage Festspielbezirk' to 'B-Lage: Festspielbezirk'
      if (fktLage.length && fktLage.match(/^\d[AB]\W/) && name.length && !name.match(/[AB]\W/)) {
        fktLage = fktLage.replace(/\d/, '') + ':'
      }

      let seperator = fktLage === '' ? '' : ' '

      // return right label
      return topic === 'residentialArea'
        ? feature.bindTooltip(`${name} - ${fktLage}`)
        : topic === 'officeArea'
        ? feature.bindTooltip(`${name}${seperator}${fktLage}`)
        : feature.bindTooltip(`${fktLage}${seperator}${name}`)
    },
    [topic, t]
  )
  const style = useMemo(() => makeStyle(topic), [topic])
  return (
    <BgagVectorTileLayer
      url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${topic}`}
      auth
      maxNativeZoom={14}
      style={style}
      onEachFeature={onEachFeature}
    />
  )
}
export const AreaPane = withPane(AreaLayer, 'areas', zIndices.areas)

const makeStyle = (topic) => (feature) => {
  const quality = feature.properties.quality || '#000000'
  const config = areaTypes.find((type) => type.key === topic)
  return {
    stroke: true,
    weight: 3,
    color: config.strokeColor,
    opacity: 1,
    fillColor: config.colors[quality],
    fillOpacity: config.colors[quality] ? 0.4 : 0,
  }
}
