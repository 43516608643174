import { useState, useEffect } from 'react'
import { VectorTiles } from 'lib/bgag-leaflet-vectortiles'

import { useBaseLayer } from './BaseLayer'
import { useAddToMap } from './LeafletMap'
import { usePaneOptions } from './Pane'
import { useGridLayerEvents } from '../hooks/useEvents'

export const BgagVectorTileLayer = ({
  url,
  style,
  layerStyles,
  filter,
  layerFilters,
  layers,
  hideLayers,
  auth = false,
  options,
  maxNativeZoom,
  interactive = true,
  onEachFeature,
  useMarker = false,
  storeAllLayers = false,
  children = null,
  callback,
  getFeatureId,
  ...props
}) => {
  const paneOptions = usePaneOptions()
  const [layer, setLayer] = useState(null)

  useAddToMap(layer)
  useBaseLayer(layer)
  useGridLayerEvents(layer, props)

  useEffect(() => {
    setLayer(
      new VectorTiles(url, {
        layers,
        hideLayers,
        fetchOptions: auth ? { credentials: 'include' } : {},
        maxNativeZoom,
        interactive,
        onEachFeature,
        getFeatureId: getFeatureId ?? ((f) => f.id ?? f.properties.id ?? f.properties.gid),
        useMarker,
        storeAllLayers,
        ...paneOptions,
        ...options,
      })
    )
  }, [
    url,
    auth,
    maxNativeZoom,
    options,
    paneOptions,
    interactive,
    layers,
    hideLayers,
    onEachFeature,
    getFeatureId,
    useMarker,
    storeAllLayers,
  ])

  useEffect(() => {
    if (!layer) return
    typeof callback === 'function' && callback(layer)
  }, [callback, layer])

  useEffect(() => {
    if (!layer || !style) return
    layer.setStyle(style)
  }, [layer, style])

  useEffect(() => {
    if (!layer || !layerStyles) return
    layer.setStyle(null, layerStyles)
  }, [layer, layerStyles])

  useEffect(() => {
    if (!layer || !filter) return
    layer.setFilter(filter)
  }, [layer, filter])

  useEffect(() => {
    if (!layer || !layerFilters) return
    layer.setFilter(null, layerFilters)
  }, [layer, layerFilters])

  useEffect(() => {
    if (!layer) return
    layer.options.onEachFeature = onEachFeature
  }, [layer, onEachFeature])

  return null
}
