import { dupeStartingPoint } from './helpers'

/**
 * Creates a buffer around a point
 * @param point
 * @param radius in meters
 * @returns {*}
 */
export function pointBuffer(point, radius, resolution) {
  if (!resolution) {
    resolution = 36
  }

  var ring = []
  var resMultiple = 360 / resolution

  for (var i = 0; i < resolution; i++) {
    ring.push(destination(point, radius, i * resMultiple))
  }

  return [dupeStartingPoint(ring)]
}

const EARTH_RADIUS = 6371008.8 // in m
/** Converts an angle in degrees to radians */
const degreesToRadians = (degrees) => ((degrees % 360) * Math.PI) / 180
/** Convert a distance measurement (assuming a spherical Earth) from meters into radians */
const lengthToRadians = (distance) => distance / EARTH_RADIUS // earth radius in m
/** Converts an angle in radians to degrees */
const radiansToDegrees = (radians) => ((radians % (2 * Math.PI)) * 180) / Math.PI

/**
 * https://en.wikipedia.org/wiki/Haversine_formula
 * https://github.com/Turfjs/turf/blob/master/packages/turf-destination/index.ts
 * @param {*} point
 * @param {*} distance - in meters
 * @param {*} bearing - from -180 to 180
 */
export function destination(point, distance, bearing) {
  const [lon1, lat1] = point.map(degreesToRadians)
  const bearingRad = degreesToRadians(bearing)
  const radians = lengthToRadians(distance)

  const lat2 = Math.asin(
    Math.sin(lat1) * Math.cos(radians) + Math.cos(lat1) * Math.sin(radians) * Math.cos(bearingRad)
  )
  const lon2 =
    lon1 +
    Math.atan2(
      Math.sin(bearingRad) * Math.sin(radians) * Math.cos(lat1),
      Math.cos(radians) - Math.sin(lat1) * Math.sin(lat2)
    )

  return [radiansToDegrees(lon2), radiansToDegrees(lat2)]
}

/** https://stackoverflow.com/a/43208163 */
export function getDistance(src, dest) {
  const [lon1, lat1] = src.map(degreesToRadians)
  const [lon2, lat2] = dest.map(degreesToRadians)
  const deltaLat = lat2 - lat1
  const deltaLon = lon2 - lon1

  const a =
    Math.pow(Math.sin(deltaLat / 2), 2) +
    Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2)
  const c = 2 * Math.asin(Math.sqrt(a))
  return c * EARTH_RADIUS
}

export function getAngle(src, dest) {
  const [lon1, lat1] = src.map(degreesToRadians)
  const [lon2, lat2] = dest.map(degreesToRadians)
  const dy = lat2 - lat1
  const dx = Math.cos(lat1) * (lon2 - lon1)
  const angle = Math.atan2(dx, dy)
  return radiansToDegrees(angle)
}
