import React, { useEffect, useState, useRef, Suspense, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import Layout from 'components/Layout'

import { ImpersonateFrame } from 'pages/admin/ImpersonateUser'
import { QueueUpdates } from 'components/QueueUpdates'

import api from 'stores/api'
import { AppStoreProvider, useAppStore } from 'stores/AppStore'
import { AdminStoreProvider } from 'stores/AdminStore'
import { MapMultiStoreProvider } from 'lib/leaflet/store'
import { AuthStoreProvider, useAuthStore } from 'stores/AuthStore'
import { QueueUpdatesProvider } from 'stores/QueueUpdates'
import { CityStructureProvider } from 'pages/cityStructure/store'
import { ObjectStateProvider } from 'pages/objects/stateStore'
import { ObjectDataStoreProvider } from 'pages/objects/dataStore'
import { GlobalObjectStoreProvider } from 'pages/globalObjects/ObjectStore'
import { MarketTextStateProvider } from 'pages/marketText/stateStore'
import { MarketTextDataProvider } from 'pages/marketText/dataStore'
import { MarketDataDataProvider } from 'pages/marketData/dataStore'
import { L3PlusDataProvider } from 'pages/l3plus/dataStore'
import { HighstreetReportDataProvider } from 'pages/HighstreetReport/dataStore'
import { ThematicMapsProvider } from 'pages/thematicMaps/ThematicMapsStore'
import { IndexProvider } from 'pages/propertyIndices/IndexStore'
import { HedonicRentsDataProvider } from 'pages/Hedonic/Rents/dataStore'
import { HedonicHouseDataProvider } from 'pages/Hedonic/House/dataStore'
import { DownloadDocumentsDataProvider } from 'pages/Download/Documents/dataStore'

import { LoginModule, LogoutModule } from 'pages/Login'
import LandingPageHome from 'pages/LandingPage'
import SetPasswordModule from 'pages/SetPasswordModule'
import ForgotPasswordModule from 'pages/ForgotPasswordModule'
import RegisterTestAccountModule from 'pages/RegisterTestAccountModule'
import RegisterTestAccountL3PlusModule from 'pages/RegisterTestAccountL3PlusModule'

import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { getSsoStatus, ssoLogout } from 'lib/sso'

import { SingleSignOn } from 'components/SingleSignOn'
import { GeotoolsDraftProvider, GeotoolsStoreProvider, EditZoneStoreProvider } from 'components/geotools'
import ErrorBoundary from 'components/ErrorBoundary'
import { LoginModuleBMO } from 'pages/HighstreetReport/Login/Login'
import { MarketDataStateProvider } from 'pages/marketData/stateStore'

// lazy modules
// see https://medium.com/hackernoon/lazy-loading-and-preloading-components-in-react-16-6-804de091c82d
function lazyWithPreload(factory) {
  const Component = React.lazy(factory)
  requestIdleCallback(() => factory())
  return Component
}

const AdminModule = lazyWithPreload(() => import('pages/admin'))
const SysAdminModule = lazyWithPreload(() => import('pages/SystemAdmin'))
const ExportModule = lazyWithPreload(() => import('pages/export'))
const MarketAnalysis = lazyWithPreload(() => import('pages/MarketAnalysis'))
const GlobalObjects = lazyWithPreload(() => import('pages/globalObjects'))
const Makro = lazyWithPreload(() => import('pages/Makro'))
const Disco = lazyWithPreload(() => import('pages/Disco'))
const L3Plus = lazyWithPreload(() => import('pages/l3plus'))
const Hedonic = lazyWithPreload(() => import('pages/Hedonic'))
const Download = lazyWithPreload(() => import('pages/Download'))
const RetailMarkets = lazyWithPreload(() => import('pages/RetailMarkets'))
const HighstreetReport = lazyWithPreload(() => import('pages/HighstreetReport'))
const Developer = lazyWithPreload(() => import('pages/Developer'))

const PublicAccess = ({ Component }) => {
  const location = useLocation()

  const [currentUser, pullCurrentUser] = useAuthStore((state, actions) => [
    state.currentUser,
    actions.pullCurrentUser,
  ])
  const [loadAppState] = useAppStore((state, actions) => [actions.loadState])
  const [isLoggedIn, setIsLoggedIn] = useState(null)

  const { getDefaultRoute } = useAuthzChecks()

  useEffect(() => {
    if (currentUser === 'init' && isLoggedIn === null) {
      api.Auth.isLoggedIn().then((isLoggedIn) => {
        setIsLoggedIn(isLoggedIn.data.data)
      })
    }
    if (currentUser === 'init' && isLoggedIn) {
      pullCurrentUser()
      loadAppState()
    }
  }, [isLoggedIn, currentUser, pullCurrentUser, loadAppState])

  useEffect(() => {
    if (location.pathname.startsWith('/sso/')) {
      const [, , redirectAfterLogin] = location.pathname.match(/^(\/sso\/[^/]+\/?)(.*)/)
      if (redirectAfterLogin.length) {
        let search = location.search.replace(/(&|\?)(chk=|uid=|ts=)[^&]*/g, '')
        if (!search.startsWith('?')) {
          search = '?' + search
        }
        localStorage.setItem('ssoRedirect', '/' + redirectAfterLogin + search)
      }
    }
  }, [location])

  if (
    currentUser === 'init' &&
    (isLoggedIn === null || isLoggedIn === true) /* <-- wait for currentUser to be loaded!*/
  ) {
    return null
  } else if (isLoggedIn) {
    const ssoRedirect = localStorage.getItem('ssoRedirect')
    let to
    if (typeof ssoRedirect === 'string') {
      localStorage.removeItem('ssoRedirect')
      to = ssoRedirect
    } else {
      to = getDefaultRoute()
    }
    return <Navigate replace to={to} />
  } else {
    return <Component />
  }
}

const PrivateAccess = ({ Component }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { hasRouteAccess, getDefaultRoute } = useAuthzChecks()

  const [isLoggedIn, setIsLoggedIn] = useState(null)

  const [currentUser, pullCurrentUser] = useAuthStore((state, actions) => [
    state.currentUser,
    actions.pullCurrentUser,
  ])
  const [searchString, setSearchString] = useState(location.search)
  const [loadAppState, setLastRoute, isLoadingAppState] = useAppStore((state, actions) => [
    actions.loadState,
    actions.setLastRoute,
    state.isLoading,
  ])
  const userEmail = useRef(null)

  useEffect(() => {
    if (currentUser && typeof currentUser === 'object') {
      userEmail.current = currentUser.data.email
    }
  }, [currentUser])

  useEffect(() => {
    if (!location.pathname.startsWith('/logout')) {
      getSsoStatus(currentUser, navigate)
    }
  }, [currentUser, navigate, location.pathname])

  useEffect(() => {
    let detectedToken = searchString.match(/(?:&|\?)(token=[^&]*)/)
    if (detectedToken !== null) {
      detectedToken = detectedToken[1].replace(/^token=/, '')
      api.Auth.loginWithToken(detectedToken).finally(() => {
        setSearchString((s) => s.replace(/(&|\?)(token=[^&]*&?)/, '$1'))
      })
    } else {
      if (currentUser === 'init' && isLoggedIn === null) {
        api.Auth.isLoggedIn().then((isLoggedIn) => {
          setIsLoggedIn(isLoggedIn.data.data)
        })
      }
      if (currentUser === 'init' && isLoggedIn) {
        pullCurrentUser()
        loadAppState()
      }
    }
  }, [isLoggedIn, searchString, currentUser, pullCurrentUser, loadAppState])

  useEffect(() => {
    if (currentUser !== 'init' && currentUser && !isLoadingAppState) {
      const doRefresh = !location.pathname.startsWith('/logout')
      const doSetLastRoute =
        !location.pathname.startsWith('/home') &&
        !location.pathname.startsWith('/logout') &&
        !location.pathname.startsWith('/admin')

      if (doSetLastRoute) {
        setLastRoute(location.pathname)
      }
      if (doRefresh) {
        const now = Date.now()
        // localStorage lastRefreshToken gets set in src/stores/api axios-interceptors
        const lastRefreshToken = parseInt(localStorage.getItem('lastRefreshToken') || 0)
        if (now - lastRefreshToken > 20 * 60 * 1000 /* refresh every 20 minutes */) {
          api.Auth.refresh()
        }
      }
    }
  }, [location.pathname, setLastRoute, currentUser, isLoadingAppState])

  let shouldRedirect = true
  if (currentUser !== 'init' && !currentUser) {
    shouldRedirect = !ssoLogout(userEmail.current)
  }

  if ((currentUser === 'init' && isLoggedIn !== false) || !shouldRedirect) {
    return null
  } else if (currentUser !== 'init' && currentUser) {
    return hasRouteAccess(location.pathname) ? (
      <Component />
    ) : (
      <Navigate replace to={getDefaultRoute()} state={{ error: 'noRouteAccess' }} />
    )
  } else {
    return <Navigate replace to="/" state={{ from: location }} />
  }
}

const SyncedThemeProvider = ({ children }) => {
  const [{ currentTheme }] = useAppStore()
  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}

// BlockRender is a component wrapped in memo to prevent the whole subtree of a Provider to be rerendered on every state-change
// memo's isEqual returns always true, otherwise usage of "children" will rerender the subtree anyways
// https://gist.github.com/slikts/e224b924612d53c1b61f359cfb962c06
const BlockRender = memo(
  ({ children }) => children,
  () => true
)

const createStoreProvider =
  (providers) =>
  ({ children }) =>
    providers
      .reverse()
      .reduce((tree, Provider) => <Provider>{tree}</Provider>, <BlockRender>{children}</BlockRender>)

const NestedProviders = createStoreProvider([
  AuthStoreProvider,
  QueueUpdatesProvider,
  AppStoreProvider,
  AdminStoreProvider,
  MapMultiStoreProvider,
  CityStructureProvider,
  ObjectStateProvider,
  ObjectDataStoreProvider,
  GlobalObjectStoreProvider,
  MarketTextStateProvider,
  MarketTextDataProvider,
  MarketDataDataProvider,
  MarketDataStateProvider,
  L3PlusDataProvider,
  HighstreetReportDataProvider,
  ThematicMapsProvider,
  IndexProvider,
  GeotoolsDraftProvider,
  GeotoolsStoreProvider,
  EditZoneStoreProvider,
  HedonicRentsDataProvider,
  HedonicHouseDataProvider,
  DownloadDocumentsDataProvider,
  SyncedThemeProvider,
])

/**
 * Checks if the user has i18n license and forces german language if not
 *
 * Temporary until i18n module is released universally.
 * Needs to be a Component, so it can be mounted as the child of the relevant
 * Provider
 *
 * Might "flicker" on first change of the language, but since i18next remembers
 * the language selection subsequent page loads already have the correct
 * language selected.
 */
const I18nAuthzCheck = () => {
  const { i18n } = useTranslation()
  const [{ currentUser }] = useAuthStore()
  useEffect(() => {
    const licences = currentUser.permissions?.licence?.modules ?? []
    if (currentUser !== 'init' && !licences.includes('i18n')) {
      i18n.changeLanguage('de')
    }
  }, [currentUser, i18n])
  return null
}

const LayoutOutlet = () => {
  return (
    <Suspense fallback={<Layout />}>
      <Layout>
        <Outlet />
      </Layout>
    </Suspense>
  )
}

const App = () => (
  <>
    <ImpersonateFrame />
    <NestedProviders>
      <ErrorBoundary>
        <QueueUpdates />
        <I18nAuthzCheck />
        <Routes>
          <Route path="/" element={<PublicAccess Component={LoginModule} />} />
          <Route path="/BMOLogin" element={<PublicAccess Component={LoginModuleBMO} />} />
          <Route
            path="/registerTestAccount"
            element={<PublicAccess Component={RegisterTestAccountModule} />}
          />
          <Route
            path="/registerTestAccountL3Plus"
            element={<PublicAccess Component={RegisterTestAccountL3PlusModule} />}
          />
          <Route path="/setPassword/:type/:userId/:token" element={<SetPasswordModule />} />
          <Route path="/sso/:token/*" element={<PublicAccess Component={SingleSignOn} />} />
          <Route path="/forgotPassword" element={<PublicAccess Component={ForgotPasswordModule} />} />
          <Route path="/export/*" element={<PrivateAccess Component={ExportModule} />} />
          <Route path="/apps/l3plus/standalone/*" element={<PrivateAccess Component={L3Plus} />} />
          <Route path="*" element={<LayoutOutlet />}>
            <Route path="admin/*" element={<PrivateAccess Component={AdminModule} />} />
            <Route path="apps/l3plus/*" element={<PrivateAccess Component={L3Plus} />} />
            <Route path="apps/developer/*" element={<PrivateAccess Component={Developer} />} />
            <Route path="apps/disco" element={<PrivateAccess Component={Disco} />} />
            <Route path="apps/hedonic/*" element={<PrivateAccess Component={Hedonic} />} />
            <Route path="apps/retail-markets/*" element={<PrivateAccess Component={RetailMarkets} />} />
            <Route path="download/*" element={<PrivateAccess Component={Download} />} />
            <Route path="apps/highstreet-report/*" element={<PrivateAccess Component={HighstreetReport} />} />
            <Route path="home" element={<PrivateAccess Component={LandingPageHome} />} />
            <Route path="logout" element={<PrivateAccess Component={LogoutModule} />} />
            <Route path="makro/*" element={<PrivateAccess Component={Makro} />} />
            <Route path="market-analysis/*" element={<PrivateAccess Component={MarketAnalysis} />} />
            <Route path="objects/*" element={<PrivateAccess Component={GlobalObjects} />} />
            <Route path="sysadmin/*" element={<PrivateAccess Component={SysAdminModule} />} />
          </Route>
          {/* <Route path="/login" component={Login} /> */}
        </Routes>
      </ErrorBoundary>
    </NestedProviders>
  </>
)

export default App
