import React, { useState, useEffect, useContext, createContext } from 'react'
import L from 'leaflet'

import { useAddToMap } from './LeafletMap'
import { useOverlay } from './Overlay'
import { usePaneOptions } from './Pane'

const lgContext = createContext(null)

/** Wrap multiple Layers (GeoJSON, Markers, ...) in a LayerGroup */
export const LayerGroup = ({ children, options }) => {
  const paneOptions = usePaneOptions()
  const [layerGroup] = useState(L.layerGroup(undefined, { ...paneOptions, ...options }))
  useAddToMap(layerGroup)
  useOverlay(layerGroup)
  return <lgContext.Provider value={layerGroup}>{children}</lgContext.Provider>
}

/** Appends and removes itself from a LayerGroup if one exists */
export const useLayerGroup = (layer) => {
  const layerGroup = useContext(lgContext)

  useEffect(() => {
    if (layerGroup == null) return
    layerGroup.addLayer(layer)
    return () => {
      layerGroup.removeLayer(layer)
    }
  }, [layer, layerGroup])
}
