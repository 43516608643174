import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import Card from 'components/atoms/Card'
import { TextHeader } from 'components/atoms/Text'
import { Flex } from 'components/atoms/Box'
import useEscapeKey from 'lib/hooks/useEscapeKey'
import { BudiconCrossUi } from 'bgag-budicons'
// import { navigate } from '@reach/router'

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000050;
  outline: 0;
  display: block;
  width: 100%;
  height: 100%;
  /* overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto; */
  overflow: auto;
`

const ModalDialog = styled.div`
  position: relative;
  width: auto;
  margin: 0.5rem;
  /* pointer-events: none; */
  pointer-events: auto;
  transition: transform 0.3s ease-out;
  /* transform: translate(0, -50px); */
  /* TODO: @media (prefers-reduced-motion: reduce) {} */
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  &::before {
    display: block;
    height: calc(100vh - 1rem);
    content: '';
  }
  @media (min-width: 576px) {
    max-width: 500px;
    margin: 10px auto 1.75rem auto;
    min-height: calc(100% - 3.5rem);
    &::before {
      height: calc(100vh - 3.5rem);
    }
  }
  @media (min-width: 992px) {
    max-width: ${(props) => (props.fixedMaxWidth ? '500px' : '800px')};
  }
  /* @media (min-width: 1200px) {
    max-width: 1080px;
  } */
`

export const ToggleContent = ({ toggle, content, hashID = false }) => {
  const [isShown, setIsShown] = useState(false)
  const hide = () => {
    setIsShown(false)
    // if (hashID) history.replaceState({}, hashID, '')
  }
  const show = () => {
    // if (hashID) history.replaceState({}, '', hashID) // navigate('?' + hashID)
    // if (hashID) setIsShown(true)
    setIsShown(true)
  }

  return (
    <React.Fragment>
      {toggle(show)}
      {isShown && content(hide)}
    </React.Fragment>
  )
}

export const ModalHeader = ({ title, hide }) => {
  useEscapeKey(hide)
  return (
    <Flex justifyContent="space-between" mb={3} pr={1}>
      <TextHeader>{title}</TextHeader>
      <BudiconCrossUi style={{ cursor: 'pointer' }} color="#111" size="20px" onClick={hide} />
    </Flex>
  )
}

export const ModalContainer = ({ children, fixedMaxWidth = false, style = {} }) =>
  ReactDOM.createPortal(
    <div style={style}>
      <ModalBackdrop />
      <ModalWrapper tabIndex="-1" role="dialog" aria-modal="true">
        <ModalDialog role="document" fixedMaxWidth={fixedMaxWidth}>
          <Card width="100%" flexDirection="column" bg="white" p={4}>
            {children}
          </Card>
        </ModalDialog>
      </ModalWrapper>
    </div>,
    document.getElementById('modal')
  )
