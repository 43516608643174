export function makePolygon(exterior) {
  return [exterior]
}

export function makeMultiPolygon(exterior) {
  return [makePolygon(exterior)]
}

export const isEmptyPolygon = (poly) => (poly.length === 0 ? true : false)

/**
 * Takes bounds and converts it to polygon coordinates.
 * @param {Array.<Number>)} bounds - in [lng1, lat1, lng2, lat2]
 * @returns {Array} - a polygon in anti-clockwise order
 */
export function polyFromBounds(bounds) {
  const [lng1, lat1, lng2, lat2] = bounds
  return makePolygon([
    [lng1, lat1],
    [lng2, lat1],
    [lng2, lat2],
    [lng1, lat2],
    [lng1, lat1],
  ])
}
