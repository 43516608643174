export const topics = [
  'popDensity',
  'hhDensity',
  'ppower',
  'gebDom',
  'wohnlagen',
  'retailFood',
  'gastro',
  'school',
  'oepnvWeight',
]

export const colors = {
  wohnlagen: {
    101: '#FF8C85',
    101.5: '#FFD1AD',
    102: '#FFFAC2',
    102.5: '#E6F5FE',
    103: '#B9E6F9',
    103.5: '#94D9F8',
    104: '#4E91C6',
  },
  hhDensity: {
    j00: '#DDDDDD',
    j71: '#FFF7BC',
    j72: '#FEE391',
    j73: '#FEC44F',
    j74: '#FE9929',
    j75: '#EC7014',
    j76: '#CC4C02',
    j77: '#993404',
  },
  oepnvWeight: { j51: '#eff3ff', j52: '#c6dbef', j53: '#6baed6', j54: '#2171b5', j55: '#084594' },
  ppower: {
    j71: '#f7fcb9',
    j72: '#d9f0a3',
    j73: '#addd8e',
    j74: '#78c679',
    j75: '#41ab5d',
    j76: '#238443',
    j77: '#006837',
  },
  retailFood: {
    j91: '#ffffe5',
    j92: '#fff7bc',
    j93: '#fee391',
    j94: '#fec44f',
    j95: '#fe9929',
    j96: '#ec7014',
    j97: '#cc4c02',
    j98: '#993404',
    j99: '#662506',
  },
  gebDom: {
    p01: '#9ABC9A',
    p02: '#689A68',
    p03: '#357835',
    p04: '#DDDD9A',
    p05: '#CDCD68',
    p06: '#BCBC35',
    p07: '#FFBC9A',
    p08: '#FF9A68',
    p09: '#FF884D',
    p21: '#99B3CC',
    p22: '#7194B8',
    p23: '#5D86AE',
  },
  school: {
    j50: '#FFC8C8',
    j51: '#eef5d3',
    j52: '#d9e899',
    j53: '#b4cb3c',
    j54: '#799c29',
    j55: '#023d02',
  },
  gastro: {
    j51: '#fcc5c0',
    j52: '#fa9fb5',
    j53: '#f768a1',
    j54: '#c51b8a',
    j55: '#7a0177',
  },
  popDensity: {
    j71: '#f7fcb9',
    j72: '#d9f0a3',
    j73: '#addd8e',
    j74: '#78c679',
    j75: '#41ab5d',
    j76: '#238443',
    j77: '#006837',
  },
}
