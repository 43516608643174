import { useState, useCallback } from 'react'

import { isProduction, isUndefined, warnOnce, isFunction } from 'lib/util'

import { usePrevious } from './usePrevious'
import { useIsFirstRender } from './useDidRender'

/**
 * Hook that behaves like useState but is controllable (e.g from parent).
 *
 * Based on the behavior of input elements.
 * Found an existing implementation with examples:
 * - https://github.com/alirezamirian/react-use-controllable-state
 * - https://medium.com/quick-code/writing-ui-components-with-optionally-controllable-state-86e396a6f1ec
 */
export const useControllableState = (initialValue, externalValue, onChange) => {
  const [localValue, setLocalValue] = useState(initialValue)

  const wasControlled = !isUndefined(usePrevious(externalValue))
  const isControlled = !isUndefined(externalValue)

  const isFirstRender = useIsFirstRender()
  if (!isProduction && !isFirstRender && isControlled !== wasControlled) {
    const from = isControlled ? 'uncontrolled' : 'controlled'
    const to = isControlled ? 'controlled' : 'uncontrolled'
    const msg = `useControllableState: mode was changed from ${from} to ${to}`
    warnOnce('useControllableState', msg).then(() => console.trace())
  }

  const value = isControlled ? externalValue : localValue
  const setValue = useCallback(
    (update) => {
      setLocalValue(update)
      if (isFunction(onChange)) {
        onChange(isFunction(update) ? update(value) : update)
      }
    },
    [onChange, value]
  )

  return [value, setValue]
}
