import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import api from 'stores/api'
import useApi from 'stores/useApi'

import Box from 'components/atoms/Box'
import { TextBanner, TextParagraph } from 'components/atoms/Text'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'

const ForgotPasswordForm = ({ onMailSent }) => {
  const { t } = useTranslation()

  const [sendMail, mailSent, isSendingMail, failedSendingMail] = useApi(api.Auth.sendForgotPasswordMail, {})

  const [email, setEmail] = useState('')

  const onSubmit = (evt) => {
    evt.preventDefault()
    sendMail({ email })
  }

  useEffect(() => {
    if (typeof mailSent === 'object' && mailSent.success === true && typeof onMailSent === 'function') {
      onMailSent()
    }
  }, [mailSent, onMailSent])

  const invalidEmail = useMemo(() => {
    if (
      failedSendingMail &&
      typeof mailSent === 'object' &&
      Array.isArray(mailSent.messages) &&
      mailSent.messages.length &&
      mailSent.messages.find((msg) => msg.msg === 'invalid')
    ) {
      return true
    }
    return false
  }, [mailSent, failedSendingMail])

  return (
    <Box width="100%" textAlign="center">
      <TextBanner mt={3}>{t('forgotPassword.header')}</TextBanner>
      <TextParagraph mt={3} mb={6}>
        {t('forgotPassword.intro')}
      </TextParagraph>
      <form onSubmit={onSubmit}>
        <Input
          id="forgotPasswordEmail"
          label={t('forgotPassword.email')}
          hideLabel
          placeholder={t('forgotPassword.email')}
          autoComplete="off"
          autoFocus={true}
          disabled={isSendingMail}
          isDisabled={isSendingMail}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          appearance="default"
        />
        <Box width="100%">
          <Button
            type="submit"
            mt={6}
            mb={8}
            appearance="darker"
            isLoading={isSendingMail}
            isDisabled={isSendingMail}
            disabled={isSendingMail}
          >
            {t('forgotPassword.submit')}
          </Button>
        </Box>
      </form>
      {failedSendingMail && !invalidEmail && (
        <TextParagraph color="danger" mt={2}>
          {t('forgotPassword.failedSendingMail')}
        </TextParagraph>
      )}
      {invalidEmail && (
        <TextParagraph color="danger" mt={2}>
          {t('forgotPassword.invalidEmail')}
        </TextParagraph>
      )}
    </Box>
  )
}

export default ForgotPasswordForm
