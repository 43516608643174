import createStore from 'lib/flux-store'
import api from 'stores/api'

const configKeys = ['apps', 'assetClasses', 'licenceModules', 'geoModules', 'roleTypes']

const getAdminDef = () => {
  let getConfigs = configKeys.reduce((getConfigs, key) => {
    getConfigs.push(api.Config.byType(key))
    return getConfigs
  }, [])

  return Promise.all(getConfigs).then((configs) => {
    configs = configs.reduce((configs, res, index) => {
      configs[configKeys[index]] = res.data.data
      return configs
    }, {})

    let def = {
      modules: [],
      assetClasses: [],
      geo: { areaTypes: [], cityTypes: [] },
    }
    configs.licenceModules.forEach((lm) => {
      def.modules.push({
        key: lm.key,
        i18key: 'licenceModule.' + lm.key,
        hasSegmentPermissions: lm.hasSegmentPermissions,
        hasGeoPermissions: lm.hasGeoPermissions,
      })
    })
    configs.assetClasses.forEach((ac) => {
      def.assetClasses.push({
        key: ac,
        i18key: 'assetClasses.' + ac,
      })
    })
    configs.geoModules.forEach((module) => {
      module.keys.forEach((key) => {
        def.geo[module.type].push({
          key,
          i18key: module.type + '.' + key,
        })
      })
    })

    let valid = new Date()
    valid = valid.setDate(valid.getDate() + 1)

    return {
      licenceDef: def,
      roleTypes: configs.roleTypes,
      validUntil: valid,
    }
  })
}

const actions = {
  fetchLicenceDef: () => (dispatch) => {
    dispatch({ type: 'setLoading', payload: true })

    getAdminDef()
      .then((adminDef) => {
        dispatch({
          type: 'setAdminDef',
          payload: adminDef,
        })
      })
      .catch((err) => dispatch({ type: 'setFailure', payload: err }))
  },
}

const reducer = {
  setLoading: (state, { payload }) => ({ ...state, isLoading: payload, isFailure: false }),
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setAdminDef: (state, { payload }) => ({ ...state, adminDef: payload }),
}

const initialState = {
  isLoading: false,
  isFailure: false,
  messages: null,
  adminDef: {
    licenceDef: {
      modules: [],
      assetClasses: [],
      geo: { areaTypes: [], cityTypes: [] },
    },
    roleTypes: [],
    validUntil: 0,
  },
}

export const [StoreContext, AdminStoreProvider, useAdminStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'AdminStore'
)
