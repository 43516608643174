import React from 'react'
import { GeoJSON, withPane } from 'lib/leaflet'

import riwisbland from 'assets/geo/riwisbland'
import { zIndices } from 'config/zIndices'

const riwisblandStyle = {
  color: '#BBBBBB',
  weight: '2.0',
  opacity: '1',
  fillColor: '#FFFFCC',
  fillOpacity: 0.0,
  clickable: false,
  interactive: false,
}

export const FederalStates = () => {
  return <GeoJSON data={riwisbland} style={riwisblandStyle} />
}

export const FederalStatesPane = withPane(FederalStates, 'federal-states', zIndices.federalStates)
