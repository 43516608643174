import { createSelector } from 'reselect'

import createStore from 'lib/flux-store'
import { sorter } from 'lib/helper/sorter'

import { filterObjects } from '../objects/ObjectStore.functions'
import { getObjects } from './ObjectsCache'

const initialState = {
  isLoading: false,
  isFailure: false,
  messages: null,
  objectType: null,
  objects: {},
  geometryHash: null,
  renderMap: [],
  filteredTable: [],
  renderTable: [],
  filter: {},
  sort: {},
  curObject: { setBy: null, id: null },
}

// configuration for sort algorithms.
const sortDef = [
  {
    keys: [
      'object.id',
      'object.spaceTotal',
      'tenant.space',
      'tenant.rent',
      'sales.space',
      'sales.price',
      'sales.yield',
    ],
    type: 'numeric',
  },
  {
    keys: [
      'object.name',
      'object.address',
      'object.city',
      'object.officeMarketLocation',
      'object.investor',
      'object.status',
      'object.use',
      'tenant.name',
      'tenant.category',
      'tenant.contracttype',
      'sales.buyer',
      'sales.seller',
      'sales.propertystate',
      'sales.propertytype',
    ],
    type: 'string',
  },
  {
    keys: ['object.startOfConstruction', 'object.completed', 'sales.date', 'tenant.contractsigning'],
    type: 'date',
  },
]
const getValuesByKeys = (keys, dataSources, index1, index2) => {
  let val1, val2
  if (keys[0] === 'object') {
    val1 = dataSources[index1.objIndex].properties[keys[1]]
    val2 = dataSources[index2.objIndex].properties[keys[1]]
  } else {
    if (
      typeof index1.index === 'undefined' ||
      typeof index2.index === 'undefined' ||
      typeof dataSources[index1.objIndex].properties[keys[0]] === 'undefined' ||
      typeof dataSources[index2.objIndex].properties[keys[0]] === 'undefined'
    ) {
      return [0, 0]
    }
    val1 = dataSources[index1.objIndex].properties[keys[0]][index1.index][keys[1]]
    val2 = dataSources[index2.objIndex].properties[keys[0]][index2.index][keys[1]]
  }
  return [val1, val2]
}

const actions = {
  fetchObjects:
    ({ objectType, filter, countryCodes }) =>
    (dispatch) => {
      dispatch({ type: 'setLoading', payload: true })
      getObjects(objectType, filter, countryCodes)
        .then((features) => {
          dispatch({
            type: 'setObjects',
            payload: { objects: { type: 'FeatureCollection', features }, objectType },
          })
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: err })
          throw err
        })
    },
  resetPreparedObjects: () => ({}),
  prepareObjects: ({ sort, objects }) => ({ sort, objects }),
  setCurObject: (curObject) => ({ curObject }),
}
const reducer = {
  setLoading: (state, { payload }) => ({ ...state, isLoading: payload, isFailure: false }),
  setFailure: (state, { payload }) => ({ ...state, isLoading: false, isFailure: true, messages: payload }),
  setObjects: (state, { payload }) => {
    return {
      ...state,
      isLoading: false,
      renderMap: [],
      renderTable: [],
      sort: {},
      filter: {},
      curObject: { setBy: null, id: null },
      ...payload,
    }
  },
  resetPreparedObjects: (state) => {
    if (!state.renderMap.length && !state.renderTable.length) {
      return state
    }
    return {
      ...state,
      renderMap: [],
      renderTable: [],
    }
  },
  prepareObjects: (state, action) => {
    if (state.isLoading || state.isFailure || state.objectType === null) {
      return state
    }
    const newState = { ...state }
    let changed = false
    if (!action.objects || (action.objects && !Object.is(state.objects, action.objects))) {
      changed = true
      let objects = action.objects || newState.objects
      objects = objects.features || []
      const { render, renderTable } = filterObjects({
        objectType: newState.objectType,
        objects,
        // no actual filter set, since filtered already server side
        filter: {},
      })
      newState.renderMap = render
      newState.filteredTable = renderTable
      newState.renderTable = renderTable
    }
    if (
      changed ||
      !Object.is(state.sort, action.sort) ||
      (action.objects && !Object.is(state.objects, action.objects))
    ) {
      changed = true
      newState.renderTable = sorter({
        indices: newState.filteredTable,
        dataSources: newState.objects.features,
        sort: action.sort,
        sortDef,
        getValuesByKeys,
      })
      newState.sort = action.sort
    }
    return changed ? newState : state
  },
  setCurObject: (state, { curObject }) => {
    if (curObject.setBy !== 'loadProfile' && state.curObject.id === curObject.id) {
      curObject = { ...initialState.curObject }
    }
    return { ...state, curObject }
  },
}

const $curObject = createSelector(
  (state) => state.objects,
  (state) => state.curObject,
  (state) => state.renderMap,
  (objects, curObject, renderMap) => {
    if (curObject.id === null) return null
    if (!renderMap.find((item) => item.id === curObject.id)) return null
    const { features = [] } = objects
    return features.find((feature) => feature.properties.id === curObject.id)
  }
)

export const [ObjectStoreContext, GlobalObjectStoreProvider, useObjectStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'GlobalObjectStore'
)

export const ObjectStoreSelector = { $curObject }
