import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Range } from 'rc-slider'

import { useNumbersFormats } from 'config/numbersFormats'
import { useDebouncedUpdates } from 'lib/hooks'
import { rgbToHex } from 'lib/util/colors'
import Spinner from 'components/atoms/Spinner'
import { ColorAllocator } from 'components/UtilHelper'
import { TextHeader } from 'components/atoms/Text'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import { Flex } from 'components/atoms/Box'

import { useMethodStore } from './Store'
import { generateZoneName } from './generateZoneName'
import { useGeotoolsStore } from '../Zones/Store'

export const ControlNewFzr = () => {
  const { t } = useTranslation()
  const [{ isLoading, localFeatureCollection, rangetype, mode, center, ...opts }, setOpts, close] =
    useMethodStore('fzr')
  const [{ collection }, { addZone }] = useGeotoolsStore()
  const numbersFormats = useNumbersFormats()

  const [range, setRange] = useDebouncedUpdates(
    opts.range,
    useCallback((range) => setOpts({ range }), [setOpts]),
    500
  )

  const rangeMin = rangetype === 'time' ? 60 : 500
  const rangeMax = rangetype === 'time' ? 60 * 60 : 50_000
  const rangeStep = rangetype === 'time' ? 60 : 500
  const addStep = () => {
    if (range.length < 4) {
      setOpts({ isLoading: true })
      const step = rangetype === 'time' ? 300 : 1000
      const curMax = Math.max(...range)
      const curMin = Math.min(...range)
      const oobMax = curMax + step > rangeMax
      const oobMin = curMin - step < rangeMin
      const position = oobMax ? (oobMin ? 1 : 0) : range.length
      const value = oobMax ? (oobMin ? curMin + step : curMin - step) : curMax + step
      const newRange = [...range.slice(0, position), value, ...range.slice(position)]
      setRange(newRange)
    }
  }
  const removeStep = () => {
    if (range.length > 1) {
      setOpts({ isLoading: true })
      setRange(range.slice(0, range.length - 1))
    }
  }

  useEffect(() => {
    setOpts({ center: collection.meta.locationGeometry })
  }, [collection, setOpts])

  const addZones = () => {
    let index = collection.meta.featureCount
    for (const feature of localFeatureCollection.features.slice().reverse()) {
      const creationMethod = 'fzr'
      const range = [feature.properties.fromRange, feature.properties.range]
      const creationParams = { center: center, range, rangetype, mode }
      const name = generateZoneName(t, creationMethod, creationParams)
      const style = { color: rgbToHex(ColorAllocator(index)) }
      addZone({ creationMethod, creationParams, polygon: feature.geometry, style, name })
      index++
    }
    close()
  }

  return (
    <div>
      <TextHeader>{t('geotools.fzr.title')}</TextHeader>
      <div>
        {t(`geotools.fzr.drivetime.${rangetype}`)}:{' '}
        {range
          .map((r) => numbersFormats.roundedFraction0.format(rangetype === 'time' ? parseInt(r / 60) : r))
          .join(', ')}{' '}
        {t(`geotools.fzr.units.${rangetype}`)}
      </div>
      <Range
        value={range}
        onChange={(val) => {
          setOpts({ isLoading: true })
          setRange(val)
        }}
        max={rangeMax}
        min={rangeMin}
        step={rangeStep}
      />
      <Button onClick={removeStep} mr="1" mt="1" disabled={range.length <= 1}>
        -
      </Button>
      <Button onClick={addStep} mr="1" mt="1" disabled={range.length >= 4}>
        +
      </Button>
      {rangetype === 'time' && (
        <Button onClick={() => setRange([300, 600, 900])} mt="1">
          {t('geotools.fzr.btm_5_10_15_min')}
        </Button>
      )}
      <Select
        id="geotools.fzr.mode"
        label={t('geotools.fzr.mode')}
        value={mode}
        options={MODES.map((mode) => ({ value: mode, label: t(`geotools.fzr.modes.${mode}`) }))}
        onChange={(evt) => {
          setOpts({ mode: evt.target.value })
        }}
      />
      <Select
        id="geotools.fzr.rangetype"
        label={t('geotools.fzr.rangetype')}
        value={rangetype}
        options={RANGETYPES.map((type) => ({ value: type, label: t(`geotools.fzr.rangetypes.${type}`) }))}
        onChange={(evt) => {
          const rangetype = evt.target.value
          setOpts({ rangetype, range: [rangetype === 'time' ? 300 : 2000] })
        }}
      />
      <Flex justifyContent="center">
        <Button onClick={addZones} disabled={isLoading || !localFeatureCollection} width="100px">
          {t('actions.actionAdd')} {isLoading && <Spinner inline appearance="tertiary" />}
        </Button>

        <Button ml={2} onClick={close} appearance="secondary">
          {t('actions.actionCancel')}
        </Button>
      </Flex>
    </div>
  )
}

const MODES = ['car', 'pedestrian']
const RANGETYPES = ['time', 'distance']
