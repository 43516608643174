import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { useTheme, css } from 'styled-components'
import { rgba } from 'polished'
import { themeGet } from '@styled-system/theme-get'

import typography from 'components/theming/shared/typography'
import sizes from 'components/theming/shared/sizes'

import { Flex } from './Box'
import { TextFormLabel } from './Text'
import CheckboxIcon from 'components/icons/CheckboxIcon'

const CheckboxContainer = styled(Flex)`
  ${(props) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `}
`
const Label = styled.span`
  ${(props) => {
    if (props.appearance === 'normal') {
      return css`
        margin-left: 0.125rem;
      `
    } else if (props.appearance === 'icon') {
      return css`
        margin-top: 0.25rem;
        font-size: 0.75rem;
      `
    } else if (props.appearance === 'dotted-border') {
      return css`
        margin-left: 0.3rem;
        border: 1px dotted black;
        border-radius: 3px;
        flex: 1;
        padding: 3px;
      `
    }
  }}
`

const Error = styled.div`
  font-family: ${typography.fonts.sansSerif};
  font-size: ${typography.fontSizes.xs};
  line-height: ${typography.lineHeights.shorter};
  pointer-events: none;

  background: none;
  color: ${themeGet('colors.negative', 'red')};
  padding: ${sizes['1']} ${sizes['5']} ${sizes['1']} ${sizes['2']};
`

const Checkbox = forwardRef((props, ref) => {
  const {
    id,
    name,
    flex,
    disabled,
    color,
    iconColor,
    icon = null,
    iconSize,
    transparent = icon === null,
    appearance,
    label = null,
    hideLabel,
    checked,
    onChange,
    onBlur,
    onFocus,
    error,
    children,
    pt = 2,
    pb = 1,
    ml = 2,
    mr = 1,
    ...rest
  } = props
  // const computedColor = useTheme().colors[props.color] || props.color
  const themeColor = useTheme().colors['secondary']
  const computedColor = iconColor ? iconColor : themeColor

  let appearanceProperties = {}

  if (appearance === 'icon') {
    appearanceProperties = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    }
  }
  if (appearance === 'dotted-border') {
    appearanceProperties = {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    }
  }

  return (
    <CheckboxContainer alignItems="center" flexWrap="wrap" flex={flex}>
      <TextFormLabel
        disabled={disabled}
        fontWeight="normal"
        htmlFor={id}
        bg={transparent ? 'transparent' : 'white'}
        pt={pt !== null ? pt : 2}
        pb={pb}
        mr={mr}
        ml={ml}
        color={icon ? (checked ? computedColor : rgba(computedColor, 0.5)) : color}
        style={{
          borderRadius: '0.3rem',
          // backgroundColor: 'white',
          // margin: '0.125rem',
          // padding: '0.125rem',
        }}
        {...appearanceProperties}
        {...rest}
      >
        <input
          id={id}
          name={name}
          style={{ display: 'none' }}
          type="checkbox"
          ref={ref}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {icon ? (
          React.createElement(icon, {
            size: iconSize,
            color: checked ? computedColor : rgba(computedColor, 0.5),
          })
        ) : (
          <CheckboxIcon
            size={iconSize}
            strokeWidth="0.8"
            isChecked={checked}
            activeColor={computedColor}
            inactiveColor={rgba(computedColor, 0.6)}
          />
        )}
        {!hideLabel && label !== null && <Label appearance={appearance}>{label}</Label>}
        {!hideLabel && label === null && <Label appearance={appearance}>{children}</Label>}
        {error && <Error>{error}</Error>}
      </TextFormLabel>
    </CheckboxContainer>
  )
})

Checkbox.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
  flex: PropTypes.string,
  hideLabel: PropTypes.bool,
  checked: PropTypes.bool,
  iconSize: PropTypes.string,
  appearance: PropTypes.string,
}

Checkbox.defaultProps = {
  label: null,
  flex: null,
  hideLabel: false,
  checked: false,
  iconSize: '1em',
  appearance: 'normal',
}

export default Checkbox
