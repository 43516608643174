import { useEffect } from 'react'

/** Registers and unregisters an eventlistener by name
 * Searches (case insensitive) in the props for the event listener function
 * @param {Evented} evented - A leaflet class inheriting from Evented (can fire events)
 * @param {String} name - The name of the event
 * @param {Object} props - The component properties. Contains the event listener function with "on" prepended
 */
const useEventListener = (evented, name, props) => {
  const func = getI(props, 'on' + name)
  useEffect(() => {
    if (evented === null) return
    if (typeof func !== 'function') return
    evented.on(name, func)
    return () => {
      evented.off(name, func)
    }
  }, [evented, name, func])
}

export const useLayerEvents = (evented, props) => {
  usePopupEvents(evented, props)
  useTooltipEvents(evented, props)
  useEventListener(evented, 'add', props)
  useEventListener(evented, 'remove', props)
}

export const useGridLayerEvents = (evented, props) => {
  useInteractiveLayerEvents(evented, props)
}

export const useFeatureGroupEvents = (evented, props) => {
  useEventListener(evented, 'layeradd', props)
  useEventListener(evented, 'layerremove', props)
}

export const useGeoJSONEvents = (evented, props) => {
  useInteractiveLayerEvents(evented, props)
  useFeatureGroupEvents(evented, props)
}

export const useMapEvents = (evented, props) => {
  usePopupEvents(evented, props)
  useEventListener(evented, 'baselayerchange', props)
  useEventListener(evented, 'overlayadd', props)
  useEventListener(evented, 'overlayremove', props)
  useEventListener(evented, 'layeradd', props)
  useEventListener(evented, 'layerremove', props)
  useEventListener(evented, 'zoomlevelschange', props)
  useEventListener(evented, 'resize', props)
  useEventListener(evented, 'unload', props)
  useEventListener(evented, 'viewreset', props)
  useEventListener(evented, 'load', props)
  useEventListener(evented, 'zoomstart', props)
  useEventListener(evented, 'movestart', props)
  useEventListener(evented, 'zoom', props)
  useEventListener(evented, 'move', props)
  useEventListener(evented, 'zoomend', props)
  useEventListener(evented, 'moveend', props)
  useEventListener(evented, 'autopanstart', props)
  useEventListener(evented, 'tooltipopen', props)
  useEventListener(evented, 'tooltipclose', props)
  useEventListener(evented, 'locationerror', props)
  useEventListener(evented, 'locationfound', props)
  useEventListener(evented, 'click', props)
  useEventListener(evented, 'dblclick', props)
  useEventListener(evented, 'mousedown', props)
  useEventListener(evented, 'mouseup', props)
  useEventListener(evented, 'mouseover', props)
  useEventListener(evented, 'mouseout', props)
  useEventListener(evented, 'mousemove', props)
  useEventListener(evented, 'contextmenu', props)
  useEventListener(evented, 'keypress', props)
  useEventListener(evented, 'keydown', props)
  useEventListener(evented, 'keyup', props)
  useEventListener(evented, 'preclick', props)
  useEventListener(evented, 'zoomanim', props)
}

export const useMarkerEvents = (evented, props) => {
  useDraggingEvents(evented, props)
  useEventListener(evented, 'move', props)
}

export const useDraggingEvents = (evented, props) => {
  useEventListener(evented, 'dragstart', props)
  useEventListener(evented, 'movestart', props)
  useEventListener(evented, 'drag', props)
  useEventListener(evented, 'dragend', props)
  useEventListener(evented, 'moveend', props)
}

export const useInteractiveLayerEvents = (evented, props) => {
  useLayerEvents(evented, props)
  useEventListener(evented, 'click', props)
  useEventListener(evented, 'dblclick', props)
  useEventListener(evented, 'mousedown', props)
  useEventListener(evented, 'mouseup', props)
  useEventListener(evented, 'mouseover', props)
  useEventListener(evented, 'mouseout', props)
  useEventListener(evented, 'contextmenu', props)
}

export const usePopupEvents = (evented, props) => {
  useEventListener(evented, 'popupopen', props)
  useEventListener(evented, 'popupclose', props)
}

export const useTooltipEvents = (evented, props) => {
  useEventListener(evented, 'tooltipopen', props)
  useEventListener(evented, 'tooltipclose', props)
}

/** Access an objects property in a case insensitive way */
function getI(obj, searchKey) {
  const compareI = (str1, str2) => str1.toLowerCase() === str2.toLowerCase()
  const findKey = Object.keys(obj).find((key) => compareI(key, searchKey))
  return obj[findKey]
}
