import { useAddToMap } from '../components/LeafletMap'
import { useLayerGroup } from '../components/LayerGroup'
import { useOverlay } from '../components/Overlay'
import { useBaseLayer } from '../components/BaseLayer'
/** Does what a layer should do:
 * Appends itself to the map
 * Registers itself in wrapped LayerGroups, Overlays or BaseLayers.
 */
export const useLayerHelper = (layer) => {
  useAddToMap(layer)
  useLayerGroup(layer)
  useOverlay(layer)
  useBaseLayer(layer)
}
