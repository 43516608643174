import React, { useCallback, useMemo } from 'react'

import api from 'stores/api'
import { zIndices } from 'config/zIndices'
import { useGeotoolsStore, useCollectionPermissions } from 'components/geotools'
import { makeIcon } from 'components/MapHelpers'

import { Marker, withPane } from 'lib/leaflet'

export const CollectionMarker = () => {
  const [{ collection }, { updateCollection }] = useGeotoolsStore()
  const { hasWriteAccess } = useCollectionPermissions()
  const markerColor = collection.meta.locationColor

  const handleDragEnd = useCallback(
    (evt) => {
      const coordinates = Object.values(evt.target.getLatLng()).reverse()
      api.Location.reverse({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates,
        },
      })
        .then((res) => {
          let locationName = 'Unknown Address'
          if (res.data.data) {
            const feature = res.data.data
            locationName = feature.properties.name
          }
          const locationGeometry = { type: 'Point', coordinates }
          updateCollection({ locationName, locationGeometry })
        })
        .catch((err) => console.log(err))
    },
    [updateCollection]
  )

  const point = useMemo(() => collection.meta.locationGeometry.coordinates.slice().reverse(), [collection])

  const options = useMemo(
    () => ({
      draggable: true,
      icon: makeIcon(markerColor),
    }),
    [markerColor]
  )

  return <Marker position={point} options={options} onDragEnd={handleDragEnd} draggable={hasWriteAccess} />
}

export const CollectionMarkerPane = withPane(CollectionMarker, 'collection-marker', zIndices.collectionMarker)
