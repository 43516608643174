import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BudiconStar, BudiconChevronTop, BudiconChevronBottom, BudiconCrossUi } from 'bgag-budicons'

import SimpleColorPicker from 'simple-color-picker'

import Box, { Flex } from 'components/atoms/Box'
import Button from 'components/atoms/Button'
import Input from 'components/atoms/Input'
import { TextHeader, TextSubHeader } from 'components/atoms/Text'
import { NoStyleButton } from 'components/atoms/NoStyleButton'
import { useControllableState, useStableCallback, useListState, usePriorityListState } from 'lib/hooks'
import { toggle } from 'lib/util'

export const ColorPicker = ({
  defaultValue,
  value,
  onChange,
  favorites,
  onFavoritesChange,
  history,
  onHistoryChange,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation('translation')
  const [localValue, setValue] = useControllableState(defaultValue ?? value ?? DEFAULT_COLOR, value, onChange)
  const [displayCustom, setDisplayCustom] = useState(false)
  const [displaySuggestions, setDisplaySuggestions] = useState((favorites ?? []).length === 0)

  const [localFavorites, setFavorites] = useListState(
    favorites ?? [],
    favorites,
    onFavoritesChange,
    isEqualColors
  )
  const [localHistory, setHistory] = usePriorityListState(
    history ?? [],
    history,
    onHistoryChange,
    10,
    isEqualColors
  )

  return (
    <Container>
      <TextHeader mb={2}>{t('colorPicker.title')}</TextHeader>
      <Flex alignItems="center">
        <ColorBox color={localValue} />
        <Box flex="1" />
        <IconButton onClick={() => setFavorites(localValue)}>
          {localFavorites.includes(localValue) ? (
            <BudiconFilledStar size="1.6em" />
          ) : (
            <BudiconStar type="shady" size="1.6em" />
          )}
        </IconButton>
        <Box mr={1} />
        <Button
          onClick={() => {
            setHistory(localValue)
            onSubmit?.(localValue)
          }}
        >
          {t('actions.actionSelect')}
        </Button>
        {onCancel && (
          <>
            <Box mr={1} />
            <Button
              appearance="secondary"
              svgOnly
              onClick={() => onCancel(localValue)}
              style={{ paddingLeft: '8px', paddingRight: '8px' }}
            >
              <BudiconCrossUi size="1em" />
            </Button>
          </>
        )}
      </Flex>
      {localFavorites.length > 0 && (
        <>
          <TextSubHeader mt={2} mb={1}>
            {t('colorPicker.favorites')}
          </TextSubHeader>
          <ColorRow colors={localFavorites} onChange={setValue} />
        </>
      )}
      {localHistory.length > 0 && (
        <>
          <TextSubHeader mt={2} mb={1}>
            {t('colorPicker.history')}
          </TextSubHeader>
          <ColorRow colors={localHistory} onChange={setValue} />
        </>
      )}
      <TextSubHeader mt={2} mb={1}>
        <NoStyleButton onClick={() => setDisplaySuggestions(toggle)}>
          {t('colorPicker.suggestions')}{' '}
          {displaySuggestions ? <Chevron type="top" /> : <Chevron type="bottom" />}
        </NoStyleButton>
      </TextSubHeader>
      {displaySuggestions && <ColorRow colors={SUGGESTIONS} onChange={setValue} />}
      <TextSubHeader mt={3} mb={1}>
        <NoStyleButton onClick={() => setDisplayCustom(toggle)}>
          {t('colorPicker.custom')} {displayCustom ? <Chevron type="top" /> : <Chevron type="bottom" />}
        </NoStyleButton>
      </TextSubHeader>
      {displayCustom && (
        <div>
          <ColorTextInput value={localValue} onChange={setValue} />
          <CustomColor value={localValue} onChange={setValue} />
        </div>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: inline-block;
  background-color: white;
`

const ColorRow = ({ colors, onChange }) => {
  return (
    <Flex flexWrap="wrap" width="200px">
      {colors.map((color, index) => (
        <ColorButton key={index} title={color} color={color} onClick={() => onChange(color)} />
      ))}
    </Flex>
  )
}

const ColorBox = styled.div.attrs(({ color }) => ({
  style: { backgroundColor: color },
}))`
  width: 40px;
  height: 40px;
  border: 1px solid grey;
  border-radius: 4px;
`

const ColorButton = styled.button`
  background-color: ${({ color }) => color};
  width: 40px;
  height: 40px;
  border: ${({ color }) => (isEqualColors(color, '#ffffff') ? '1px solid grey' : 'none')};
`

const CustomColor = ({ value, onChange }) => {
  const container = useRef(null)
  const [colorPicker] = useState(() => new SimpleColorPicker({ color: value, width: 200, height: 175 }))
  const lastUpdatedFromColorPicker = useRef(value)
  const stableOnChange = useStableCallback((color) => {
    // when color has been set via colorPicker.setColor do not process further
    const isUpdateFromValue = isEqualColors(color, value)
    // colorPicker.onChange may trigger between renders. do not process those updates
    const lastUpdateHasRendered = lastUpdatedFromColorPicker.current === value

    if (!isUpdateFromValue && lastUpdateHasRendered) {
      lastUpdatedFromColorPicker.current = color
      onChange(color)
    }
  })

  useLayoutEffect(() => {
    colorPicker.appendTo(container.current)
    colorPicker.onChange(stableOnChange)
    return () => {
      colorPicker.remove()
    }
  }, [colorPicker, stableOnChange])

  useEffect(() => {
    const prevColor = lastUpdatedFromColorPicker.current
    const isUpdateFromColorPicker = isEqualColors(prevColor, value)
    if (!isUpdateFromColorPicker) {
      colorPicker.setColor(value)
    }
    lastUpdatedFromColorPicker.current = value
  }, [colorPicker, value])

  return <div ref={container} />
}

const ColorTextInput = ({ value, onChange }) => {
  const noUpdate = useRef(true)
  const [inputText, setInputText] = useState(value)
  const stableOnChange = useStableCallback(onChange)

  useEffect(() => {
    noUpdate.current = true
    setInputText(value)
  }, [value])

  useEffect(() => {
    if (noUpdate.current) {
      noUpdate.current = false
      return
    }
    if (!inputText.match(/^#[0-9a-fA-F]{6}$/)) {
      return
    }
    stableOnChange(inputText)
  }, [inputText, stableOnChange])

  return (
    <Input
      id="colorpicker-value"
      label="colorpicker"
      hideLabel
      value={inputText}
      onChange={(evt) => setInputText(evt.target.value)}
    />
  )
}

const IconButton = styled.button`
  background-color: white;
  border-radius: 6px;
`

const BudiconFilledStar = (props) => {
  const node = useRef(null)
  useLayoutEffect(() => {
    const svg = node.current.firstChild
    const g = svg.firstChild
    const path = g.childNodes[1]
    path.setAttribute('stroke-width', '1')
    path.setAttribute('fill', 'yellow')
    const d = path.getAttribute('d')
    path.setAttribute('d', 'M' + d.split('M')[2])
  }, [])
  return (
    <span ref={node}>
      <BudiconStar type="shady" {...props} />
    </span>
  )
}

const Chevron = ({ type }) => {
  const offset = type === 'top' ? '-3px' : '-5px'
  return (
    <span style={{ fontSize: '14px', position: 'relative', top: offset }}>
      {type === 'top' ? <BudiconChevronTop /> : <BudiconChevronBottom />}
    </span>
  )
}

const isEqualColors = (a, b) => a.toUpperCase() === b.toUpperCase()

const DEFAULT_COLOR = '#002940'
const SUGGESTIONS = [
  '#00487A',
  '#0091CA',
  '#58BEE4',
  '#29AC9A',
  '#A9CA5C',
  '#F8E558',
  '#F8A03C',
  '#E15136',
  '#825598',
  '#B0B2B1',
  '#336D95',
  '#33A7D6',
  '#7ACBE9',
  '#54BDAE',
  '#BBD57E',
  '#FAEB78',
  '#FAB363',
  '#E7745F',
  '#9C77AD',
  '#C0C2C1',
  '#002046',
  '#005B9A',
  '#2C8CBE',
  '#107764',
  '#749B2E',
  '#DBC02B',
  '#DA6919',
  '#BA2717',
  '#4B2862',
  '#7C7E7D',
]
