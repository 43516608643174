import { useEffect } from 'react'
import { useLeaflet } from './LeafletMap'

export const AttributionControl = ({ prefix }) => {
  const map = useLeaflet()
  useEffect(() => {
    map.attributionControl.addAttribution(prefix)
    return () => {
      map.attributionControl.removeAttribution(prefix)
    }
  }, [prefix, map])
  return null
}
