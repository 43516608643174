import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useLeaflet } from 'lib/leaflet'
import { InitGeoman } from 'lib/leaflet-geoman'

import { useGeotoolsStore } from './Zones/Store'
import { ZonesPane } from './Zones/Layers.js'
import { EditPane } from './Edit/Layers'
import { DraftPane } from './Drafts'

export const GeotoolsLayer = () => {
  const [{ collection }] = useGeotoolsStore()
  const map = useLeaflet()
  const { i18n } = useTranslation()

  useEffect(() => {
    map.pm.setLang(i18n.language)
  }, [map, i18n.language])

  if (!collection) return null
  return (
    <>
      <InitGeoman />
      <DraftPane />
      <ZonesPane />
      <EditPane />
    </>
  )
}
