import styled from 'styled-components'

export const NoStyleButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background-color: inherit;
  font: inherit;
  color: inherit;
`
