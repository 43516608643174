import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { space } from 'styled-system'

import { BudiconCrossUi } from 'bgag-budicons'

import { TextFormLabel } from './Text'
import Box, { Flex } from './Box'
import typography from 'components/theming/shared/typography'
import sizes from 'components/theming/shared/sizes'
import { jiggle } from 'components/theming/shared/animation'
import { radii } from 'components/theming/shared/misc'

// prettier-ignore
const InputText = styled.input`
  ::placeholder {
    opacity: .7;
  }
  appearance: none;
  border:none;
  box-sizing: border-box;
  display: block;
  outline: none;
  width: 100%;
  margin: 0;

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }

  &:-webkit-autofill { -webkit-box-shadow: 0 0 0 3em ${themeGet('colors.lightest', 'white')} inset; }
`;

const Error = styled.div`
  position: absolute;
  right: 0;
`

// prettier-ignore
const InputWrapper = styled.div`
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 100%;
  margin-bottom: ${sizes['2']};

  ${InputText} {
    background: ${themeGet('colors.lightest', 'white')};
    border-radius: ${radii['sm']};
    color: ${themeGet('colors.darker', '#999')};
    font-family: ${typography.fonts.sansSerif};
    font-size: ${typography.fontSizes.sm};
    line-height: ${typography.lineHeights.base};
    padding: ${sizes['1']} ${sizes['2']};
    
    box-shadow: ${themeGet('colors.mediumdark', '#ccc')} 0 0 0 1px inset;
    &:focus { box-shadow: ${themeGet('colors.primary', 'darkblue')} 0 0 0 1px inset; }

    ${props => props.appearance === 'borderless' && css`
      border: none;
      box-shadow: none;
      &:focus { box-shadow: none !important; }
    `}

    ${props => props.appearance === 'embedded' && css`
      padding: 0;
      border: none;
      background: none;
      box-shadow: none;
      font: inherit;
      &:focus { box-shadow: none !important; }
    `}

    ${props => props.appearance === 'pill' && css`
      border-radius: 3em;
      background: transparent;
      /* font-size: ${typography.fontSizes.xs};
      line-height: ${typography.lineHeights.short};
      padding: ${sizes['1']} ${sizes['3']};
      box-shadow: ${themeGet('colors.darkest', '#999')} 0 0 0 1px inset;
      &:focus { box-shadow: ${themeGet('colors.primary', 'darkblue')} 0 0 0 1px inset; } */
    `}

  }

  ${Error} {
    position: absolute;
    top: 50%;
    right: 1px;
    margin-left: 1px;
    transform: translate3d(100%, -50%, 0);
    transition: all 200ms ease-out;
    font-family: ${typography.fonts.sansSerif};
    font-size: ${typography.fontSizes.xs};
    line-height: ${typography.lineHeights.shorter};
    opacity: 0;
    pointer-events: none;

    background: ${props =>
      props.appearance !== 'embedded' &&
       'rgba(255,255,255,.9)' };
    color: ${themeGet('colors.negative', 'red')};

    ${props => props.appearance === 'embedded' && css` right: 0; `}
  }

  ${props => props.iconExists && css`
    > svg {
      transition: all 150ms ease-out;
      position: absolute;
      top: 50%;
  		margin-top: -.6em;
  		z-index: 1;
      background: transparent;

      path {
        transition: all 150ms ease-out;
        fill: ${themeGet('colors.dark', '#666')};
      }
    }

    ${InputText}:focus + svg path {
      fill: ${themeGet('colors.darker', '#333')};
    }

    ${InputText} {
      padding-left: ${sizes['10']};
      ${props.appearance === 'embedded' && css` padding-left: ${sizes['7']}; `};
    }
    > svg { left: ${props.appearance === 'embedded' ? 0 : 0.8 }em; }

  `}

  ${props => props.error && css`
    ${Error} {
      color: ${themeGet('colors.negative', 'red')};
      background: none;
      transform: translate3d(0%, -50%, 0);
      opacity: 1;
      padding: ${sizes['1']} ${sizes['5']} ${sizes['1']} ${sizes['2']};
    }

    ${InputText}:hover + ${Error},
    ${InputText}:focus + ${Error} {
      opacity: 0;
      transform: translate3d(100%, -50%, 0);
      padding: 0;
    }

    ${props.focused && css`
      ${Error} {
        opacity: 0;
        transform: translate3d(100%, -50%, 0);
      }
    `}

    ${props.appearance !== 'embedded' && css`
      ${InputText} {
          box-shadow: ${themeGet('colors.negative', 'red')} 0 0 0 1px inset;
          &:focus { box-shadow: ${themeGet('colors.negative', 'red')} 0 0 0 1px inset !important;  }
      }
    `};

    svg {
      animation: ${jiggle} 700ms ease-out;
      path { fill: ${themeGet('colors.negative', 'red')}; }
    }
  `}

  ${space}
`;

// prettier-ignore
const InputContainer = styled.div`
  overflow: hidden;
  ${props => props.flexed && css`flex: 1 1 auto;`}
  ${props => props.orientation === 'horizontal' && css`
    display: flex;
    align-items: center;
    ${InputWrapper} {
      flex: 1 1 auto;
    }
    & > ${Flex} {
      margin-right: 8px;
      flex: 0 0 ${props.labelWidth};
    }
  `}
  ${space}
`;

const ResetButton = styled(Box)`
  cursor: pointer;
  margin-left: 0.2rem;
  opacity: 0;
  color: ${(props) => props.theme.colors.mediumGrey};
  transition: all 0.3s linear;

  ${InputContainer}:hover & {
    opacity: 1;
  }
`

const Input = forwardRef(
  (
    {
      id,
      type,
      value,
      label,
      labelWeight,
      labelWidth = 'auto',
      hideLabel,
      showReset,
      orientation,
      Icon,
      error,
      appearance,
      focused,
      lastErrorValue,
      flexed,
      mb,
      onChange,
      ...rest
    },
    ref
  ) => {
    const errorId = `${id}-error`
    let errorMessage = error
    if (lastErrorValue && value !== lastErrorValue) {
      errorMessage = null
    }

    const reset = useCallback(() => {
      onChange({ target: { id, value: '' } })
    }, [id, onChange])

    return (
      <InputContainer orientation={orientation} flexed={flexed} labelWidth={labelWidth}>
        <Flex flexDirection="row-reverse">
          {showReset === true && (
            <ResetButton onClick={reset}>
              <BudiconCrossUi />
            </ResetButton>
          )}
          {label && !hideLabel && (
            <Box flex="1 0 auto">
              <TextFormLabel fontWeight={labelWeight} htmlFor={id} ml={1} mb={1}>
                {label}
              </TextFormLabel>
            </Box>
          )}
        </Flex>
        <InputWrapper
          error={errorMessage}
          data-error={error}
          iconExists={Icon ? true : false}
          appearance={appearance}
          focused={focused}
          mb={mb}
        >
          {Icon && <Icon size="1.1em" type="outline" aria-hidden />}
          <InputText
            id={id}
            ref={ref}
            type={type}
            value={value}
            aria-describedby={errorId}
            aria-invalid={!!error}
            onChange={onChange}
            {...rest}
          />
          <Error id={errorId}>{error}</Error>
        </InputWrapper>
      </InputContainer>
    )
  }
)

Input.defaultProps = {
  value: '',
  type: 'text',
  appearance: 'default',
  hideLabel: false,
  orientation: 'vertical',
  Icon: null,
  error: null,
  focused: false,
  lastErrorValue: null,
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  value: PropTypes.string,
  appearance: PropTypes.oneOf(['default', 'embedded', 'pill', 'borderless']),
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  orientation: PropTypes.oneOf(['vertical', 'horizontal']),
  Icon: PropTypes.elementType,
  error: PropTypes.string,
  focused: PropTypes.bool,
  lastErrorValue: PropTypes.string,
}

export default Input
