export function sortLocaleCompareAsc(val1, val2) {
  val1 = typeof val1?.localeCompare !== 'function' ? '' : val1
  val2 = typeof val2?.localeCompare !== 'function' ? '' : val2
  return val1.trim().localeCompare(val2.trim())
}

export function sorter({ indices, dataSources, sort, sortDef, getValuesByKeys = null }) {
  const sorted = [...indices]
  // console.time('sorting')
  if (sort.key !== null && sort.dir !== null) {
    const keys = sort.key.split('.')
    let curSortDef = sortDef.find((def) => def.keys.indexOf(sort.key) >= 0)
    if (curSortDef && curSortDef.type) {
      const sortType = curSortDef.type
      if (sortType === 'fct') {
        sorted.sort(curSortDef.sort(dataSources, sort.dir, sort.extra))
      } else {
        sorted.sort((index1, index2) => {
          if (sort.dir !== 'asc') {
            const tmp = index1
            index1 = index2
            index2 = tmp
          }
          let val1, val2
          if (typeof getValuesByKeys === 'function') {
            ;[val1, val2] = getValuesByKeys(keys, dataSources, index1, index2)
          } else {
            val1 = dataSources[index1.index][keys[0]]
            val2 = dataSources[index2.index][keys[0]]
          }
          if (sortType === 'numeric') {
            val1 = parseFloat(val1)
            val2 = parseFloat(val2)
            // conditionals in following structure for sort performance!
            if (isNaN(val1) || isNaN(val2)) {
              if (isNaN(val1) && isNaN(val2)) {
                val1 = 0
                val2 = 0
              } else {
                val1 = isNaN(val1) ? val2 - 1 : val1
                val2 = isNaN(val2) ? val1 - 1 : val2
              }
            }
            return val1 - val2
          } else if (sortType === 'date') {
            // the val have been set to Date('0101-01-01') instead of 0 in order to avoid sort error when a given date is less tha 1970
            // example from DB by tenure type there are dates from 1728;1860;1899 ....
            val1 = val1 === null ? Date.parse('0001') : Date.parse(val1)
            val2 = val2 === null ? Date.parse('0001') : Date.parse(val2)
            return val1 - val2
          } else if (sortType === 'boolean') {
            return val1 === val2 ? 0 : val1 ? -1 : 1
          } else {
            val1 = !val1 || typeof val1.localeCompare !== 'function' ? '' : val1
            val2 = !val2 || typeof val2.localeCompare !== 'function' ? '' : val2
            return val1.trim().localeCompare(val2.trim())
          }
        })
      }
    }
  }
  // console.timeEnd('sorting')
  return sorted
}
