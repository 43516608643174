import React, { memo } from 'react'
import { withInputGroup } from './InputGroup'

/**
 * A wrapper around the Input-element
 *
 * This Component merely for experimenting with Forms. The HTML-input spec
 * combines various types of input in one element. This leads to a lot of
 * different interfaces to deal with.
 *
 * In the Application use specialized components like TextInput, Checkbox, Colorpicker, DateInput, ...
 * These will conform to a common Interface while each handling their own implementation details.
 */
export const InputComponent = (props) => <input {...props} />
export const Input = withInputGroup(memo(InputComponent))
