/** Accepts coordinates from MultiPolygon or Polygon and returns MultiPolygon coords */
export function assureMultiPolygon(poly) {
  return typeof poly[0][0][0] === 'number' ? [poly] : poly
}

/** Wether two points are equal */
export function equalPoint(pt1, pt2) {
  return pt1[0] === pt2[0] && pt1[1] === pt2[1]
}

/** Returns a linear ring where the first and last point are not equal */
export function dedupeStartingPoint(ring) {
  const lastIdx = ring.length - 1
  return equalPoint(ring[0], ring[lastIdx]) ? ring.slice(0, lastIdx) : ring
}

const copyPoint = (pt) => JSON.parse(JSON.stringify(pt))
/** Assure the first and last point are equal in a ring */
export function dupeStartingPoint(ring) {
  const lastIdx = ring.length - 1
  return equalPoint(ring[0], ring[lastIdx]) ? ring : ring.concat([copyPoint(ring[0])])
}

/**
 * Generator that provides all the rings segments
 * Order: [[last, first], [first, second], [second, third], ...]
 */
export function* generateRingSegments(ring) {
  ring = dedupeStartingPoint(ring)
  for (let i = 0, j = ring.length - 1; i < ring.length; j = i++) {
    yield [ring[j], ring[i]]
  }
  return
}

/** Generator for all segments of Polygon|MultiPolygon */
export function* generatePolygonSegments(mpoly) {
  mpoly = assureMultiPolygon(mpoly)
  for (let poly of mpoly) {
    for (let ring of poly) {
      for (let segment of generateRingSegments(ring)) {
        yield segment
      }
    }
  }
  return
}

/**
 * Returns a bbox as [llx, lly, urx, ury]
 * ll = lower left
 * ur = upper right
 */
export function polygonBBox(mpoly) {
  const ll = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY]
  const ur = [Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY]

  mpoly = assureMultiPolygon(mpoly)
  for (let poly of mpoly) {
    for (let ring of poly) {
      for (let point of ring) {
        if (point[0] < ll[0]) ll[0] = point[0]
        if (point[1] < ll[1]) ll[1] = point[1]
        if (point[0] > ur[0]) ur[0] = point[0]
        if (point[1] > ur[1]) ur[1] = point[1]
      }
    }
  }

  return ll.concat(ur)
}
