import React, { memo, useCallback, useState, useEffect, useMemo, useRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { space } from 'styled-system'

import BodyAnchor from 'components/BodyAnchor'
import { Box } from 'components/atoms/Box'
import Input from 'components/atoms/Input'
import useOnEscape from 'lib/hooks/useOnEscape'
import useOnTab from 'lib/hooks/useOnTab'
import useOnEnter from 'lib/hooks/useOnEnter'

import { Table, Column, HeaderCell, DataCell } from 'components/Table'

const rowHeight = 28

const DropdownContainer = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  text-align: left;

  ${(props) =>
    props.appearance === 'roundedShadow' &&
    css`
      background-color: white;
      border-radius: 4px;
      padding: 4px 0px;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    `}

  ${space}
`

const InputContainer = styled.div``

const repos = (expander, container, dropdownPosition) => {
  container.style.visibility = 'hidden'
  container.style.display = 'block'

  const style = container.currentStyle || window.getComputedStyle(container)
  let hMargin = parseInt(style.marginRight) + parseInt(style.marginLeft)
  let vMargin = parseInt(style.marginTop) + parseInt(style.marginBottom)
  hMargin = !isNaN(hMargin) ? hMargin : 0
  vMargin = !isNaN(vMargin) ? vMargin : 0

  const expanderRect = expander.getBoundingClientRect()

  const overflowBottom =
    dropdownPosition.top + expanderRect.bottom + container.offsetHeight + vMargin > window.innerHeight

  const newLeft = expanderRect.left + dropdownPosition.left + hMargin
  const newTop = overflowBottom
    ? expanderRect.top - (container.offsetHeight + vMargin)
    : expanderRect.bottom + dropdownPosition.top + vMargin

  container.style.left = (newLeft < 0 ? 0 : newLeft) + 'px'
  container.style.top = (newTop < 0 ? 0 : newTop) + 'px'

  container.style.visibility = 'visible'
  container.style.display = 'none'
}

const buildItem = (entry) => {
  const value = entry !== null && typeof entry.value !== 'undefined' ? entry.value : entry
  let label = entry.label ? entry.label : value
  if (typeof entry.amount !== 'undefined') {
    label += ' (' + entry.amount + ')'
  }
  return {
    label,
    value,
    disabled: !!entry.disabled,
    error: !!entry.error,
  }
}

const pointer = { cursor: 'pointer' }
const disabled = { opacity: 0.5 }

const InputSuggestions = memo(
  forwardRef(
    (
      {
        id,
        label,
        type = 'default', // type "embedded": without expander, table with suggestions is always visible
        showAll = false,
        renderCell,
        listWidth = 200,
        dropdownPosition = { top: 0, left: 0 },
        placeholder = 'Suchen...',
        appearance,
        value,
        entries,
        resetOnValueNotFound = false,
        filterPreset = '',
        noChangeOnArrow = false,
        onChange,
        onChangeFilter,
        onSelect,
        onExpand,
        forceExpanded = false,
        expandOnFocus = false,
        ignoreFirstExpand = false,
        numRows = 5,
        refSetFilter,
      },
      ref
    ) => {
      forceExpanded = type === 'embedded' ? true : forceExpanded
      onExpand = type === 'embedded' ? null : onExpand
      appearance = type === 'embedded' ? 'embedded' : appearance
      const [isExpanded, setExpanded] = useState(forceExpanded)
      const [forcedClose, setForcedClose] = useState(false)
      const [filter, setFilter] = useState(filterPreset)
      const [localValue, setLocalValue] = useState(value ? buildItem(value) : null)
      const [, setRerender] = useState(0)
      const [renderListWidth, setRenderListWidth] = useState(listWidth)

      const isMounted = useRef(true)
      const ignoreExpand = useRef(ignoreFirstExpand)

      const forceRecalculateHeight = useRef(0)
      const lastExternalValue = useRef(value)
      const lastExternalEntries = useRef(entries)
      const domDropdownContainer = useRef()
      const tableScrollY = useRef(0)
      const eventsSet = useRef(false)
      const isFocused = useRef(false)

      const localInput = useRef()
      const inputFilter = ref || localInput

      const setScrollY = useCallback((scrollX, scrollY) => {
        tableScrollY.current = Math.abs(scrollY)
      }, [])

      useEffect(() => {
        isMounted.current = true
        return () => {
          isMounted.current = false
        }
      }, [])

      useEffect(() => {
        if (refSetFilter && typeof refSetFilter !== 'undefined') {
          refSetFilter.current = setFilter
        }
      }, [refSetFilter])

      const frmChanged = useCallback(
        (evt) => {
          if (evt.target.name === 'filter') {
            tableScrollY.current = 0
            setFilter(evt.target.value)
            setLocalValue(null)
            if (type !== 'embedded') {
              setForcedClose(false)
            }
            if (evt.target.value === '') {
              onChange({ target: { id, value: '' } })
            }
          } else if (evt.target.value === null || evt.target.value.value !== null) {
            // timeout for rendering every step of key down/up pressed for longer time:
            setTimeout(() => {
              if (isMounted.current) {
                setLocalValue(evt.target.value)
              }
            }, 0)
            if (type !== 'embedded') {
              setForcedClose(false)
            }
            if (evt.target.setBy === 'arrow' && noChangeOnArrow) {
              return
            }
            if (evt.target.setBy === 'rowClick' && onSelect) {
              onSelect({ target: { id, value: evt.target.value } })
            }
            onChange({ target: { id, value: evt.target.value } })
          }
        },
        [id, onChange, onSelect, noChangeOnArrow, type]
      )

      const localRenderCell = useMemo(() => {
        return typeof renderCell === 'function'
          ? renderCell
          : ({ rowData, dataKey }) => {
              let style = rowData.disabled ? disabled : pointer
              if (!rowData.disabled && localValue !== null && rowData.value === localValue.value) {
                style = { ...style, fontWeight: 600 }
              }
              return { style, value: rowData[dataKey] }
            }
      }, [renderCell, localValue])

      useEffect(() => {
        if (lastExternalValue.current === value && lastExternalEntries.current === entries) {
          return
        }
        lastExternalValue.current = value
        lastExternalEntries.current = entries
        const cmpVal = value ? buildItem(value).value : null
        if (
          (localValue === null && cmpVal !== null) ||
          localValue === '_init' ||
          (localValue && localValue.value !== cmpVal)
        ) {
          if (entries.find((entry) => entry === cmpVal || entry.value === cmpVal)) {
            setLocalValue(buildItem(value))
          } else if (resetOnValueNotFound || value === null) {
            setLocalValue(null)
          }
        }
      }, [value, localValue, entries, resetOnValueNotFound])

      useEffect(() => {
        if (
          localValue !== '_init' &&
          localValue !== null &&
          !entries.find(
            (entry) => !entry.disabled && (entry === localValue.value || entry.value === localValue.value)
          )
        ) {
          setLocalValue(null)
        }
      }, [localValue, entries])

      useEffect(() => {
        if (typeof onChangeFilter === 'function') {
          onChangeFilter(filter)
        }
      }, [filter, onChangeFilter])

      const options = useMemo(() => {
        if (filter.length === 0 && forceExpanded === false && expandOnFocus === false) {
          return []
        } else {
          const filterUC = filter.toUpperCase()
          return entries.reduce((options, entry) => {
            const item = buildItem(entry)
            if (
              typeof onChangeFilter === 'function' ||
              ((typeof entry.amount === 'undefined' || entry.amount > 0) &&
                item.label.toUpperCase().indexOf(filterUC) >= 0)
            ) {
              options.push(item)
            }
            return options
          }, [])
        }
      }, [entries, filter, onChangeFilter, forceExpanded, expandOnFocus])

      useEffect(() => {
        forceRecalculateHeight.current = options && options.length ? options.length : null
      }, [options])

      const index = useMemo(() => {
        return localValue === null || typeof localValue.value === 'undefined'
          ? -1
          : options.findIndex((opt) => opt.value === localValue.value)
      }, [localValue, options])

      const calculatedTableHeight = useMemo(() => {
        return options.length < numRows
          ? (options.length > 0 ? options.length : forceExpanded ? 2 : 0) * rowHeight
          : numRows * rowHeight
      }, [options, forceExpanded, numRows])

      // expand if filter results in selectable options
      useEffect(() => {
        if (forceExpanded || expandOnFocus) {
          return
        }
        if (filter.length && options.length) {
          if (!forcedClose && !isExpanded) {
            repos(inputFilter.current, domDropdownContainer.current, dropdownPosition)
            if (!ignoreExpand.current) {
              setExpanded(true)
            } else if (options[0].value !== null) {
              ignoreExpand.current = false
            }
          }
        } else {
          setExpanded(false)
        }
      }, [
        filter,
        options,
        isExpanded,
        forceExpanded,
        expandOnFocus,
        forcedClose,
        inputFilter,
        dropdownPosition,
      ])

      useEffect(() => {
        if (forceExpanded && type !== 'embedded') {
          repos(inputFilter.current, domDropdownContainer.current, dropdownPosition)
          domDropdownContainer.current.style.display = 'block'
          inputFilter.current.select()
          setRerender((cur) => cur + 1)
        }
      }, [forceExpanded, inputFilter, dropdownPosition, type])

      useOnEscape(() => {
        if (isFocused.current && type !== 'embedded') {
          setForcedClose(true)
          setExpanded(false)
        }
      })

      useOnTab(() => {
        if (isFocused.current && type !== 'embedded') {
          setForcedClose(true)
          setExpanded(false)
        }
      })

      useOnEnter(() => {
        if (isFocused.current || type === 'embedded') {
          if (type !== 'embedded') {
            setForcedClose(isExpanded)
            setExpanded(!isExpanded)
          }
          if (noChangeOnArrow && isExpanded) {
            onChange({ target: { id, value: localValue } })
          }
          if (onSelect && isExpanded) {
            onSelect({ target: { id, value: localValue } })
          }
        }
      })

      const testOuterClick = useCallback(
        (evt) => {
          if (
            type !== 'embedded' &&
            !domDropdownContainer.current.contains(evt.target) &&
            !inputFilter.current.contains(evt.target)
          ) {
            setForcedClose(true)
            setExpanded(false)
          }
        },
        [inputFilter, type]
      )

      const changeLocalValue = useCallback(
        (evt) => {
          if ((type !== 'embedded' && !isFocused.current) || ['ArrowUp', 'ArrowDown'].indexOf(evt.key) < 0) {
            return
          }
          let newIndex = index
          let newValue
          do {
            if (evt.key === 'ArrowUp') {
              if (newIndex === 0) {
                newIndex = 0
                newValue = null
              } else {
                newIndex = newIndex === -1 ? options.length - 1 : newIndex - 1
                newValue = options[newIndex] || null
              }
            } else if (evt.key === 'ArrowDown') {
              if (newIndex === options.length - 1) {
                newIndex = 0
                newValue = null
              } else {
                newIndex = newIndex + 1
                newValue = options[newIndex]
              }
            }
          } while (newValue !== null && newValue.disabled)

          const topLimit = tableScrollY.current
          const bottomLimit = topLimit + calculatedTableHeight
          const topCurIndex = newIndex * rowHeight
          const bottomCurIndex = topCurIndex + rowHeight
          if (topLimit > topCurIndex) {
            tableScrollY.current = topCurIndex
          } else if (bottomLimit < bottomCurIndex) {
            tableScrollY.current = bottomCurIndex - calculatedTableHeight
          }
          frmChanged({ target: { value: newValue, setBy: 'arrow' } })
        },
        [options, calculatedTableHeight, frmChanged, index, type]
      )

      // add events:
      //  - close expansion on outer click, set forcedClose (forcedClose gets reseted on filterinput's focus event)
      //  - change localValue on arrow keys or mousewheel
      useEffect(() => {
        if (!eventsSet.current) {
          eventsSet.current = true
          if (isExpanded) {
            document.addEventListener('mousedown', testOuterClick)
          }
          document.addEventListener('keydown', changeLocalValue)
          return () => {
            eventsSet.current = false
            if (isExpanded) {
              document.removeEventListener('mousedown', testOuterClick)
            }
            document.removeEventListener('keydown', changeLocalValue)
          }
        }
      }, [isExpanded, changeLocalValue, testOuterClick])

      useEffect(() => {
        if (typeof onExpand === 'function') {
          onExpand(isExpanded)
        }
      }, [onExpand, isExpanded])

      const onFocus = () => {
        if (type === 'embedded') {
          return
        }
        const curFocused = isFocused.current
        isFocused.current = true
        if (forcedClose) {
          setForcedClose(false)
        }
        if (expandOnFocus) {
          if (!curFocused) {
            inputFilter.current.select()
          }
          repos(inputFilter.current, domDropdownContainer.current, dropdownPosition)
          setExpanded(true)
        }
      }
      const onBlur = () => {
        if (type === 'embedded') {
          return
        }
        isFocused.current = false
      }

      const isHidden = {}
      if (!isExpanded && type !== 'embedded') {
        isHidden.display = 'none'
      }

      const onRowClick = useCallback(
        (data) => {
          if (data.disabled) {
            return
          }
          frmChanged({ target: { value: data, setBy: 'rowClick' } })
          if (type !== 'embedded') {
            setForcedClose(true)
            setExpanded(false)
          }
        },
        [frmChanged, type]
      )

      const Suggestions = useCallback(() => {
        if (type !== 'embedded' && forceExpanded && forceRecalculateHeight.current <= 0) {
          return null
        }

        const error = entries.some((entry) => entry.error)
        const calcRowHeight = error && renderListWidth < 780 ? 2 * rowHeight : rowHeight

        return (
          <Table
            data={options}
            showHeader={false}
            virtualized={!showAll}
            autoHeight={showAll}
            calculateHeight={!showAll}
            forceRecalculateHeight={forceRecalculateHeight.current}
            rowHeight={calcRowHeight}
            onRowClick={onRowClick}
            onScroll={setScrollY}
            scrollToPx={tableScrollY.current}
          >
            <Column key="label" flexGrow={1} align="left">
              <HeaderCell />
              <DataCell dataKey="label" render={localRenderCell} />
            </Column>
          </Table>
        )
      }, [
        showAll,
        forceExpanded,
        options,
        onRowClick,
        setScrollY,
        localRenderCell,
        type,
        entries,
        renderListWidth,
      ])

      const containerStyle = useMemo(() => {
        return type === 'embedded' ? { height: '100%' } : {}
      }, [type])

      if (renderListWidth === 'auto' && inputFilter.current) {
        const width = inputFilter.current.getBoundingClientRect().width
        if (width > 0) {
          setRenderListWidth(width)
        }
      }

      return (
        <InputContainer style={containerStyle}>
          <Box px={type === 'embedded' ? 3 : 0} py={type === 'embedded' ? 2 : 0}>
            <Input
              id={`tagList_filter_${id}`}
              label={label}
              name="filter"
              hideLabel={label.length === 0}
              appearance={appearance}
              placeholder={placeholder}
              value={localValue ? localValue.label : filter}
              onChange={frmChanged}
              onFocus={onFocus}
              onBlur={onBlur}
              ref={inputFilter}
              autoComplete="off"
              mb={0}
            />
          </Box>
          {type === 'embedded' ? (
            <Suggestions />
          ) : (
            <BodyAnchor id="InputSuggestions">
              <DropdownContainer
                ref={domDropdownContainer}
                appearance="roundedShadow"
                mt={1}
                style={isHidden}
              >
                {isExpanded && (
                  <Box
                    width={renderListWidth}
                    height={showAll ? 'auto' : calculatedTableHeight + 'px'}
                    p={0}
                    m={0}
                    overflow="hidden"
                  >
                    <Suggestions />
                  </Box>
                )}
              </DropdownContainer>
            </BodyAnchor>
          )}
        </InputContainer>
      )
    }
  )
)

InputSuggestions.propTypes = {
  id: PropTypes.string,
  entries: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  appearance: PropTypes.string, // goes to atoms/Input
}

InputSuggestions.defaultProps = {
  id: '',
  entries: [],
  label: '',
  placeholder: '',
  appearance: 'default',
}

export default InputSuggestions
