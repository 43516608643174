import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react'

import { useMapMultiStore } from 'lib/leaflet/store'

import { LayerGroup, Marker, withPane } from 'lib/leaflet'

import { addressLevelToZoom } from '../controls/locationHelpers'

import { makeIcon } from '../MapHelpers'
import { zIndices } from 'config/zIndices'

const options = {
  icon: makeIcon('#333'),
  draggable: true,
}

export const LocationSearchPin = ({ mapId, choosenLocation, onDrag }) => {
  const [setViewport] = useMapMultiStore((state, actions) => [actions.setViewport], mapId)

  const changedByDrag = useRef(false)

  const [innerCoords, setInnerCoords] = useState(null)

  useEffect(() => {
    if (innerCoords) onDrag(innerCoords)
  }, [innerCoords, onDrag])

  const point = useMemo(() => choosenLocation.geometry.coordinates.slice().reverse(), [choosenLocation])

  const onDragend = useCallback((evt) => {
    changedByDrag.current = true
    setInnerCoords(Object.values(evt.target.getLatLng()).reverse())
  }, [])

  useEffect(() => {
    const zoom =
      changedByDrag.current === true ? {} : { zoom: addressLevelToZoom(choosenLocation.addressLevel) }
    changedByDrag.current = false
    setViewport({ center: choosenLocation.geometry.coordinates.slice().reverse(), quick: true, ...zoom })
  }, [choosenLocation, setViewport])

  return (
    <LayerGroup>
      <Marker position={point} options={options} onDragend={onDragend}></Marker>
    </LayerGroup>
  )
}

export const LocationSearchPinPane = withPane(
  LocationSearchPin,
  'location-search-pin',
  zIndices.locationSearchPin
)
