export const marketDataInitialGroups = {
  office: [
    'office_rents',
    'office_yields',
    'office_take-up_and_vacancy',
    'office_stock',
    'office_completions',
    'office_space_by_yr_of_completion',
    'GPI_office',
    'office_company_size',
    'employment_svp',
    'office_employment_by_industries',
    'gross_value_added',
    'gross_domestic_product',
    'unemployment_all',
    'office_rents_quarterly',
    'office_yields_quarterly',
    'office_stock_quarterly',
    'office_take-up_and_vacancy_quarterly',
    'office_rents_forecast',
    'office_yields_forecast',
    'office_take-up_forecast',
    'office_take-up_by_industries_forecast',
    'office_vacancy_forecast',
    'office_completions_forecast',
    'GPI_office_forecast',
    'office_employment_forecast',
  ],
  residential: [
    'residential_rents_apartments',
    'residential_multiplier_apartments',
    'residential_rents_o_o_apartments',
    'residential_terrace_houses',
    'residential_family_homes',
    'residential_multi_family',
    'residential_affordability_index_prices',
    'residential_affordability_index_rents',
    'GPI_residential',
    'transactions_apartments',
    'residential_stock',
    'residential_building_completions_new',
    'residential_building_completions_freehold',

    'population',
    'population_change',
    'commuter',
    'households',
    'students',
    'unemployment_all',
    'income',
    'purchasing_power',
    'residential_rents_apartments_quarterly',
    'residential_multiplier_apartments_quarterly',
    'residential_rents_o_o_apartments_quarterly',
    'residential_rents_apartments_forecast',
    'residential_multiplier_apartments_forecast',
    'residential_rents_o_o_apartments_forecast',
    'GPI_residential_forecast',
    'population_forecast',
    'households_forecast',
  ],
  commercial: [
    'logistics_rents',
    'logistics_yields',
    'GPI_logistics',
    'population',
    'gross_value_added',
    'gross_domestic_product',
    'employment_svp',
    'office_employment_by_industries',
    'unemployment_all',
    'logistics_rents_quarterly',
    'logistics_yields_quarterly',
    'logistics_rents_forecast',
    'logistics_yields_forecast',
    'GPI_logistics_forecast',
  ],
  retail: [
    'retail_rents',
    'retail_yields',
    'GPI_retail',
    'population',
    'population_change',
    'commuter',
    'households',
    'unemployment_all',
    'income',
    'purchasing_power',
    'retail_rents_quarterly',
    'retail_yields_quarterly',
    'retail_rents_forecast',
    'retail_yields_forecast',
    'GPI_retail_forecast',
    'population_forecast',
    'households_forecast',
  ],
}

// used for charts only, to replace one source by another:
export const submarketChartAlternative = {
  AT: {
    'data_at.md_office_market.rent_city_average': 'data_at.md_office_market_submarket.rent_average',
    'data_at.md_office_market.rent_city_max': 'data_at.md_office_market_submarket.rent_prime',
    'data_at.md_office_market.rent_city_fringe_average': 'data_at.md_office_market_submarket.rent_average',
    'data_at.md_office_market.rent_city_fringe_max': 'data_at.md_office_market_submarket.rent_prime',
    'data_at.md_office_market.rent_suburb_average': 'data_at.md_office_market_submarket.rent_average',
    'data_at.md_office_market.rent_suburb_max': 'data_at.md_office_market_submarket.rent_prime',
    'data_at.md_office_market.rent_office_agglomeration_average':
      'data_at.md_office_market_submarket.rent_average',
    'data_at.md_office_market.rent_office_agglomeration_max': 'data_at.md_office_market_submarket.rent_prime',
  },
  DE: {
    'data_de.md_office_market.rent_city_average': 'data_de.md_office_market_submarket.rent_average',
    'data_de.md_office_market.rent_city_max': 'data_de.md_office_market_submarket.rent_prime',
    'data_de.md_office_market.rent_city_fringe_average': 'data_de.md_office_market_submarket.rent_average',
    'data_de.md_office_market.rent_city_fringe_max': 'data_de.md_office_market_submarket.rent_prime',
    'data_de.md_office_market.rent_suburb_average': 'data_de.md_office_market_submarket.rent_average',
    'data_de.md_office_market.rent_suburb_max': 'data_de.md_office_market_submarket.rent_prime',
    'data_de.md_office_market.rent_office_agglomeration_average':
      'data_de.md_office_market_submarket.rent_average',
    'data_de.md_office_market.rent_office_agglomeration_max': 'data_de.md_office_market_submarket.rent_prime',
  },
}

// used for tables and charts to replace one source by another:
export const submarketEquivalents = {
  AT: {
    'data_at.md_office_market.turnover': 'data_at.md_office_market_submarket.turnover',
    'data_at.md_office_market_submarket.rent_average': 'data_at.md_office_market_submarket.rent_average',
    'data_at.md_office_market_submarket.rent_prime': 'data_at.md_office_market_submarket.rent_prime',
    'data_at.md_office_market.space_net': 'data_at.md_office_market_submarket.space_net',
    'data_at.md_office_market.space_new_total': 'data_at.md_office_market_submarket.space_new_total',
    'data_at.md_office_market.space_new_modernisation':
      'data_at.md_office_market_submarket.space_new_modernisation',
    'data_at.md_office_market.vacancy': 'data_at.md_office_market_submarket.vacancy',
    'data_at.md_residential_market.price_condo_existing_min':
      'data_at.md_residential_market_submarket.price_condo_existing_min',
    'data_at.md_residential_market.price_condo_existing_average':
      'data_at.md_residential_market_submarket.price_condo_existing_average',
    'data_at.md_residential_market.price_condo_existing_max':
      'data_at.md_residential_market_submarket.price_condo_existing_max',
    'data_at.md_residential_market.price_condo_new_min':
      'data_at.md_residential_market_submarket.price_condo_new_min',
    'data_at.md_residential_market.price_condo_new_average':
      'data_at.md_residential_market_submarket.price_condo_new_average',
    'data_at.md_residential_market.price_condo_new_max':
      'data_at.md_residential_market_submarket.price_condo_new_max',
    'data_at.md_residential_market.price_detached_house_min':
      'data_at.md_residential_market_submarket.price_detached_house_min',
    'data_at.md_residential_market.price_detached_house_average':
      'data_at.md_residential_market_submarket.price_detached_house_average',
    'data_at.md_residential_market.price_detached_house_max':
      'data_at.md_residential_market_submarket.price_detached_house_max',
    'data_at.md_residential_market.price_row_hs_existing_min':
      'data_at.md_residential_market_submarket.price_row_hs_existing_min',
    'data_at.md_residential_market.price_row_hs_existing_average':
      'data_at.md_residential_market_submarket.price_row_hs_existing_average',
    'data_at.md_residential_market.price_row_hs_existing_max':
      'data_at.md_residential_market_submarket.price_row_hs_existing_max',
    'data_at.md_residential_market.price_row_hs_new_min':
      'data_at.md_residential_market_submarket.price_row_hs_new_min',
    'data_at.md_residential_market.price_row_hs_new_average':
      'data_at.md_residential_market_submarket.price_row_hs_new_average',
    'data_at.md_residential_market.price_row_hs_new_max':
      'data_at.md_residential_market_submarket.price_row_hs_new_max',
    'data_at.md_residential_market.rent_existing_min':
      'data_at.md_residential_market_submarket.rent_existing_min',
    'data_at.md_residential_market.rent_existing_average':
      'data_at.md_residential_market_submarket.rent_existing_average',
    'data_at.md_residential_market.rent_existing_max':
      'data_at.md_residential_market_submarket.rent_existing_max',
    'data_at.md_residential_market.rent_new_min': 'data_at.md_residential_market_submarket.rent_new_min',
    'data_at.md_residential_market.rent_new_average':
      'data_at.md_residential_market_submarket.rent_new_average',
    'data_at.md_residential_market.rent_new_max': 'data_at.md_residential_market_submarket.rent_new_max',
  },
  DE: {
    'data_de.md_office_market.turnover': 'data_de.md_office_market_submarket.turnover',
    'data_de.md_office_market_submarket.rent_average': 'data_de.md_office_market_submarket.rent_average',
    'data_de.md_office_market_submarket.rent_prime': 'data_de.md_office_market_submarket.rent_prime',
    'data_de.md_office_market.space_net': 'data_de.md_office_market_submarket.space_net',
    'data_de.md_office_market.space_new_total': 'data_de.md_office_market_submarket.space_new_total',
    'data_de.md_office_market.space_new_modernisation':
      'data_de.md_office_market_submarket.space_new_modernisation',
    'data_de.md_office_market.vacancy': 'data_de.md_office_market_submarket.vacancy',
    'data_de.md_residential_market.price_condo_existing_min':
      'data_de.md_residential_market_submarket.price_condo_existing_min',
    'data_de.md_residential_market.price_condo_existing_average':
      'data_de.md_residential_market_submarket.price_condo_existing_average',
    'data_de.md_residential_market.price_condo_existing_max':
      'data_de.md_residential_market_submarket.price_condo_existing_max',
    'data_de.md_residential_market.price_condo_new_min':
      'data_de.md_residential_market_submarket.price_condo_new_min',
    'data_de.md_residential_market.price_condo_new_average':
      'data_de.md_residential_market_submarket.price_condo_new_average',
    'data_de.md_residential_market.price_condo_new_max':
      'data_de.md_residential_market_submarket.price_condo_new_max',
    'data_de.md_residential_market.price_detached_house_min':
      'data_de.md_residential_market_submarket.price_detached_house_min',
    'data_de.md_residential_market.price_detached_house_average':
      'data_de.md_residential_market_submarket.price_detached_house_average',
    'data_de.md_residential_market.price_detached_house_max':
      'data_de.md_residential_market_submarket.price_detached_house_max',
    'data_de.md_residential_market.price_row_hs_existing_min':
      'data_de.md_residential_market_submarket.price_row_hs_existing_min',
    'data_de.md_residential_market.price_row_hs_existing_average':
      'data_de.md_residential_market_submarket.price_row_hs_existing_average',
    'data_de.md_residential_market.price_row_hs_existing_max':
      'data_de.md_residential_market_submarket.price_row_hs_existing_max',
    'data_de.md_residential_market.price_row_hs_new_min':
      'data_de.md_residential_market_submarket.price_row_hs_new_min',
    'data_de.md_residential_market.price_row_hs_new_average':
      'data_de.md_residential_market_submarket.price_row_hs_new_average',
    'data_de.md_residential_market.price_row_hs_new_max':
      'data_de.md_residential_market_submarket.price_row_hs_new_max',
    'data_de.md_residential_market.rent_existing_min':
      'data_de.md_residential_market_submarket.rent_existing_min',
    'data_de.md_residential_market.rent_existing_average':
      'data_de.md_residential_market_submarket.rent_existing_average',
    'data_de.md_residential_market.rent_existing_max':
      'data_de.md_residential_market_submarket.rent_existing_max',
    'data_de.md_residential_market.rent_new_min': 'data_de.md_residential_market_submarket.rent_new_min',
    'data_de.md_residential_market.rent_new_average':
      'data_de.md_residential_market_submarket.rent_new_average',
    'data_de.md_residential_market.rent_new_max': 'data_de.md_residential_market_submarket.rent_new_max',
  },
}
