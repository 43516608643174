import React, { useContext, useEffect, createContext } from 'react'
import { layersControlContext } from './LayersControl'
import { useAddToMap } from './LeafletMap'

export const overlayContext = createContext({ checked: true })

/** Wrap a layer in an Overlay
 * Overlays need to be wrapped in a LayerControl where they will be displayed.
 * @param {String} name - The displayed name
 * @param {Boolean} checked - If the layer is initially displayed on the map. (True by default)
 */
export const Overlay = ({ name, checked = true, children }) => {
  const layersControl = useContext(layersControlContext)
  return (
    <overlayContext.Provider value={{ layersControl, checked, name }}>{children}</overlayContext.Provider>
  )
}

/** Layers that can be used in an Overlay implement this hook
 * It needs no be applied after other hooks that append layers to a map
 * so the layer gets appended or removed based on the checked value.
 */
export const useOverlay = (layer) => {
  const { layersControl, checked, name } = useContext(overlayContext)
  useAddToMap(layer, checked)

  useEffect(() => {
    if (layersControl == null || layer === null) return
    layersControl.addOverlay(layer, name)
    return () => {
      layersControl.removeLayer(layer)
    }
  }, [layer, layersControl, name])
}
