import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { rgba } from 'polished'
import { ColorAllocator } from 'components/UtilHelper'

import { BudiconHidePassword, BudiconCrossUi, BudiconLocationPin, BudiconView } from 'bgag-budicons'
import { TextSubHeader } from 'components/atoms/Text'
import { Flex } from 'components/atoms/Box'

const Bubble = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.4rem 0.7rem;
  margin: 0.4rem 0.1em;
  background-color: white;
  border-radius: 2px;
`

export const AddLocationBubble = styled(Bubble)`
  justify-content: center;
  padding: 0.15rem 0.5rem;
  margin-bottom: 0.8rem;
  font-size: 0.9em;
  cursor: pointer;
  color: ${(props) => rgba(props.theme.colors.mediumGrey, 0.8)};
  background-color: ${rgba('white', 0.5)};
`

const DotStyles = css`
  ::after {
    width: 5px;
    height: 5px;
    background: white;
    border-radius: 50%;
    position: relative;
    z-index: 10;
    content: '';
  }
`
export const ColorIndicatorCircle = styled.div`
  cursor: pointer;
  border-radius: 50%;
  height: ${(props) => props.size || '15px'};
  width: ${(props) => props.size || '15px'};
  border: transparent;
  margin-right: 0.4rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) => props.dot && DotStyles};
`

const ControlButtonContainer = styled.div`
  font-size: 1.3rem;
  cursor: pointer;
  margin-left: 0.2rem;
  opacity: ${(props) => (props.showOnHover ? 0 : 1)};
  color: ${(props) => props.theme.colors.mediumGrey};
  transition: all 0.3s linear;

  &:hover {
    color: ${(props) => props.locationColor};
  }

  ${Bubble}:hover & {
    opacity: 1;
  }
`

const LocationBubble = ({
  locationEntry,
  removeLocation,
  setMainLocation,
  mainLocation,
  hideable = true,
  locationsVisibilityMap,
  toggleLocationVisibility,
  ignoreMainLocation,
}) => {
  const [locationID, locationFeature] = locationEntry

  const isMainLocation = mainLocation !== null && parseInt(locationID) === parseInt(mainLocation)
  const isVisible = !hideable || locationsVisibilityMap.get(locationID)
  const isAuthorized =
    !hideable ||
    Object.values(locationFeature.areas).some(
      (area) => area.access && area.type !== '60' && area.type !== '50'
    )
  const locationColor = useMemo(() => ColorAllocator(locationID), [locationID])

  const bubbleStyle = {
    backgroundColor: isMainLocation || ignoreMainLocation ? rgba(locationColor, 0.1) : 'white',
    filter:
      isVisible && isAuthorized
        ? 'none'
        : !isAuthorized
        ? 'opacity(0.5)'
        : 'opacity(0.3) grayscale(1) brightness(0.8)',
  }

  return (
    <Bubble style={bubbleStyle}>
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={() => {
          setMainLocation(parseInt(locationID))
        }}
      >
        <span flex="0 0 auto">
          <BudiconLocationPin
            size="24px"
            type={isMainLocation || ignoreMainLocation ? 'solid' : 'outline'}
            color={locationColor}
          />
        </span>
        <TextSubHeader ml={2} style={{ fontSize: '0.8rem' }}>
          {locationFeature.displayedName || locationFeature.properties.name}
        </TextSubHeader>
      </Flex>
      <Flex flex="0 0 auto" alignItems="center">
        {isAuthorized && hideable && (
          <ControlButtonContainer
            showOnHover
            locationColor
            onClick={() => toggleLocationVisibility(locationID)}
          >
            {isVisible ? <BudiconHidePassword /> : <BudiconView />}
          </ControlButtonContainer>
        )}
        <ControlButtonContainer
          onClick={() => {
            removeLocation(locationID)
          }}
        >
          <BudiconCrossUi />
        </ControlButtonContainer>
      </Flex>
    </Bubble>
  )
}

export default LocationBubble
