/* eslint-env browser */
import { googleFontsUrl } from './GlobalStyle'

// Load the font and avoid re-loading it when components change
const fontLinkId = 'google-font-link-tag'

const loadGoogleFonts = () => {
  if (!document.getElementById(fontLinkId)) {
    const fontLink = document.createElement('link')

    fontLink.id = fontLinkId
    fontLink.href = googleFontsUrl
    fontLink.rel = 'stylesheet'

    document.head.appendChild(fontLink)
  }
}

export default loadGoogleFonts
