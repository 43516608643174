import React, { memo, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { LocationSearch } from './LocationSearch'
import { LocationSearchInput } from './LocationSearchInput'
import LocationBubble from './LocationBubble'
import ControlSection from './ControlSection'
import { ToggleContent, ModalContainer, ModalHeader } from 'components/molecules/Modal'
import Box from 'components/atoms/Box'

const AddLocationModal = memo(({ addLocation, country, setCountry, countries, showCountrySelection }) => {
  const { t } = useTranslation()
  return (
    <ToggleContent
      toggle={(show) => (
        <Box cursor="pointer" onClick={show}>
          + {t('controls.add')}
        </Box>
      )}
      content={(hide) => (
        <ModalContainer>
          <ModalHeader title={t('controls.addLocation')} hide={hide} />
          <LocationSearch
            hide={hide}
            addLocation={addLocation}
            country={country}
            setCountry={setCountry}
            countries={countries}
            showCountrySelection={showCountrySelection}
          />
        </ModalContainer>
      )}
    />
  )
})

export const AddLocationModalPreselect = memo(({ addLocation, placeholder, country }) => {
  const { t } = useTranslation()

  const [choosenLocation, setChoosenLocation] = useState(null)

  const [showModal, setShowModal] = useState(false)

  const onLocationSelect = useCallback((evt) => {
    setShowModal(true)
  }, [])

  const hide = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <>
      <LocationSearchInput
        placeholder={placeholder}
        isStandalone={true}
        onLocationSelect={onLocationSelect}
        choosenLocation={choosenLocation}
        setChoosenLocation={setChoosenLocation}
        country={country}
      />
      {showModal && (
        <ModalContainer>
          <ModalHeader title={t('controls.addLocation')} hide={hide} />
          <LocationSearch
            hide={hide}
            addLocation={addLocation}
            location={choosenLocation}
            country={country}
          />
        </ModalContainer>
      )}
    </>
  )
})

/*
 *
 *  externalLoactions used in admin to set default locations for user-groups
 *
 * */
export const ControlLocations = ({
  locations = null,
  mainLocation = null,
  addLocation = null,
  removeLocation = null,
  setMainLocation = null,
  locationsVisibilityMap,
  toggleLocationVisibility,
  title = null,
  hideable = true,
  country,
  setCountry,
  countries,
  showCountrySelection = false,
  ignoreMainLocation = false,
}) => {
  const { t } = useTranslation()

  const belowLocationLimit = React.useMemo(() => Object.keys(locations).length < 5, [locations])

  return (
    <ControlSection
      title={title || t('disco.location_other')}
      TitleActionArea={belowLocationLimit && AddLocationModal}
      titleActionAreaProperties={{ country, setCountry, countries, addLocation, showCountrySelection }}
    >
      <div>
        {Object.entries(locations).map((location) => (
          <LocationBubble
            key={location[0]}
            locationEntry={location}
            mainLocation={mainLocation}
            removeLocation={removeLocation}
            setMainLocation={setMainLocation}
            locationsVisibilityMap={locationsVisibilityMap}
            toggleLocationVisibility={toggleLocationVisibility}
            hideable={hideable}
            ignoreMainLocation={ignoreMainLocation}
          />
        ))}
        {/* {Object.keys(locations).length > 0 && <ControlLocationsDraggable items={Object.entries(locations)} />} */}
      </div>
    </ControlSection>
  )
}
