import React, { useRef, useLayoutEffect, createContext, useContext, useEffect, useState } from 'react'
import L from 'leaflet'

import { uniqueName } from 'lib/unique-name'
import { isFunction } from 'lib/util'

import { useSyncStore } from '../store/useSyncStore'
import { useMapMultiStore } from '../store/index'
import { useMapEvents } from '../hooks/useEvents'

export const leafletContext = createContext(null)
export const useLeaflet = () => useContext(leafletContext).mapInstance

export const LeafletMap = ({ mapId, children, options, center, mapStyle, ...props }) => {
  mapId = useState(() => mapId ?? uniqueName('leaflet-map'))[0]
  const mapContainer = useRef(null)
  const [mapInstance, setInstance] = useState(null)
  const [contextValue, setContext] = useState(null)
  const setViewport = useMapMultiStore((_, actions) => actions.setViewport, mapId)

  useMapEvents(mapContainer.current, props)

  // setup and teardown
  useLayoutEffect(() => {
    const mapOptions = { ...mapDefaultOptions, ...options }
    if (center) mapOptions.center = center
    const mapInstance = L.map(mapContainer.current, mapOptions)
    setInstance(mapInstance)
    return () => {
      isFunction(setViewport) && setViewport({ center: mapInstance.getCenter(), zoom: mapInstance.getZoom() })
      mapInstance.remove()
    }
    // actively ignoring changes to options and center
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setViewport])

  /** Rerender subtree on moveend.
   * Otherwise child components wouldn't get updates of map internal state.
   */
  useEffect(() => {
    if (mapInstance === null) return
    setContext({ mapInstance })
    mapInstance.on('moveend', () => setContext({ mapInstance }))
  }, [mapInstance])

  /** Fix: leaflet won't catch resizes by css */
  useLayoutEffect(() => {
    if (!mapInstance) return
    const observer = new ResizeObserver(() => mapInstance.invalidateSize())
    observer.observe(mapContainer.current)
    return () => observer.disconnect()
  }, [mapInstance])

  useSyncStore(mapInstance, mapId)

  return (
    <div ref={mapContainer} style={{ ...defaultMapStyle, ...mapStyle }}>
      {contextValue && <leafletContext.Provider value={contextValue}>{children}</leafletContext.Provider>}
    </div>
  )
}

// utility

const mapDefaultOptions = {
  center: L.latLng([0, 0]),
  zoomControl: true,
  zoom: 12,
  minZoom: 4,
  scrollWheelZoom: true,
  zoomAnimation: false,
  fadeAnimation: false,
  // maxBounds: latLngBounds([[46.5, 3.5], [56, 17]]),
  layers: [],
  // Hotfix for https://github.com/Leaflet/Leaflet/issues/7255
  // Prevent duplicate click events on safari
  tap: false,
}

const defaultMapStyle = {
  width: '100%',
  height: '100%',
  backgroundColor: 'white',
  borderRadius: '0.75rem',
}

/** Mounts a layer to the map
 * @param {Layer} layer - the layer to conditionally add or remove
 * @param {Boolean} checked - Should the layer be added or removed (add by default)
 */
export const useAddToMap = (layer, checked = true) => {
  const map = useLeaflet()
  useEffect(() => {
    if (layer === null) return
    if (checked) {
      layer.addTo(map)
    } else map.removeLayer(layer)
    return () => {
      map.removeLayer(layer)
    }
  }, [map, layer, checked])
}
