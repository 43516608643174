import createStore from 'lib/flux-store'

import { setTopicColors, setClusterComputedValues, getCountryCodeByGac } from './helper'
import { getTopicData, getTopicReferenceData, getMinMaxYears, getMarketDataTopics } from './Cache'

const topicTpl = {
  time: null,
  locations: {},
  locationData: {},
  dataSources: [],
  chartViews: [],
  colors: {},
  cluster: {},
  clusterReference: {},
  clusterTime: null,
  clusterColors: {},
  clusterLocations: {},
  clusterLocationData: {},
  clusterReferenceCurve: '',
  minYear: null,
  maxYear: null,
}
const initialState = {
  isLoading: false,
  isFailure: false,
  loading: {},
  failure: {},
  messages: {},
  marketDataTopics: {},
  availableTopicGroups: {},
  topics: {},
  maxYear: null,
}

const normalizeTopic = (topic) => {
  Object.values(topic.chartViews).forEach((chartViewsByCountry) => {
    chartViewsByCountry = chartViewsByCountry.filter(
      (view) => !(typeof view === 'string' && view.startsWith('#'))
    )
  })

  Object.values(topic.chartViews).forEach((chartViewsByCountry) => {
    chartViewsByCountry.forEach((chartView) => {
      chartView.yAxis.forEach((axis) => {
        if (!axis.axisSources && Array.isArray(axis.series)) {
          axis.axisSources = Array.from(
            axis.series.reduce((sources, serie) => {
              serie.sources.forEach((source) => sources.add(source))
              return sources
            }, new Set())
          )
        }
      })
    })
  })
  topic.dataSources = topic.dataSources.filter(
    (source) => !(typeof source === 'string' && source.startsWith('#'))
  )
}

const actions = {
  fetchMarketDataTopics: () => (dispatch) => {
    getMarketDataTopics().then((topics) => {
      topics = topics.reduce((topics, topic) => {
        normalizeTopic(topic)
        topics[topic.key] = topic
        return topics
      }, {})
      dispatch({ type: 'setMarketDataTopics', payload: { topics } })
    })
  },
  fetchTopicData:
    (
      { key = null, locations = {}, locationData = {}, time = null, dataSources },
      marketDataTopics,
      referenceCurve,
      referenceOnly
    ) =>
    (dispatch) => {
      const gacs = Array.from(new Set(Object.values(locations)))
      if (gacs.length === 0 || time === null) {
        dispatch({ type: 'setTopicData', payload: { key, cluster: {}, clusterReference: {} } })
        return
      }
      dispatch({ type: 'setLoading', payload: key })
      const isQuarter = !!marketDataTopics[key].isQuarter
      let cluster
      Promise.resolve()
        .then(() => {
          if (referenceOnly) {
            return 'referenceOnly'
          } else {
            return getTopicData(gacs, time, dataSources, isQuarter)
          }
        })
        .then((resCluster) => {
          cluster = resCluster
          if (referenceCurve === 'none') {
            return {}
          } else {
            return getTopicReferenceData(referenceCurve, time, dataSources, isQuarter)
          }
        })
        .then((clusterReference) => {
          dispatch({
            type: 'setTopicData',
            payload: { key, cluster, clusterReference, time, referenceCurve, locations, locationData },
          })
        })
        .catch((err) => {
          dispatch({ type: 'setFailure', payload: { key, err } })
          throw err
        })
    },
  setAvailableTopicGroups: (groups, marketDataTopics) => (dispatch) => {
    let countryCodes = new Set()
    let dataSources = new Set()
    let areaTypes = new Set()
    Object.values(groups).forEach((groupsTopics) => {
      groupsTopics.forEach((topic) => {
        if (!topic.disabled) {
          topic.dataSources.forEach((source) => {
            dataSources.add(source)
          })
          Object.values(topic.locationData).forEach((location) => {
            countryCodes.add(getCountryCodeByGac(location.gac))
            if (location.areaType !== null) {
              areaTypes.add(location.areaType)
            }
          })
        }
      })
    })
    dataSources = Array.from(dataSources)
    areaTypes = Array.from(areaTypes)
    countryCodes = Array.from(countryCodes)

    getMinMaxYears(areaTypes, dataSources)
      .then((minMaxYears) => {
        let maxYear = 0
        Object.values(groups).forEach((groupsTopics) => {
          groupsTopics.forEach((topic) => {
            if (!topic.disabled) {
              topic.minYear = 2500
              topic.maxYear = 0
              const hiddenSourceKeys = countryCodes.reduce((set, countryCode) => {
                for (const dataSource of marketDataTopics[topic.topicKey].dataSources) {
                  const source = dataSource.source[countryCode]
                  if (source !== null && source?.key && dataSource.tableHidden) {
                    set.add(source.key)
                  }
                }
                return set
              }, new Set())
              const isForecast = !!marketDataTopics[topic.topicKey].isForecast
              const isQuarter = !!marketDataTopics[topic.topicKey].isQuarter
              Object.values(topic.locationData).forEach((location) => {
                topic.dataSources.forEach((source) => {
                  if (
                    marketDataTopics[topic.topicKey]?.maxYearSourceException?.includes(source) ||
                    hiddenSourceKeys.has(source)
                  ) {
                    return
                  }
                  if (!isForecast && minMaxYears?.[location.areaType]?.[source]?.max > maxYear) {
                    maxYear = minMaxYears[location.areaType][source].max
                  }
                  if (minMaxYears?.[location.areaType]?.[source]?.max > topic.maxYear) {
                    topic.maxYear = minMaxYears[location.areaType][source].max
                  }
                  if (
                    minMaxYears?.[location.areaType]?.[source]?.min < topic.minYear &&
                    minMaxYears?.[location.areaType]?.[source]?.min !== null
                  ) {
                    topic.minYear = minMaxYears[location.areaType][source].min
                  }
                  if (
                    isQuarter &&
                    (typeof topic.maxMonth === 'undefined' ||
                      minMaxYears?.[location.areaType]?.[source]?.maxMonth > topic.maxMonth)
                  ) {
                    topic.maxMonth = minMaxYears?.[location.areaType]?.[source]?.maxMonth
                  }
                })
              })
              if (topic.minYear > topic.maxYear) {
                topic.disabled = true
              }
            }
          })
        })
        dispatch({ type: 'setAvailableTopicGroups', payload: { groups, maxYear } })
      })
      .catch((err) => {
        throw err
      })
  },
  setTopicTime: (key, time, noForecastOverwrite) => ({
    key,
    time,
    noForecastOverwrite,
  }),
}
const reducer = {
  setLoading: (state, { payload }) => {
    const loading = { ...state.loading, [payload]: true }
    return { ...state, isLoading: Object.values(loading).some((val) => val), loading, isFailure: false }
  },
  setFailure: (state, { payload }) => {
    const failure = { ...state.failure, [payload.key]: true }
    const loading = { ...state.loading, [payload.key]: false }
    const messages = { ...state.messages, [payload.key]: payload.err }
    return {
      ...state,
      isLoading: Object.values(loading).some((val) => val),
      isFailure: Object.values(failure).some((val) => val),
      loading,
      failure,
      messages,
    }
  },
  setTopicData: (
    state,
    { payload: { key, cluster, clusterReference, time, locations, locationData, colors, referenceCurve } }
  ) => {
    const loading = { ...state.loading, [key]: false }
    const topicConfig = state.marketDataTopics[key]
    setClusterComputedValues({
      key,
      dataSources: topicConfig.dataSources,
      cluster: clusterReference,
      forcedIsSubmarket: false,
      time,
      topicConfig,
    })

    const newTopic = {
      ...state.topics[key],
      clusterReference,
      topicConfigDataSources: topicConfig.dataSources,
    }

    if (cluster !== 'referenceOnly') {
      setClusterComputedValues({
        key,
        dataSources: topicConfig.dataSources,
        cluster,
        time,
        topicConfig,
      })
      Object.assign(newTopic, {
        cluster,
        clusterTime: time,
        clusterLocations: locations,
        clusterLocationData: locationData,
        clusterColors: setTopicColors({ config: topicConfig, locations, locationData }),
        clusterReferenceCurve: referenceCurve,
      })
    }

    return {
      ...state,
      isLoading: Object.values(loading).some((val) => val),
      loading,
      topics: {
        ...state.topics,
        [key]: newTopic,
      },
    }
  },
  setTopicTime: (state, { key, time, noForecastOverwrite = false }) => {
    if (
      state.marketDataTopics[key].isForecast &&
      state.marketDataTopics[key].forecastType !== 'noPlotBands' &&
      !noForecastOverwrite
    ) {
      const now = new Date().getFullYear()
      const to = state?.topics?.[key]?.maxYear ?? null
      const from = to === null ? null : to - 12 > now - 2 ? now - 2 : to - 12
      time = to === null ? null : { from, to }
    } else if (key === 'office_space_by_yr_of_completion') {
      const to = state.topics[key].maxYear
      time = { from: 1990, to }
    }
    const stateTime = state?.topics?.[key]?.time ? state.topics[key].time : {}
    if (time === null || (time.from === stateTime.from && time.to === stateTime.to)) {
      return state
    }
    return {
      ...state,
      topics: { ...state.topics, [key]: { ...state.topics[key], time } },
    }
  },
  setMarketDataTopics: (state, { payload: { topics } }) => {
    return { ...state, marketDataTopics: topics }
  },
  setAvailableTopicGroups: (state, { payload: { groups, maxYear } }) => {
    const newTopics = { ...state.topics }
    Object.values(groups).forEach((topics) => {
      topics.forEach((topic) => {
        if (typeof newTopics[topic.topicKey] === 'undefined') {
          newTopics[topic.topicKey] = {
            ...topicTpl,
          }
        }
        newTopics[topic.topicKey] = {
          ...newTopics[topic.topicKey],
          minYear: topic.minYear,
          maxYear: topic.maxYear,
          maxMonth: topic.maxMonth || null,
          locations: topic.locations,
          locationData: topic.locationData,
          dataSources: topic.dataSources,
          chartViews: topic.chartViews,
          colors: topic.colors,
        }
      })
    })

    return {
      ...state,
      availableTopicGroups: groups,
      topics: newTopics,
      maxYear,
    }
  },
}

export const [MarketDataDataContext, MarketDataDataProvider, useMarketDataDataStore] = createStore(
  reducer,
  actions,
  initialState,
  undefined,
  'MarketDataDataStore'
)
