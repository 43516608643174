import React, { useState, useCallback, createContext, useContext } from 'react'
import propTypes from 'prop-types'
import { useControllableState } from 'lib/hooks'
import { isNullOrUndefined, isUndefined, warnOnce } from 'lib/util'
import { uniqueName } from 'lib/unique-name'

export const groupContext = createContext(null)
export const useInputGroup = () => useContext(groupContext)
export const useIsGroupControlled = (name) => !isUndefined(useInputGroup()?.groupState?.[name])

/**
 * The InputGroup Component
 * Encapsulates input components and manages their state for them.
 * "value" has to be an Object:
 * - The keys represent the names of the inputs that shall be controlled
 * - The values are the related values of the input field
 */
export const InputGroup = React.memo(({ name, id, value, onChange, defaultValue, children }) => {
  const [initialState] = useState(defaultValue ?? value ?? {})
  const [state, setState] = useControllableState(initialState, value, onChange)
  const [groupName] = useState(() => name || uniqueName('InputGroup'))

  const setStateByKey = useCallback(
    (key, value) => setState((prevState) => ({ ...prevState, [key]: value })),
    [setState]
  )

  const reset = useCallback(() => setState(initialState), [setState, initialState])

  return (
    <groupContext.Provider
      value={{ groupState: state, setGroupState: setStateByKey, reset, groupName, groupId: id ?? groupName }}
    >
      {children}
    </groupContext.Provider>
  )
})

InputGroup.propTypes = {
  onChange: propTypes.func,
}

/**
 * A hook to connect an input to its group.
 *
 * - Returns the "value" and "onChange" props to use in the input
 * - Sets the id property
 */
export const useInputProps = (props) => {
  const { name, type } = props
  const id = props.id ?? name
  const groupContext = useInputGroup()
  const { groupState, setGroupState, groupId } = groupContext ?? {}
  const isGroupControlled = !isUndefined(groupState?.[name])

  if (isGroupControlled && !isNullOrUndefined(props.value)) {
    const msg = `A group-controlled input has its "value" property set. This value will be ignored. Remove the property to resolve.`
    warnOnce('FORM_INPUT_VALUE_SET', msg)
  }
  if (isGroupControlled && !isNullOrUndefined(props.defaultValue)) {
    const msg = `A group-controlled input has its "defaultValue" property set. This value will be ignored. Remove the property to resolve.`
    warnOnce('FORM_INPUT_DEFAULT_VALUE_SET', msg)
  }
  if (isGroupControlled && !isNullOrUndefined(props.id)) {
    const msg = `A group-controlled input specified its own id. Ids are be handled by the group and will be overwritten.`
    warnOnce('FORM_INPUT_ID_SET', msg)
  }

  const onChange = useCallback(
    (evt) => {
      setGroupState(name, getValueFromInput(evt, type))
    },
    [setGroupState, type, name]
  )

  const compoundId = [groupId, id].filter(Boolean).join('.')
  return isGroupControlled ? { value: groupState[name], onChange, id: compoundId } : {}
}

/**
 * A HOC to connect an arbitrary component to its group.
 * Will inject the "value" and "onChange" props to its wrapped component
 */
export const withInputGroup = (WrappedInput) => {
  return (props) => {
    const childProps = { ...props, ...useInputProps(props) }
    return <WrappedInput {...childProps} />
  }
}

/**
 * Extract the value from an onChange event based on input type
 */
function getValueFromInput(evt, type) {
  type = type || evt.target?.type
  switch (type) {
    case 'checkbox':
      return evt.target.checked
    case 'button':
    case 'color':
    case 'date':
    case 'datetime-local':
    case 'email':
    case 'file':
    case 'hidden':
    case 'image':
    case 'month':
    case 'number':
    case 'password':
    case 'radio':
    case 'range':
    case 'reset':
    case 'search':
    case 'tel':
    case 'text':
    case 'time':
    case 'url':
    case 'week':
      return evt.target.value
    case 'custom':
    default:
      return evt
  }
}
