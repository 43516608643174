import 'regenerator-runtime/runtime'
import 'airbnb-browser-shims/browser-only'
import 'lib/polyfill'
import '@okta/okta-auth-js/polyfill'

import 'i18n'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import App from './App'

import './custom.scss'
import fontFacesWhitney from 'components/theming/fontFacesWhitney'
import loadGoogleFonts from 'components/theming/loadGoogleFonts'

ReactDOM.render(
  <Suspense fallback="">
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <App />
    </BrowserRouter>
  </Suspense>,
  document.getElementById('root')
)

loadGoogleFonts()

const fontWhitney = document.createElement('style')
fontWhitney.innerHTML = fontFacesWhitney.join('')
document.head.appendChild(fontWhitney)
