import React from 'react'
import Box, { Flex } from 'components/atoms/Box'

export const LandingPage = ({ children, ...rest }) => {
  return (
    <Box width="100%" backgroundColor="white" {...rest} height="100vh" overflowY="auto">
      {children}
    </Box>
  )
}

LandingPage.Header = ({ children, backgroundImage, minHeight = 212, ...rest }) => {
  return (
    <Flex
      m="0.5rem 0"
      backgroundImage={`url(${backgroundImage})`}
      backgroundSize="cover"
      borderRadius="0.75rem"
      overflow="hidden"
      minHeight={minHeight}
      {...rest}
    >
      {children}
    </Flex>
  )
}

LandingPage.Header.Overlay = ({ children, background, ...rest }) => {
  background = background || 'linear-gradient(92.06deg, #000000 -1.43%, rgba(0, 52, 62, 0.22) 71.88%)'
  return (
    <Flex width="100%" position="relative" background={background} {...rest}>
      {children}
    </Flex>
  )
}

LandingPage.Section = ({ children, ...rest }) => {
  return (
    <Flex m="0.5rem 0" flexWrap="wrap" {...rest}>
      {children}
    </Flex>
  )
}

LandingPage.Section.Description = ({ children, ...rest }) => {
  return (
    <Flex
      padding="2.375rem"
      flex="1"
      flexDirection="column"
      borderRadius={[
        '0.75rem 0.75rem 0 0',
        '0.75rem 0.75rem 0 0',
        '0.75rem 0.75rem 0 0',
        '0.75rem 0.75rem 0 0',
        '0.75rem 0 0 0.75rem',
      ]}
      {...rest}
    >
      {children}
    </Flex>
  )
}
