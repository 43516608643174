import createMultiStore from 'lib/flux-store/createMultiStore'

const initialState = {
  viewport: {
    center: [0, 0],
    zoom: 12,
    quick: false,
  },
  bounds: null,
  prevMainLocation: '_init', // needs to be different from mainLocation on component load
}

const substate = (state, id) => {
  return state[id] || initialState
}

const actions = {
  setViewport:
    (id, { center, quick, zoom }) =>
    (dispatch) => {
      dispatch({ type: 'SET_VIEWPORT', id, payload: { center, quick, zoom } })
    },
  setBounds: (id, bounds) => (dispatch) => {
    dispatch({ type: 'SET_BOUNDS', id, payload: bounds })
  },
  setPrevMainLocation: (id, locationID) => (dispatch) => {
    dispatch({ type: 'SET_PREV_MAIN_LOCATION', id, payload: locationID })
  },
}

const reducer = {
  SET_VIEWPORT: (state, id, payload) => ({
    ...state,
    [id]: { ...substate(state, id), viewport: payload },
  }),
  SET_BOUNDS: (state, id, payload) => ({
    ...state,
    [id]: { ...substate(state, id), bounds: payload },
  }),
  SET_PREV_MAIN_LOCATION: (state, id, payload) => ({
    ...state,
    [id]: {
      ...substate(state, id),
      prevMainLocation: payload,
    },
  }),
}

export const [MapMultiStoreContext, MapMultiStoreProvider, useMapMultiStore] = createMultiStore(
  reducer,
  actions,
  { default: initialState },
  'MapMultiStore'
)
