import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import SetPasswordForm from 'components/SetPasswordForm'
import LoginCardWrapper from 'components/LoginCardWrapper'
import Layout from 'components/Layout'

const SetPasswordModule = () => {
  const params = useParams()
  const navigate = useNavigate()
  const onSetPassword = () => {
    navigate('/', { replace: true, showMessage: 'passwordSet' })
  }

  return (
    <Layout showMainMenu={false}>
      <LoginCardWrapper logoTop="riwis">
        <SetPasswordForm
          token={params.token}
          userId={params.userId}
          type={params.type}
          onSetPassword={onSetPassword}
        />
      </LoginCardWrapper>
    </Layout>
  )
}

export default SetPasswordModule
