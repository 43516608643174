import React from 'react'
import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAppStore } from 'stores/AppStore'
import { useAuthStore } from 'stores/AuthStore'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import Box, { Flex } from 'components/atoms/Box'

import MainMenuItem from './MainMenuItem'
import { MainMenuLanguageSelect } from './MainMenuLanguageSelect'

// Imported Icons
import LogoRiwis from 'components/icons/LogoRiwis'
import LogoRiwisOld from 'components/icons/LogoRiwisOld'
import LogoBulwiengesa from 'components/icons/LogoBulwiengesa'
import {
  BudiconPerformanceBoard,
  // BudiconCog,
  BudiconFiling,
  BudiconPowerSign,
  BudiconSharedUser,
  BudiconTaskDownload,
  BudiconBubbleChart,
  BudiconUserCard,
  BudiconTwoGrids,
  BudiconEqualizer,
  // BudiconSavings,
} from 'bgag-budicons'

const MainMenu = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [currentUser] = useAuthStore((state, actions) => [state.currentUser])
  const [{ retailMarketsActiveProject }] = useAppStore()

  const { hasRouteAccess, hasLicence } = useAuthzChecks()

  const userName = (currentUser.data?.firstname + ' ' + currentUser.data?.name).trim() || 'Max Mustermann'

  return (
    <Flex
      // position="fixed"
      height="100vh"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      flexGrow="0"
      flexShrink="0"
      flexBasis={theme.mainMenu.width}
      py={4}
      background={theme.mainMenu.background}
    >
      <Flex flexDirection="column" alignItems="center">
        <Box mb="0.125rem">
          {hasRouteAccess('/home') ? (
            <Link to="/home">
              <LogoRiwis part="icon" color={theme.mainMenu.foreground} size="20px" />
            </Link>
          ) : (
            <a href="https://apps.riwis.de/" target="_blank" rel="noopener noreferrer">
              <LogoRiwis part="icon" color={theme.mainMenu.foreground} size="20px" />
            </a>
          )}
        </Box>
        <MainMenuItem Icon={BudiconUserCard} title={userName} />
        {hasLicence('i18n') && <MainMenuLanguageSelect />}
        {/*route="/account"*/}
      </Flex>
      <Flex flexDirection="column">
        {
          //hasRouteAccess('/market-analysis/key-facts') ||
          (hasRouteAccess('/market-analysis/market-data') ||
            hasRouteAccess('/market-analysis/objects') ||
            hasRouteAccess('/market-analysis/city-structure') ||
            hasRouteAccess('/market-analysis/market-text')) && (
            <MainMenuItem
              Icon={BudiconPerformanceBoard}
              activeRouteMatch="market-analysis"
              title={t('modules.marketAnalysis')}
              subitems={[
                /*
              {
                label: 'Key-Facts',
                route: '/market-analysis/key-facts',
                visible: hasRouteAccess('/market-analysis/key-facts'),
              },
              */
                {
                  label: t('modules.market-data'),
                  route: '/market-analysis/market-data',
                  visible: hasRouteAccess('/market-analysis/market-data'),
                },
                {
                  label: t('modules.objects'),
                  route: '/market-analysis/objects',
                  visible: hasRouteAccess('/market-analysis/objects'),
                },
                {
                  label: t('modules.city-structure'),
                  route: '/market-analysis/city-structure',
                  visible: hasRouteAccess('/market-analysis/city-structure'),
                },
                {
                  label: t('modules.marketText'),
                  route: '/market-analysis/market-text',
                  visible: hasRouteAccess('/market-analysis/market-text'),
                },
              ]}
            />
          )
        }
        {(hasRouteAccess('/objects/comparables') || hasRouteAccess('/objects/pipeline')) && (
          <MainMenuItem
            Icon={LogoRiwisOld}
            activeRouteMatch="objectable"
            title={t('modules.objects')}
            subitems={[
              {
                label: t('modules.comparables'),
                route: '/objects/comparables',
                visible: hasRouteAccess('/objects/comparables'),
              },
              {
                label: t('modules.pipeline'),
                route: '/objects/pipeline',
                visible: hasRouteAccess('/objects/pipeline'),
              },
            ]}
          />
        )}
        {
          // hasRouteAccess('/makro/konjunktur') ||
          (hasRouteAccess('/makro/propertyindices') || hasRouteAccess('/makro/thematic-maps')) && (
            <MainMenuItem
              Icon={BudiconBubbleChart}
              activeRouteMatch="makro"
              title={t('modules.macro')}
              subitems={[
                {
                  label: t('modules.economy'),
                  route: '/makro/economy',
                  visible: hasRouteAccess('/makro/economy'),
                },
                {
                  label: t('modules.immoIndex'),
                  route: '/makro/propertyindices',
                  visible: hasRouteAccess('/makro/propertyindices'),
                },
                {
                  label: t('modules.thematicMaps'),
                  route: '/makro/thematic-maps',
                  visible: hasRouteAccess('/makro/thematic-maps'),
                },
              ]}
            />
          )
        }
        {(hasRouteAccess('/apps/disco') ||
          hasRouteAccess('/apps/l3plus') ||
          hasRouteAccess('/apps/retail-markets') ||
          hasRouteAccess('/apps/hedonic') ||
          hasRouteAccess('/apps/highstreet-report')) && (
          <MainMenuItem
            Icon={BudiconTwoGrids}
            title={t('modules.apps')}
            subitems={[
              {
                label: t('modules.disco'),
                route: '/apps/disco',
                visible: hasRouteAccess('/apps/disco'),
              },
              {
                label: t('modules.l3plus'),
                route: '/apps/l3plus',
                visible: hasRouteAccess('/apps/l3plus'),
              },
              {
                label: t('modules.retailMarkets'),
                route: retailMarketsActiveProject
                  ? `/apps/retail-markets/projects/${retailMarketsActiveProject}`
                  : '/apps/retail-markets',
                visible: hasRouteAccess('/apps/retail-markets'),
              },
              {
                label: 'Prospect', //t('modules.hedonic.residential'),
                route: '/apps/hedonic',
                visible: hasRouteAccess('/apps/hedonic'),
              },
              {
                label: t('modules.highstreetReport'),
                route: '/apps/highstreet-report',
                visible: hasRouteAccess('/apps/highstreet-report'),
              },
              {
                label: t('modules.developer'),
                route: '/apps/developer',
                visible: hasRouteAccess('/apps/developer'),
              },
            ]}
          />
        )}
        {(hasRouteAccess('/download/raw-data') || hasRouteAccess('/download/reports')) && (
          <MainMenuItem
            Icon={BudiconTaskDownload}
            activeRouteMatch="download"
            title={t('modules.download')}
            subitems={[
              {
                label: t('modules.documents'),
                route: '/download/documents',
                visible: hasRouteAccess('/download/documents'),
              },
              {
                label: t('modules.rawData'),
                route: '/download/raw-data',
                visible: hasRouteAccess('/download/raw-data'),
              },
              {
                label: t('modules.reports'),
                route: '/download/reports',
                visible: hasRouteAccess('/download/reports'),
              },
            ]}
          />
        )}
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        {hasRouteAccess('/sysadmin/i18n') && (
          <MainMenuItem
            Icon={BudiconEqualizer}
            title={t('modules.sysadmin')}
            subitems={[
              {
                label: t('modules.translations'),
                route: '/sysadmin/i18n',
                visible: hasRouteAccess('/sysadmin/i18n'),
              },
              {
                label: t('modules.errorLog'),
                route: '/sysadmin/error-log',
                visible: hasRouteAccess('/sysadmin/error-log'),
              },
              {
                label: t('modules.matviews'),
                route: '/sysadmin/matviews',
                visible: hasRouteAccess('/sysadmin/matviews'),
              },
            ]}
          />
        )}
        {hasRouteAccess('/admin/user') && (
          <MainMenuItem
            Icon={BudiconSharedUser}
            title={t('modules.admin')}
            subitems={[
              { label: t('modules.adminUsers'), Icon: BudiconFiling, route: '/admin/user' },
              {
                label: t('modules.adminGroups'),
                route: '/admin/group',
                visible: hasRouteAccess('/admin/group'),
              },
              {
                label: t('modules.adminRoles'),
                route: '/admin/role',
                visible: hasRouteAccess('/admin/role'),
              },
            ]}
          />
        )}
        {/*<MainMenuItem Icon={BudiconCog} title="Einstellungen" route="/settings" />*/}
        <MainMenuItem Icon={BudiconPowerSign} title={t('menu.logout')} route="/logout" />
        <a href="https://bulwiengesa.de" target="_blank" rel="noopener noreferrer">
          <LogoBulwiengesa part="icon" color={theme.mainMenu.foreground} size="20px" />
        </a>
      </Flex>
    </Flex>
  )
}

export default MainMenu
