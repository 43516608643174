export default {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE1', STAT_LEVL_: 1, Shape_Leng: 12.335639, Shape_Area: 4.393752 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.651418500000034, 49.780556997000076],
              [9.632460499000047, 49.693185999000036],
              [9.711135999000021, 49.726987001000055],
              [9.831804500000032, 49.70380349900006],
              [9.875725, 49.578631500000029],
              [9.915721, 49.582227999000054],
              [9.92656099900006, 49.484835501000077],
              [10.020789999000044, 49.486147499000033],
              [10.055085, 49.515003500000041],
              [10.03749, 49.529965501000049],
              [10.083623, 49.54295549900008],
              [10.118327500000021, 49.473169499000051],
              [10.094741500000055, 49.447898501000054],
              [10.161698, 49.395493999000053],
              [10.111966500000051, 49.384910499000057],
              [10.124643500000047, 49.200824501000056],
              [10.248023, 49.149281],
              [10.256947500000024, 49.129050500000069],
              [10.204997499000058, 49.098118999000064],
              [10.250596500000029, 49.092814998000051],
              [10.256763500000034, 49.059495],
              [10.409849500000064, 48.97743549900008],
              [10.457000500000049, 48.917141499000081],
              [10.423689, 48.744495997000058],
              [10.487258, 48.69666200100005],
              [10.361222500000054, 48.653186500000061],
              [10.268706999000074, 48.703546999000025],
              [10.25953, 48.654892499000027],
              [10.297810499000036, 48.624187499000072],
              [10.314184, 48.556289501000045],
              [10.312023, 48.522944],
              [10.278268, 48.516074499000069],
              [10.23077950000004, 48.510511],
              [10.241406500000039, 48.491959501000053],
              [10.133895500000051, 48.454871499000035],
              [10.11736649900007, 48.475387499000078],
              [10.032694500000048, 48.457196499000077],
              [10.042414, 48.429732],
              [9.987734, 48.370625998000037],
              [9.997606, 48.350117999000076],
              [10.065816500000039, 48.281816999000057],
              [10.095357500000034, 48.164014999000074],
              [10.136457, 48.108459498000059],
              [10.135728, 48.023761497000066],
              [10.132387, 48.015399999000067],
              [10.114954500000067, 47.976257501000077],
              [10.104207, 47.974358497000026],
              [10.110136500000067, 47.937149999000042],
              [10.104277500000023, 47.863723998000076],
              [10.131928500000072, 47.820087497000031],
              [10.064681, 47.785887500000058],
              [10.139437500000042, 47.703273497000062],
              [10.077292500000055, 47.639274498000077],
              [10.059312500000033, 47.680628999000078],
              [10.027738500000055, 47.678520001000038],
              [9.967994, 47.653549999000063],
              [9.840336, 47.677096499000072],
              [9.692543, 47.610768999000072],
              [9.558720500000049, 47.541892999000027],
              [9.495604, 47.551454999000043],
              [9.394714, 47.620325998000055],
              [9.182192, 47.655890499000066],
              [9.014395500000036, 47.685261998000044],
              [8.874998500000061, 47.655973498000037],
              [8.843022, 47.712262499000076],
              [8.806439, 47.738306999000031],
              [8.772097500000029, 47.718459498000072],
              [8.795708500000048, 47.675595500000043],
              [8.727981500000055, 47.692680499000062],
              [8.740104500000029, 47.752789001000053],
              [8.61233, 47.801462],
              [8.568785, 47.807621499000049],
              [8.510115, 47.776190500000041],
              [8.471941, 47.763224499000046],
              [8.405565999000032, 47.674513499000057],
              [8.475122, 47.640977999000029],
              [8.606369500000028, 47.669005499000036],
              [8.595602, 47.605544500000065],
              [8.562841, 47.599432498000063],
              [8.569295500000067, 47.617269499000031],
              [8.52144, 47.633524001000076],
              [8.456599500000038, 47.602355499000055],
              [8.494547, 47.581201],
              [8.426434500000028, 47.567548998000063],
              [8.221265500000072, 47.615514999000034],
              [8.01872, 47.550453997000034],
              [7.91777250000007, 47.547551],
              [7.894107500000075, 47.586374999000043],
              [7.713784500000032, 47.539405],
              [7.634097, 47.561113501000079],
              [7.689530500000046, 47.571500499000081],
              [7.671754500000077, 47.58736],
              [7.69337, 47.600644999000053],
              [7.589039, 47.589877999000066],
              [7.519558, 47.668123],
              [7.511589999000023, 47.696755999000061],
              [7.545990500000072, 47.743573999000041],
              [7.530212, 47.783140499000069],
              [7.558898500000055, 47.88182349900007],
              [7.621966500000042, 47.974748999000049],
              [7.568529, 48.035183999000026],
              [7.577291, 48.115654999000071],
              [7.577919500000065, 48.121391999000082],
              [7.680713, 48.257266997000045],
              [7.693569, 48.301722997000077],
              [7.739841500000069, 48.321731501000045],
              [7.733689, 48.398711999000056],
              [7.76694850000007, 48.487129999000047],
              [7.80394350000006, 48.511216500000053],
              [7.804824, 48.592579499000067],
              [7.959630500000061, 48.718580999000039],
              [7.968908500000055, 48.753709498000035],
              [8.087040500000057, 48.802033501000039],
              [8.141640500000051, 48.896047001000056],
              [8.232632999000032, 48.96657150100004],
              [8.261284, 48.980916999000044],
              [8.277349, 48.989939001000039],
              [8.33997, 49.080149999000071],
              [8.404737500000067, 49.219299498000055],
              [8.387592500000039, 49.231147999000029],
              [8.413072500000055, 49.249816499000076],
              [8.466985500000021, 49.282975501000067],
              [8.487268, 49.290026499000078],
              [8.451985, 49.317683],
              [8.470915500000046, 49.340712999000061],
              [8.500397500000076, 49.389013497000064],
              [8.46538, 49.387252],
              [8.497316, 49.411347],
              [8.474739704000058, 49.440616029000068],
              [8.444322500000055, 49.459187500000041],
              [8.423068, 49.541821],
              [8.422700500000076, 49.574192499000048],
              [8.422439500000053, 49.58338549900003],
              [8.475796, 49.584452499000065],
              [8.581375, 49.519780499000035],
              [8.621299500000021, 49.54501],
              [8.589315500000055, 49.604514997000088],
              [8.693837, 49.619353499000056],
              [8.67214, 49.611194],
              [8.70265, 49.535328],
              [8.899572500000033, 49.503655499000047],
              [8.899355, 49.48455],
              [8.828248, 49.470867499000072],
              [8.801542, 49.410945499000036],
              [8.850466500000039, 49.395413499000028],
              [8.950348500000075, 49.454992999000069],
              [8.93188, 49.470636997000042],
              [8.951832500000023, 49.505625999000074],
              [9.08342650000003, 49.52608699700005],
              [9.126459500000067, 49.521607],
              [9.087249501000031, 49.544535501000041],
              [9.103006, 49.577456],
              [9.238701500000047, 49.581291501000067],
              [9.304083, 49.654944499000067],
              [9.41092, 49.663507999000046],
              [9.397891500000071, 49.670660497000028],
              [9.422313, 49.696079499000064],
              [9.399960500000077, 49.728840498000068],
              [9.357409, 49.704288500000075],
              [9.295673, 49.740530999000043],
              [9.315683, 49.768328498000074],
              [9.471497499000066, 49.779726501000034],
              [9.648736, 49.791477500000042],
              [9.651418500000034, 49.780556997000076],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE2', STAT_LEVL_: 1, Shape_Leng: 20.953193, Shape_Area: 8.666946 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [10.330582, 50.494276498000033],
              [10.392067, 50.396240999000042],
              [10.450532, 50.401859500000057],
              [10.492405500000075, 50.355055999000065],
              [10.600550500000054, 50.333687999000063],
              [10.591040500000076, 50.271784498000045],
              [10.610115, 50.227994999000032],
              [10.716645, 50.204819001000033],
              [10.729202, 50.230005497000036],
              [10.851494500000058, 50.262762499000075],
              [10.715396, 50.321176498000057],
              [10.715266500000041, 50.363590998000063],
              [10.887187500000039, 50.392572499000039],
              [10.945718500000055, 50.386466499000051],
              [10.985780500000033, 50.34732050000008],
              [11.115305, 50.367006997000033],
              [11.159197500000062, 50.330392001000064],
              [11.134827500000029, 50.314054498000075],
              [11.141557, 50.289191998000035],
              [11.189943, 50.271185501000048],
              [11.259778, 50.274946499000066],
              [11.284253, 50.360307500000033],
              [11.245132, 50.470802999000057],
              [11.265938, 50.479421],
              [11.343759500000033, 50.520691999000064],
              [11.423893, 50.516064499000038],
              [11.440998, 50.500673501000051],
              [11.418814500000053, 50.493461499000034],
              [11.420630500000073, 50.443366500000081],
              [11.481568, 50.431621501000052],
              [11.519853, 50.374268499000038],
              [11.603290500000071, 50.39876600100007],
              [11.795348, 50.41818100100005],
              [11.823488500000053, 50.38966699800006],
              [11.919884500000023, 50.424403499000057],
              [11.945645, 50.416283001000075],
              [12.033299, 50.329707],
              [12.100900500000023, 50.318027998000048],
              [12.13994, 50.277718499000059],
              [12.09076, 50.252384501000051],
              [12.160679, 50.219848998000039],
              [12.208540999000036, 50.171509499000081],
              [12.199912500000039, 50.110599499000045],
              [12.275353500000051, 50.076470999000037],
              [12.260799, 50.058156498000081],
              [12.279261500000075, 50.05506249900003],
              [12.489889500000061, 49.981761],
              [12.470510500000046, 49.945025501000032],
              [12.550988500000075, 49.905088001000081],
              [12.472947, 49.83358849900003],
              [12.472723, 49.785937999000055],
              [12.401524, 49.758372998000027],
              [12.442886, 49.703699998000047],
              [12.521638, 49.686762],
              [12.593778500000042, 49.542191499000069],
              [12.644176, 49.52305750000005],
              [12.633763500000043, 49.47619499800004],
              [12.757645, 49.394529999000042],
              [12.77881, 49.348078497000074],
              [12.972595500000068, 49.333887999000069],
              [13.170907999000065, 49.173579501000063],
              [13.176875500000051, 49.164226497000072],
              [13.170321500000057, 49.144040499000027],
              [13.204510500000026, 49.122753998000064],
              [13.282030500000076, 49.120606499000075],
              [13.397147, 49.05100999900003],
              [13.416651500000057, 48.980035499000053],
              [13.508258, 48.942165998000064],
              [13.507403500000066, 48.969103499000028],
              [13.551578500000062, 48.967787497000074],
              [13.630758499000024, 48.947104001000071],
              [13.655197500000043, 48.893465499000058],
              [13.716819500000042, 48.878157500000043],
              [13.839507, 48.771605001000069],
              [13.796107, 48.713600499000052],
              [13.825764, 48.618616499000041],
              [13.727090500000031, 48.513019501000031],
              [13.567189, 48.561916500000052],
              [13.513369500000067, 48.590977998000028],
              [13.438430500000038, 48.548895001000062],
              [13.458039500000041, 48.511944499000037],
              [13.407838, 48.372160501000053],
              [13.253685500000076, 48.293155999000085],
              [13.177043, 48.294389997000053],
              [13.024532500000078, 48.258686501000057],
              [12.944684500000051, 48.206692499000042],
              [12.870912499000042, 48.201858998000034],
              [12.751555, 48.112810498000044],
              [12.761640499000066, 48.073050501000068],
              [12.860181500000067, 47.996639999000081],
              [12.875785500000063, 47.962608999000061],
              [12.911716499000022, 47.956985],
              [13.003897500000051, 47.847968997000066],
              [12.905047, 47.723643500000037],
              [13.047965, 47.709118999000061],
              [13.097948, 47.633716999000058],
              [13.043574500000034, 47.583446500000036],
              [13.03103150000004, 47.533086500000081],
              [13.046055500000023, 47.520502499000031],
              [13.047505, 47.492083],
              [13.003285500000061, 47.463374499000054],
              [12.81025, 47.547062999000048],
              [12.779256500000031, 47.579285501000072],
              [12.807303, 47.610227499000075],
              [12.74477950000005, 47.67570599700008],
              [12.695795500000031, 47.682222999000032],
              [12.604631500000039, 47.673161999000058],
              [12.575026500000035, 47.632316],
              [12.498881, 47.624945499000034],
              [12.429601, 47.696024501000068],
              [12.338045499000032, 47.697087501000055],
              [12.257177500000068, 47.679100001000052],
              [12.241167500000074, 47.694103999000049],
              [12.264886500000046, 47.732482999000069],
              [12.183931, 47.70037049900003],
              [12.20731950000004, 47.609837999000035],
              [12.060662, 47.618743499000061],
              [11.913529, 47.613772499000049],
              [11.844297500000039, 47.581265999000038],
              [11.632883, 47.592445997000027],
              [11.572426, 47.51464399900005],
              [11.410219, 47.495324001000029],
              [11.383809500000041, 47.472207497000056],
              [11.42142750000005, 47.444851999000036],
              [11.335193, 47.447955999000044],
              [11.267872, 47.397546997000063],
              [11.222306500000059, 47.397347497000055],
              [11.253411, 47.428243999000074],
              [11.205162, 47.433776499000032],
              [11.114888500000063, 47.395567999000036],
              [11.016500500000063, 47.396368001000042],
              [10.991202500000043, 47.396131],
              [10.928111, 47.471922999000071],
              [10.871043, 47.481881999000052],
              [10.90492450000005, 47.51769549900007],
              [10.886199, 47.536847999000031],
              [10.777643500000067, 47.514106499000043],
              [10.618357500000059, 47.566969],
              [10.573515, 47.533906999000074],
              [10.454439, 47.555796999000059],
              [10.431323500000076, 47.503677497000069],
              [10.463763500000027, 47.482740497000066],
              [10.475413, 47.431702001000076],
              [10.31503, 47.306524501000069],
              [10.178353, 47.270113999000046],
              [10.23393, 47.383285998000076],
              [10.09985049900007, 47.354761498000073],
              [10.070468, 47.407710998000027],
              [10.104865, 47.42806299800003],
              [10.091470500000071, 47.45907599800006],
              [9.999526, 47.48301699700005],
              [9.967813500000034, 47.546240497000042],
              [9.87449, 47.528538497000056],
              [9.815676, 47.547608999000033],
              [9.823746500000027, 47.587183999000047],
              [9.765470500000049, 47.588856001000067],
              [9.736566500000038, 47.534906499000044],
              [9.680359500000066, 47.52335749800006],
              [9.558720500000049, 47.541892999000027],
              [9.692543, 47.610768999000072],
              [9.840336, 47.677096499000072],
              [9.967994, 47.653549999000063],
              [10.027738500000055, 47.678520001000038],
              [10.059312500000033, 47.680628999000078],
              [10.077292500000055, 47.639274498000077],
              [10.139437500000042, 47.703273497000062],
              [10.064681, 47.785887500000058],
              [10.131928500000072, 47.820087497000031],
              [10.104277500000023, 47.863723998000076],
              [10.110136500000067, 47.937149999000042],
              [10.104207, 47.974358497000026],
              [10.114954500000067, 47.976257501000077],
              [10.132387, 48.015399999000067],
              [10.135728, 48.023761497000066],
              [10.136457, 48.108459498000059],
              [10.095357500000034, 48.164014999000074],
              [10.065816500000039, 48.281816999000057],
              [9.997606, 48.350117999000076],
              [9.987734, 48.370625998000037],
              [10.042414, 48.429732],
              [10.032694500000048, 48.457196499000077],
              [10.11736649900007, 48.475387499000078],
              [10.133895500000051, 48.454871499000035],
              [10.241406500000039, 48.491959501000053],
              [10.23077950000004, 48.510511],
              [10.278268, 48.516074499000069],
              [10.312023, 48.522944],
              [10.314184, 48.556289501000045],
              [10.297810499000036, 48.624187499000072],
              [10.25953, 48.654892499000027],
              [10.268706999000074, 48.703546999000025],
              [10.361222500000054, 48.653186500000061],
              [10.487258, 48.69666200100005],
              [10.423689, 48.744495997000058],
              [10.457000500000049, 48.917141499000081],
              [10.409849500000064, 48.97743549900008],
              [10.256763500000034, 49.059495],
              [10.250596500000029, 49.092814998000051],
              [10.204997499000058, 49.098118999000064],
              [10.256947500000024, 49.129050500000069],
              [10.248023, 49.149281],
              [10.124643500000047, 49.200824501000056],
              [10.111966500000051, 49.384910499000057],
              [10.161698, 49.395493999000053],
              [10.094741500000055, 49.447898501000054],
              [10.118327500000021, 49.473169499000051],
              [10.083623, 49.54295549900008],
              [10.03749, 49.529965501000049],
              [10.055085, 49.515003500000041],
              [10.020789999000044, 49.486147499000033],
              [9.92656099900006, 49.484835501000077],
              [9.915721, 49.582227999000054],
              [9.875725, 49.578631500000029],
              [9.831804500000032, 49.70380349900006],
              [9.711135999000021, 49.726987001000055],
              [9.632460499000047, 49.693185999000036],
              [9.651418500000034, 49.780556997000076],
              [9.648736, 49.791477500000042],
              [9.471497499000066, 49.779726501000034],
              [9.315683, 49.768328498000074],
              [9.295673, 49.740530999000043],
              [9.357409, 49.704288500000075],
              [9.399960500000077, 49.728840498000068],
              [9.422313, 49.696079499000064],
              [9.397891500000071, 49.670660497000028],
              [9.41092, 49.663507999000046],
              [9.304083, 49.654944499000067],
              [9.238701500000047, 49.581291501000067],
              [9.103006, 49.577456],
              [9.08299, 49.601987002000051],
              [9.088071, 49.689591999000072],
              [9.150809, 49.742850497000063],
              [9.036080500000025, 49.84650399800006],
              [9.05008, 49.866315001000032],
              [9.016088500000023, 49.991340501000082],
              [9.048820500000033, 49.992265500000038],
              [9.032088500000043, 50.03809],
              [8.975926, 50.048764499000079],
              [8.990559999000027, 50.067119001000037],
              [9.018261, 50.111603999000067],
              [9.235982500000034, 50.146280500000046],
              [9.375639500000034, 50.129660499000067],
              [9.404984500000069, 50.087734498000039],
              [9.519177500000069, 50.105850001000078],
              [9.494769500000075, 50.226671997000039],
              [9.514378, 50.234076999000088],
              [9.623150999000075, 50.229039998000076],
              [9.745881, 50.310408],
              [9.732914500000049, 50.356149499000026],
              [9.771871, 50.424859499000036],
              [9.860046500000067, 50.397474],
              [9.935655, 50.41960650000005],
              [10.018307500000049, 50.466455500000052],
              [10.041338500000052, 50.516469499000038],
              [10.12726, 50.559533001000034],
              [10.330582, 50.494276498000033],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE3', STAT_LEVL_: 1, Shape_Leng: 1.837863, Shape_Area: 0.119654 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [13.610827500000028, 52.54423550000007],
              [13.658572500000048, 52.525810999000043],
              [13.640613, 52.479484500000069],
              [13.66698, 52.474166999000033],
              [13.743118, 52.43399849900004],
              [13.699738, 52.377882998000075],
              [13.642645, 52.340012500000057],
              [13.646952, 52.368725999000048],
              [13.592762, 52.394169499000043],
              [13.479885, 52.39599650100007],
              [13.418772500000046, 52.409917499000073],
              [13.420984999000041, 52.376247001000081],
              [13.372135, 52.393824001000041],
              [13.31212, 52.399599500000079],
              [13.225344500000062, 52.420133997000057],
              [13.165898, 52.39427549800007],
              [13.088333, 52.419611501000084],
              [13.153695500000026, 52.501821501000052],
              [13.166457500000037, 52.510124498000039],
              [13.117393, 52.516995498000028],
              [13.128987500000051, 52.587441999000077],
              [13.164263, 52.59890050000007],
              [13.217336500000044, 52.587455500000033],
              [13.201606500000025, 52.606381498000076],
              [13.220683500000064, 52.628178498000068],
              [13.39854, 52.648194498000066],
              [13.475647500000036, 52.666280500000035],
              [13.508153500000049, 52.592183499000043],
              [13.610827500000028, 52.54423550000007],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE4', STAT_LEVL_: 1, Shape_Leng: 14.152645, Shape_Area: 3.90666 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [14.098893500000031, 53.261951499000077],
              [14.266023500000074, 53.259746499000073],
              [14.316569499000025, 53.312335499000028],
              [14.412157, 53.329635998000079],
              [14.441223, 53.25436],
              [14.377268, 53.201751999000074],
              [14.366404500000044, 53.172680999000079],
              [14.387275, 53.142391998000051],
              [14.357842, 53.067666999000039],
              [14.143658, 52.961368500000049],
              [14.156692, 52.895590999000035],
              [14.127624, 52.83166250000005],
              [14.351042, 52.751509998000074],
              [14.436438, 52.679900499000041],
              [14.565063, 52.624497],
              [14.639691, 52.571574498000075],
              [14.60426, 52.533489499000041],
              [14.635373, 52.496368500000074],
              [14.534361999000055, 52.395008],
              [14.600891500000047, 52.272051999000041],
              [14.716044, 52.23545800100004],
              [14.682174, 52.114688999000066],
              [14.755227, 52.070024998000065],
              [14.716716, 52.00118800100006],
              [14.719387999000046, 51.941951999000082],
              [14.693557, 51.901287997000054],
              [14.590146, 51.821025999000028],
              [14.645114, 51.795814001000053],
              [14.668124, 51.725826998000059],
              [14.752694, 51.667095998000036],
              [14.765142999000034, 51.607771500000069],
              [14.729862, 51.581776999000056],
              [14.711164, 51.59576499800005],
              [14.675624, 51.551469499000063],
              [14.569635, 51.581190499000058],
              [14.447771, 51.542068997000058],
              [14.343553, 51.517111999000065],
              [14.163324500000044, 51.541042999000069],
              [14.107796500000063, 51.521760500000028],
              [13.996318500000029, 51.382607497000038],
              [13.835313, 51.376789998000049],
              [13.691250500000024, 51.374012999000058],
              [13.537766500000032, 51.381046501000078],
              [13.412191500000063, 51.439374499000053],
              [13.21015, 51.404735999000081],
              [13.207957, 51.524353001000065],
              [13.154955, 51.600197001000026],
              [13.086303999000052, 51.608134999000072],
              [13.051025, 51.647677],
              [13.161745, 51.690602001000059],
              [13.16301150000004, 51.802018001000079],
              [13.123426500000052, 51.856899499000065],
              [13.150913500000058, 51.859610500000031],
              [13.044088500000043, 51.870122497000068],
              [12.972665, 51.900880999000037],
              [12.960568, 51.934910499000068],
              [12.85273, 51.935005498000066],
              [12.844153500000061, 51.967734001000061],
              [12.769780500000024, 51.979274500000031],
              [12.656502500000045, 52.012939501000062],
              [12.539394500000071, 51.984889],
              [12.389599500000031, 52.04356599700003],
              [12.376122500000065, 52.045119498000076],
              [12.315823500000022, 52.092372],
              [12.276724499000068, 52.104018],
              [12.216783, 52.170068499000081],
              [12.297277, 52.227545500000083],
              [12.24446, 52.248001999000053],
              [12.30812750000007, 52.344696498000076],
              [12.293404, 52.423492500000066],
              [12.31718, 52.454095499000061],
              [12.330781500000057, 52.478555498000048],
              [12.258216, 52.517885500000034],
              [12.171555, 52.506336497000063],
              [12.14324, 52.535797499000068],
              [12.230846, 52.629754999000056],
              [12.239352, 52.671736],
              [12.201294500000074, 52.729003999000042],
              [12.213132500000029, 52.773607498000054],
              [12.249203500000021, 52.791861999000048],
              [12.233961500000021, 52.859454501000073],
              [12.123714500000062, 52.853795999000056],
              [12.126811500000031, 52.890199499000062],
              [11.89485650000006, 52.894104498000047],
              [11.823375, 52.918272999000067],
              [11.848304, 52.949515498000039],
              [11.628581, 53.010597],
              [11.639365500000054, 53.038718997000046],
              [11.597784500000046, 53.035926499000027],
              [11.452104, 53.078019998000059],
              [11.338867, 53.055098999000052],
              [11.265732, 53.121978],
              [11.534572, 53.12531849700008],
              [11.569365, 53.166094999000052],
              [11.553182, 53.208890497000034],
              [11.70039, 53.244149997000079],
              [11.729017500000054, 53.217053498000041],
              [11.828893500000049, 53.228654999000078],
              [12.01853, 53.299594498000033],
              [12.057339500000069, 53.349342],
              [12.045285, 53.370987501000059],
              [12.076698500000077, 53.369312500000035],
              [12.325101500000073, 53.321301498000082],
              [12.331751500000053, 53.318011],
              [12.445312, 53.249928501000056],
              [12.654933, 53.255250999000054],
              [12.763398500000051, 53.189161999000078],
              [12.964245, 53.200391498000045],
              [12.97943250000003, 53.190551499000037],
              [12.941998500000068, 53.174315501000088],
              [12.984683, 53.164985500000057],
              [13.152506, 53.246248],
              [13.241403500000047, 53.232401],
              [13.297801500000048, 53.280892501000039],
              [13.407685, 53.25992350100006],
              [13.483196, 53.291742],
              [13.523531500000047, 53.320084501000053],
              [13.551025, 53.399436500000036],
              [13.710065500000042, 53.479062500000055],
              [13.827101500000026, 53.498713501000054],
              [13.778238499000055, 53.513434999000026],
              [13.798463499000036, 53.545689499000048],
              [13.943100500000071, 53.429916500000047],
              [14.241636500000027, 53.422151998000061],
              [14.222437, 53.357690997000077],
              [14.098893500000031, 53.261951499000077],
            ],
            [
              [13.201606500000025, 52.606381498000076],
              [13.217336500000044, 52.587455500000033],
              [13.164263, 52.59890050000007],
              [13.128987500000051, 52.587441999000077],
              [13.117393, 52.516995498000028],
              [13.166457500000037, 52.510124498000039],
              [13.153695500000026, 52.501821501000052],
              [13.088333, 52.419611501000084],
              [13.165898, 52.39427549800007],
              [13.225344500000062, 52.420133997000057],
              [13.31212, 52.399599500000079],
              [13.372135, 52.393824001000041],
              [13.420984999000041, 52.376247001000081],
              [13.418772500000046, 52.409917499000073],
              [13.479885, 52.39599650100007],
              [13.592762, 52.394169499000043],
              [13.646952, 52.368725999000048],
              [13.642645, 52.340012500000057],
              [13.699738, 52.377882998000075],
              [13.743118, 52.43399849900004],
              [13.66698, 52.474166999000033],
              [13.640613, 52.479484500000069],
              [13.658572500000048, 52.525810999000043],
              [13.610827500000028, 52.54423550000007],
              [13.508153500000049, 52.592183499000043],
              [13.475647500000036, 52.666280500000035],
              [13.39854, 52.648194498000066],
              [13.220683500000064, 52.628178498000068],
              [13.201606500000025, 52.606381498000076],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE5', STAT_LEVL_: 1, Shape_Leng: 1.815191, Shape_Area: 0.054735 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.979256, 53.045849500000031],
              [8.915827, 53.011021497000058],
              [8.778297500000065, 53.039666500000074],
              [8.711424, 53.044629997000072],
              [8.669831, 53.102974499000027],
              [8.654898500000058, 53.108864500000038],
              [8.620786, 53.166614499000048],
              [8.485330500000032, 53.22712],
              [8.530234500000063, 53.216089499000077],
              [8.984185500000024, 53.126070999000035],
              [8.947018, 53.116101],
              [8.979256, 53.045849500000031],
            ],
          ],
          [
            [
              [8.520410500000025, 53.606205001000035],
              [8.592283501000054, 53.592932500000074],
              [8.650632500000029, 53.602565001000073],
              [8.652134001000036, 53.516016999000044],
              [8.492652, 53.472420001000046],
              [8.554898500000037, 53.525131001000034],
              [8.540526131000036, 53.540239468000038],
              [8.523197255000071, 53.558455864000052],
              [8.492450101000031, 53.590777770000052],
              [8.483202500000061, 53.600498998000035],
              [8.520410500000025, 53.606205001000035],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE6', STAT_LEVL_: 1, Shape_Leng: 1.786004, Shape_Area: 0.100138 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [10.236678500000039, 53.496354498000073],
              [10.325959, 53.449639498000067],
              [10.308312, 53.43322149800008],
              [10.171563500000047, 53.397299500000031],
              [10.051810500000045, 53.463918999000043],
              [9.916487500000073, 53.414067999000054],
              [9.901583500000072, 53.457322500000032],
              [9.862387500000068, 53.429876499000045],
              [9.784525, 53.500011501000074],
              [9.768861, 53.505278998000051],
              [9.73010399900005, 53.557580997000059],
              [9.770294, 53.627462497000074],
              [9.824958, 53.584528001000081],
              [9.945376, 53.652927998000052],
              [10.069265500000029, 53.679450499000041],
              [10.072805, 53.709633999000062],
              [10.191279, 53.722186499000031],
              [10.140273, 53.680126499000039],
              [10.221761500000071, 53.633722500000033],
              [10.201449500000024, 53.584075999000049],
              [10.15208350000006, 53.576829999000047],
              [10.151152500000023, 53.545018001000074],
              [10.236678500000039, 53.496354498000073],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE7', STAT_LEVL_: 1, Shape_Leng: 11.646156, Shape_Area: 2.672911 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.498334500000055, 51.650574997000035],
              [9.52022, 51.62612450000006],
              [9.625999500000034, 51.636913998000068],
              [9.685331, 51.582016499000076],
              [9.672377500000039, 51.568403999000054],
              [9.625825001000067, 51.580205],
              [9.647755500000073, 51.552510999000049],
              [9.585751, 51.517317500000047],
              [9.642956001000073, 51.416321500000038],
              [9.579345500000045, 51.401769],
              [9.557291, 51.351378499000077],
              [9.568025, 51.340001499000039],
              [9.710295479000024, 51.30153775000008],
              [9.775202, 51.325195999000073],
              [9.702144, 51.364571999000077],
              [9.803510499000026, 51.410400499000048],
              [9.913393500000041, 51.410630999000034],
              [9.928339, 51.375299],
              [9.948770500000023, 51.316989497000066],
              [10.067095, 51.22779299900003],
              [10.206942, 51.190648997000039],
              [10.235999, 51.184767499000031],
              [10.210013, 51.144082999000034],
              [10.184905, 51.141119998000079],
              [10.118321500000036, 51.139573],
              [10.143678, 51.057786498000041],
              [10.215185500000075, 51.026419499000042],
              [10.202181, 51.012009001000081],
              [10.182348500000046, 50.998487500000074],
              [10.021558500000026, 50.992949500000066],
              [10.063865, 50.945432997000069],
              [9.949583500000074, 50.927478500000063],
              [10.055980999000042, 50.879771498000082],
              [10.023054500000057, 50.831631500000071],
              [9.952253, 50.821374499000065],
              [9.926176500000054, 50.767389999000045],
              [9.941371500000059, 50.74183799900004],
              [9.881643500000052, 50.672171499000058],
              [9.878551, 50.634780999000043],
              [9.960722, 50.62948349900006],
              [9.949108, 50.664142499000036],
              [10.001995, 50.676602499000069],
              [10.077555500000074, 50.637623997000048],
              [10.037268, 50.610883999000066],
              [10.041338500000052, 50.516469499000038],
              [10.018307500000049, 50.466455500000052],
              [9.935655, 50.41960650000005],
              [9.860046500000067, 50.397474],
              [9.771871, 50.424859499000036],
              [9.732914500000049, 50.356149499000026],
              [9.745881, 50.310408],
              [9.623150999000075, 50.229039998000076],
              [9.514378, 50.234076999000088],
              [9.494769500000075, 50.226671997000039],
              [9.519177500000069, 50.105850001000078],
              [9.404984500000069, 50.087734498000039],
              [9.375639500000034, 50.129660499000067],
              [9.235982500000034, 50.146280500000046],
              [9.018261, 50.111603999000067],
              [8.990559999000027, 50.067119001000037],
              [8.975926, 50.048764499000079],
              [9.032088500000043, 50.03809],
              [9.048820500000033, 49.992265500000038],
              [9.016088500000023, 49.991340501000082],
              [9.05008, 49.866315001000032],
              [9.036080500000025, 49.84650399800006],
              [9.150809, 49.742850497000063],
              [9.088071, 49.689591999000072],
              [9.08299, 49.601987002000051],
              [9.103006, 49.577456],
              [9.087249501000031, 49.544535501000041],
              [9.126459500000067, 49.521607],
              [9.08342650000003, 49.52608699700005],
              [8.951832500000023, 49.505625999000074],
              [8.93188, 49.470636997000042],
              [8.950348500000075, 49.454992999000069],
              [8.850466500000039, 49.395413499000028],
              [8.801542, 49.410945499000036],
              [8.828248, 49.470867499000072],
              [8.899355, 49.48455],
              [8.899572500000033, 49.503655499000047],
              [8.70265, 49.535328],
              [8.67214, 49.611194],
              [8.693837, 49.619353499000056],
              [8.589315500000055, 49.604514997000088],
              [8.621299500000021, 49.54501],
              [8.581375, 49.519780499000035],
              [8.475796, 49.584452499000065],
              [8.422439500000053, 49.58338549900003],
              [8.414774, 49.595051998000031],
              [8.356921, 49.69088399900005],
              [8.44637749900005, 49.730799497000078],
              [8.448365, 49.73366149900005],
              [8.479186, 49.764212],
              [8.426199, 49.767193501000065],
              [8.400055, 49.803675],
              [8.382785999000021, 49.856715999000073],
              [8.346520500000054, 49.874819500000058],
              [8.343030500000054, 49.940506],
              [8.288245, 49.995134],
              [8.244557500000042, 50.027628500000048],
              [8.190038500000071, 50.035295997000048],
              [8.175135500000067, 50.034255497000061],
              [7.886587500000076, 49.971904001000041],
              [7.773997, 50.066539999000042],
              [7.83923, 50.087480499000037],
              [7.854016, 50.128328999000082],
              [7.925326, 50.108845497000068],
              [7.932942500000023, 50.14388450000007],
              [7.884014, 50.169568499000036],
              [7.907826500000056, 50.200756501000058],
              [7.992549, 50.232315501000073],
              [8.035050500000068, 50.215592998000034],
              [8.058909500000027, 50.234405498000058],
              [8.021650500000021, 50.258344998000041],
              [8.035892, 50.271191999000052],
              [8.121913500000062, 50.277224999000055],
              [8.059757, 50.375311501000056],
              [7.97155950000007, 50.406220499000035],
              [7.992857501000061, 50.52211150100004],
              [8.041611500000045, 50.556993498000054],
              [8.11773850000003, 50.53450900100006],
              [8.151592, 50.59937099900003],
              [8.110092501000054, 50.657250999000041],
              [8.125776500000029, 50.685813998000071],
              [8.166829, 50.73571049800006],
              [8.125512, 50.788355999000032],
              [8.291897500000061, 50.87715600100006],
              [8.355495999000029, 50.862001],
              [8.477892, 50.969047497000076],
              [8.538782, 51.019448500000067],
              [8.501733500000057, 51.079465499000037],
              [8.549084999000058, 51.101868001000071],
              [8.656632500000057, 51.094902999000055],
              [8.758214500000065, 51.177181499000028],
              [8.721997500000043, 51.273743997000054],
              [8.59729, 51.247179500000072],
              [8.556348, 51.277495],
              [8.68347650000004, 51.362177999000039],
              [8.939502, 51.389498999000075],
              [8.948108500000046, 51.42821899900008],
              [8.890376500000059, 51.481928500000038],
              [8.970653500000026, 51.506773500000065],
              [9.023958, 51.511333997000065],
              [9.092329, 51.494388001000061],
              [9.077185500000041, 51.464871499000026],
              [9.093033500000047, 51.445076999000037],
              [9.155410500000073, 51.442674999000076],
              [9.373522500000036, 51.591013499000042],
              [9.337098500000025, 51.614701500000081],
              [9.440457, 51.65039399900008],
              [9.498334500000055, 51.650574997000035],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE8', STAT_LEVL_: 1, Shape_Leng: 18.796721, Shape_Area: 3.126836 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [13.972084500000051, 54.06874749800005],
              [14.141100291000043, 53.975606063000043],
              [14.226302, 53.928652998000075],
              [14.22628308700007, 53.928564082000037],
              [14.213077499000065, 53.866479500000082],
              [14.212804695000045, 53.866467658000033],
              [14.004035114000033, 53.857406050000066],
              [13.954101226000034, 53.855238678000035],
              [13.920590656000059, 53.853784157000064],
              [13.846603626000046, 53.85057276200007],
              [13.82615050000004, 53.849684998000043],
              [13.826176767000049, 53.849699048000048],
              [13.878293284000051, 53.87757619000007],
              [13.938169500000072, 53.90960399800008],
              [13.896613, 53.963705001000051],
              [13.911026779000053, 53.991133977000061],
              [13.911077500000033, 53.991230498000057],
              [13.969587428000068, 53.968339645000071],
              [13.988917542000024, 53.960777120000046],
              [14.010670472000072, 53.952266716000061],
              [14.039896336000027, 53.940832674000035],
              [14.040180500000078, 53.940721500000052],
              [14.040955294000071, 53.947213307000027],
              [14.042787221000026, 53.962562563000063],
              [14.047417500000051, 54.001358498000059],
              [14.016433351000046, 54.015298040000062],
              [14.003099821000035, 54.021296698000072],
              [13.916600895000045, 54.060211933000062],
              [13.910431500000072, 54.06298749900003],
              [13.910452475000056, 54.062844249000079],
              [13.912034038000058, 54.052043056000059],
              [13.912970103000077, 54.04565025100004],
              [13.915249080000024, 54.030086102000041],
              [13.916453, 54.02186400100004],
              [13.903396221000037, 54.016575848000059],
              [13.888764355000035, 54.010649766000029],
              [13.861864500000024, 53.999755],
              [13.861928953000074, 53.999901568000041],
              [13.877441132000058, 54.035176708000051],
              [13.879676500000073, 54.040259999000057],
              [13.879360882000071, 54.040218645000039],
              [13.810579, 54.031206499000064],
              [13.81054255500004, 54.031240695000065],
              [13.790694500000029, 54.049863999000081],
              [13.798404935000065, 54.066972030000045],
              [13.802809110000055, 54.076744080000026],
              [13.813017, 54.099393501000065],
              [13.79868362900004, 54.111032876000081],
              [13.75074599900006, 54.149960501000066],
              [13.812212429000056, 54.169352261000029],
              [13.812452500000063, 54.169428],
              [13.855302500000052, 54.112850499000047],
              [13.972084500000051, 54.06874749800005],
            ],
          ],
          [
            [
              [12.817046817000062, 54.440487317000077],
              [12.92695150000003, 54.427963999000042],
              [12.926598364000029, 54.427970346000052],
              [12.722117501000071, 54.43164562700008],
              [12.718841862000033, 54.431704502000059],
              [12.696726500000068, 54.432101998000064],
              [12.696702355000072, 54.432097086000056],
              [12.688156895000077, 54.430358631000047],
              [12.643195287000026, 54.421211819000064],
              [12.61548533000007, 54.415574614000036],
              [12.436138500000027, 54.379089001000068],
              [12.415738505000036, 54.358933767000053],
              [12.399950403000048, 54.343335092000075],
              [12.392043233000038, 54.335522793000052],
              [12.366495331000067, 54.310281417000056],
              [12.358043, 54.301930498000047],
              [12.363174500000071, 54.267097999000043],
              [12.459206359000065, 54.248041903000058],
              [12.459669500000075, 54.24794999900007],
              [12.447237014000054, 54.254845181000064],
              [12.40778350000005, 54.276726499000063],
              [12.449787723000043, 54.299163405000058],
              [12.477974259000064, 54.314219478000041],
              [12.513784238000028, 54.333347677000063],
              [12.535630339000022, 54.345016954000073],
              [12.57614650000005, 54.366659],
              [12.656624309000051, 54.385215745000046],
              [12.68766572100003, 54.392373340000063],
              [12.698259475000043, 54.394816070000047],
              [12.714825745000041, 54.398635956000078],
              [12.71511, 54.398701500000072],
              [12.720666, 54.371067499000056],
              [12.808984551000037, 54.358181560000048],
              [12.850138873000049, 54.352177022000035],
              [12.850375500000041, 54.352142497000045],
              [12.883621129000062, 54.371018646000039],
              [12.978497550000043, 54.424887427000051],
              [12.997575949000066, 54.435719729000027],
              [12.989185140000075, 54.436477049000075],
              [12.984929129000022, 54.436861179000061],
              [12.983112043000062, 54.43702518200007],
              [12.97203999900006, 54.438024499000051],
              [12.978578965000054, 54.438943461000065],
              [12.990356950000036, 54.44059869500007],
              [13.055387038000049, 54.449737782000064],
              [13.055968500000063, 54.449819499000057],
              [13.029606067000032, 54.442234901000063],
              [13.017892769000071, 54.438864929000033],
              [13.017391719000045, 54.438720774000046],
              [13.026707745000067, 54.422552445000065],
              [13.051188548000027, 54.380065046000027],
              [13.103352521000033, 54.289532417000032],
              [13.109225, 54.279340498000067],
              [13.150401069000054, 54.269090598000048],
              [13.159146202000045, 54.266913685000077],
              [13.173577052000043, 54.263321434000034],
              [13.206090967000023, 54.255227791000038],
              [13.291229, 54.234034501000053],
              [13.319396, 54.193505998000035],
              [13.344701500000042, 54.181111497000074],
              [13.340417627000022, 54.177978765000034],
              [13.336994525000023, 54.17547550200004],
              [13.316316344000029, 54.160353860000043],
              [13.316288500000041, 54.160333498000057],
              [13.40860473500004, 54.166112455000075],
              [13.408885, 54.166129999000077],
              [13.410342700000058, 54.164640355000074],
              [13.412988189000032, 54.161936893000075],
              [13.488926298000024, 54.08433468100003],
              [13.489404499000045, 54.08384600100004],
              [13.476623, 54.118398999000085],
              [13.517493692000073, 54.127854486000047],
              [13.678872202000036, 54.165189611000073],
              [13.706417646000034, 54.171562285000057],
              [13.706645500000038, 54.171614999000042],
              [13.717516383000032, 54.163379039000063],
              [13.748779452000065, 54.139693623000028],
              [13.751450628000043, 54.137669896000034],
              [13.785319704000075, 54.112010127000076],
              [13.789632961000054, 54.108742332000077],
              [13.79361437600005, 54.105725947000053],
              [13.803770500000041, 54.098031500000047],
              [13.795282369000063, 54.077744968000047],
              [13.794949889000065, 54.076950345000064],
              [13.784359982000069, 54.051640593000066],
              [13.782645499000068, 54.047543],
              [13.742715500000031, 54.031386499000064],
              [13.750135719000072, 54.026585307000062],
              [13.759313989000077, 54.02064658200004],
              [13.798721346000036, 53.995148373000063],
              [13.85404509500006, 53.959351593000065],
              [13.866647498000077, 53.951197311000044],
              [13.914190500000075, 53.920435],
              [13.893205745000046, 53.910231341000042],
              [13.85498814400006, 53.891648357000065],
              [13.826154, 53.877627999000026],
              [13.80763550000006, 53.855680500000069],
              [13.819152500000031, 53.841944997000041],
              [13.873391895000054, 53.822404658000039],
              [14.059659047000025, 53.75529986500004],
              [14.060216106000041, 53.75509917800008],
              [14.102668449000078, 53.739805254000032],
              [14.102733, 53.739781999000058],
              [14.213501618000066, 53.750843141000082],
              [14.256777647000035, 53.755164603000082],
              [14.257057, 53.755192500000078],
              [14.258497916000067, 53.752969415000052],
              [14.26865, 53.737306501000035],
              [14.264888726000038, 53.734919987000069],
              [14.264520246000075, 53.734686188000069],
              [14.227083125000037, 53.710932473000071],
              [14.214806500000067, 53.703143],
              [14.267382812000051, 53.697822606000045],
              [14.267542002000027, 53.69780649900008],
              [14.316945, 53.618063],
              [14.305796, 53.543918001000065],
              [14.371761, 53.454964],
              [14.412157, 53.329635998000079],
              [14.316569499000025, 53.312335499000028],
              [14.266023500000074, 53.259746499000073],
              [14.098893500000031, 53.261951499000077],
              [14.222437, 53.357690997000077],
              [14.241636500000027, 53.422151998000061],
              [13.943100500000071, 53.429916500000047],
              [13.798463499000036, 53.545689499000048],
              [13.778238499000055, 53.513434999000026],
              [13.827101500000026, 53.498713501000054],
              [13.710065500000042, 53.479062500000055],
              [13.551025, 53.399436500000036],
              [13.523531500000047, 53.320084501000053],
              [13.483196, 53.291742],
              [13.407685, 53.25992350100006],
              [13.297801500000048, 53.280892501000039],
              [13.241403500000047, 53.232401],
              [13.152506, 53.246248],
              [12.984683, 53.164985500000057],
              [12.941998500000068, 53.174315501000088],
              [12.97943250000003, 53.190551499000037],
              [12.964245, 53.200391498000045],
              [12.763398500000051, 53.189161999000078],
              [12.654933, 53.255250999000054],
              [12.445312, 53.249928501000056],
              [12.331751500000053, 53.318011],
              [12.325101500000073, 53.321301498000082],
              [12.076698500000077, 53.369312500000035],
              [12.045285, 53.370987501000059],
              [12.057339500000069, 53.349342],
              [12.01853, 53.299594498000033],
              [11.828893500000049, 53.228654999000078],
              [11.729017500000054, 53.217053498000041],
              [11.70039, 53.244149997000079],
              [11.553182, 53.208890497000034],
              [11.569365, 53.166094999000052],
              [11.534572, 53.12531849700008],
              [11.265732, 53.121978],
              [11.171861500000034, 53.15664399800005],
              [11.194784500000026, 53.180229499000063],
              [11.090005500000075, 53.214280500000029],
              [10.988000500000055, 53.333426499000041],
              [10.91836550000005, 53.347964499000057],
              [10.834041, 53.305774500000041],
              [10.703123, 53.369419500000056],
              [10.595047, 53.363927501000035],
              [10.634904500000061, 53.456033],
              [10.756012, 53.48339350100008],
              [10.850145, 53.565270497000029],
              [10.911107500000071, 53.572574501000076],
              [10.951918500000033, 53.647622499000079],
              [10.916660500000035, 53.699166498000068],
              [10.771398, 53.749504998000077],
              [10.762964500000066, 53.811153],
              [10.747876999000027, 53.833347997000033],
              [10.770835501000022, 53.873462497000048],
              [10.968375500000036, 53.908861999000067],
              [10.895097500000077, 53.924547999000026],
              [10.903661501000045, 53.956822001000035],
              [11.181358472000056, 54.014741103000063],
              [11.181602500000054, 54.014792],
              [11.192361810000023, 54.004538104000062],
              [11.209959956000034, 53.987766623000034],
              [11.242161188000068, 53.957078030000048],
              [11.268202500000029, 53.932259997000074],
              [11.428322140000034, 53.912709606000078],
              [11.444202020000034, 53.910770694000064],
              [11.444478, 53.910736997000072],
              [11.476517053000066, 53.961061674000064],
              [11.482856033000076, 53.971018495000067],
              [11.497865345000037, 53.994594059000065],
              [11.507899499000075, 54.010354998000082],
              [11.561778500000059, 54.028088498000045],
              [11.585982933000025, 54.051114458000036],
              [11.603987543000073, 54.068242454000028],
              [11.626486, 54.089645499000035],
              [11.626111206000076, 54.089571905000071],
              [11.57362560100006, 54.079265896000038],
              [11.535243051000066, 54.071729145000063],
              [11.52476150000007, 54.069671001000074],
              [11.524935162000077, 54.069763505000026],
              [11.533508620000077, 54.07433029200007],
              [11.579688131000069, 54.098928535000027],
              [11.68004650000006, 54.152386001000082],
              [11.699691081000026, 54.153779218000068],
              [11.998484500000075, 54.174969999000041],
              [12.027755432000049, 54.178322177000048],
              [12.086471796000069, 54.185046518000036],
              [12.086768500000062, 54.185080497000058],
              [12.087690898000062, 54.142111837000073],
              [12.088310500000034, 54.113248499000065],
              [12.105339016000073, 54.108119511000041],
              [12.142728098000077, 54.096857922000027],
              [12.148454774000072, 54.095133048000037],
              [12.151354999000034, 54.094259500000078],
              [12.143606404000025, 54.096860079000066],
              [12.102071500000022, 54.1108],
              [12.097017, 54.151898],
              [12.099294923000059, 54.153929557000026],
              [12.125295994000055, 54.177118516000064],
              [12.201068, 54.244695499000045],
              [12.28553050000005, 54.274951999000052],
              [12.405862111000033, 54.376842509000028],
              [12.406055120000076, 54.377005939000071],
              [12.475868371000047, 54.436120147000054],
              [12.518858520000038, 54.472521955000047],
              [12.520711347000031, 54.474090831000069],
              [12.520845789000077, 54.474204670000063],
              [12.520881067000062, 54.474234542000033],
              [12.527650811000058, 54.473463149000054],
              [12.817046817000062, 54.440487317000077],
            ],
          ],
          [
            [
              [13.391083500000036, 54.651317],
              [13.383719, 54.611990500000047],
              [13.423516, 54.578565999000034],
              [13.498065780000047, 54.573966522000035],
              [13.67934, 54.562782498000047],
              [13.667497500000025, 54.51992199700004],
              [13.569855500000074, 54.467150500000059],
              [13.60317850000007, 54.410273999000026],
              [13.628295139000045, 54.399169065000081],
              [13.72786, 54.355148],
              [13.726035890000048, 54.317452714000069],
              [13.72390450000006, 54.273407499000029],
              [13.700812493000058, 54.280512437000027],
              [13.697713041000043, 54.281466075000026],
              [13.685990071000049, 54.285072993000028],
              [13.66685266600007, 54.290961181000057],
              [13.645403999000052, 54.297560498000053],
              [13.676622992000034, 54.308703629000036],
              [13.682448346000058, 54.31078289800007],
              [13.700380500000051, 54.317183499000066],
              [13.695471053000063, 54.326055803000031],
              [13.684248155000034, 54.34633771600005],
              [13.682453500000065, 54.349581],
              [13.650362394000069, 54.348597588000075],
              [13.598378890000049, 54.347004587000079],
              [13.566324803000043, 54.34602231000008],
              [13.507070500000056, 54.344206499000052],
              [13.477992119000021, 54.329855270000053],
              [13.466638197000066, 54.324251700000048],
              [13.445927945000051, 54.314030444000082],
              [13.416489246000026, 54.299501385000042],
              [13.352598500000056, 54.267968999000061],
              [13.363175966000028, 54.263771523000059],
              [13.371403358000066, 54.260506632000045],
              [13.383605346000024, 54.255664495000076],
              [13.425133, 54.239184999000031],
              [13.394217500000025, 54.222502500000076],
              [13.342263373000037, 54.23497992800003],
              [13.318828650000057, 54.240608067000039],
              [13.168518646000052, 54.276706877000038],
              [13.165092358000038, 54.277529743000059],
              [13.146746251000025, 54.281935788000055],
              [13.138667500000054, 54.283876],
              [13.127270384000042, 54.306608231000048],
              [13.122812107000074, 54.315500532000044],
              [13.114586, 54.33190799700003],
              [13.12019223400006, 54.348936793000064],
              [13.122169026000051, 54.354941250000081],
              [13.127354500000024, 54.370692],
              [13.161830354000076, 54.37383412500003],
              [13.261483500000054, 54.382916500000078],
              [13.239270953000073, 54.403695502000062],
              [13.237791538000069, 54.405079439000076],
              [13.211039999000038, 54.430104499000038],
              [13.149309500000072, 54.423915501000067],
              [13.249893482000061, 54.470750323000061],
              [13.270131, 54.480173499000045],
              [13.229465839000056, 54.501738465000074],
              [13.215854339000032, 54.508956721000061],
              [13.144181, 54.546965499000066],
              [13.243102746000034, 54.560483162000082],
              [13.282402500000046, 54.645704500000079],
              [13.230539536000038, 54.610974746000068],
              [13.221200464000049, 54.604720887000042],
              [13.18159991400006, 54.578202593000071],
              [13.160170500000049, 54.563852501000042],
              [13.171963834000053, 54.575200764000044],
              [13.222226039000077, 54.623566112000049],
              [13.26698250000004, 54.666633499000056],
              [13.438944, 54.677533],
              [13.391083500000036, 54.651317],
            ],
            [
              [13.353637943000024, 54.608418223000058],
              [13.243615336000062, 54.560553218000052],
              [13.254901704000076, 54.562096082000039],
              [13.265849240000023, 54.563592627000048],
              [13.279133337000076, 54.565408584000068],
              [13.344634601000052, 54.574362708000081],
              [13.370426500000065, 54.577888500000029],
              [13.371063321000065, 54.558745338000058],
              [13.371526966000033, 54.544807911000078],
              [13.372302, 54.521509999000052],
              [13.475655500000073, 54.426834],
              [13.557416500000045, 54.440045],
              [13.548616500000037, 54.473742499000082],
              [13.508891374000029, 54.479692638000074],
              [13.502463, 54.480655498000033],
              [13.487517, 54.552170499000056],
              [13.428007367000077, 54.565505030000054],
              [13.396437500000047, 54.572579],
              [13.383698095000057, 54.591494766000046],
              [13.368071500000042, 54.614697499000044],
              [13.353637943000024, 54.608418223000058],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DE9', STAT_LEVL_: 1, Shape_Leng: 20.67843, Shape_Area: 6.33533 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [9.029612384000075, 53.825575252000078],
              [9.029917500000067, 53.825564997000072],
              [9.022127178000062, 53.835963034000031],
              [8.994673500000035, 53.872606497000049],
              [9.02242, 53.879517500000077],
              [9.199750999000059, 53.880104498000037],
              [9.289728, 53.863350500000081],
              [9.48589, 53.707664498000042],
              [9.550639999000056, 53.616034498000033],
              [9.73010399900005, 53.557580997000059],
              [9.768861, 53.505278998000051],
              [9.784525, 53.500011501000074],
              [9.862387500000068, 53.429876499000045],
              [9.901583500000072, 53.457322500000032],
              [9.916487500000073, 53.414067999000054],
              [10.051810500000045, 53.463918999000043],
              [10.171563500000047, 53.397299500000031],
              [10.308312, 53.43322149800008],
              [10.469184, 53.385844],
              [10.498919500000056, 53.374459999000067],
              [10.595047, 53.363927501000035],
              [10.703123, 53.369419500000056],
              [10.834041, 53.305774500000041],
              [10.91836550000005, 53.347964499000057],
              [10.988000500000055, 53.333426499000041],
              [11.090005500000075, 53.214280500000029],
              [11.194784500000026, 53.180229499000063],
              [11.171861500000034, 53.15664399800005],
              [11.265732, 53.121978],
              [11.338867, 53.055098999000052],
              [11.452104, 53.078019998000059],
              [11.597784500000046, 53.035926499000027],
              [11.512490500000069, 53.007445],
              [11.492342500000063, 52.959663001000081],
              [11.505027, 52.941032499000073],
              [11.29541050000006, 52.874906999000075],
              [11.039049500000033, 52.913399500000082],
              [10.939633500000072, 52.853335500000071],
              [10.841556, 52.852205],
              [10.766691500000036, 52.842192497000042],
              [10.759314500000073, 52.795830500000079],
              [10.796697, 52.714326499000038],
              [10.921412, 52.610437001000037],
              [10.976968, 52.624141498000029],
              [10.937393, 52.567899999000076],
              [11.008781500000055, 52.496747500000026],
              [10.934542500000077, 52.47179499900006],
              [10.933745500000043, 52.460952001000067],
              [11.0363, 52.38520950000003],
              [11.057474500000069, 52.350332997000066],
              [11.036015, 52.310396],
              [11.086244, 52.228633999000067],
              [11.01364650000005, 52.199556],
              [11.034852500000056, 52.171993997000072],
              [10.964414500000032, 52.056642998000029],
              [10.801428500000043, 52.048000500000057],
              [10.561227, 52.004065999000034],
              [10.619617, 51.966935997000064],
              [10.648214500000051, 51.908245001000068],
              [10.576971, 51.842987500000049],
              [10.58152, 51.780005498000037],
              [10.627215, 51.757925500000063],
              [10.701372, 51.642187500000034],
              [10.677283, 51.63837600100004],
              [10.557603, 51.556607499000052],
              [10.488551, 51.574778498000057],
              [10.369346, 51.586968998000032],
              [10.365365, 51.555891001000077],
              [10.150399, 51.429424499000049],
              [10.046672500000057, 51.436762997000073],
              [9.928339, 51.375299],
              [9.913393500000041, 51.410630999000034],
              [9.803510499000026, 51.410400499000048],
              [9.702144, 51.364571999000077],
              [9.775202, 51.325195999000073],
              [9.710295479000024, 51.30153775000008],
              [9.568025, 51.340001499000039],
              [9.557291, 51.351378499000077],
              [9.579345500000045, 51.401769],
              [9.642956001000073, 51.416321500000038],
              [9.585751, 51.517317500000047],
              [9.647755500000073, 51.552510999000049],
              [9.625825001000067, 51.580205],
              [9.672377500000039, 51.568403999000054],
              [9.685331, 51.582016499000076],
              [9.625999500000034, 51.636913998000068],
              [9.52022, 51.62612450000006],
              [9.498334500000055, 51.650574997000035],
              [9.440457, 51.65039399900008],
              [9.417317500000024, 51.64726949900006],
              [9.374706, 51.64984049800006],
              [9.375365, 51.743614],
              [9.436367500000074, 51.797015499000054],
              [9.459648500000071, 51.862797498000077],
              [9.323343500000021, 51.855060997000066],
              [9.34515250000004, 51.896340001000056],
              [9.308893, 51.922719501000074],
              [9.272069, 51.932304],
              [9.274375500000076, 51.973603499000035],
              [9.18955150000005, 51.964566999000056],
              [9.155604499000049, 52.09783],
              [9.132923, 52.097734],
              [9.159335, 52.119839997000042],
              [9.143607, 52.13507300100008],
              [8.99376, 52.19167199900005],
              [8.985787500000072, 52.19457649900005],
              [9.045349, 52.183773501000076],
              [9.075462, 52.234116999000037],
              [8.963536500000032, 52.279027],
              [9.125252500000045, 52.411993498000072],
              [9.110523, 52.435427],
              [9.131973500000072, 52.483982499000035],
              [9.052655999000024, 52.500297999000054],
              [8.937577, 52.402100499000028],
              [8.71523650000006, 52.392569],
              [8.703008999000076, 52.500437998000052],
              [8.671481500000027, 52.517394497000055],
              [8.508848500000056, 52.514693497000053],
              [8.429822500000057, 52.447669499000028],
              [8.297213500000055, 52.456497998000032],
              [8.313627, 52.406026499000063],
              [8.444324500000052, 52.361153498000078],
              [8.466186500000049, 52.267614],
              [8.442230500000051, 52.213789501000065],
              [8.517049, 52.185123999000041],
              [8.410586500000022, 52.115114997000035],
              [8.285225, 52.12549849800007],
              [8.096448, 52.057145001000038],
              [8.030835500000023, 52.06790649900006],
              [7.981101499000033, 52.035510001000034],
              [7.885159, 52.083302500000059],
              [8.007796, 52.115332998000042],
              [8.016978, 52.172050500000068],
              [7.902993500000036, 52.198869500000058],
              [7.925623500000029, 52.262814497000079],
              [7.956465, 52.27249050000006],
              [7.931592, 52.30146849700003],
              [7.990608, 52.309539501000074],
              [7.964625500000067, 52.324858501000051],
              [7.891770500000065, 52.380512998000029],
              [7.71374449900003, 52.401047998000081],
              [7.684739500000035, 52.455855497000073],
              [7.608038500000021, 52.474015999000073],
              [7.570965500000057, 52.432330500000035],
              [7.602719500000036, 52.42103049900004],
              [7.563964500000055, 52.370651999000074],
              [7.317481, 52.280271999000036],
              [7.099148999000023, 52.24305849700005],
              [7.065685, 52.241372999000077],
              [7.026305, 52.287443998000072],
              [7.072438, 52.373116501000034],
              [6.987941500000034, 52.46954099900006],
              [6.947163500000045, 52.436614997000049],
              [6.697865500000034, 52.486285998000028],
              [6.726181, 52.563211999000032],
              [6.709732500000031, 52.627823499000044],
              [6.897121500000026, 52.651332499000034],
              [7.006229500000074, 52.638762999000051],
              [7.055577, 52.643369999000072],
              [7.072045, 52.810874500000068],
              [7.092692, 52.838200999000037],
              [7.209295, 53.000244498000029],
              [7.202794500000039, 53.113281499000038],
              [7.178938, 53.138570500000078],
              [7.217603500000052, 53.198971],
              [7.208935, 53.243064498000081],
              [7.192496, 53.317627501000061],
              [7.249316082000063, 53.323878151000031],
              [7.2643, 53.325526498000045],
              [7.221392371000037, 53.331093157000055],
              [6.999446500000033, 53.359887501000067],
              [7.002442926000072, 53.374769875000027],
              [7.010120357000062, 53.412901434000048],
              [7.017071629000043, 53.447426374000031],
              [7.024107500000071, 53.482371497000031],
              [7.092530212000042, 53.514133409000067],
              [7.099257227000066, 53.517256098000075],
              [7.116094438000061, 53.525071953000065],
              [7.138518, 53.535481],
              [7.090335500000038, 53.579652],
              [7.130638951000037, 53.602814933000047],
              [7.144672064000076, 53.610879951000072],
              [7.243681499000047, 53.667781997000077],
              [7.479258835000053, 53.683265999000071],
              [7.479449, 53.68327849800005],
              [7.550018701000055, 53.675055479000036],
              [7.550173, 53.67503750000003],
              [7.564662041000076, 53.676857511000037],
              [7.80989550000004, 53.707661999000038],
              [8.016865120000034, 53.710492446000046],
              [8.017161500000043, 53.710496499000044],
              [8.023218384000074, 53.687189531000058],
              [8.029593500000033, 53.662658001000068],
              [8.069781265000074, 53.646667573000059],
              [8.091291, 53.638109001000032],
              [8.115573, 53.614553001000047],
              [8.148864, 53.601832501000047],
              [8.152291773000059, 53.584233092000034],
              [8.157796024000049, 53.555972298000029],
              [8.161585092000053, 53.536517866000061],
              [8.162998177000077, 53.529262580000079],
              [8.164292999000054, 53.522614499000042],
              [8.061327, 53.50595700100007],
              [8.076937500000042, 53.459283497000065],
              [8.109063462000051, 53.445711333000077],
              [8.195555, 53.409171499000081],
              [8.259195280000029, 53.401368762000068],
              [8.259462500000041, 53.401335999000089],
              [8.296261996000055, 53.441240895000078],
              [8.296802868000043, 53.44182741000003],
              [8.315217500000074, 53.46179599900006],
              [8.303364, 53.525487001000045],
              [8.303070028000036, 53.525468407000062],
              [8.23084852900007, 53.520900349000044],
              [8.22693, 53.520652499000057],
              [8.22693569300003, 53.520671672000049],
              [8.236534927000037, 53.552998104000039],
              [8.246581500000048, 53.586831],
              [8.329344109000033, 53.613961474000064],
              [8.329544, 53.614027],
              [8.371357593000027, 53.585937715000057],
              [8.394221999000024, 53.570578001000058],
              [8.554491577000022, 53.525246098000082],
              [8.554898500000037, 53.525131001000034],
              [8.492652, 53.472420001000046],
              [8.652134001000036, 53.516016999000044],
              [8.650632500000029, 53.602565001000073],
              [8.592283501000054, 53.592932500000074],
              [8.520410500000025, 53.606205001000035],
              [8.483350501000075, 53.694783999000038],
              [8.541255172000035, 53.804454600000042],
              [8.550902500000063, 53.822726498000065],
              [8.574340238000048, 53.844472629000052],
              [8.60431064900007, 53.872279942000034],
              [8.614913999000066, 53.882118],
              [8.682960728000069, 53.885833433000073],
              [8.683227500000044, 53.885847999000077],
              [8.699687201000074, 53.878028520000043],
              [8.70985659400003, 53.873197366000056],
              [8.793387499000062, 53.833514499000046],
              [8.872626812000021, 53.830851357000029],
              [8.912236881000069, 53.829520108000054],
              [9.026553954000065, 53.82567804200005],
              [9.029612384000075, 53.825575252000078],
            ],
            [
              [8.778297500000065, 53.039666500000074],
              [8.915827, 53.011021497000058],
              [8.979256, 53.045849500000031],
              [8.947018, 53.116101],
              [8.984185500000024, 53.126070999000035],
              [8.530234500000063, 53.216089499000077],
              [8.485330500000032, 53.22712],
              [8.620786, 53.166614499000048],
              [8.654898500000058, 53.108864500000038],
              [8.669831, 53.102974499000027],
              [8.711424, 53.044629997000072],
              [8.778297500000065, 53.039666500000074],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DEA', STAT_LEVL_: 1, Shape_Leng: 14.970553, Shape_Area: 4.420199 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.703008999000076, 52.500437998000052],
              [8.71523650000006, 52.392569],
              [8.937577, 52.402100499000028],
              [9.052655999000024, 52.500297999000054],
              [9.131973500000072, 52.483982499000035],
              [9.110523, 52.435427],
              [9.125252500000045, 52.411993498000072],
              [8.963536500000032, 52.279027],
              [9.075462, 52.234116999000037],
              [9.045349, 52.183773501000076],
              [8.985787500000072, 52.19457649900005],
              [8.99376, 52.19167199900005],
              [9.143607, 52.13507300100008],
              [9.159335, 52.119839997000042],
              [9.132923, 52.097734],
              [9.155604499000049, 52.09783],
              [9.18955150000005, 51.964566999000056],
              [9.274375500000076, 51.973603499000035],
              [9.272069, 51.932304],
              [9.308893, 51.922719501000074],
              [9.34515250000004, 51.896340001000056],
              [9.323343500000021, 51.855060997000066],
              [9.459648500000071, 51.862797498000077],
              [9.436367500000074, 51.797015499000054],
              [9.375365, 51.743614],
              [9.374706, 51.64984049800006],
              [9.417317500000024, 51.64726949900006],
              [9.440457, 51.65039399900008],
              [9.337098500000025, 51.614701500000081],
              [9.373522500000036, 51.591013499000042],
              [9.155410500000073, 51.442674999000076],
              [9.093033500000047, 51.445076999000037],
              [9.077185500000041, 51.464871499000026],
              [9.092329, 51.494388001000061],
              [9.023958, 51.511333997000065],
              [8.970653500000026, 51.506773500000065],
              [8.890376500000059, 51.481928500000038],
              [8.948108500000046, 51.42821899900008],
              [8.939502, 51.389498999000075],
              [8.68347650000004, 51.362177999000039],
              [8.556348, 51.277495],
              [8.59729, 51.247179500000072],
              [8.721997500000043, 51.273743997000054],
              [8.758214500000065, 51.177181499000028],
              [8.656632500000057, 51.094902999000055],
              [8.549084999000058, 51.101868001000071],
              [8.501733500000057, 51.079465499000037],
              [8.538782, 51.019448500000067],
              [8.477892, 50.969047497000076],
              [8.355495999000029, 50.862001],
              [8.291897500000061, 50.87715600100006],
              [8.125512, 50.788355999000032],
              [8.166829, 50.73571049800006],
              [8.125776500000029, 50.685813998000071],
              [8.03969, 50.697375499000032],
              [8.040326, 50.72565699900008],
              [7.968862, 50.773921],
              [7.963944, 50.84603499900004],
              [7.829263500000025, 50.882386499000063],
              [7.851496, 50.925832500000077],
              [7.828498500000023, 50.928889499000036],
              [7.785898, 50.939913999000055],
              [7.74831, 50.867217001000029],
              [7.66100350000005, 50.820364501000029],
              [7.675945, 50.77932749900004],
              [7.440771999000049, 50.711437998000065],
              [7.374011, 50.718218500000035],
              [7.358976500000041, 50.649493499000073],
              [7.21239950000006, 50.623404999000066],
              [7.210872, 50.64954349900006],
              [7.195200500000055, 50.642722999000057],
              [7.147851499000069, 50.600293499000031],
              [7.056131500000049, 50.606240999000079],
              [6.927901, 50.55861850000008],
              [6.901481500000045, 50.469092],
              [6.759128500000031, 50.47459],
              [6.745523500000047, 50.435929500000043],
              [6.800711500000034, 50.361781499000081],
              [6.69924450000002, 50.336097498000072],
              [6.60308550000002, 50.388221997000073],
              [6.420878, 50.368903001000035],
              [6.385006, 50.384186500000055],
              [6.425295, 50.323011998000027],
              [6.405028500000071, 50.323308499000063],
              [6.342218500000058, 50.380005501000028],
              [6.374430500000074, 50.452269501000046],
              [6.315556, 50.497042499000031],
              [6.226167, 50.494633498000042],
              [6.20628750000003, 50.521302001000038],
              [6.192200499000023, 50.521055499000063],
              [6.189312500000029, 50.566094001000067],
              [6.268615500000067, 50.625981],
              [6.183289, 50.632286501000067],
              [6.115294, 50.722114],
              [6.036454500000048, 50.720063500000045],
              [6.020998999000028, 50.754295500000069],
              [6.00348450000007, 50.801313499000059],
              [6.017985, 50.843796498000081],
              [6.086947500000065, 50.913134999000079],
              [6.018124, 50.934592999000081],
              [6.006087, 50.957568],
              [6.026435, 50.983273998000072],
              [5.897198, 50.974845499000025],
              [5.877084999000033, 51.032101],
              [5.866250500000035, 51.051204001000031],
              [5.913170500000035, 51.066730999000079],
              [5.953838500000074, 51.036489997000047],
              [5.995551500000033, 51.082717999000067],
              [6.175415500000042, 51.158479999000065],
              [6.138811500000031, 51.173333],
              [6.174812, 51.184513500000037],
              [6.082150999000021, 51.171799],
              [6.072657, 51.242587498000034],
              [6.224405, 51.364978999000073],
              [6.20522, 51.399518499000067],
              [6.213423, 51.507841499000051],
              [6.091235500000039, 51.605909497000027],
              [6.118209500000034, 51.656102999000041],
              [6.031969500000059, 51.676179497000078],
              [6.02631, 51.709275001000044],
              [6.044862, 51.717176501000047],
              [5.953192, 51.747845998000059],
              [5.991237500000068, 51.769544999000068],
              [5.988173, 51.830574499000079],
              [6.063382500000046, 51.865443999000036],
              [6.166407500000048, 51.840834498000056],
              [6.103341500000056, 51.89261699900004],
              [6.167766, 51.90080449900006],
              [6.407779500000061, 51.828092001000073],
              [6.390816, 51.873934001000066],
              [6.472575500000062, 51.853808499000081],
              [6.684230500000069, 51.917617501000052],
              [6.72106, 51.896488999000042],
              [6.828513, 51.964066500000058],
              [6.826574, 51.993532001000062],
              [6.714659, 52.039893497000037],
              [6.760465, 52.118569500000035],
              [6.855364, 52.120433499000058],
              [6.989967, 52.226930499000048],
              [7.065685, 52.241372999000077],
              [7.099148999000023, 52.24305849700005],
              [7.317481, 52.280271999000036],
              [7.563964500000055, 52.370651999000074],
              [7.602719500000036, 52.42103049900004],
              [7.570965500000057, 52.432330500000035],
              [7.608038500000021, 52.474015999000073],
              [7.684739500000035, 52.455855497000073],
              [7.71374449900003, 52.401047998000081],
              [7.891770500000065, 52.380512998000029],
              [7.964625500000067, 52.324858501000051],
              [7.990608, 52.309539501000074],
              [7.931592, 52.30146849700003],
              [7.956465, 52.27249050000006],
              [7.925623500000029, 52.262814497000079],
              [7.902993500000036, 52.198869500000058],
              [8.016978, 52.172050500000068],
              [8.007796, 52.115332998000042],
              [7.885159, 52.083302500000059],
              [7.981101499000033, 52.035510001000034],
              [8.030835500000023, 52.06790649900006],
              [8.096448, 52.057145001000038],
              [8.285225, 52.12549849800007],
              [8.410586500000022, 52.115114997000035],
              [8.517049, 52.185123999000041],
              [8.442230500000051, 52.213789501000065],
              [8.466186500000049, 52.267614],
              [8.444324500000052, 52.361153498000078],
              [8.313627, 52.406026499000063],
              [8.297213500000055, 52.456497998000032],
              [8.429822500000057, 52.447669499000028],
              [8.508848500000056, 52.514693497000053],
              [8.671481500000027, 52.517394497000055],
              [8.703008999000076, 52.500437998000052],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DEB', STAT_LEVL_: 1, Shape_Leng: 9.661125, Shape_Area: 2.488561 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [8.040326, 50.72565699900008],
              [8.03969, 50.697375499000032],
              [8.125776500000029, 50.685813998000071],
              [8.110092501000054, 50.657250999000041],
              [8.151592, 50.59937099900003],
              [8.11773850000003, 50.53450900100006],
              [8.041611500000045, 50.556993498000054],
              [7.992857501000061, 50.52211150100004],
              [7.97155950000007, 50.406220499000035],
              [8.059757, 50.375311501000056],
              [8.121913500000062, 50.277224999000055],
              [8.035892, 50.271191999000052],
              [8.021650500000021, 50.258344998000041],
              [8.058909500000027, 50.234405498000058],
              [8.035050500000068, 50.215592998000034],
              [7.992549, 50.232315501000073],
              [7.907826500000056, 50.200756501000058],
              [7.884014, 50.169568499000036],
              [7.932942500000023, 50.14388450000007],
              [7.925326, 50.108845497000068],
              [7.854016, 50.128328999000082],
              [7.83923, 50.087480499000037],
              [7.773997, 50.066539999000042],
              [7.886587500000076, 49.971904001000041],
              [8.175135500000067, 50.034255497000061],
              [8.190038500000071, 50.035295997000048],
              [8.244557500000042, 50.027628500000048],
              [8.288245, 49.995134],
              [8.343030500000054, 49.940506],
              [8.346520500000054, 49.874819500000058],
              [8.382785999000021, 49.856715999000073],
              [8.400055, 49.803675],
              [8.426199, 49.767193501000065],
              [8.479186, 49.764212],
              [8.448365, 49.73366149900005],
              [8.44637749900005, 49.730799497000078],
              [8.356921, 49.69088399900005],
              [8.414774, 49.595051998000031],
              [8.422439500000053, 49.58338549900003],
              [8.422700500000076, 49.574192499000048],
              [8.423068, 49.541821],
              [8.444322500000055, 49.459187500000041],
              [8.474739704000058, 49.440616029000068],
              [8.497316, 49.411347],
              [8.46538, 49.387252],
              [8.500397500000076, 49.389013497000064],
              [8.470915500000046, 49.340712999000061],
              [8.451985, 49.317683],
              [8.487268, 49.290026499000078],
              [8.466985500000021, 49.282975501000067],
              [8.413072500000055, 49.249816499000076],
              [8.387592500000039, 49.231147999000029],
              [8.404737500000067, 49.219299498000055],
              [8.33997, 49.080149999000071],
              [8.277349, 48.989939001000039],
              [8.261284, 48.980916999000044],
              [8.232632999000032, 48.96657150100004],
              [8.068407500000035, 48.999316498000042],
              [7.91065450000002, 49.045163499000076],
              [7.635651, 49.053950500000042],
              [7.528678, 49.097092001000078],
              [7.493791, 49.167735501000038],
              [7.36875550000002, 49.161457999000049],
              [7.320341, 49.189498999000079],
              [7.289732, 49.238669501000061],
              [7.394494, 49.316351998000073],
              [7.402075500000024, 49.36771850100007],
              [7.395898500000044, 49.372045997000043],
              [7.298941500000069, 49.398519497000052],
              [7.292601500000046, 49.408222497000054],
              [7.252589, 49.431465501000048],
              [7.304916500000047, 49.474195501000054],
              [7.278135500000076, 49.484274998000046],
              [7.276622500000031, 49.548623500000076],
              [7.268704, 49.584083500000077],
              [7.224401, 49.56483399800004],
              [7.05908, 49.630479501000089],
              [7.02798, 49.63943849900005],
              [6.891454, 49.613422498000034],
              [6.606324, 49.527990999000053],
              [6.380052500000033, 49.551104999000074],
              [6.357511, 49.572258499000043],
              [6.422795500000063, 49.622294998000029],
              [6.440607, 49.654859499000054],
              [6.426077500000077, 49.663689001000087],
              [6.507018500000072, 49.712640501000067],
              [6.520996, 49.796985498000026],
              [6.474962500000061, 49.821274999000082],
              [6.42914, 49.810859],
              [6.235510500000032, 49.899188500000037],
              [6.223907499000063, 49.949939498000049],
              [6.177785500000027, 49.954034999000044],
              [6.116835500000036, 50.056710999000074],
              [6.137662500000033, 50.129951499000072],
              [6.185831, 50.184037499000056],
              [6.176784500000054, 50.235862999000062],
              [6.334891500000026, 50.316421999000056],
              [6.405028500000071, 50.323308499000063],
              [6.425295, 50.323011998000027],
              [6.385006, 50.384186500000055],
              [6.420878, 50.368903001000035],
              [6.60308550000002, 50.388221997000073],
              [6.69924450000002, 50.336097498000072],
              [6.800711500000034, 50.361781499000081],
              [6.745523500000047, 50.435929500000043],
              [6.759128500000031, 50.47459],
              [6.901481500000045, 50.469092],
              [6.927901, 50.55861850000008],
              [7.056131500000049, 50.606240999000079],
              [7.147851499000069, 50.600293499000031],
              [7.195200500000055, 50.642722999000057],
              [7.210872, 50.64954349900006],
              [7.21239950000006, 50.623404999000066],
              [7.358976500000041, 50.649493499000073],
              [7.374011, 50.718218500000035],
              [7.440771999000049, 50.711437998000065],
              [7.675945, 50.77932749900004],
              [7.66100350000005, 50.820364501000029],
              [7.74831, 50.867217001000029],
              [7.785898, 50.939913999000055],
              [7.828498500000023, 50.928889499000036],
              [7.851496, 50.925832500000077],
              [7.829263500000025, 50.882386499000063],
              [7.963944, 50.84603499900004],
              [7.968862, 50.773921],
              [8.040326, 50.72565699900008],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DEC', STAT_LEVL_: 1, Shape_Leng: 3.082176, Shape_Area: 0.322132 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [7.05908, 49.630479501000089],
              [7.224401, 49.56483399800004],
              [7.268704, 49.584083500000077],
              [7.276622500000031, 49.548623500000076],
              [7.278135500000076, 49.484274998000046],
              [7.304916500000047, 49.474195501000054],
              [7.252589, 49.431465501000048],
              [7.292601500000046, 49.408222497000054],
              [7.298941500000069, 49.398519497000052],
              [7.395898500000044, 49.372045997000043],
              [7.402075500000024, 49.36771850100007],
              [7.394494, 49.316351998000073],
              [7.289732, 49.238669501000061],
              [7.320341, 49.189498999000079],
              [7.36875550000002, 49.161457999000049],
              [7.293177, 49.114907498000036],
              [7.158748500000058, 49.120634499000062],
              [7.101069, 49.155998],
              [7.057195999000044, 49.111931499000036],
              [7.03497150000004, 49.191414998000027],
              [6.925060500000029, 49.223346499000058],
              [6.837607499000057, 49.212932998000042],
              [6.861058, 49.178549500000038],
              [6.834567, 49.151310999000039],
              [6.738397500000076, 49.163598],
              [6.711208500000055, 49.18834200100008],
              [6.723465500000032, 49.218828999000038],
              [6.696024500000021, 49.215127499000069],
              [6.660423999000045, 49.261184999000079],
              [6.670060500000034, 49.28033049700008],
              [6.565664, 49.347651498000062],
              [6.538646500000027, 49.41220449900004],
              [6.556986, 49.419208499000035],
              [6.429131, 49.476240500000074],
              [6.367107500000031, 49.469507001000068],
              [6.357006, 49.530041997000069],
              [6.380052500000033, 49.551104999000074],
              [6.606324, 49.527990999000053],
              [6.891454, 49.613422498000034],
              [7.02798, 49.63943849900005],
              [7.05908, 49.630479501000089],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DED', STAT_LEVL_: 1, Shape_Leng: 10.322138, Shape_Area: 2.376024 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [12.862227500000074, 51.67378149700005],
              [12.942163500000049, 51.638006],
              [12.983901, 51.66908999900005],
              [13.051025, 51.647677],
              [13.086303999000052, 51.608134999000072],
              [13.154955, 51.600197001000026],
              [13.207957, 51.524353001000065],
              [13.21015, 51.404735999000081],
              [13.412191500000063, 51.439374499000053],
              [13.537766500000032, 51.381046501000078],
              [13.691250500000024, 51.374012999000058],
              [13.835313, 51.376789998000049],
              [13.996318500000029, 51.382607497000038],
              [14.107796500000063, 51.521760500000028],
              [14.163324500000044, 51.541042999000069],
              [14.343553, 51.517111999000065],
              [14.447771, 51.542068997000058],
              [14.569635, 51.581190499000058],
              [14.675624, 51.551469499000063],
              [14.711164, 51.59576499800005],
              [14.729862, 51.581776999000056],
              [14.711243, 51.562658001000052],
              [14.735769, 51.525952],
              [14.948293, 51.471768999000062],
              [14.974151500000062, 51.441869],
              [14.958241, 51.395721998000056],
              [14.974183, 51.363949997000077],
              [15.033353, 51.294091998000056],
              [15.037271, 51.243749999000045],
              [14.91943, 50.975342999000077],
              [14.823362, 50.870550497000067],
              [14.766383, 50.819332501000076],
              [14.6188, 50.857804497000075],
              [14.650508500000059, 50.929418500000054],
              [14.564038, 50.918497498000079],
              [14.599177500000055, 50.987110998000055],
              [14.491221, 51.043530499000042],
              [14.421785999000067, 51.019569499000056],
              [14.317873500000076, 51.054698999000038],
              [14.258529500000066, 50.987989499000037],
              [14.396727999000065, 50.936490501000037],
              [14.388335, 50.900298998000039],
              [13.897461500000077, 50.792145999000077],
              [13.898492500000032, 50.74495349700004],
              [13.855824, 50.727325998000026],
              [13.652174, 50.73035999800004],
              [13.525244500000042, 50.704411],
              [13.544373, 50.677447500000028],
              [13.501846, 50.633642999000074],
              [13.462718, 50.601826998000035],
              [13.3777, 50.627674998000032],
              [13.255104, 50.595300999000074],
              [13.193359500000042, 50.503172499000073],
              [13.059457500000065, 50.501047499000038],
              [12.948144500000071, 50.404311499000073],
              [12.820126500000072, 50.459762499000078],
              [12.707607, 50.407829998000068],
              [12.583778500000051, 50.407078999000078],
              [12.398014999000054, 50.321153499000047],
              [12.331084500000031, 50.242713998000056],
              [12.335119500000076, 50.172007499000074],
              [12.291233, 50.177351999000052],
              [12.274797500000034, 50.196592497000047],
              [12.293878, 50.221269997000043],
              [12.184453, 50.322324499000047],
              [12.100900500000023, 50.318027998000048],
              [12.033299, 50.329707],
              [11.945645, 50.416283001000075],
              [11.919884500000023, 50.424403499000057],
              [11.96484, 50.484857999000042],
              [11.871462500000064, 50.539822500000071],
              [11.944137500000068, 50.591275499000062],
              [12.010397, 50.635461],
              [12.035008, 50.59459399900004],
              [12.017535500000065, 50.567841001000033],
              [12.052068, 50.55096099900004],
              [12.146427500000074, 50.625978998000051],
              [12.233248500000061, 50.627006499000061],
              [12.318916500000057, 50.676532499000082],
              [12.226984500000071, 50.738218499000027],
              [12.250825500000076, 50.818318499000043],
              [12.652865500000075, 50.923678001000042],
              [12.617355, 50.980792998000027],
              [12.52755, 50.998897998000075],
              [12.501522500000021, 51.064076],
              [12.356579999000076, 51.094342998000059],
              [12.284594500000026, 51.091162497000028],
              [12.196192500000052, 51.141907500000059],
              [12.213084, 51.183500499000047],
              [12.170904500000063, 51.275508500000065],
              [12.147653500000047, 51.319967],
              [12.19354450000003, 51.332518501000038],
              [12.148583, 51.463124999000058],
              [12.198939, 51.531320498000071],
              [12.243211, 51.566876001000033],
              [12.580263, 51.626065500000038],
              [12.862227500000074, 51.67378149700005],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DEE', STAT_LEVL_: 1, Shape_Leng: 10.046538, Shape_Area: 2.685081 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [12.126811500000031, 52.890199499000062],
              [12.123714500000062, 52.853795999000056],
              [12.233961500000021, 52.859454501000073],
              [12.249203500000021, 52.791861999000048],
              [12.213132500000029, 52.773607498000054],
              [12.201294500000074, 52.729003999000042],
              [12.239352, 52.671736],
              [12.230846, 52.629754999000056],
              [12.14324, 52.535797499000068],
              [12.171555, 52.506336497000063],
              [12.258216, 52.517885500000034],
              [12.330781500000057, 52.478555498000048],
              [12.31718, 52.454095499000061],
              [12.293404, 52.423492500000066],
              [12.30812750000007, 52.344696498000076],
              [12.24446, 52.248001999000053],
              [12.297277, 52.227545500000083],
              [12.216783, 52.170068499000081],
              [12.276724499000068, 52.104018],
              [12.315823500000022, 52.092372],
              [12.376122500000065, 52.045119498000076],
              [12.389599500000031, 52.04356599700003],
              [12.539394500000071, 51.984889],
              [12.656502500000045, 52.012939501000062],
              [12.769780500000024, 51.979274500000031],
              [12.844153500000061, 51.967734001000061],
              [12.85273, 51.935005498000066],
              [12.960568, 51.934910499000068],
              [12.972665, 51.900880999000037],
              [13.044088500000043, 51.870122497000068],
              [13.150913500000058, 51.859610500000031],
              [13.123426500000052, 51.856899499000065],
              [13.16301150000004, 51.802018001000079],
              [13.161745, 51.690602001000059],
              [13.051025, 51.647677],
              [12.983901, 51.66908999900005],
              [12.942163500000049, 51.638006],
              [12.862227500000074, 51.67378149700005],
              [12.580263, 51.626065500000038],
              [12.243211, 51.566876001000033],
              [12.198939, 51.531320498000071],
              [12.148583, 51.463124999000058],
              [12.19354450000003, 51.332518501000038],
              [12.147653500000047, 51.319967],
              [12.170904500000063, 51.275508500000065],
              [12.213084, 51.183500499000047],
              [12.196192500000052, 51.141907500000059],
              [12.284594500000026, 51.091162497000028],
              [12.250268500000061, 51.037873001000037],
              [12.29299050000003, 51.021581500000082],
              [12.224169, 50.942934998000055],
              [12.197979499000041, 50.956783999000038],
              [12.163369500000044, 50.958822498000082],
              [12.134986500000025, 50.964975499000047],
              [12.021018500000025, 50.969122499000036],
              [11.891187500000058, 51.054630997000061],
              [11.699681, 51.074813998000081],
              [11.696938500000044, 51.087490998000078],
              [11.601667500000076, 51.113867001000074],
              [11.484608500000036, 51.105437499000061],
              [11.451004500000067, 51.146669999000039],
              [11.48526, 51.165593],
              [11.468567500000063, 51.192653999000072],
              [11.391420001000029, 51.20353349800007],
              [11.368967499000064, 51.229565501000081],
              [11.385374500000069, 51.245886999000049],
              [11.473968, 51.295058499000049],
              [11.428868500000021, 51.339809999000074],
              [11.32126, 51.41037250100004],
              [11.152934, 51.401644498000053],
              [10.978113, 51.426884999000038],
              [10.935151500000075, 51.53322200100007],
              [10.882051501000035, 51.580508],
              [10.937540500000068, 51.601983001000072],
              [10.916058999000029, 51.616374001000061],
              [10.785421, 51.641879499000026],
              [10.701372, 51.642187500000034],
              [10.627215, 51.757925500000063],
              [10.58152, 51.780005498000037],
              [10.576971, 51.842987500000049],
              [10.648214500000051, 51.908245001000068],
              [10.619617, 51.966935997000064],
              [10.561227, 52.004065999000034],
              [10.801428500000043, 52.048000500000057],
              [10.964414500000032, 52.056642998000029],
              [11.034852500000056, 52.171993997000072],
              [11.01364650000005, 52.199556],
              [11.086244, 52.228633999000067],
              [11.036015, 52.310396],
              [11.057474500000069, 52.350332997000066],
              [11.0363, 52.38520950000003],
              [10.933745500000043, 52.460952001000067],
              [10.934542500000077, 52.47179499900006],
              [11.008781500000055, 52.496747500000026],
              [10.937393, 52.567899999000076],
              [10.976968, 52.624141498000029],
              [10.921412, 52.610437001000037],
              [10.796697, 52.714326499000038],
              [10.759314500000073, 52.795830500000079],
              [10.766691500000036, 52.842192497000042],
              [10.841556, 52.852205],
              [10.939633500000072, 52.853335500000071],
              [11.039049500000033, 52.913399500000082],
              [11.29541050000006, 52.874906999000075],
              [11.505027, 52.941032499000073],
              [11.492342500000063, 52.959663001000081],
              [11.512490500000069, 53.007445],
              [11.597784500000046, 53.035926499000027],
              [11.639365500000054, 53.038718997000046],
              [11.628581, 53.010597],
              [11.848304, 52.949515498000039],
              [11.823375, 52.918272999000067],
              [11.89485650000006, 52.894104498000047],
              [12.126811500000031, 52.890199499000062],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DEF', STAT_LEVL_: 1, Shape_Leng: 14.11096, Shape_Area: 2.153408 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [11.177786619000074, 54.402281110000047],
              [11.120965500000068, 54.402075499000034],
              [11.095116500000074, 54.445452498000066],
              [11.038601233000067, 54.447660625000026],
              [11.004257, 54.449002500000063],
              [11.066274500000077, 54.535723999000027],
              [11.100909326000021, 54.529060303000058],
              [11.190205394000031, 54.511879854000028],
              [11.22579999900006, 54.505031498000051],
              [11.246050397000033, 54.485416027000042],
              [11.275491, 54.456898499000033],
              [11.287255752000021, 54.439872220000041],
              [11.312892500000032, 54.402770001000079],
              [11.177786619000074, 54.402281110000047],
            ],
          ],
          [
            [
              [8.669849, 54.494526501000053],
              [8.612529, 54.493944501000044],
              [8.588513500000033, 54.529934],
              [8.611362916000076, 54.534772088000068],
              [8.638261023000041, 54.540467437000075],
              [8.708688, 54.555379499000082],
              [8.669849, 54.494526501000053],
            ],
          ],
          [
            [
              [8.401144500000044, 54.618659001000026],
              [8.365439500000036, 54.609131998000073],
              [8.293601, 54.669801497000037],
              [8.360574500000043, 54.710751001000062],
              [8.340786, 54.68672250000003],
              [8.382007508000072, 54.640238906000036],
              [8.383007493000036, 54.639111269000068],
              [8.392533596000021, 54.628369121000048],
              [8.395818182000028, 54.624665244000028],
              [8.401144500000044, 54.618659001000026],
            ],
          ],
          [
            [
              [8.575555500000064, 54.701984999000047],
              [8.472704, 54.682247500000074],
              [8.446409363000043, 54.692976147000088],
              [8.39494, 54.713976498000079],
              [8.541799500000025, 54.755410500000046],
              [8.588693, 54.740983499000038],
              [8.596963, 54.718554500000039],
              [8.575555500000064, 54.701984999000047],
            ],
          ],
          [
            [
              [8.372664500000042, 54.896856498000034],
              [8.480546422000034, 54.877866969000081],
              [8.480711, 54.877838],
              [8.510984454000038, 54.884439198000052],
              [8.635925999000051, 54.91168299800006],
              [9.113097, 54.873601500000063],
              [9.240282, 54.849945001000037],
              [9.250086, 54.809711499000059],
              [9.419865966000032, 54.831919152000069],
              [9.420151500000031, 54.831956501000036],
              [9.422934, 54.823222998000062],
              [9.431530471000031, 54.811757886000066],
              [9.431566, 54.81171050100005],
              [9.459609356000046, 54.816784298000073],
              [9.491949500000034, 54.822635499000057],
              [9.506223992000059, 54.829185820000077],
              [9.523128667000037, 54.836943088000055],
              [9.524271226000053, 54.837467389000039],
              [9.556723737000027, 54.852359294000053],
              [9.567481029000021, 54.857295632000046],
              [9.584446139000022, 54.865080632000058],
              [9.584703, 54.865198501000066],
              [9.589763258000062, 54.850497661000077],
              [9.59334350000006, 54.840096498000037],
              [9.620152158000053, 54.831688756000062],
              [9.646478917000024, 54.823432148000052],
              [9.65473127000007, 54.820844042000033],
              [9.823140670000043, 54.768027421000056],
              [9.873418387000072, 54.752259304000063],
              [9.873658500000033, 54.752184],
              [9.908982733000073, 54.798591058000056],
              [9.909055, 54.798685999000043],
              [9.957707, 54.778172],
              [9.979445653000028, 54.746307108000053],
              [10.006511351000029, 54.706633740000029],
              [10.015784500000052, 54.693040998000072],
              [9.974551352000049, 54.67410059000008],
              [9.974529113000074, 54.674090374000059],
              [9.974781127000028, 54.674098427000047],
              [9.975065, 54.674107499000058],
              [10.03117, 54.63657500100004],
              [10.029834207000022, 54.611205225000056],
              [10.026625500000023, 54.550264500000083],
              [9.995853, 54.519735500000081],
              [9.832854, 54.473559002000059],
              [9.864173602000051, 54.448560435000047],
              [9.864218, 54.448524998000039],
              [9.920312796000076, 54.456380964000061],
              [10.125695303000043, 54.485144382000044],
              [10.125917500000073, 54.485175500000082],
              [10.198897501000033, 54.45499049700004],
              [10.18989834000007, 54.448430667000082],
              [10.168517, 54.432844998000064],
              [10.188773, 54.411083],
              [10.176036673000056, 54.38778467700007],
              [10.153305528000033, 54.346203020000075],
              [10.137013296000021, 54.316399946000047],
              [10.136784, 54.315980499000034],
              [10.174162, 54.345745499000031],
              [10.188492213000075, 54.364083325000088],
              [10.208493607000038, 54.38967834400006],
              [10.215235606000022, 54.398305822000054],
              [10.216256962000045, 54.399612812000044],
              [10.227138500000024, 54.413537500000075],
              [10.250517298000034, 54.416701235000062],
              [10.291652733000035, 54.422267887000039],
              [10.358321587000034, 54.431289848000063],
              [10.358537, 54.431318999000041],
              [10.679288170000063, 54.317320764000044],
              [10.713605667000024, 54.305123976000061],
              [10.713750499000071, 54.305072501000041],
              [10.915313164000056, 54.373062550000043],
              [10.946416932000034, 54.383554308000043],
              [10.946459, 54.383568498000045],
              [11.021278003000079, 54.379410971000027],
              [11.073080054000059, 54.376532445000066],
              [11.125609500000053, 54.373613500000033],
              [11.071833, 54.339935],
              [11.078591292000056, 54.296557385000028],
              [11.083574824000038, 54.264570944000077],
              [11.090270386000043, 54.221595960000059],
              [11.093783, 54.199050501000045],
              [11.054632338000033, 54.18227603400004],
              [10.993173847000037, 54.155943569000044],
              [10.950282783000034, 54.13756649100003],
              [10.839010684000073, 54.089890919000027],
              [10.755363500000044, 54.05405150100006],
              [10.787249, 53.997438498000065],
              [10.814990576000071, 53.994570829000054],
              [10.840886001000058, 53.99189399800008],
              [10.876835001000074, 53.987365],
              [10.884153511000022, 53.979032596000081],
              [10.903615450000075, 53.956874432000063],
              [10.903661501000045, 53.956822001000035],
              [10.895097500000077, 53.924547999000026],
              [10.968375500000036, 53.908861999000067],
              [10.770835501000022, 53.873462497000048],
              [10.747876999000027, 53.833347997000033],
              [10.762964500000066, 53.811153],
              [10.771398, 53.749504998000077],
              [10.916660500000035, 53.699166498000068],
              [10.951918500000033, 53.647622499000079],
              [10.911107500000071, 53.572574501000076],
              [10.850145, 53.565270497000029],
              [10.756012, 53.48339350100008],
              [10.634904500000061, 53.456033],
              [10.595047, 53.363927501000035],
              [10.498919500000056, 53.374459999000067],
              [10.469184, 53.385844],
              [10.308312, 53.43322149800008],
              [10.325959, 53.449639498000067],
              [10.236678500000039, 53.496354498000073],
              [10.151152500000023, 53.545018001000074],
              [10.15208350000006, 53.576829999000047],
              [10.201449500000024, 53.584075999000049],
              [10.221761500000071, 53.633722500000033],
              [10.140273, 53.680126499000039],
              [10.191279, 53.722186499000031],
              [10.072805, 53.709633999000062],
              [10.069265500000029, 53.679450499000041],
              [9.945376, 53.652927998000052],
              [9.824958, 53.584528001000081],
              [9.770294, 53.627462497000074],
              [9.73010399900005, 53.557580997000059],
              [9.550639999000056, 53.616034498000033],
              [9.48589, 53.707664498000042],
              [9.289728, 53.863350500000081],
              [9.199750999000059, 53.880104498000037],
              [9.02242, 53.879517500000077],
              [8.990476114000046, 53.894267865000074],
              [8.909394500000076, 53.931707998000036],
              [8.832592, 54.023929499000076],
              [8.916215774000023, 54.04269998500007],
              [8.985603500000025, 54.058274997000069],
              [8.975359772000047, 54.070875250000029],
              [8.972921581000037, 54.073874336000074],
              [8.925278500000047, 54.132477499000061],
              [8.924981200000047, 54.132477115000029],
              [8.894956405000073, 54.132437141000089],
              [8.838163, 54.132361528000047],
              [8.823457052000037, 54.151776346000077],
              [8.808867500000076, 54.171037500000068],
              [8.822826238000061, 54.208050665000037],
              [8.833541840000066, 54.236464291000068],
              [8.840395879000027, 54.254638545000041],
              [8.844804500000066, 54.266328500000043],
              [8.696450185000062, 54.279510308000063],
              [8.660822, 54.282675999000048],
              [8.602134, 54.340507997000032],
              [8.653711503000068, 54.348944179000057],
              [8.653805937000072, 54.348959625000077],
              [8.691683500000067, 54.355155001000071],
              [8.65908433200002, 54.37424700300005],
              [8.648903500000074, 54.380209501000081],
              [8.672763, 54.400466],
              [8.711257984000042, 54.402382579000061],
              [8.846990540000036, 54.409140398000034],
              [8.877224893000061, 54.410645698000053],
              [8.889372501000025, 54.411250500000051],
              [9.010464, 54.496822999000074],
              [8.993087532000061, 54.514563069000076],
              [8.983176, 54.524682],
              [8.983007752000049, 54.524550112000043],
              [8.925141719000067, 54.479189536000035],
              [8.922777300000064, 54.477336093000076],
              [8.916170195000063, 54.472156853000065],
              [8.899057, 54.45874199900004],
              [8.898802446000047, 54.458775612000068],
              [8.806592500000022, 54.470951497000044],
              [8.806437, 54.498341499000048],
              [8.821824434000064, 54.505430166000053],
              [8.871453, 54.528293001000065],
              [8.876763569000047, 54.545984562000058],
              [8.892347, 54.59789900100003],
              [8.848040428000047, 54.624828501000081],
              [8.846253919000048, 54.625914340000065],
              [8.783583345000068, 54.664005467000045],
              [8.75409, 54.681931499000036],
              [8.719219320000036, 54.725717838000037],
              [8.706489063000049, 54.741702939000049],
              [8.668218185000057, 54.789758832000075],
              [8.605446657000073, 54.868579648000036],
              [8.594786, 54.881965999000045],
              [8.594528101000037, 54.881916357000087],
              [8.414381, 54.847240499000065],
              [8.414292106000062, 54.847280640000065],
              [8.371660079000037, 54.866531597000062],
              [8.350456398000063, 54.876106351000033],
              [8.340561500000035, 54.88057450000008],
              [8.340448735000052, 54.880491111000026],
              [8.312542569000072, 54.859854768000055],
              [8.299860500000023, 54.85047650000007],
              [8.299104994000061, 54.808743789000061],
              [8.299005646000069, 54.803255974000081],
              [8.298541812000053, 54.777634662000082],
              [8.298265885000035, 54.762392967000039],
              [8.297863500000062, 54.740166],
              [8.297569388000056, 54.740349432000073],
              [8.278555500000039, 54.752207999000063],
              [8.297903, 54.910656501000062],
              [8.399123933000055, 55.036373250000054],
              [8.407705736000025, 55.047031879000031],
              [8.416792783000062, 55.058318023000027],
              [8.416845499000033, 55.058383497000079],
              [8.464195500000073, 55.045714998000051],
              [8.436260642000036, 55.026186544000048],
              [8.410173814000075, 55.007949995000047],
              [8.352822, 54.967856997000069],
              [8.362025039000059, 54.934926654000044],
              [8.372664500000042, 54.896856498000034],
            ],
            [
              [9.723188575000052, 54.532414442000061],
              [9.711328, 54.51990150000006],
              [9.635570449000056, 54.515101709000085],
              [9.632615260000023, 54.514914476000058],
              [9.590591459000052, 54.51225196300004],
              [9.573595901000033, 54.511175171000048],
              [9.546011500000077, 54.509427499000026],
              [9.59336303300006, 54.504529988000058],
              [9.674876355000038, 54.496099164000043],
              [9.675124500000038, 54.496073499000033],
              [9.70449956300007, 54.511369804000026],
              [9.716006613000047, 54.517361803000028],
              [9.735100708000061, 54.527304560000069],
              [9.869337621000057, 54.59720496500006],
              [9.926964079000072, 54.62721245400008],
              [9.932257380000067, 54.629968804000043],
              [9.945232, 54.636725002000048],
              [9.947329153000055, 54.661600889000056],
              [9.947019757000021, 54.66145898700006],
              [9.937616598000034, 54.657146307000062],
              [9.935842726000033, 54.656332736000081],
              [9.767434, 54.579093497000031],
              [9.723188575000052, 54.532414442000061],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: { NUTS_ID: 'DEG', STAT_LEVL_: 1, Shape_Leng: 9.960371, Shape_Area: 2.071799 },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [10.882051501000035, 51.580508],
              [10.935151500000075, 51.53322200100007],
              [10.978113, 51.426884999000038],
              [11.152934, 51.401644498000053],
              [11.32126, 51.41037250100004],
              [11.428868500000021, 51.339809999000074],
              [11.473968, 51.295058499000049],
              [11.385374500000069, 51.245886999000049],
              [11.368967499000064, 51.229565501000081],
              [11.391420001000029, 51.20353349800007],
              [11.468567500000063, 51.192653999000072],
              [11.48526, 51.165593],
              [11.451004500000067, 51.146669999000039],
              [11.484608500000036, 51.105437499000061],
              [11.601667500000076, 51.113867001000074],
              [11.696938500000044, 51.087490998000078],
              [11.699681, 51.074813998000081],
              [11.891187500000058, 51.054630997000061],
              [12.021018500000025, 50.969122499000036],
              [12.134986500000025, 50.964975499000047],
              [12.163369500000044, 50.958822498000082],
              [12.197979499000041, 50.956783999000038],
              [12.224169, 50.942934998000055],
              [12.29299050000003, 51.021581500000082],
              [12.250268500000061, 51.037873001000037],
              [12.284594500000026, 51.091162497000028],
              [12.356579999000076, 51.094342998000059],
              [12.501522500000021, 51.064076],
              [12.52755, 50.998897998000075],
              [12.617355, 50.980792998000027],
              [12.652865500000075, 50.923678001000042],
              [12.250825500000076, 50.818318499000043],
              [12.226984500000071, 50.738218499000027],
              [12.318916500000057, 50.676532499000082],
              [12.233248500000061, 50.627006499000061],
              [12.146427500000074, 50.625978998000051],
              [12.052068, 50.55096099900004],
              [12.017535500000065, 50.567841001000033],
              [12.035008, 50.59459399900004],
              [12.010397, 50.635461],
              [11.944137500000068, 50.591275499000062],
              [11.871462500000064, 50.539822500000071],
              [11.96484, 50.484857999000042],
              [11.919884500000023, 50.424403499000057],
              [11.823488500000053, 50.38966699800006],
              [11.795348, 50.41818100100005],
              [11.603290500000071, 50.39876600100007],
              [11.519853, 50.374268499000038],
              [11.481568, 50.431621501000052],
              [11.420630500000073, 50.443366500000081],
              [11.418814500000053, 50.493461499000034],
              [11.440998, 50.500673501000051],
              [11.423893, 50.516064499000038],
              [11.343759500000033, 50.520691999000064],
              [11.265938, 50.479421],
              [11.245132, 50.470802999000057],
              [11.284253, 50.360307500000033],
              [11.259778, 50.274946499000066],
              [11.189943, 50.271185501000048],
              [11.141557, 50.289191998000035],
              [11.134827500000029, 50.314054498000075],
              [11.159197500000062, 50.330392001000064],
              [11.115305, 50.367006997000033],
              [10.985780500000033, 50.34732050000008],
              [10.945718500000055, 50.386466499000051],
              [10.887187500000039, 50.392572499000039],
              [10.715266500000041, 50.363590998000063],
              [10.715396, 50.321176498000057],
              [10.851494500000058, 50.262762499000075],
              [10.729202, 50.230005497000036],
              [10.716645, 50.204819001000033],
              [10.610115, 50.227994999000032],
              [10.591040500000076, 50.271784498000045],
              [10.600550500000054, 50.333687999000063],
              [10.492405500000075, 50.355055999000065],
              [10.450532, 50.401859500000057],
              [10.392067, 50.396240999000042],
              [10.330582, 50.494276498000033],
              [10.12726, 50.559533001000034],
              [10.041338500000052, 50.516469499000038],
              [10.037268, 50.610883999000066],
              [10.077555500000074, 50.637623997000048],
              [10.001995, 50.676602499000069],
              [9.949108, 50.664142499000036],
              [9.960722, 50.62948349900006],
              [9.878551, 50.634780999000043],
              [9.881643500000052, 50.672171499000058],
              [9.941371500000059, 50.74183799900004],
              [9.926176500000054, 50.767389999000045],
              [9.952253, 50.821374499000065],
              [10.023054500000057, 50.831631500000071],
              [10.055980999000042, 50.879771498000082],
              [9.949583500000074, 50.927478500000063],
              [10.063865, 50.945432997000069],
              [10.021558500000026, 50.992949500000066],
              [10.182348500000046, 50.998487500000074],
              [10.202181, 51.012009001000081],
              [10.215185500000075, 51.026419499000042],
              [10.143678, 51.057786498000041],
              [10.118321500000036, 51.139573],
              [10.184905, 51.141119998000079],
              [10.210013, 51.144082999000034],
              [10.235999, 51.184767499000031],
              [10.206942, 51.190648997000039],
              [10.067095, 51.22779299900003],
              [9.948770500000023, 51.316989497000066],
              [9.928339, 51.375299],
              [10.046672500000057, 51.436762997000073],
              [10.150399, 51.429424499000049],
              [10.365365, 51.555891001000077],
              [10.369346, 51.586968998000032],
              [10.488551, 51.574778498000057],
              [10.557603, 51.556607499000052],
              [10.677283, 51.63837600100004],
              [10.701372, 51.642187500000034],
              [10.785421, 51.641879499000026],
              [10.916058999000029, 51.616374001000061],
              [10.937540500000068, 51.601983001000072],
              [10.882051501000035, 51.580508],
            ],
          ],
        ],
      },
    },
  ],
}
