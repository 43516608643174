import React, { useCallback, useEffect, useState, useMemo } from 'react'

import { LeafletMap, Overlay, GeoJSON, withPane } from 'lib/leaflet'
import { useMapMultiStore } from 'lib/leaflet/store'
import { DefaultBaseLayers, Attribution, LocationSearchPin } from 'components/mapLayers'

import api from 'stores/api'

import { reduceFeatures, setLocationSubmarket } from './locationHelpers'

import { zIndices } from 'config/zIndices'
import areaTypes from 'config/areaTypes'

const mapOptions = {
  DE: {
    center: [51.27, 10.35],
    zoomControl: true,
    zoom: 5.5,
    minZoom: 5,
    scrollWheelZoom: true,
    maxBounds: [
      [45.5, 3.5],
      [56, 18],
    ],
    layers: [],
  },
  AT: {
    center: [47.54, 13.34],
    zoomControl: true,
    zoom: 7,
    minZoom: 5,
    scrollWheelZoom: true,
    maxBounds: [
      [45.5, 3.5],
      [56, 18],
    ],
    layers: [],
  },
}

// 47.506069781910846, lng: 13.002319335937502

const createSubmarketStyle = (type = null) => {
  return (feature) => {
    if (!type) {
      return {}
    }
    const config = areaTypes.find((areaType) => areaType.key === type + 'Area') || null
    return {
      stroke: true,
      weight: 2,
      fill: true,
      color: config.strokeColor,
      fillColor: config.strokeColor,
      fillOpacity: feature.properties.selected ? 0.3 : 0.1,
    }
  }
}

const onEachFeatureSubmarkets = (feature, layer) => {
  if (feature.properties.name && feature.properties.name.length) {
    layer.bindTooltip(feature.properties.name)
  }
}

const SubmarketsLayer = ({ setSubmarket, submarkets, type, ...rest }) => {
  const [features, setFeatures] = useState([])

  useEffect(() => {
    if (submarkets?.features?.length) {
      setFeatures([submarkets])
    } else {
      setFeatures([])
    }
  }, [submarkets])

  const onClick = useCallback(
    (evt) => {
      const submarket = evt.layer?.feature
      if (submarket) {
        setSubmarket(submarket)
      }
    },
    [setSubmarket]
  )

  const style = useMemo(() => createSubmarketStyle(type), [type])

  return (
    <GeoJSON
      data={features}
      style={style}
      onEachFeature={onEachFeatureSubmarkets}
      onClick={onClick}
      {...rest}
    />
  )
}

const SubmarketsPane = withPane(
  SubmarketsLayer,
  'locationSearchSubmarkets',
  zIndices.locationSearchSubmarkets
)

export default ({
  mapId = 'locationSearchMap',
  choosenLocation,
  setChoosenLocation,
  setSelectedSubmarket,
  additionalAreas,
  country = 'DE',
}) => {
  const setViewport = useMapMultiStore((_, actions) => actions.setViewport, mapId)

  const onDrag = useCallback(
    (coords) => {
      api.Location.reverse({
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: coords,
        },
      })
        .then((res) => {
          if (res.data.data) {
            const feature = res.data.data
            const preparedFeature = [feature].reduce(reduceFeatures, [])?.[0] || null
            setChoosenLocation(preparedFeature)
          }
        })
        .catch((err) => console.log(err))
    },
    [setChoosenLocation]
  )

  const setSubmarket = useCallback(
    (submarket) => {
      const type = choosenLocation?.submarketType

      if (type) {
        const newLocation = setLocationSubmarket(choosenLocation, submarket.properties, type)
        setSelectedSubmarket({ value: submarket.properties.id, label: submarket.properties.name, key: type })
        setChoosenLocation(newLocation)
      }
    },
    [choosenLocation, setChoosenLocation, setSelectedSubmarket]
  )

  useEffect(() => {
    if (!choosenLocation) {
      setViewport({
        center: mapOptions[country].center,
        quick: true,
        zoom: mapOptions[country].zoom,
      })
    }
  }, [choosenLocation, setViewport, additionalAreas, country])

  const submarkets = useMemo(() => {
    let submarkets = { features: [] }
    if (choosenLocation?.submarketType && additionalAreas[choosenLocation.submarketType].length) {
      const selectedArea =
        Object.values(choosenLocation.areas).filter(
          (area) => area.type === (choosenLocation?.submarketType === 'office' ? '107' : '108')
        )?.[0] || {}

      submarkets.features = additionalAreas[choosenLocation.submarketType].reduce((features, area) => {
        const { geoJSON, ...properties } = { ...area }
        const feature = {
          type: 'Feature',
          properties: {
            selected: selectedArea.gac === area.gac,
            ...properties,
          },
          geometry: geoJSON,
        }
        features.push(feature)
        return features
      }, [])
    }
    return submarkets
  }, [choosenLocation, additionalAreas])

  return (
    <LeafletMap mapId={mapId} options={mapOptions[country]}>
      <DefaultBaseLayers />
      <SubmarketsPane
        submarkets={submarkets}
        type={choosenLocation?.submarketType}
        setSubmarket={setSubmarket}
      />
      {choosenLocation && (
        <Overlay name="Standorte">
          <LocationSearchPin mapId={mapId} choosenLocation={choosenLocation} onDrag={onDrag} />
        </Overlay>
      )}
      <Attribution />
    </LeafletMap>
  )
}
