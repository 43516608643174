import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import appConfig from 'config/appConfig'
import api from 'stores/api'
import { useAuthStore } from 'stores/AuthStore'
import { useAppStore } from 'stores/AppStore'

import Box from 'components/atoms/Box'
import { TextBanner, TextParagraph } from 'components/atoms/Text'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { BudiconUserCard, BudiconUnlock, BudiconEmail } from 'bgag-budicons'

const LoginForm = ({ setRedirectToReferrer, showMessage = '' }) => {
  const { t } = useTranslation()
  const [currentUser, actions] = useAuthStore((state, actions) => [state.currentUser, actions])
  const [loadAppState] = useAppStore((state, actions) => [actions.loadState])
  const [loginError, setLoginError] = useState(null)
  const [loginLoading, setLoginLoading] = useState(false)
  const [credentials, setCredentials] = useState({
    username: '',
    password: '',
  })

  useEffect(() => {
    if (typeof currentUser === 'object') {
      setRedirectToReferrer(true)
    }
  }, [currentUser, setRedirectToReferrer])

  const login = async (e) => {
    e.preventDefault()
    setLoginError(null)
    setLoginLoading(true)
    try {
      await api.Auth.login(credentials)
      actions.pullCurrentUser()
      loadAppState()
    } catch (error) {
      console.error('error', error)
      if (error?.response?.data?.messages?.[0] === 'User expired') {
        setLoginError(t('authentication.accountExpired'))
      } else {
        setLoginError(t('authentication.loginError'))
      }
      setLoginLoading(false)
    }
  }

  return (
    <Box width="100%" textAlign="center">
      <TextBanner mt={3}>{t('authentication.header')}</TextBanner>
      {/*<TextParagraph mt={1}>{t('authentication.intro')}</TextParagraph>*/}
      <Box mt={3} mb={6}>
        <BudiconUserCard size="40px" />
      </Box>
      <Box width="100%">
        <form onSubmit={login}>
          <Input
            id="username"
            value={credentials.username}
            placeholder={t('authentication.username')}
            label={t('authentication.username')}
            hideLabel
            Icon={BudiconEmail}
            appearance="default"
            onChange={(e) => {
              setCredentials({ ...credentials, username: e.target.value })
            }}
          />
          <Input
            id="password"
            type="password"
            value={credentials.password}
            label={t('authentication.password')}
            hideLabel
            placeholder={t('authentication.password')}
            Icon={BudiconUnlock}
            appearance="default"
            onChange={(e) => {
              setCredentials({ ...credentials, password: e.target.value })
            }}
          />
          <Button type="submit" mt={6} mb={8} appearance="darker" isLoading={loginLoading} minWidth="90px">
            {t('menu.login')}
          </Button>
        </form>
        {loginError && (
          <TextParagraph mt={2} color="danger">
            {loginError}
          </TextParagraph>
        )}
        {showMessage === 'passwordSet' && (
          <TextParagraph mt={2} color="success">
            {t('authentication.passwordSet')}
          </TextParagraph>
        )}
        {showMessage === 'mailSent' && (
          <TextParagraph mt={2} color="success">
            {t('authentication.mailSent')}
          </TextParagraph>
        )}
        <TextParagraph>
          <Link to="/forgotPassword">{t('authentication.actionForgotPassword')}</Link>
        </TextParagraph>
        <TextParagraph>
          <a
            href={`${appConfig.appBaseUrl}/RIWIS-Datenschutzerklaerung.pdf`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('menu.privacyPolicy')}
          </a>
        </TextParagraph>
      </Box>
    </Box>
  )
}

export default LoginForm
