// comments are leaflet's default panes
export const zIndices = {
  // tilePane: 200
  // overlayPane: 400
  districts: 401,
  federalStates: 402,
  riwisCities: 403,
  buildingblocks: 404,
  kgs12: 405,
  l3plusSubmarkets: 406,
  l3plusRetailUnits: 407,
  locationSearchSubmarkets: 408,
  currentLocation: 430,
  highstreetReportLocations: 431,
  zones: 470,
  backdrop: 480,
  areas: 482,
  // shadowPane: 500
  // markerPane: 600
  poi: 601,
  objects: 602,
  portfolios: 603,
  cityLabels: 605,
  retailMarketsAdditionalLayers: 606,
  locationPins: 610,
  locationSearchPin: 611,
  // toolTipPane: 650
  // popupPane: 700
  draftZone: 720,
  editZone: 720,
  vertices: 722,
  collectionMarker: 730,
  bodyAnchor: 20000000,
  impersonateUser: 40000000,
  queueUpdates: 60000000,
}
