import React, { useMemo } from 'react'

import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'

import { withPane, VectorTileLayer } from 'lib/leaflet'

import { zIndices } from 'config/zIndices'
import { colors } from 'config/buildingblock'
import { bgagServicesUrl } from 'config/appConfig'

import { useZoom } from 'lib/leaflet/hooks'

export const ThematicLayer = ({ topic, resolution = 'auto' }) => {
  const { hasLicence } = useAuthzChecks()
  const zoom = useZoom()
  const style = useVectorLayerStyle(topic)

  let layer = null
  if (resolution === 'buildingblock') {
    if (hasLicence('thematicLayer')) {
      layer = 'buildingBlock'
    } else if (hasLicence('thematicLayer_rough')) {
      layer = 'kgs12'
    }
  } else if (resolution === 'kgs12') {
    if (hasLicence('thematicLayer_rough')) {
      layer = 'kgs12'
    } else if (hasLicence('thematicLayer')) {
      layer = 'buildingBlock'
    }
  } else {
    // themaResolution === 'autozoom'
    if (hasLicence('thematicLayer') && hasLicence('thematicLayer_rough')) {
      layer = zoom >= 13 ? 'buildingBlock' : 'kgs12'
    } else if (hasLicence('thematicLayer')) {
      layer = 'buildingBlock'
    } else if (hasLicence('thematicLayer_rough')) {
      layer = 'kgs12'
    }
  }

  if (!layer) return null
  return (
    <VectorTileLayer
      url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${layer}`}
      auth
      maxNativeZoom={14}
      style={style}
    />
  )
}

export const ThematicPane = withPane(ThematicLayer, 'thematic-layer', zIndices.buildingblocks)

const thematicStyleFactory = (topic) => (properties) => {
  if (!properties[topic]) {
    return []
  }
  return {
    stroke: true,
    weight: 1,
    color: '#FFFFFF',
    opacity: 0.5,
    fill: true,
    fillColor: colors[topic][properties[topic]] ?? '#eeeeee',
    fillOpacity: 0.6,
  }
}

function useVectorLayerStyle(topic) {
  return useMemo(() => {
    return {
      buildingBlock: thematicStyleFactory(topic),
      kgs12: thematicStyleFactory(topic),
    }
  }, [topic])
}
