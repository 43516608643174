import React, { useMemo } from 'react'
import { createGlobalStyle, keyframes } from 'styled-components'
import { DivIcon } from 'leaflet'

import { Marker } from 'lib/leaflet'

export const PulseMarker = ({ position, size = 8 }) => {
  const options = useMemo(
    () => ({
      icon: new DivIcon({
        className: 'bgag-pulse-icon',
        iconSize: [size, size],
      }),
    }),
    [size]
  )

  return (
    <>
      <GlobalStyle />
      <Marker position={position} options={options} />
    </>
  )
}

const pulsate = keyframes`
0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
}
50% {
    opacity: 1;
}
100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
}
`

const GlobalStyle = createGlobalStyle`
.bgag-pulse-icon:after {
  content: "";
  border-radius: 100%;
  height: 300%;
  width: 300%;
  animation: ${pulsate} 1s ease-out infinite;
  position: absolute;
  margin: -100% 0 0 -100%;
  box-shadow: 0 0 6px 2px red;
}

`
