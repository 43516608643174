import React, { useEffect, useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Slider from 'rc-slider'

import { useDebouncedUpdates } from 'lib/hooks'
import { toggle } from 'lib/util'
import { hexToRgb } from 'lib/util/colors'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { sendToAutomap } from 'lib/bgag-automap'
import Box, { Flex } from 'components/atoms/Box'
import Input from 'components/atoms/Input'
import { TextHeader } from 'components/atoms/Text'
import Button from 'components/atoms/Button'
import { ColorPickerPersistent } from 'components/molecules/ColorPickerPersistent'
import { AlignmentBox } from 'components/AlignmentBox'
import { polygonBBox } from 'lib/geo/helpers'

import { useZoneProperties, useZone } from './Store'

export const EditZoneProperties = ({ zoneId, close }) => {
  const { t } = useTranslation()
  const { hasLicence } = useAuthzChecks()
  const [showColorPicker, setShowColorPicker] = useState(false)
  const [props, setProps, resetProps, persist] = useZoneProperties(zoneId)
  const zone = useZone(zoneId)

  const { name, color, opacity, weight } = props

  const [localColor, setColor] = useDebouncedUpdates(
    color,
    useCallback((color) => setProps({ color }), [setProps]),
    100
  )
  const [localOpacity, setOpacity] = useDebouncedUpdates(
    opacity,
    useCallback((opacity) => setProps({ opacity }), [setProps]),
    50
  )
  const [localWeight, setWeight] = useDebouncedUpdates(
    weight,
    useCallback((weight) => setProps({ weight }), [setProps]),
    50
  )

  useEffect(() => {
    return () => {
      persist()
    }
  }, [persist])

  return (
    <>
      <Flex>
        <Box flex="1">
          <TextHeader>{t('geotools.zones.properties.title')}</TextHeader>
        </Box>
        <Button appearance="secondary" onClick={resetProps}>
          {t('actions.actionReset')}
        </Button>
      </Flex>
      <ZoneEditStyle>
        <Flex alignItems="center">
          <Box flex="1">
            <label htmlFor="zone-name">{t('geotools.zones.properties.name')}: </label>
          </Box>
          <Box mr={2} />
          <Box flex="2">
            <Input
              type="text"
              id="zone-name"
              label={'geotools.zones.properties.name'}
              hideLabel
              value={name}
              onChange={(evt) => setProps({ name: evt.target.value })}
              style={{ width: '100%' }}
            />
          </Box>
        </Flex>
        <Box mb={2} />
        <Flex alignItems="center" mb={2}>
          <Box flex="1">
            <Flex>
              <label htmlFor="geotools.zone.color">{t('geotools.zones.properties.color')}:</label>
              <Box flex="1" />
              <Box>
                <ColorButton
                  id="geotools.zone.color"
                  onClick={(evt) => setShowColorPicker(toggle)}
                  color={localColor}
                  opacity={localOpacity}
                  weight={localWeight}
                />
              </Box>
            </Flex>
            {showColorPicker && (
              <AlignmentBox alignments={{ parentV: 'top', childV: 'bottom' }} zIndex={1002}>
                <PopupStyle>
                  <ColorPickerPersistent
                    value={localColor}
                    onChange={setColor}
                    onSubmit={() => setShowColorPicker(toggle)}
                    onCancel={() => {
                      setColor(zone.properties.color)
                      setShowColorPicker(toggle)
                    }}
                  />
                </PopupStyle>
              </AlignmentBox>
            )}
          </Box>
          <Box mr={2} />
          <Box flex="2">
            <Flex alignItems="center">
              <Box flex="1">
                <label htmlFor="zone-opacity">{t('geotools.zones.properties.opacity')}:</label>
              </Box>
              <Box flex="1">
                <Slider
                  id="zone-opacity"
                  value={localOpacity}
                  min={0}
                  max={1}
                  step={0.01}
                  onChange={setOpacity}
                />
              </Box>
            </Flex>
            <Flex alignItems="center">
              <Box flex="1">
                <label htmlFor="zone-weight">{t('geotools.zones.properties.weight')}</label>
              </Box>
              <Box flex="1">
                <Slider
                  id="zone-weight"
                  value={localWeight}
                  min={0.1}
                  max={5}
                  step={0.1}
                  onChange={setWeight}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
        {hasLicence('bgagInternalLinks') && (
          <Flex>
            <Box mt={2} flex="1">
              <span>{t('actions.actionSendTo')}:</span>
            </Box>
            <Box mr={2} />
            <Box flex="2">
              <Box mb={2}>
                <AutomapButton zone={{ ...zone, properties: { ...zone.properties, ...props } }} />
              </Box>
              <Box mb={2}>
                <EhinfoButton zone={{ ...zone, properties: { ...zone.properties, ...props } }} />
              </Box>
              <Box mb={2}>
                <ODBButton zone={{ ...zone, properties: { ...zone.properties, ...props } }} />
              </Box>
            </Box>
          </Flex>
        )}
        <div />
      </ZoneEditStyle>
      <Flex justifyContent="center">
        <Button onClick={close}>{t('actions.actionSave')}</Button>
        <Box mr="2" />
        <Button
          appearance="secondary"
          onClick={() => {
            resetProps()
            close()
          }}
        >
          {t('actions.actionCancel')}
        </Button>
      </Flex>
    </>
  )
}

const ZoneEditStyle = styled.div`
  width: 300px;
  gap: 5px 10px;
  align-items: center;
  color: #666666;
  margin: 8px 0;

  label {
    margin-bottom: 0;
  }
`

const PopupStyle = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 0 0 10px 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
`

const ColorButton = styled.button.attrs(({ color, opacity, weight }) => ({
  style: {
    backgroundColor: hexToRgb(color, opacity),
    border: `${weight}px solid ${color}`,
  },
}))`
  width: 30px;
  height: 30px;
  border-radius: 4px;
`

const AutomapButton = ({ zone }) => {
  const { t } = useTranslation()
  return <Button onClick={() => sendToAutomap(zone)}>{t('geotools.automap')}</Button>
}

const useFilemakerBBox = (zone) => {
  const bbox = useMemo(() => {
    const { geometry } = zone
    if (geometry.type === 'Polygon') {
      return polygonBBox([geometry.coordinates])
    } else {
      return polygonBBox(geometry.coordinates)
    }
  }, [zone])

  return bbox.join('|')
}

const EhinfoButton = ({ zone }) => {
  const { t } = useTranslation()
  const bbox = useFilemakerBBox(zone)
  const url = `fmp://filemaker.bgag/EH-Info?script=search_bbox_ext&param=${bbox}`
  return (
    <Button>
      <a style={{ color: 'white' }} href={url}>
        {t('geotools.ehinfo')}
      </a>
    </Button>
  )
}

const ODBButton = ({ zone }) => {
  const { t } = useTranslation()
  const bbox = useFilemakerBBox(zone)
  const url = `fmp://filemaker.bgag/Objektdatenbank?script=search_bbox_ext&param=${bbox}`
  return (
    <Button>
      <a style={{ color: 'white' }} href={url}>
        {t('geotools.odb')}
      </a>
    </Button>
  )
}
