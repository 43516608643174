import React from 'react'

import { zIndices } from 'config/zIndices'
import { withPane } from 'lib/leaflet'

import { useGeotoolsDraftStore } from './Store'
import { NewDrawLayer } from './NewDrawLayer'
import { NewCircleLayer } from './NewCircleLayer'
import { NewFzrLayer } from './NewFzrLayer'
import { NewSelectionLayer } from './NewSelectionLayer'

const COMPONENTS = {
  circle: NewCircleLayer,
  fzr: NewFzrLayer,
  draw: NewDrawLayer,
  select: NewSelectionLayer,
}

const DraftLayers = () => {
  const [{ popupsOpen }] = useGeotoolsDraftStore()
  return (
    <>
      {popupsOpen.map((method) => {
        const Component = COMPONENTS[method]
        return <Component key={method} />
      })}
    </>
  )
}

export const DraftPane = withPane(DraftLayers, 'draftZones', zIndices.draftZone)
