import React, { useEffect, useState } from 'react'

import { GeoJSON, withPane } from 'lib/leaflet'

import api from 'stores/api'
import { zIndices } from 'config/zIndices'

export const RiwisCityLayer = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    api.Geometry.area('riwisCity')
      .then((res) => {
        setData(res.data.data.features)
      })
      .catch((err) => console.log(err))
  }, [])

  return <GeoJSON data={data} />
}

export const RiwisCityLayerPane = withPane(RiwisCityLayer, 'riwis-cities', zIndices.riwisCities)
