import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  findAreaWithMarketCategory,
  findAreaByType,
  getMarketCategory,
  setLocationSubmarket,
} from './locationHelpers'

import Box, { Flex } from 'components/atoms/Box'
import { TextHeader, TextSubHeader } from 'components/atoms/Text'
import InputSuggestions from 'components/molecules/InputSuggestions'

import CityCategoryIcon from 'components/icons/CityCategoryIcon'
import { BudiconDesktop, BudiconSofa } from 'bgag-budicons'

const Warning = styled(TextSubHeader)`
  color: ${(props) => props.theme.colors.red};
`

const KeyValuePair = ({ keyString = '', valueString = '', gapFollows = false }) => (
  <TextSubHeader mb={gapFollows ? 2 : 0}>
    <Flex>
      <Box flex="0 0 140px">
        <b>{keyString}</b>
      </Box>
      <Box flex="1 0 auto">
        <span>{valueString}</span>
      </Box>
    </Flex>
  </TextSubHeader>
)

const KeyValuePairWithValueIcon = ({ keyString = '', valueIcon, gapFollows = false }) => (
  <TextSubHeader mb={gapFollows ? 2 : 0}>
    <Flex alignItems="center">
      <Box flex="0 0 140px" mt={1}>
        <b>{keyString}</b>
      </Box>
      <Box flex="1 0 auto">{valueIcon}</Box>
    </Flex>
  </TextSubHeader>
)

const KeyValuePairWithKeyIcon = ({ keyIcon, gapFollows = false, children }) => (
  <TextSubHeader mb={gapFollows ? 2 : 0}>
    <Flex alignItems="center">
      <Box flex="0 0 2.6em">{keyIcon}</Box>
      <Box flex="1 0 auto" mt={1}>
        {children}
      </Box>
    </Flex>
  </TextSubHeader>
)

let switchSubmarketTimeout = null

export const LocationSearchDetails = ({
  choosenLocation,
  setChoosenLocation,
  additionalAreas,
  selectedSubmarket,
  setSelectedSubmarket,
}) => {
  const { t } = useTranslation()

  const [optAdditionals, setOptAdditionals] = useState({ office: [], residential: [] })

  const prevChoosenLocation = useRef(null)
  const isMounted = useRef(true)

  const isAuthorized =
    choosenLocation !== null
      ? Object.values(choosenLocation.areas).some(
          (area) => area.access && area.type !== '60' && area.type !== '50'
        )
      : false

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const opts = { residential: null, office: null }
    for (const key of ['residential', 'office']) {
      opts[key] = additionalAreas[key].map((area, idx) => ({ value: key + '-' + idx, label: area.name, key }))
    }
    setOptAdditionals(opts)
  }, [additionalAreas])

  useEffect(() => {
    if (!choosenLocation?.submarketType) {
      setSelectedSubmarket(null)
    }
  }, [choosenLocation, setSelectedSubmarket])

  const onchangeAdditionals = (evt) => {
    const [key, index] = evt.target?.value?.value ? evt.target.value.value.split('-') : [null, null]
    const area = key !== null && index !== null ? additionalAreas[key][index] : null
    if (switchSubmarketTimeout !== null) {
      clearTimeout(switchSubmarketTimeout)
      switchSubmarketTimeout = null
    }
    if (area === null) {
      if (prevChoosenLocation.current !== null) {
        switchSubmarketTimeout = setTimeout(() => {
          switchSubmarketTimeout = null
          if (isMounted.current) {
            setSelectedSubmarket(null)
            setChoosenLocation(prevChoosenLocation.current)
          }
        }, 200)
      }
      return
    }

    if (!choosenLocation.submarketType) {
      prevChoosenLocation.current = { ...choosenLocation }
    }

    const newLocation = setLocationSubmarket(choosenLocation, area, key)

    setTimeout(() => setSelectedSubmarket({ value: area.id, label: area.name, key }), 0)
    switchSubmarketTimeout = setTimeout(() => {
      switchSubmarketTimeout = null
      if (isMounted.current) {
        setChoosenLocation(newLocation)
      }
    }, 200)
  }

  let cityCategoryLetter = '-'
  let officeSituational = '-'
  let residentialSituational = '-'
  let boroughSituational = '-'

  if (choosenLocation) {
    const addressLevel = parseInt(choosenLocation.addressLevel)
    const fixedSituationals = !isNaN(addressLevel) && addressLevel > 0 && addressLevel <= 4

    cityCategoryLetter = getMarketCategory(findAreaWithMarketCategory(choosenLocation))
    boroughSituational = (fixedSituationals && findAreaByType(choosenLocation, '109')?.name) || '-'
    officeSituational = (fixedSituationals && findAreaByType(choosenLocation, '107')?.name) || '-'
    residentialSituational = (fixedSituationals && findAreaByType(choosenLocation, '108')?.name) || '-'
  }

  return (
    <Flex mt={3} py={4} flex="1 0 auto" flexDirection="column" justifyContent="space-between">
      <Box flex="0 0 auto">
        {choosenLocation && (
          <>
            <TextHeader mb={3}>{choosenLocation.displayedName}</TextHeader>
            <Box>
              {boroughSituational.length > 1 && (
                <KeyValuePair
                  gapFollows
                  keyString={t('locationSearch.input.borough')}
                  valueString={boroughSituational}
                />
              )}
              {cityCategoryLetter === '-' || cityCategoryLetter === 'AT' ? (
                <KeyValuePair
                  gapFollows
                  keyString={t('locationSearch.input.riwisCategory')}
                  valueString={'-'}
                />
              ) : (
                <KeyValuePairWithValueIcon
                  keyString={t('locationSearch.input.riwisCategory')}
                  valueIcon={<CityCategoryIcon size="27px" letter={cityCategoryLetter} />}
                />
              )}
              {!isAuthorized && <Warning mt={4}>{t('locationSearch.noAuthorization')}</Warning>}
            </Box>
          </>
        )}
      </Box>
      <Box flex="0 0 220px">
        <TextSubHeader>
          <b>{t('locationSearch.input.submarket')}</b>
        </TextSubHeader>
        <Box mt={2} ml={2}>
          <KeyValuePairWithKeyIcon
            gapFollows
            keyIcon={
              <BudiconDesktop
                size="25px"
                type={choosenLocation?.submarketType === 'office' ? 'solid' : 'outline'}
              />
            }
          >
            {additionalAreas.office.length ? (
              <InputSuggestions
                id="locationAreaOffice"
                value={selectedSubmarket}
                listWidth="auto"
                resetOnValueNotFound={true}
                placeholder={t('locationSearch.selectAreaOffice')}
                entries={optAdditionals.office}
                numRows={10}
                onChange={onchangeAdditionals}
                expandOnFocus={true}
              />
            ) : (
              officeSituational
            )}
          </KeyValuePairWithKeyIcon>
          <KeyValuePairWithKeyIcon
            keyIcon={
              <BudiconSofa
                size="25px"
                type={choosenLocation?.submarketType === 'residential' ? 'solid' : 'outline'}
              />
            }
          >
            {additionalAreas.residential.length ? (
              <InputSuggestions
                id="locationAreaResidential"
                value={selectedSubmarket}
                listWidth="auto"
                resetOnValueNotFound={true}
                placeholder={t('locationSearch.selectAreaResidential')}
                entries={optAdditionals.residential}
                numRows={10}
                onChange={onchangeAdditionals}
                expandOnFocus={true}
              />
            ) : (
              residentialSituational
            )}
          </KeyValuePairWithKeyIcon>
        </Box>
      </Box>
    </Flex>
  )
}
