import polygonClipping from 'polygon-clipping'

import { validators } from 'lib/cache/validators'
import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const conditionals = [
  {
    name: 'position',
    stringify: JSON.stringify,
  },
  {
    name: 'range',
    validators: [validators.arrayOf('number', 1)],
    stringify: (val, sep) => val.join(sep),
  },
  { name: 'rangetype' },
  { name: 'mode' },
]

function getter(position, range, rangetype = 'time', mode = 'car') {
  const isStartFromZero = range[0] === 0
  if (isStartFromZero) range = range.slice(1)
  return api.Drivingdistance.get(queryObj(position, range, rangetype, mode))
    .then((res) => res.data.data)
    .then((data) => createDonuts(data, isStartFromZero))
}

export const [getDrivingdistance] = createDataCache(conditionals, getter, {
  name: 'drivingdistance',
})

function queryObj(position, range, rangetype, mode) {
  return {
    center: position,
    range,
    rangetype,
    type: 'fastest',
    mode,
    detailed: true,
  }
}

function createDonuts(ddFeatureCollection, startFromZero = false) {
  // helper: get coordinates of GeoJSON features
  const featureCoords = (f) => f.geometry.coordinates
  // sort range largest to smallest
  const { features } = ddFeatureCollection
  features.sort((a, b) => b.properties.range - a.properties.range)
  for (let i = 0; i < features.length - 1; i++) {
    const [current, next] = features.slice(i, i + 2)
    current.geometry.type = 'MultiPolygon'
    current.geometry.coordinates = polygonClipping.difference(featureCoords(current), featureCoords(next))
    current.properties.fromRange = next.properties.range
  }
  if (!startFromZero) {
    features.pop()
  } else {
    features[features.length - 1].properties.fromRange = 0
  }
  return ddFeatureCollection
}
