import styled from 'styled-components'
import {
  space,
  layout,
  color,
  border,
  flexbox,
  typography,
  background,
  position,
  system,
  grid,
} from 'styled-system'

export const Grid = styled.div`
  display: grid;
  ${grid}
  ${space}
  ${layout}
  ${position}
  ${color}
  ${border}
  ${flexbox}
  ${typography}
  ${background}
  ${system({
    cursor: true,
  })}
`
