import React, { useState, useEffect } from 'react'

import { GeoJSON } from 'lib/leaflet'

import { useEditZoneStore } from './Store'
import { zoneStyle } from '../Zones/Layers'

export const EditDrawLayer = () => {
  const [layer, setLayer] = useState()
  const [{ zone, isLoading, drawMode }] = useEditZoneStore()

  useEffect(() => {
    if (!layer) return
    const handleEdit = (evt) => {
      const geometry = evt.layer.toGeoJSON().geometry
      zone.geometry = geometry
    }
    layer.on('pm:edit', handleEdit)
    return () => {
      layer.off('pm:edit', handleEdit)
    }
  }, [layer, zone])

  useEffect(() => {
    if (!layer) return
    if (isLoading || !drawMode) {
      layer.pm.disable()
    } else {
      layer.pm.enable({ allowSelfIntersection: false })
    }
  }, [layer, isLoading, drawMode])

  useEffect(() => {
    if (!layer) return
    return () => {
      layer.pm.disable()
    }
  }, [layer])

  return <GeoJSON data={zone} style={zoneStyle} callback={setLayer} />
}
