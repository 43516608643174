import { useEffect, useState } from 'react'

import { usePrevious, usePromiseHandle, ignoreCanceled } from 'lib/hooks'
import { getGacFeature } from 'stores/GacFeatureCache'

/** Returns the feature to the gac or null while it hasn't been fetched */
export const useGacFeature = (gac) => {
  const [feature, setFeature] = useState(null)
  const lastGac = usePrevious(gac)

  const [register, cancelAll] = usePromiseHandle()

  useEffect(() => {
    cancelAll()
    if (gac === null) {
      setFeature(null)
    } else {
      register(getGacFeature(gac)).then(setFeature).catch(ignoreCanceled)
    }
  }, [gac, register, cancelAll])

  return gac === lastGac ? feature : null
}
