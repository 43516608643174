import React, { useState, useEffect, useMemo } from 'react'
import { createGlobalStyle, keyframes } from 'styled-components'

import { zIndices } from 'config/zIndices'
import { GeoJSON, withPane, useLeaflet } from 'lib/leaflet'

import { useGeotoolsStore } from './Store'
import { useEditZoneStore } from '../Edit/Store'

const ZonesLayer = () => {
  const map = useLeaflet()
  const [{ collection, hoveredZone, localProperties, flyTo, algebraMode }, { setFlyTo, selectZone }] =
    useGeotoolsStore()
  const [{ zone: editedZone }] = useEditZoneStore()
  const editedZoneId = editedZone?.id
  const [geoJSONLayer, setLayer] = useState(null)

  // add pulsate effect to hovered layer
  useEffect(() => {
    if (geoJSONLayer === null) return
    for (const layer of geoJSONLayer.getLayers()) {
      if (layer.feature.id === hoveredZone) {
        layer._path.classList.add('bgag-pulsate')
      } else {
        layer._path.classList.remove('bgag-pulsate')
      }
    }
  }, [geoJSONLayer, hoveredZone])

  // apply style updates to layers
  useEffect(() => {
    if (geoJSONLayer === null) return
    geoJSONLayer.resetStyle()
    for (const layer of geoJSONLayer.getLayers()) {
      const localProps = localProperties[layer.feature.id]
      if (typeof localProps !== 'undefined') {
        const properties = { ...layer.feature.properties, ...localProperties[layer.feature.id] }
        layer.setStyle(zoneStyle({ properties }))
      }
    }
  }, [collection, geoJSONLayer, localProperties])

  // fly to clicked zone
  useEffect(() => {
    if (geoJSONLayer === null || flyTo === null) return
    const layer = geoJSONLayer.getLayers().find((l) => l.feature.id === flyTo)
    // zone may be inactive
    if (!layer) return
    map.flyToBounds(layer.getBounds())
    setFlyTo(null)
  }, [flyTo, geoJSONLayer, map, setFlyTo])

  useEffect(() => {
    if (geoJSONLayer === null) return
    for (const zone of collection.features.slice().reverse()) {
      const layer = geoJSONLayer.getLayers().find((l) => l.feature.id === zone.id)
      layer && layer.bringToFront()
    }
  }, [geoJSONLayer, collection])

  return (
    <>
      <GeoJSON
        data={useMemo(
          () => collection.features.filter((zone) => zone.id !== editedZoneId),
          [collection, editedZoneId]
        )}
        filter={filter}
        style={zoneStyle}
        callback={setLayer}
        onClick={(evt) => algebraMode && selectZone(evt.layer.feature.id)}
      />
      <BgagPulsateClass />
    </>
  )
}

const filter = (feature) => feature.properties.active

export const ZonesPane = withPane(ZonesLayer, 'zones', zIndices.zones)

export const zoneStyle = (feature) => {
  const { color, weight, opacity } = feature.properties
  return { color, fillColor: color, fillOpacity: opacity, weight, className: 'bg-zone' }
}

const pulsate = keyframes`
  0% {stroke-width: 2;}
  50% {stroke-width: 5;}
  100% {stroke-width: 2;}
`

const BgagPulsateClass = createGlobalStyle`
.bgag-pulsate {
  animation: ${pulsate} 1s linear infinite;
}
`
