import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import styled from 'styled-components'
import { space } from 'styled-system'

import appConfig from 'config/appConfig'
import { zIndices } from 'config/zIndices'

import api from 'stores/api'
import { useAuthStore } from 'stores/AuthStore'
import { useQueueUpdatesStore } from 'stores/QueueUpdates'

import { sleep } from 'lib/util'

import Button from 'components/atoms/Button'
import { Box, Flex } from 'components/atoms/Box'
import { TextParagraph } from 'components/atoms/Text'
import { BudiconFileDownload, BudiconCrossUi } from 'bgag-budicons'

const bgagServicesUrl = appConfig.bgagServicesUrl
const downloadUrl = `${bgagServicesUrl.replace(/\/$/, '')}/queue/jobDownload/`

const connectionStatus = {
  [ReadyState.CONNECTING]: 'connecting',
  [ReadyState.OPEN]: 'open',
  [ReadyState.CLOSING]: 'closing',
  [ReadyState.CLOSED]: 'closed',
  [ReadyState.UNINSTANTIATED]: 'uninstantiated',
}

const containerStyle = {
  position: 'absolute',
  right: 4,
  top: 8,
  pointerEvents: 'none',
  zIndex: zIndices.queueUpdates,
}

const downloadButtonStyle = {
  width: '100%',
}

const MessageContainer = styled.div`
  pointer-events: auto;
  min-width: 300px;
  background-color: white;
  border-radius: 4px;
  padding: 12px 16px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 4px 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`

const Progress = styled.progress`
  width: 100%;
  vertical-align: bottom;
  ${space}
`

const runningStatusPolls = {}

async function* createStatusPoll(id, intervall = 200) {
  let message
  while (!(message && message.status === 'completed')) {
    const pollResult = await api.Queue.getJob(id)
    message = pollResult.data.data
    if (message.status === 'active') {
      yield message
    } else if (message.status === 'failed') {
      throw new Error('job failed')
    }
    await sleep(intervall)
  }
  yield message
}

const useSocket = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const isPuppeteer = params.get('puppeteer') === 'true'

  const [{ currentUser }] = useAuthStore()

  const [socketUrl, setSocketUrl] = useState(null)

  const [{ messages }, { addMessage, closeMessage }] = useQueueUpdatesStore()

  useEffect(() => {
    if (!isPuppeteer && typeof currentUser === 'object' && currentUser !== null) {
      setSocketUrl(bgagServicesUrl.replace(/^http/, 'ws') + '/ws/queueUpdates')
    } else {
      setSocketUrl(null)
    }
  }, [currentUser, isPuppeteer])

  const { sendMessage, lastMessage, lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    shouldReconnect: (closeEvent) => {
      if (closeEvent.reason) {
        console.log(closeEvent.reason)
      }
      return closeEvent.code !== 1008
    },
  })

  useEffect(() => {
    console.debug('websocket ', connectionStatus[readyState])
  }, [readyState])

  useEffect(() => {
    const poll = async (generator) => {
      try {
        for await (const message of generator) {
          addMessage(message)
        }
      } catch (err) {
        console.error(err)
      }
    }

    if (readyState !== 1) {
      const setupFallbackFor = Object.values(messages).filter(
        (message) =>
          !runningStatusPolls[message.id] && message.status !== 'completed' && message.status !== 'failed'
      )
      setupFallbackFor.forEach((message) => {
        const generator = createStatusPoll(message.id)
        runningStatusPolls[message.id] = true
        poll(generator).then(() => {
          delete runningStatusPolls[message.id]
        })
      })
    }
  }, [readyState, sendMessage, messages, addMessage])

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastJsonMessage.type === 'dbUpdate') {
        addMessage(lastJsonMessage.message)
      }
      if (lastJsonMessage.type === 'clientState' && lastJsonMessage.message.type === 'closed') {
        closeMessage(lastJsonMessage.message.id)
      }
    }
  }, [lastMessage, lastJsonMessage, addMessage, closeMessage])

  return [sendMessage, readyState]
}

const DownloadButton = ({ message, closeMessage }) => {
  const href = downloadUrl + message.id

  return (
    <Button
      style={downloadButtonStyle}
      isLink={true}
      sizeVariant="small"
      href={href}
      onClick={() => closeMessage(message.id)}
    >
      <BudiconFileDownload type="solid" />
      Download
    </Button>
  )
}

const Message = ({ message, closeMessage }) => {
  const { t } = useTranslation()

  const progress = message.progress > 0 ? message.progress : null

  return (
    <MessageContainer>
      <Flex>
        <Box flex="1 0 auto">
          <Box>{t('export.inProgress.' + message.queue_name)}</Box>
          {message.status !== 'completed' && <Progress value={progress} max="100" mt={1} />}
          {message.status === 'failed' && (
            <Box>
              <TextParagraph color="danger" m={0} mt={2} p={0}>
                {t('export.failed')}
              </TextParagraph>
            </Box>
          )}
          {message.status === 'completed' && message.flags.downloadAvailable === true && (
            <Box mt={1}>
              <DownloadButton message={message} closeMessage={closeMessage} />
            </Box>
          )}
        </Box>
        {message.status === 'failed' && (
          <Box flex="0 0 auto" onClick={() => closeMessage(message.id)} cursor="pointer">
            <BudiconCrossUi />
          </Box>
        )}
      </Flex>
    </MessageContainer>
  )
}

export const QueueUpdates = () => {
  const [sendMessage] = useSocket()

  const [{ messages }, { closeMessage }] = useQueueUpdatesStore()

  const closeMessageWrapper = useCallback(
    (id) => {
      closeMessage(id)
      sendMessage(JSON.stringify({ type: 'closed', id }))
    },
    [closeMessage, sendMessage]
  )

  const displayMessages = Object.values(messages).filter((message) => !message.meta.closed)
  /*
  displayMessages.push({
    id: 141413:,
    status: 'running',
    progress: 40,
    queue_name: 'xls',
    flags: {},
  })
  displayMessages.push({
    id: 141414,
    status: 'completed',
    progress: 100,
    queue_name: 'xls',
    flags: { downloadAvailable: true },
  })
  */

  useEffect(() => {
    // auto download, if message reciever initiated the queue job
    Object.values(messages).forEach((message) => {
      if (message.flags.downloadAvailable && message.meta.clientIsInitiator && !message.meta.closed) {
        closeMessageWrapper(message.id)
        window.location.href = downloadUrl + message.id
      } else if (
        message.queue_name === 'bulk' &&
        (message.status === 'completed' || message.status === 'failed') &&
        !message.meta.closed
      ) {
        closeMessageWrapper(message.id)
      }
    })
  }, [messages, closeMessageWrapper])

  return (
    <div style={containerStyle}>
      {displayMessages.map((message) => (
        <Message key={message.id} message={message} closeMessage={closeMessageWrapper}></Message>
      ))}
    </div>
  )
}
