import React from 'react'
import { useTranslation, Trans } from 'react-i18next'

import RegisterTestAccountL3PlusForm from 'components/RegisterTestAccountL3PlusForm'
import LoginCardWrapper from 'components/LoginCardWrapper'
import { TextSectionHeader, TextParagraph } from 'components/atoms/Text'

import Layout from 'components/Layout'
import Box from 'components/atoms/Box'

const MarketingBox = () => {
  const { t } = useTranslation()
  return (
    <>
      <Box flex="1 0 auto" />
      <Box flex="0 0 auto" width="100%" mb={2} textAlign="center">
        <TextSectionHeader>{t('registerTestAccount.info.header')}</TextSectionHeader>
      </Box>
      <Box flex="0 0 auto">
        <TextParagraph style={{ whiteSpace: 'pre-line' }}>
          <Trans t={t} i18nKey="registerTestAccount.info.text" values={{ email: 'sales@riwis.de' }}>
            <a href="mailto:sales@riwis.de">email</a>
          </Trans>
        </TextParagraph>
      </Box>
      <Box flex="1 0 auto" />
    </>
  )
}

const RegisterTestAccountL3PlusModule = () => {
  return (
    <Layout showMainMenu={false}>
      <LoginCardWrapper logoTop="riwis" MarketingBox={MarketingBox} marketingBg="white">
        <RegisterTestAccountL3PlusForm />
      </LoginCardWrapper>
    </Layout>
  )
}

export default RegisterTestAccountL3PlusModule
