import { useState, useEffect } from 'react'
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid.js'
import L from 'leaflet'

import { useBaseLayer } from './BaseLayer'
import { useAddToMap, useLeaflet } from './LeafletMap'
import { usePaneOptions } from './Pane'
import { useGridLayerEvents } from '../hooks/useEvents'

export const VectorTileLayer = ({
  url,
  style,
  auth = false,
  options,
  maxNativeZoom,
  interactive = false,
  children = null,
  callback = () => {},
  ...props
}) => {
  const map = useLeaflet()
  const paneOptions = usePaneOptions()
  const [layer, setLayer] = useState(null)

  useAddToMap(layer)
  useBaseLayer(layer)
  useGridLayerEvents(layer, props)

  useEffect(() => {
    setLayer(
      L.vectorGrid.protobuf(url, {
        fetchOptions: auth ? { credentials: 'include' } : {},
        maxNativeZoom,
        ...paneOptions,
        ...options,
        interactive,
        getFeatureId: (f) => f.properties.gid,
      })
    )
  }, [url, auth, maxNativeZoom, options, paneOptions, interactive])

  useEffect(() => {
    if (!layer) return
    layer.options.vectorTileLayerStyles = style
    layer.redraw()
    // this does only work for restyling features
    // toggling layers does not work
    // for (const tile of Object.values(layer._vectorTiles)) {
    //   for (const feature of Object.values(tile._features)) {
    //     const styleOptions =
    //       layer.options.vectorTileLayerStyles[feature.layerName] || L.Path.prototype.options
    //     layer._updateStyles(feature.feature, tile, styleOptions)
    //   }
    // }
  }, [layer, style])

  /*
   * Remove layers while panning (performance)
   *
   * When panning leaflet requests all the tiles on its path. This clogs the application as:
   * - The browser is limited in parallel requests of HTTP/1.1 calls (6 to 8 depending on browser)
   *   - HTTP/2 should be affected by a lesser extent as the calls are multiplexed
   * - The client will wait for all requests in order
   *
   * Leaflet mitigates this issues with ordinary TileLayers, by switching the "src"-property of the img elements.
   * The browser then aborts the requests. You can see all the requests triggering and being aborted with
   * any of the base layers.
   *
   * The VectorGrid plugin uses the fetch-API, so in order to abort requests, they will have to implement
   * AbortController.
   * - see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
   * - related PR: https://github.com/Leaflet/Leaflet.VectorGrid/pull/161
   *
   * This will not happen any time soon, thus this workaround.
   */
  useEffect(() => {
    const handleMove = (evt) => {
      if (evt.flyTo && map.hasLayer(layer)) {
        map.removeLayer(layer)
      }
    }
    const handleMoveEnd = (evt) => {
      layer.addTo(map)
    }
    map.on('move', handleMove)
    map.on('moveend', handleMoveEnd)
    return () => {
      map.off('move', handleMove)
      map.off('moveend', handleMoveEnd)
    }
  }, [map, layer])

  useEffect(() => {
    if (layer) callback(layer)
  }, [callback, layer])

  return null
}
