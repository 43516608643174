import Color from 'lib/color'
import { typeOfGoods } from 'config/typeOfGoods'

export const l3plusTypeOfGoodsMainOrder = [
  'main_0',
  'main_2',
  'main_1',
  'main_3',
  'main_5',
  'main_4',
  'main_6',
  'main_8',
  'main_9',
  'main_7',
  'main_10',
]
export const l3plusTypeOfGoodsMainMapping = {
  main_0: ['0', '0.1', '0.2', '0.3'],
  main_1: ['1', '1.1', '1.2', '1.3', '1.4'],
  main_2: ['2.1', '2.11', '2.111', '2.112', '2.113', '2.114', '2.115', '2.12'],
  main_3: ['2.2', '2.21', '2.22', '2.23', '2.24', '2.25'],
  main_4: ['2.3', '2.31', '2.33', '2.34', '2.35', '2.37', '2.38'],
  main_5: ['2.4', '2.41', '2.42', '2.43', '2.44'],
  main_6: ['2.5', '2.51', '2.52', '2.53', '2.54', '2.55', '2.56', '2.57', '2.58', '2.59', '2.6'],
  main_7: ['3', '3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8', '3.9', '3.9'],
  main_8: ['4', '4.1', '4.2', '4.3', '4.4', '4.5', '4.6', '4.7'],
  main_9: ['5', '5.1', '5.2', '5.3', '5.4', '5.5', '5.6', '5.7'],
  main_10: ['6', '7', '8', '9'],
}

export const l3plusTypeOfGoods = Object.values(l3plusTypeOfGoodsMainMapping).flat()

export const l3plusTypeOfGoodsToMain = Object.entries(l3plusTypeOfGoodsMainMapping).reduce(
  (mapping, [main, togs]) => {
    togs.forEach((tog) => {
      mapping[tog] = main
    })
    return mapping
  },
  {}
)

export const l3plusTypeOfGoodsMainColors = l3plusTypeOfGoodsMainOrder.reduce((colors, id) => {
  const colorTogs = {
    main_0: '0',
    main_1: '1',
    main_2: '2.1',
    main_3: '2.2',
    main_4: '2.3',
    main_5: '2.4',
    main_6: '2.5',
    main_7: '3',
    main_8: '4',
    main_9: '5',
    main_10: '9',
  }
  colors[id] = typeOfGoods.colors[colorTogs[id]]
  return colors
}, {})

export const l3plusTypeOfGoodsToMainColors = l3plusTypeOfGoods.reduce((colors, tog) => {
  colors[tog] = l3plusTypeOfGoodsMainColors[l3plusTypeOfGoodsToMain[tog]]
  return colors
}, {})

export const l3plusTypeOfGoodsColors = l3plusTypeOfGoods.reduce((colors, tog) => {
  const colorTog = l3plusTypeOfGoodsToMain[tog]
  const index = l3plusTypeOfGoodsMainMapping[colorTog].indexOf(tog)
  const length = l3plusTypeOfGoodsMainMapping[colorTog].length

  const baseColor = l3plusTypeOfGoodsMainColors[colorTog]

  const newColor = Color(baseColor).hsl()
  newColor.color[2] += index * ((95 - newColor.color[2]) / length)

  colors[tog] = Color(newColor).hex()
  return colors
}, {})
