import React from 'react'

import Layout from './Layout'
import { Flex } from 'components/atoms/Box'
import { TextHighlight, TextSubHeader } from 'components/atoms/Text'
import ErrorGraphic from './ErrorGraphic'
import api from 'stores/api'

const showCrashReport = (error, errorInfo) => {
  return !errorInfo.componentStack.includes('Highcharts')
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null, crashReport: false }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
      crashReport: errorInfo && showCrashReport(error, errorInfo),
    })
    const report = {
      message: error.message,
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      location: window.location.pathname,
    }

    api.ErrorReport.log(report)
      .then(() => {
        console.log('Error has been reported')
      })
      .catch((err) => {
        console.warn('Could not report error.')
        throw err
      })
  }

  render() {
    if (this.state.crashReport) {
      return (
        <Layout showMainMenu={false}>
          <Flex width="50%" justifyContent="center" alignItems="center" style={{ padding: '5%' }}>
            <ErrorGraphic />
          </Flex>
          <Flex width="50%" flexDirection="column" justifyContent="center">
            <TextHighlight mb={3}>Absturzbericht.</TextHighlight>
            {/* <TextSubHeader mb={2}>Der Fehler wurde übermittelt. Bitte laden Sie die Seite neu.</TextSubHeader> */}
            <TextSubHeader mb={2}>
              Hier ist leider etwas schief gegangen. Bitte laden Sie die Seite neu.
            </TextSubHeader>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </div>
          </Flex>
        </Layout>
      )
    }
    // Normally, just render children
    return this.props.children
  }
}

export default ErrorBoundary
