import { GridLayer, FeatureGroup, SVG, Util } from 'leaflet'
import { Tile } from './Tile'

const defaultOptions = {
  // the default style for all layers
  style: {},
  // styles per layerName
  layerStyles: {},
  updateWhenZooming: false,
  // wether to hold filtered layers in memory
  // when this is true you will be able to dynamically filter layers after loading
  storeAllLayers: false,
  // possible options:
  // filter - a function that should return a boolean wether the passed layer should be drawn
  // layerFilters - a filter function per layer
  // layers - only draw layers in this array
  // hideLayers - an array of layers that should be dropped
  // onEachFeature - will be called on each drawn feature
  // useMarker - either a boolean wether point features should be drawn as a marker or an array of layers for wich to choose a marker
}

export class VectorTiles extends GridLayer {
  initialize(url, options) {
    super.initialize({ ...defaultOptions, ...options, url })
    this.on('tileunload', this.onTileUnload.bind(this))
    this.on('tileload', this.onTileLoad.bind(this))
    this._vtLayers = {}
  }

  onAdd() {
    super.onAdd(arguments)
    this.rootGroup = new FeatureGroup().addTo(this._map)
    this.rootGroup.addEventParent(this)
    this.rootGroup.vtLayers = {}
    this._clipPathsRoot = SVG.create('svg')
    this._clipPaths = SVG.create('defs')
    this._clipPathsRoot.appendChild(this._clipPaths)
    this._container.appendChild(this._clipPathsRoot)
    this._map.on('viewreset', this._scaleClipPaths.bind(this))
    this._map.on('zoom', this._scaleClipPaths.bind(this))
    this.on('load', this._cleanupEmptyFeatures.bind(this))

    // Patch: disable invalidate all tiles on viewprereset
    this._map.off({ viewprereset: this.getEvents().viewprereset }, this)
  }

  onTileUnload(evt) {
    evt.tile._tile.remove()
  }

  onTileLoad(evt) {
    const clipPath = makeClipPath(this._getTilePos(evt.coords), this.getTileSize())
    clipPath.style.position = this._level.el.style.position
    clipPath.style.transform = this._level.el.style.transform
    clipPath.setAttribute('id', this.getClipId(evt.coords))
    this._clipPaths.appendChild(clipPath)
  }

  /** on any change of the view the clippaths will have to be scaled and traslated */
  _scaleClipPaths() {
    for (const clipPath of this._clipPaths.children) {
      clipPath.style.position = this._level.el.style.position
      clipPath.style.transform = this._level.el.style.transform
    }
  }

  createTile(coords, done) {
    const tile = new Tile(coords, this._getTilePos(coords), this.getTileSize(), this.options)
    tile.on('layeradd', this.onLayerAdd.bind(this))
    tile.on('layerremove', this.onLayerRemove.bind(this))
    tile.render(this).then(() => Util.requestAnimFrame(done.bind(coords, null, null)))
    const container = document.createElement('div')
    container._tile = tile
    return container
  }

  onLayerAdd(evt) {
    const { layer, layerName } = evt

    const filtered = this.isFiltered(layer)
    if (!this.options.storeAllLayers && filtered) return

    let vtLayer = this.getVtLayer(layerName)
    // create new vtLayer if necessary
    if (!vtLayer) {
      vtLayer = new FeatureGroup().addTo(this.rootGroup)
      this.rootGroup.vtLayers[layerName] = vtLayer
      vtLayer.features = {}
      extendSetStyle(vtLayer)
      if (layer.options.interactive) {
        extendEvents(vtLayer, 'vtLayer', vtLayer)
      }
    }

    const featureId = this.options.getFeatureId?.(layer) ?? Util.stamp(layer)
    let feature = this.getFeature(layerName, featureId)
    // create new feature if necessary
    if (!feature) {
      feature = new FeatureGroup()
      feature._featureId = featureId
      feature._vtLayerName = layerName
      feature.properties = layer.properties
      feature.zoom = layer.zoom
      vtLayer.features[featureId] = feature
      keepCount(feature)
      extendSetStyle(feature)
      if (layer.options.interactive) {
        extendEvents(feature, 'feature', feature)
      }
      this.options.onEachFeature && this.options.onEachFeature(feature)
    }
    layer.setStyle(this.getStyle(layer))
    layer._initialOptions = Object.assign({}, layer.options)
    layer.setStyle(feature._overriddenStyles)
    layer.addTo(feature)
    if (filtered) {
      feature.removeFrom(vtLayer)
    } else {
      feature.addTo(vtLayer)
    }
  }

  onLayerRemove(evt) {
    const { layer, layerName } = evt
    const featureId = this.options.getFeatureId?.(layer) ?? Util.stamp(layer)
    const feature = this.getFeature(layerName, featureId)
    if (feature) {
      feature.removeLayer(layer)
    }
  }

  /**
   * Lazily cleanup features when all layers are loaded
   * This keeps features alive in between zooms so custom styles are retained
   */
  _cleanupEmptyFeatures() {
    this.eachVtLayer((vtLayer) => {
      for (const feature of Object.values(vtLayer.features)) {
        if (feature._layerCount === 0) {
          vtLayer.removeLayer(feature)
          delete vtLayer.features[feature._featureId]
        }
      }
    })
  }

  getVtLayer(layerName) {
    return this.rootGroup.vtLayers[layerName]
  }

  getFeature(layerName, id) {
    const vtLayer = this.getVtLayer(layerName)
    if (vtLayer) return vtLayer.features[id]
  }

  eachVtLayer() {
    this.rootGroup.eachLayer.apply(this.rootGroup, arguments)
  }

  eachFeature() {
    this.eachVtLayer((layer) => layer.eachLayer.apply(layer, arguments))
  }

  eachLayer() {
    this.eachFeature((layer) => layer.eachLayer.apply(layer, arguments))
  }

  resetStyle(layer) {
    if (!layer) {
      // reset all layers
      this.rootGroup.eachLayer((layer) => this.resetStyle(layer))
    } else {
      if (layer.eachLayer) {
        // is FeatureGroup
        layer._overriddenStyles = {}
        layer.eachLayer((layer) => this.resetStyle(layer))
      } else {
        // is Layer
        layer.setStyle({ ...layer._initialOptions, ...this.getStyle(layer) })
      }
    }
  }

  getStyle(layer) {
    const layerName = layer._vtLayerName
    let style = this.options.layerStyles[layerName] || this.options.style
    return typeof style === 'function' ? style(layer) : style
  }

  setStyle(style, layerStyles) {
    if (style) this.options.style = style
    if (layerStyles) this.options.layerStyles = layerStyles
    this.resetStyle()
  }

  setFilter(filter, layerFilters) {
    if (filter) this.options.filter = filter
    if (layerFilters) this.options.layerFilters = layerFilters
    this.eachVtLayer((vtLayer) => {
      for (const feature of Object.values(vtLayer.features)) {
        if (this.isFiltered(feature)) {
          feature.removeFrom(vtLayer)
        } else {
          this.resetStyle(feature)
          feature.addTo(vtLayer)
        }
      }
    })
  }

  /** returns wether the feature shall not be displayed */
  isFiltered(feature) {
    const layerName = feature._vtLayerName
    let filtered = false
    if (this.options.filter && !this.options.filter(feature)) {
      filtered = true
    }
    if (this.options.layerFilters?.[layerName] && !this.options.layerFilters[layerName](feature)) {
      filtered = true
    }
    return filtered
  }

  /**
   * Patch: Tiles need to be invalidated when the tileZoom changes
   * This is necessary as we have disabled the viewprereset (see this.onAdd)
   * event where all tiles will be invalidated on any viewprereset (e.g.
   * fractional zoom)
   */
  _setView() {
    const zoom = arguments[1]
    let tileZoom = Math.round(zoom)
    if (
      (this.options.maxZoom !== undefined && tileZoom > this.options.maxZoom) ||
      (this.options.minZoom !== undefined && tileZoom < this.options.minZoom)
    ) {
      tileZoom = undefined
    } else {
      tileZoom = this._clampZoom(tileZoom)
    }

    var tileZoomChanged = tileZoom !== this._tileZoom

    if (tileZoomChanged) {
      this._invalidateAll()
    }
    return super._setView.apply(this, arguments)
  }

  getClipId(coords) {
    const tilePos = this._getTilePos(coords)
    const tileSize = this.getTileSize()
    return `clip-${this._leaflet_id}-${tilePos.x}-${tilePos.y}-${tileSize.x}-${tileSize.y}`
  }

  getScale() {
    const currentZoom = this._map.getZoom()
    const tileZoom = this._level.zoom
    return this._map.getZoomScale(currentZoom, tileZoom)
  }

  getTranslate() {
    const currentZoom = this._map.getZoom()
    const center = this._map.getCenter()
    const newPixelOrigin = this._map._getNewPixelOrigin(center, currentZoom)
    const scale = this.getScale()
    const origin = this._level.origin
    const translate = origin.multiplyBy(scale).subtract(newPixelOrigin).round()
    return translate
  }
}

function makeClipPath(tilePos, tileSize) {
  const clipPath = SVG.create('clipPath')
  const rect = SVG.create('rect')
  rect.setAttribute('x', tilePos.x)
  rect.setAttribute('y', tilePos.y)
  rect.setAttribute('width', tileSize.x)
  rect.setAttribute('height', tileSize.y)
  clipPath.appendChild(rect)
  return clipPath
}

const INTERACTIVE_EVENTS = ['click', 'dblclick', 'mousedown', 'mouseup', 'mouseover', 'mouseout']
function extendEvents(evented, name, obj) {
  evented.on(INTERACTIVE_EVENTS.join(' '), (evt) => (evt[name] = obj))
}

function extendSetStyle(featureGroup) {
  const originalSetStyle = featureGroup.setStyle
  featureGroup.setStyle = function (style) {
    this._overriddenStyles = { ...this._overriddenStyles, ...style }
    return originalSetStyle.apply(this, arguments)
  }
}

function keepCount(featureGroup) {
  featureGroup._layerCount = 0
  featureGroup.on('layeradd', () => (featureGroup._layerCount += 1))
  featureGroup.on('layerremove', () => (featureGroup._layerCount -= 1))
}
