import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import api from 'stores/api'
import { useAuthStore } from 'stores/AuthStore'
import LoginForm from 'components/LoginForm'
import LoginCardWrapper from 'components/LoginCardWrapper'
import Layout from 'components/Layout'

export const LogoutModule = () => {
  const [resetCurrentUser] = useAuthStore((state, actions) => [actions.resetCurrentUser])

  useEffect(() => {
    api.Auth.logout().then(() => {
      resetCurrentUser()
    })
  }, [resetCurrentUser])

  return null
}

export const LoginModule = () => {
  const location = useLocation()
  const locationState = location.state || {}

  let from = locationState.from && locationState.from.pathname ? locationState.from.pathname : '/home'
  if (from.startsWith('/logout')) {
    from = '/home'
  } else if (locationState.from && typeof locationState.from.search === 'string') {
    from += locationState.from.search
  }
  const showMessage = locationState.showMessage || ''

  const [redirectToReferrer, setRedirectToReferrer] = useState(false)

  // remove ssoConfig, to allow normal login after sso logout
  localStorage.removeItem('ssoConfig')

  if (redirectToReferrer === true) {
    return <Navigate to={from} replace="true" />
  }

  return (
    <Layout showMainMenu={false}>
      <LoginCardWrapper logoTop="riwis">
        <LoginForm setRedirectToReferrer={setRedirectToReferrer} showMessage={showMessage} />
      </LoginCardWrapper>
    </Layout>
  )
}
