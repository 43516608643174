import riwisColors from 'config/colors/riwis-colors.json'

import typography from './shared/typography'
import sizes, { baseSizes as space } from './shared/sizes'
import { breakpoints, zIndices, radii, opacity, borders, shadows } from './shared/misc'
import { rgb } from 'polished'

export default {
  name: 'themeOne',
  breakpoints,
  zIndices,
  radii,
  opacity,
  borders,
  ...typography,
  sizes,
  shadows,
  space,
  colors: {
    // Palette
    primary: riwisColors['riwis-brand']['blue-light'],
    primaryBackground: 'white',
    secondary: riwisColors['riwis-brand']['blue-dark'],
    tertiary: '#effc70',
    // tertiary: '#868e96',

    orange: riwisColors['riwis-orange'].light,
    red: riwisColors['riwis-red'].light,
    lightgreen: riwisColors['riwis-lightgreen'].light,
    darkgreen: riwisColors['riwis-darkgreen'].light,
    blue: riwisColors['riwis-blue'].light,
    violet: riwisColors['riwis-violet'].light,
    fuchsia: 'rgb(255, 0, 255)',
    grey: riwisColors['riwis-lightgrey'].dark,

    // Status
    success: riwisColors['riwis-darkgreen'].dark,
    positive: riwisColors['riwis-darkgreen'].dark,
    warning: riwisColors['riwis-yellow'].light,
    negative: riwisColors['riwis-red'].light,
    danger: riwisColors['riwis-red'].light,
    info: riwisColors['riwis-darkgreen'].light,
    inactiveText: '#979797',
    inactiveBackground: '#d7d7d7',
    lightGrey: '#dee2e6',
    mediumGrey: '#495057',
    darkGrey: '#343a40',
    // Monochrome
    lightest: '#FFF',
    lighter: '#F8F8F8',
    light: '#F3F3F3',
    mediumlight: '#EEE',
    medium: '#DDD',
    mediumdark: '#AAA',
    dark: '#454545',
    darker: '#454545',
    darkest: '#454545',
    // dark: '#666',
    // darker: '#444',
    // darkest: '#333',
    // utils
    border: 'rgba(0,0,0,.1)',
  },
  linkHover: riwisColors['riwis-blue'].light,
  primaryIsDark: true,
  // bodyBackground: riwisColors['riwis-lightgrey'].dark,
  bodyBackground: rgb(240, 240, 240),
  sidebarBackground: rgb(240, 240, 240),
  tableHeader: riwisColors['riwis-brand']['blue-dark'],
  table: {
    headerColor: 'white',
    headerBg: riwisColors['riwis-brand']['blue-dark'],
  },
  mainMenu: {
    background: 'white',
    foreground: riwisColors['riwis-brand']['blue-dark'],
    foregroundHover: '#effc70',
    width: '40px',
  },
}

/**
 *
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: map-get($riwis-blue, main) !default;
$cyan: map-get($riwis-cyan, main) !default;
$indigo: #3f51b5 !default;
$purple: map-get($riwis-violet, main) !default;
$pink: #e91e63 !default;
$red: map-get($riwis-red, main) !default;
$orange: map-get($riwis-orange, main) !default;
$yellow: map-get($riwis-yellow, main) !default;
$green: map-get($riwis-lightgreen, main) !default;
$teal: map-get($riwis-darkgreen, main) !default;

 */
