import React from 'react'
import { useTranslation } from 'react-i18next'
import Slider from 'rc-slider'

import { isEqualPoint } from 'lib/geojson'
import { useNumbersFormats } from 'config/numbersFormats'
import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'

import { useEditZoneStore } from './Store'
import { useGeotoolsStore } from '../Zones/Store'

export const EditCircleControl = () => {
  const { t } = useTranslation()
  const [{ collection }] = useGeotoolsStore()
  const numbersFormats = useNumbersFormats()
  const [{ zone, drawMode }, { setParams, setDrawMode }] = useEditZoneStore()
  const { radius, center } = zone.properties.creationParams

  const isOnProjectCenter = isEqualPoint(center, collection.meta.locationGeometry)

  return (
    <>
      <Slider
        id="zone-weight"
        value={radius}
        min={50}
        max={30_000}
        step={50}
        onChange={(radius) => setParams({ radius })}
      />
      <div style={{ textAlign: 'center' }}>
        <span>{numbersFormats.roundedFraction0.format(radius)}m</span>
      </div>
      <Button
        onClick={() => setParams({ center: collection.meta.locationGeometry })}
        disabled={isOnProjectCenter}
        title={
          isOnProjectCenter ? t('geotools.circle.resetCenterDisabled') : t('geotools.circle.resetCenterInfo')
        }
      >
        {t('geotools.circle.resetCenter')}
      </Button>
      <Checkbox
        checked={drawMode}
        onChange={(evt) => setDrawMode(evt.target.checked)}
        label={t('geotools.circle.drawMode')}
      />
    </>
  )
}
