import React from 'react'
import { useTranslation } from 'react-i18next'

import Button from 'components/atoms/Button'

import { useEditZoneStore } from './Store'
import { useGeotoolsStore } from '../Zones/Store'

export const EditSelectionControl = () => {
  const { t } = useTranslation()
  const [, { updateZoneGeometry }] = useGeotoolsStore()
  const [{ zone }] = useEditZoneStore()
  const { ids, names } = zone.properties.creationParams

  const handleTransform = async () => {
    const polygon = zone.geometry
    const creationMethod = 'draw'
    const creationParams = {}
    await updateZoneGeometry(zone.id, { polygon, creationMethod, creationParams })
  }

  return (
    <>
      <Button mb={2} onClick={handleTransform}>
        {t(`geotools.select.transform`)}
      </Button>
      <p>{t(`geotools.select.featuresSelected`, { count: ids.length })}</p>
      <ul>
        {names.filter(Boolean).map((name) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </>
  )
}
