import { useEffect } from 'react'

export default (onTab) => {
  const testTab = (evt) => {
    if (evt.keyCode === 9) {
      onTab()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', testTab, false)
    return () => {
      document.removeEventListener('keydown', testTab, false)
    }
  })
}
