import { useMemo } from 'react'
import { polyFromBounds } from 'lib/bounds-cache/polyutils'

import { useLeaflet } from '../components/LeafletMap'

export { useLeaflet }
export const useBounds = () => useLeaflet().getBounds().toBBoxString()
export const useBoundsArray = () => {
  const bounds = useBounds()
  return useMemo(() => bounds.split(',').map(Number), [bounds])
}
export const useBoundsPoly = () => {
  const bounds = useBoundsArray()
  return useMemo(() => polyFromBounds(bounds), [bounds])
}
export const useCenter = () => useLeaflet().getCenter()
export const useZoom = () => useLeaflet().getZoom()
export const useViewport = () => {
  const zoom = useZoom()
  const center = useCenter()
  const viewport = useMemo(() => ({ zoom, center }), [zoom, center])
  return viewport
}

export { usePaneOptions } from '../components/Pane'
