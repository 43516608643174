import { numToAlpha } from 'lib/util'

export const generateZoneName = (t, type, opts) => {
  if (type === 'circle') {
    const { radius, zoneNum } = opts
    return `Zone ${numToAlpha(zoneNum)} ${Math.round(radius)}m`
  }

  if (type === 'fzr') {
    const { mode, rangetype, range } = opts
    const displayRangeFrom = rangetype === 'time' ? Math.round(range[0] / 60) : range[0]
    const displayRangeTo = rangetype === 'time' ? Math.round(range[1] / 60) : range[1]

    return `${t(`geotools.fzr.modes.${mode}`)} ${displayRangeFrom} - ${displayRangeTo} ${t(
      `geotools.fzr.unitsShort.${rangetype}`
    )}`
  }

  if (type === 'select') {
    const { layer, ids, names } = opts
    if (opts.names.filter(Boolean).length > 0) {
      return `${t(`geotools.select.layers.${layer}`)}: ${names.filter(Boolean).join(', ')}`
    } else {
      return `${t('geotools.select.defaultName', {
        count: ids.length,
        type: t(`geotools.select.layers.${layer}`),
      })}`
    }
  }

  if (type === 'draw') {
    const { zoneNum } = opts
    return `Zone ${numToAlpha(zoneNum + 1)}`
  }

  throw Error(`Geotools type "${type}" not defined.`)
}
