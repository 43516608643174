import React, { useContext, useEffect, createContext } from 'react'
import { layersControlContext } from './LayersControl'
import { useAddToMap } from './LeafletMap'

export const baseLayerContext = createContext({ checked: true })

/** Wrap a layer in an BaseLayer
 * BaseLayers need to be wrapped in a LayerControl where they will be displayed.
 * @param {String} name - The displayed name
 * @param {Boolean} checked - If the layer is initially displayed on the map. (False by default)
 */
export const BaseLayer = ({ name, checked = false, children }) => {
  const layersControl = useContext(layersControlContext)
  return (
    <baseLayerContext.Provider value={{ layersControl, checked, name }}>{children}</baseLayerContext.Provider>
  )
}

/** Layers that can be used in an BaseLayer implement this hook (currently only TileLayer)
 * It needs no be applied after other hooks that append layers to a map
 * so the layer gets appended or removed based on the checked value.
 */
export const useBaseLayer = (layer) => {
  const { layersControl, checked, name } = useContext(baseLayerContext)
  useAddToMap(layer, checked)

  useEffect(() => {
    if (layersControl == null) return
    layersControl.addBaseLayer(layer, name)
    return () => {
      layersControl.removeLayer(layer)
    }
  }, [layer, layersControl, name])
}
