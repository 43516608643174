import { createDataCache } from 'lib/cache'

import api from 'stores/api'

const dataSources = [
  'data_de.st_purchasing_power.centrality',
  'data_de.st_purchasing_power.purchasing_power_index',
  'data_de.st_employee.unemployment_percentage',
]

// returns dataOptions of current study year and population 3 years ago for population developement
const getDataOptions = (gacs, year, dataSources = ['data_de.st_population.population_total']) => {
  const dataOptions = {
    format: 'flat',
    classifyValues: false,
    failOnAuthz: true,
    time: {
      from: year,
      to: year,
    },
    dataSources,
    gacs,
    collections: ['highstreetReport'],
  }
  return dataOptions
}

const conditionalsShoppingCenters = [{ name: 'gac' }, { name: 'year' }]
const getterShoppingCenters = (gac, year) => {
  return api.HighstreetReport.getShoppingCenterByGacAndYear({ gac, year }).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsHighstreets = [{ name: 'gac' }, { name: 'year' }]
const getterHighstreets = (gac, year) => {
  return api.HighstreetReport.getHighstreetsByGacAndYear({ gac, year }).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsShops = [{ name: 'year' }]
const getterShops = (year) => {
  return api.HighstreetReport.getShops(year).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsRents = [{ name: 'year' }]
const getterRents = (year) => {
  return api.HighstreetReport.getRents(year).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsRetailScores = [{ name: 'year' }]
const getterRetailScores = (year) => {
  return api.HighstreetReport.getReatilScores(year).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsSociodemographicData = [{ name: 'gac' }, { name: 'year' }]
const getterSociodemographicData = (gac, year) => {
  return api.Data.getData(getDataOptions([gac], year, dataSources)).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsPopulation = [{ name: 'gac' }, { name: 'year' }]
const getterPopulation = (gac, year) => {
  return api.Data.getData(getDataOptions([gac], year)).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsPopulationPast = [{ name: 'gac' }, { name: 'year' }]
const getterPopulationPast = (gac, year) => {
  return api.Data.getData(getDataOptions([gac], year)).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsLocations = []
const getterLocations = () => {
  return api.Areas.byType('highstreetReport', true).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

const conditionalsDocumentList = [{ name: 'type' }]
const getterDocumentList = (type) => {
  return api.Downloads.getDocuments(type).then((result) => {
    if (result.data.status && result.data.status !== 'success') {
      throw new Error(result.data.messages)
    }
    return result.data.data
  })
}

export const [getShoppingCenters, bustShoppingCenters] = createDataCache(
  conditionalsShoppingCenters,
  getterShoppingCenters,
  {
    name: 'HighstreetReportShoppingCenters',
  }
)

export const [getHighstreets, bustHighstreets] = createDataCache(conditionalsHighstreets, getterHighstreets, {
  name: 'HighstreetReportHighstreets',
})

export const [getShops, bustShops] = createDataCache(conditionalsShops, getterShops, {
  name: 'HighstreetReportShops',
})

export const [getRents, bustRents] = createDataCache(conditionalsRents, getterRents, {
  name: 'HighstreetReportRents',
})

export const [getRetailScores, bustRetailScores] = createDataCache(
  conditionalsRetailScores,
  getterRetailScores,
  {
    name: 'HighstreetReportRetailScores',
  }
)

export const [getSociodemographicData, bustSociodemographicData] = createDataCache(
  conditionalsSociodemographicData,
  getterSociodemographicData,
  {
    name: 'HighstreetReportSociodemographicData',
  }
)

export const [getPopulation, bustPopulation] = createDataCache(conditionalsPopulation, getterPopulation, {
  name: 'HighstreetReportPopulation',
})

export const [getPopulationPast, bustPopulationPast] = createDataCache(
  conditionalsPopulationPast,
  getterPopulationPast,
  {
    name: 'HighstreetReportPopulationPast',
  }
)

export const [getLocations, bustLocations] = createDataCache(conditionalsLocations, getterLocations, {
  name: 'HighstreetReportLocations',
})

export const [getDocumentList, bustDocumentList] = createDataCache(
  conditionalsDocumentList,
  getterDocumentList,
  {
    name: 'HighstreetReportDocumentList',
  }
)
