import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, color, variant, layout, position } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { darken, lighten, getLuminance } from 'polished'

import { easing } from 'components/theming/shared/animation'
import typography from 'components/theming/shared/typography'

const Text = styled.span`
  display: inline-block;
  vertical-align: top;
`

const Loading = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  opacity: 0;
`

const StyledButton = styled.button`
  border-radius: ${(props) => (props.rounded ? '0.4rem' : props.borderRadius ? props.borderRadius : 0)};
  font-family: ${typography.fonts.headingNew};
  cursor: pointer;
  /* display: inline-block; */
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 150ms ease-out;
  vertical-align: top;
  white-space: nowrap;
  user-select: none;
  opacity: 1;
  margin: 0;
  line-height: 1;
  ${variant({
    prop: 'sizeVariant',
    variants: {
      extraSmall: {
        fontSize: 'sm',
        fontWeight: 'regular',
        px: 2,
        py: 1,
      },
      small: {
        fontSize: 'sm',
        fontWeight: 'semibold',
        px: 3,
        py: 2,
      },
      medium: {
        fontSize: 'md',
        fontWeight: 'bold',
        px: 5,
        py: 2,
      },
      large: {
        fontSize: 'lg',
        fontWeight: 'bold',
        px: 6,
        py: 3,
      },
    },
  })}
  ${(props) =>
    variant({
      prop: 'typeVariant',
      variants: {
        default: {
          color:
            getLuminance(themeGet(`colors.${props.appearance}`, props.appearance)(props)) > 0.325 // 0.179
              ? themeGet(`colors.darkest`, 'black')(props)
              : themeGet(`colors.lightest`, 'white')(props),
          bg: props.appearance,
          border: props.border || 0,
          '&:hover': {
            color: themeGet(`colors.tertiary`, 'black')(props),
          },
          '&:active': {
            color: themeGet(`colors.tertiary`, 'black')(props),
            bg: themeGet(`colors.success`, 'black')(props),
          },
        },
        outline: {
          color: props.appearance,
          bg: 'transparent',
          borderWidth: props.borderWidth || '2px',
          borderStyle: 'solid',
          borderColor: props.appearance,
          '&:hover': {
            color: themeGet(`colors.tertiary`, 'black')(props),
            bg: lighten(0.5, themeGet(`colors.${props.appearance}`, props.appearance)(props)),
          },
        },
        text: {
          color: props.appearance,
          bg: 'transparent',
          border: 0,
          '&:hover': {
            color: lighten(0.15, themeGet(`colors.${props.appearance}`, props.appearance)(props)),
            bg: lighten(0.8, 'black'),
          },
        },
        contextMenu: {
          color: props.appearance,
          bg: 'white',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: props.appearance,
          '&:hover': {
            color: lighten(0.15, themeGet(`colors.${props.appearance}`, props.appearance)(props)),
            bg: darken(0.05, 'white'),
          },
        },
        switchOff: {
          color: props.appearance,
          bg: 'white',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: props.appearance,
          '&:hover': {
            color: lighten(0.15, themeGet(`colors.${props.appearance}`, props.appearance)(props)),
            bg: darken(0.05, 'white'),
          },
        },
      },
    })}
    // need to be positioned after typeVariant definition, e.g. to allow overwrite by "space" properties
    ${position} ${layout} ${space} ${color} ${Text} {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
    transition: transform 700ms ${easing.rubber};
    opacity: 1;
  }

  ${Loading} {
    transform: translate3d(0, 100%, 0);
  }

  svg {
    ${(props) => {
      const isSmall = props.sizeVariant === 'small' || props.sizeVariant === 'extraSmall'
      return props.svgOnly
        ? `
          vertical-align: top;
          margin: 0px;
        `
        : `
          height: ${isSmall ? '14' : '16'}px;
          width: ${isSmall ? '14' : '16'}px;
          vertical-align: top;
          margin-right: ${isSmall ? '4' : '6'}px;
          margin-top: ${isSmall ? '-1' : '-2'}px;
          margin-bottom: ${isSmall ? '-1' : '-2'}px;
        `
    }}
    /* Necessary for js mouse events to not glitch out when hovering on svgs */
    pointer-events: none;
  }

  ${(props) =>
    props.disabled &&
    `
      cursor: not-allowed !important;
      opacity: 0.5;
    `}

  ${(props) =>
    props.isUnclickable &&
    `
      cursor: default !important;
      pointer-events: none;
    `}

  ${(props) =>
    props.isLoading &&
    `
      cursor: progress !important;
      opacity: 0.7;

      ${Loading} {
        transition: transform 700ms ${easing.rubber};
        transform: translate3d(0, -50%, 0);
        opacity: 1;
      }

      ${Text} {
        transform: scale3d(0, 0, 1) translate3d(0, -100%, 0);
        opacity: 0;
      }
    `}

  ${(props) =>
    props.containsIcon &&
    `
      svg {
        display: block;
        margin: 0;
      }
      padding: ${props.sizeVariant === 'small' ? '7' : '12'}px;
    `}
`

const StyledLink = StyledButton.withComponent('a')

const Button = ({ isDisabled, isLoading, loadingText, isLink, children, ...props }) => {
  let SelectedButton = isLink ? StyledLink : StyledButton
  return (
    <SelectedButton isLoading={isLoading} disabled={isDisabled} {...props}>
      <Text>{children}</Text>
      {isLoading && <Loading>{loadingText || 'Loading...'}</Loading>}
    </SelectedButton>
  )
}

Button.propTypes = {
  isLoading: PropTypes.bool,
  /**
   When a button is in the loading state you can supply custom text
  */
  loadingText: PropTypes.node,
  /**
   Buttons that have hrefs should use <a> instead of <button>
  */
  isLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  // appearance: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  sizeVariant: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'large']),
  typeVariant: PropTypes.oneOf(['default', 'outline', 'text', 'contextMenu', 'switchOff']),
  isDisabled: PropTypes.bool,
  /**
   Prevents users from clicking on a button multiple times (for things like payment forms)
  */
  isUnclickable: PropTypes.bool,
  /**
   Buttons with icons by themselves have a circular shape
  */
  containsIcon: PropTypes.bool,
  rounded: PropTypes.bool,
  type: PropTypes.string,
  appearance: PropTypes.string,
}

Button.defaultProps = {
  isLoading: false,
  loadingText: null,
  isLink: false,
  isDisabled: false,
  isUnclickable: false,
  containsIcon: false,
  rounded: true,
  sizeVariant: 'small',
  typeVariant: 'default',
  type: 'button',
  appearance: 'primary',
}

export default Button
