import { memo, useState, useEffect, useRef } from 'react'
import L from 'leaflet'

import { usePaneOptions } from './Pane'
import { useLayerHelper } from '../hooks/useLayerHelper'
import { useGeoJSONEvents } from '../hooks/useEvents'

export const GeoJSON = memo(
  ({ data, onEachFeature, style, children = null, pointToLayer, filter, callback, onChange, ...props }) => {
    // const [layer, setLayer] = useState(null)
    const [layer, setLayer] = useState(L.geoJSON())
    const creatingNewLayer = useRef(false)
    useGeoJSONEvents(layer, props)
    useLayerHelper(layer)
    const paneOptions = usePaneOptions()

    useEffect(() => {
      creatingNewLayer.current = true
      setLayer(L.geoJSON(null, { onEachFeature, pointToLayer, style, ...paneOptions }))
    }, [onEachFeature, style, pointToLayer, paneOptions])

    useEffect(() => {
      if (creatingNewLayer.current) {
        return
      }
      if (!layer) return
      layer.clearLayers()
      if (!data) return
      const collection = makeFeatureCollection(data)
      layer.options.filter = filter
      layer.addData(collection)
      typeof onChange === 'function' && onChange(layer)
    }, [layer, data, filter, onChange])

    useEffect(() => {
      /**  if data and pointToLayer has changed at the same time, both effects above trigger.
       *  (or any other combination of the two effect dependencies)
       *  As a result the old layer rerenders with new data and in a second cycle the new pointToLayer gets
       * created and filled with data again (statemanipulation setLayer in first effect)
       *
       * resetting creatingNewLayer to false has to happen in an extra effect without dependencies
       * because otherwise a newly created layer will not get the old data, if only i.e. pointToLayer changed
       */
      creatingNewLayer.current = false
    })

    useEffect(() => {
      typeof callback === 'function' && callback(layer)
      return () => {
        typeof callback === 'function' && callback(null)
      }
    }, [layer, callback])
    return children
  }
)

function makeFeatureCollection(data) {
  if (Array.isArray(data)) return { type: 'FeatureCollection', features: data }
  if (data && data.type === 'Feature') return { type: 'FeatureCollection', features: [data] }
  return data
}
