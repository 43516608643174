import React, { useState } from 'react'
import styled from 'styled-components'
import Slider from 'rc-slider'

import { useControllableState } from 'lib/hooks'
import { clamp } from 'lib/util/math'
import Box, { Flex } from 'components/atoms/Box'
import { TextFormLabel } from 'components/atoms/Text'

export const NumberWithSlider = ({ initialValue, value, onChange, min, max, step, label, hideLabel }) => {
  const [localValue, setValue] = useControllableState(initialValue || value, value, onChange)
  const [numberInputValue, setNumberInputValue] = useState(initialValue || value)

  return (
    <Box>
      <TextFormLabel width="100%" mb={0}>
        <Flex>
          <span style={{ flex: 1 }}>{!hideLabel && label}</span>
          <InputStyle
            type="number"
            value={numberInputValue}
            onChange={(evt) => {
              setNumberInputValue(evt.target.value)
              const clamped = clamp(evt.target.value, min, max)
              setValue(clamped)
            }}
            onBlur={(evt) => {
              const clamped = clamp(evt.target.value, min, max)
              setNumberInputValue(clamped)
            }}
            min={min}
            max={max}
            step={step}
          />
        </Flex>
      </TextFormLabel>
      <Slider
        value={localValue}
        onChange={(value) => {
          setValue(value)
          setNumberInputValue(value)
        }}
        min={min}
        max={max}
        step={step}
      />
    </Box>
  )
}

const InputStyle = styled.input`
  text-align: right;
  background: transparent;
  color: inherit;
  font: inherit;
  border: none;
  box-sizing: border-box;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-underline-position: under;

  &:focus {
    background: #fff;
    color: #000;
    text-decoration: none;
  }

  /* Remove the number controls */
  -moz-appearance: textfield;
`
