import React, { useEffect } from 'react'

import { isUndefined } from 'lib/util'
import { pointBuffer } from 'lib/geo/pointBuffer'
import { usePrevious } from 'lib/hooks'

import { useEditZoneStore } from './Store'
import { EditDrawLayer } from './EditDrawLayer'

export const EditCircleLayer = () => {
  const [{ zone }, { setGeometry }] = useEditZoneStore()
  const { creationParams } = zone.properties
  const prevParams = usePrevious(creationParams)

  useEffect(() => {
    const { radius, center } = creationParams
    if (center === null) return
    if (isUndefined(prevParams) || prevParams === creationParams) return
    const coordinates = pointBuffer(center.coordinates, radius)
    setGeometry({ type: 'Polygon', coordinates })
  }, [creationParams, prevParams, setGeometry])

  return <EditDrawLayer />
}
