import L from 'leaflet'

// Fix blurry problem on map move, see https://github.com/Leaflet/Leaflet/issues/6069
L.Draggable.include({
  _updatePosition() {
    var e = { originalEvent: this._lastEvent }

    // @event predrag: Event
    // Fired continuously during dragging *before* each corresponding
    // update of the element's position.
    this.fire('predrag', e)
    L.DomUtil.setPosition(this._element, !L.Browser.retina ? this._newPos.round() : this._newPos)

    // @event drag: Event
    // Fired continuously during dragging.
    this.fire('drag', e)
  },
})
