import { icon } from 'leaflet'

import Color from 'lib/color'

export const PinSVGHtml = (color = 'rgb(255,70,70)') => {
  let svgStyle = `fill-rule:evenodd;clip-rule:evenodd;`
  let pathStyle = `fill:${Color(color).rgb().string()};fill-rule:nonzero;stroke:${Color(color)
    .darken(0.3)
    .rgb()
    .string()};stroke-width:0.8px;`
  let circleStyle = `fill: ${Color(color).lighten(0.4).rgb().string()};`
  return `
      <svg width="100%" height="100%" viewBox="0 0 16 28" style="${svgStyle}" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="g3477" transform="matrix(1.18559,0,0,1.18559,-772.618,-265.427)">
          <path id="path4337-3" style="${pathStyle}" d="M653.69,226.377C652.683,227.451 652.053,229.016 652.077,230.488C652.129,233.564 653.515,234.728 655.732,238.961C656.531,240.824 657.364,242.795 658.157,246.06C658.267,246.541 658.375,246.989 658.425,247.027C658.474,247.066 658.582,246.617 658.692,246.136C659.485,242.871 660.318,240.902 661.117,239.039C663.334,234.806 664.72,233.641 664.772,230.565C664.796,229.093 664.164,227.527 663.157,226.453C662.008,225.226 660.273,224.318 658.425,224.28C656.576,224.242 654.84,225.15 653.69,226.377Z" />
          <circle id="path3049" style="${circleStyle}" cx="658.425" cy="230.602" r="2.428" />
        </g>
      </svg>
    `
}

const degToRad = (deg) => deg * (Math.PI / 180)
const computeX = (angle, cx, r) => cx + r * Math.cos(degToRad(angle))
const computeY = (angle, cy, r) => cy + r * Math.sin(degToRad(angle))

export const SplitCircleSVG = (color = 'rgb(255,70,70)', options = { feature: {}, parts: [] }) => {
  const pathStart = ['M115 115', 'L115 5']
  const pathArc = ['A110 110', '1', '0 1']

  const paths = options.parts
    .sort((a, b) => b.spaceInPercent - a.spaceInPercent)
    .reduce(
      (acc, part) => {
        const d = []
        d.push(...pathStart)
        let pathAngle = 360 - acc.consumedAngle
        if (pathAngle > 180) {
          d.push(...pathArc)
          d.push('115 225')
        }
        pathAngle = 180 - pathAngle

        const x = computeX(pathAngle, 115, 110)
        const y = computeY(pathAngle, 115, 110)
        d.push(...pathArc)
        d.push(y + ' ' + x)
        d.push('z')
        acc.paths = acc.paths.concat(`<path d="${d.join(' ')}" fill="${part.color}"/>`)
        acc.consumedAngle += 360 * part.spaceInPercent
        return acc
      },
      { consumedAngle: 0, paths: `` }
    )
  return `
  <svg width="100%" height="100%" viewBox="0 0 230 230" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <circle cx="115" cy="115" r="118" fill="white" ></circle>
    ${paths.paths}
  </svg>
`
}

export const makeIcon = (color, formObject = PinSVGHtml, options = {}) => {
  const type = options.type
  let iconSize = [18, 35]

  if (type === 'object') {
    const weight = sizeBySpace(options?.feature?.properties?.spaceTotal)
    iconSize = [15 + weight, 15 + weight]
  }
  return new icon({
    iconUrl: 'data:image/svg+xml,' + formObject(color, options),
    iconSize: iconSize, // size of the icon
    iconAnchor: [9, 35], // point of the icon which will correspond to marker's location
    // shadowUrl: leafShadow,
    // shadowSize: [50, 64], // size of the shadow
    // shadowAnchor: [4, 62],  // the same for the shadow
  })
}

export const wrapInFC = (features) => ({ type: 'FeatureCollection', features: features })

export const mapAttribution =
  '&copy; <a href="https://www.bulwiengesa.de" target="_blank" rel="noopener noreferrer">bulwiengesa AG 2019</a>'

export const sizeBySpace = (space) => {
  if (isNaN(space)) {
    return 4
  }
  return space < 2500 ? 4 : space < 5000 ? 5 : space < 10000 ? 6 : space < 25000 ? 7 : 8
}
