export const bgagServicesUrl = process.env.BACKEND_URL

export const appBaseUrl = process.env.PUBLIC_URL ?? '/'

export const appUrl = window.location.protocol + '//' + window.location.host + appBaseUrl

console.debug('RIWIS app configuration:', { bgagServicesUrl, appBaseUrl, appUrl })

console.debug('GIT_BRANCH', process.env.GIT_BRANCH)
console.debug('GIT_VERSION', process.env.GIT_VERSION)
console.debug('GIT_AUTHOR_DATE', process.env.GIT_AUTHOR_DATE)

export default {
  bgagServicesUrl,
  appBaseUrl,
  appUrl,
  stackedContextMenu: false,
  quietStoreLogger: process.env.REACT_APP_QUIET_STORE_LOGGER === 'true',
}
