import { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'

import L from 'leaflet'
import { useAddToMap } from './LeafletMap'

L.Control.Custom = L.Control.extend({
  onAdd: function () {
    return document.createElement('div')
  },
  onRemove: function () {},
})

L.control.custom = function (opts) {
  return new L.Control.Custom(opts)
}

export const Control = ({ position = 'topleft', children = null }) => {
  const [control] = useState(L.control.custom({ position }))
  const [container, setContainer] = useState(null)

  useAddToMap(control)

  useEffect(() => {
    const container = control.getContainer()
    if (container) {
      setContainer(container)
      L.DomEvent.disableClickPropagation(container)
    }
  }, [control])

  useEffect(() => {}, [container])

  if (container) {
    return ReactDOM.createPortal(children, container)
  }
  return null
}
