import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'debounce'

import { bgagServicesUrl } from 'config/appConfig'
import { zIndices } from 'config/zIndices'
import { withPane, BgagVectorTileLayer, useLeaflet } from 'lib/leaflet'

const getTooltipZoomThreshold = (vtLayerName) => {
  return vtLayerName === 'rmOsm' ? 17 : 14
}

export const RetailMarketsObjectsLayer = ({ layer: layerName, filter, onClick, labels = 'automatic' }) => {
  const [layer, setLayer] = useState(null)
  const map = useLeaflet()

  useEffect(() => {
    if (map === null) return
    if (layer === null) return
    const TOOLTIP_ZOOM_FROM = getTooltipZoomThreshold(layerName)

    const updateAllTooltips = () => layer.eachLayer((layer) => updateTooltip(layer, labels))
    const debouncedUpdateAllTooltips = debounce(updateAllTooltips, 200)

    const onMove = () => {
      const zoom = map.getZoom()
      if (zoom >= TOOLTIP_ZOOM_FROM) {
        debouncedUpdateAllTooltips()
      }
    }

    map.on('viewreset', updateAllTooltips)
    map.on('move', onMove)
    return () => {
      map.off('viewreset', updateAllTooltips)
      map.off('move', onMove)
    }
  }, [map, layer, layerName, labels])

  const labelsForNewLayers = useCallback(
    (feature) => {
      feature.on('layeradd', ({ layer }) => {
        layer._map ? updateTooltip(layer, labels) : layer.on('add', () => updateTooltip(layer, labels))
      })
    },
    [labels]
  )

  if (!layerName) return null
  return (
    <BgagVectorTileLayer
      url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${layerName}&v=3`}
      auth
      maxNativeZoom={14}
      useMarker={useMarker}
      style={style}
      layerStyles={layerStyles}
      onEachFeature={labelsForNewLayers}
      filter={filter}
      storeAllLayers={true}
      callback={setLayer}
      onClick={(evt) => onClick?.(evt)}
    />
  )
}

export const RetailMarketsObjectsPane = withPane(RetailMarketsObjectsLayer, 'poi', zIndices.objects)

const useMarker = []

const style = (f) => {
  return {
    weight: 1.5,
    radius: getMarkerSize(f.properties.space),
    fillColor: f.properties.color || '#FF0000',
    fillOpacity: 1,
    color: '#f0f0f0',
  }
}
const layerStyles = {}

const updateTooltip = (layer, labels) => {
  layer.unbindTooltip()
  if (labels === 'always-off') {
    return
  }
  if (labels === 'always-on') {
    bindRmObjectLabel(layer)
    return
  }
  if (labels === 'hover-only') {
    bindRmObjectTooltip(layer)
    return
  }
  if (labels === 'automatic') {
    const map = layer._map
    const zoom = map.getZoom()
    if (zoom < getTooltipZoomThreshold(layer._vtLayerName)) {
      bindRmObjectTooltip(layer)
    } else {
      const bounds = map.getBounds()
      const isInViewbox = bounds.contains(layer.getCenter())
      if (isInViewbox) {
        bindRmObjectLabel(layer)
      }
    }
    return
  }
  throw new Error(`Labels option "${labels}" is not configured`)
}

function bindRmObjectLabel(layer) {
  bindRmObjectTooltip(layer, { permanent: true })
}

function bindRmObjectTooltip(layer, options = {}) {
  if (layer.properties.name && layer.properties.name.length) {
    layer.bindTooltip(layer.properties.name, options)
  }
}

function getMarkerSize(space) {
  if (isNaN(space)) {
    return 4
  }
  return space < 800
    ? 4
    : space < 1500
    ? 6
    : space < 2500
    ? 8
    : space < 5000
    ? 10
    : space < 10000
    ? 12
    : space < 25000
    ? 14
    : 16
}
