import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLeaflet } from 'lib/leaflet'

import { generateZoneName } from './generateZoneName'
import { useMethodStore } from './Store'
import { useGeotoolsStore } from '../Zones/Store'

const CONTINUE_DRAW = false

export const NewDrawLayer = () => {
  const { t } = useTranslation()
  const map = useLeaflet()
  const [, , close] = useMethodStore('draw')
  const [{ collection }, { addZone }] = useGeotoolsStore()

  useEffect(() => {
    const onCreate = (evt) => {
      const zoneNum = collection.meta.featureCount + 1
      const geoJSON = evt.layer.toGeoJSON()
      const creationMethod = 'draw'
      const name = generateZoneName(t, creationMethod, { zoneNum })
      addZone({ creationMethod, name, polygon: geoJSON.geometry })
      evt.layer.remove()
      !CONTINUE_DRAW && close()
    }
    map.on('pm:create', onCreate)
    map.pm.enableDraw('Polygon', { continueDrawing: CONTINUE_DRAW, allowSelfIntersection: false })

    return () => {
      map.off('pm:create', onCreate)
      map.pm.disableDraw()
    }
  }, [t, map, close, addZone, collection])

  return null
}
