import React, { useState } from 'react'

import Box, { Flex } from './atoms/Box'
import Card from './atoms/Card'

import bgagColors from 'config/colors/bgag-colors.json'

import { bgImages } from 'assets/modules/bgImages'

const LoginCardWrapper = ({ children, logoTop, MarketingBox = null, marketingBg = null, BMOBg = null }) => {
  const [image] = useState(() => {
    const images = bgImages()
    return images.length ? images[Math.floor(Math.random() * images.length)] : 'nope.jpg'
  })

  const marketingBoxEnabled = MarketingBox !== null

  return (
    <Box
      className="bg-image-center"
      height="100vh"
      width="100vw"
      p={0}
      style={{
        backgroundImage: `url(${BMOBg ? BMOBg : image})`,
      }}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100vw"
        p={0}
        style={{
          background: `linear-gradient(328.46deg, rgba(207, 222, 64, 0.435) 0.16%, rgba(255, 255, 255, 0) 33.43%),
                       linear-gradient(120.87deg, rgba(0, 105, 124, 0.6) -2.74%, rgba(196, 196, 196, 0) 83.7%)`,
        }}
      >
        <Card
          border="none"
          width={['100%', 3 / 4, 2 / 3]}
          maxWidth={marketingBoxEnabled ? 600 : 320}
          px={0}
          py={0}
          style={{
            overflow: 'hidden',
            boxShadow: '0 1rem 1rem 0 rgba(0,0,0, 0.7)',
          }}
        >
          <Flex width="100%">
            <Box width={marketingBoxEnabled ? ['100%', '100%', '50%'] : '100%'} bg="white">
              <Flex
                p={4}
                style={{ transition: 'all 1s linear' }}
                flexDirection="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                {children}
              </Flex>
            </Box>
            {marketingBoxEnabled && (
              <Box
                display={['none', 'none', 'block']}
                width={[0, 0, '50%']}
                bg={marketingBg || bgagColors['bgag-blue'].dark}
                py={8}
              >
                <Flex
                  height="100%"
                  style={{ transition: 'all 1s linear' }}
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  borderLeft={`1px solid ${bgagColors['bgag-blue'].dark}`}
                  px={4}
                >
                  <MarketingBox />
                </Flex>
              </Box>
            )}
          </Flex>
        </Card>
      </Flex>
    </Box>
  )
}

export default LoginCardWrapper
