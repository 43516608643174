import React, { forwardRef } from 'react'
import Box from './Box'

const Card = forwardRef(({ children, ...props }, ref) => {
  if (typeof props.py !== 'undefined') {
    delete props.pb
    delete props.pt
  }
  return (
    <Box ref={ref} {...props}>
      {children}
    </Box>
  )
})

Card.defaultProps = {
  px: 2,
  pt: 3,
  pb: 3,
  borderRadius: 'lg',
  border: '1px solid #f6f6f6',
  boxShadow: '0 2px 4px rgba(0, 0, 0, .125)',
}

export default Card
