export const OBJ_SOURCES = ['rmEhinfo', 'rmHahnimmo', 'rmOsm', 'rmTradeDimensions']
export const PCE_SOURCES = ['buildingblock', 'municipality', 'postcode']

export const PCE_TYPE_OF_GOODS = [
  '1',
  '2',
  '1.01',
  '1.02',
  '1.1',
  '1.2',
  '1.21',
  '1.22',
  '1.3',
  '1.31',
  '1.32',
  '1.4',
  '1.41',
  '1.42',
  '2.1',
  '2.11',
  '2.111',
  '2.112',
  '2.113',
  '2.114',
  '2.115',
  '2.12',
  '2.2',
  '2.21',
  '2.22',
  '2.23',
  '2.24',
  '2.25',
  '2.26',
  '2.3',
  '2.31',
  '2.32',
  '2.33',
  '2.34',
  '2.35',
  '2.36',
  '2.37',
  '2.38',
  '2.4',
  '2.41',
  '2.42',
  '2.43',
  '2.44',
  '2.5',
  '2.51',
  '2.52',
  '2.53',
  '2.54',
  '2.55',
  '2.56',
  '2.57',
  '2.58',
  '2.59',
]

export const HAHNIMMO_PORTFOLIOS = [
  { value: 'AIF_PWF', color: '#f3ac2d' },
  { value: 'FCP', color: '#f3ac2d' },
  { value: 'GRF II', color: '#f3ac2d' },
  { value: 'GRF III', color: '#f3ac2d' },
  { value: 'HGMF I', color: '#f3ac2d' },
  { value: 'HGMF II', color: '#f3ac2d' },
  { value: 'HGRF  IV', color: '#f3ac2d' },
  { value: 'PRIMUS', color: '#f3ac2d' },
  { value: 'PRIMUS_VZWL', color: '#f3ac2d' },
  { value: 'PWF', color: '#f3ac2d' },
  { value: 'RIO', color: '#f3ac2d' },
  { value: 'VZWL', color: '#f3ac2d' },
]
