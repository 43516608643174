import { useState } from 'react'
import L from 'leaflet'
import { useBaseLayer } from './BaseLayer'
import { useAddToMap } from './LeafletMap'
import { usePaneOptions } from './Pane'

export const TileLayer = ({ url, options, children = null }) => {
  const paneOptions = usePaneOptions()
  const [tileLayer] = useState(L.tileLayer(url, { ...paneOptions, ...options }))
  useAddToMap(tileLayer)
  useBaseLayer(tileLayer)
  return children
}
