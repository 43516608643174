import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { keyframes } from 'styled-components'

import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import Box from 'components/atoms/Box'
import ControlSection from 'components/controls/ControlSection'
import Button from 'components/atoms/Button'

import { ControlZones } from './Zones/Control'
import { DraftControls } from './Drafts'
import { useGeotoolsStore } from './Zones/Store'

export const GeotoolsControl = () => {
  const { hasLicence } = useAuthzChecks()
  const [{ isLoading, isFailure, collection }] = useGeotoolsStore()

  if (!hasLicence('geotools')) {
    return null
  }

  return (
    <div style={{ position: 'relative' }}>
      <ControlSection title={'Geotools'}>
        {isLoading && <LoadingOverlay />}
        {isFailure && <GeotoolsError />}
        {!isFailure &&
          (collection === null ? (
            'No project selected'
          ) : (
            <>
              <DraftControls />
              <Box mb={2} />
              <ControlZones />
            </>
          ))}
      </ControlSection>
    </div>
  )
}

const LoadingAnimation = keyframes`
  0% {opacity: .1;}
  50% {opacity: .3;}
  100% {opacity: .1}
`

const LoadingOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  border-radius: 6px;
  background-color: black;
  filter: blur(1px);
  opacity: 0.1;
  animation: ${LoadingAnimation} 1s ease-in-out infinite;
`

const GeotoolsError = () => {
  const { t } = useTranslation()
  const [{ err }, { loadCollection }] = useGeotoolsStore()

  if (err.response?.status === 403) {
    return 'Forbidden'
  }

  return (
    <>
      {t('status.failed')}
      <Button onClick={() => loadCollection()}>{t('actions.actionReset')}</Button>
    </>
  )
}
