import { useRef, useEffect } from 'react'

/** Returns true if the component rendered once already */
export function useDidRender() {
  const didRender = useRef(false)

  useEffect(() => {
    didRender.current = true
  }, [])

  return didRender.current
}

/** Returns true if this is the first render run-through of the component */
export const useIsFirstRender = () => !useDidRender()
