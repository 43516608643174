import React from 'react'
import styled from 'styled-components'

export const MenuIcon = ({
  checked,
  disabled,
  Icon,
  label,
  iconSize = '32px',
  fontSize = '12px',
  padding = '8px',
}) => {
  return (
    <MenuIconStyle checked={checked} disabled={disabled} padding={padding}>
      <div>
        <Icon size={iconSize} />
      </div>
      <div style={{ fontSize }}>{label}</div>
    </MenuIconStyle>
  )
}

const MenuIconStyle = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: ${({ padding }) => padding};
  background-color: ${({ checked }) => (checked ? 'rgba(0, 72, 122, 0.1)' : 'white')};
  border-radius: 3px;
  text-align: center;
  color: ${({ checked }) => (checked ? 'rgb(0,48,73)' : 'rgba(0, 0, 0, 0.5)')};
`
