import React, { useState, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import { Flex } from 'components/atoms/Box'
import { TextHeader, TextHighlight } from 'components/atoms/Text'
import { useMarketDataStateStore, MarketDataSelectors } from 'pages/marketData/stateStore'

export const ItemText = styled(TextHeader)`
  line-height: normal;
  transform: translateY(1px);
  font-family: 'whitney-medium-2', 'whitney-medium-1';
`

const AbsoluteContainer = styled(Flex)`
  position: absolute;
  top: 0;
  left: ${(props) => props.theme.mainMenu.width};
  white-space: nowrap;
  z-index: 100000;
  background: ${(props) => props.theme.mainMenu.foregroundHover};
`
const MainIconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.mainMenu.width};
  height: ${(props) => props.theme.mainMenu.width};
  background: ${(props) => (props.isActive ? props.theme.mainMenu.foregroundHover : 'transparent')};
  &:hover {
    background: ${(props) => props.theme.mainMenu.foregroundHover};
  }
`

const MainMenuItem = ({ title, Icon, route, activeRouteMatch, subitems, onClickOverride, children }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const routeIsActive = route ? pathname === route : pathname.includes(activeRouteMatch)

  const { locations, locationsVisibilityMap } = useMarketDataStateStore(
    MarketDataSelectors.$curCountryLocationData
  )

  const gacs = useMemo(() => {
    return Object.entries(locations).reduce((gacs, [id, location]) => {
      if (locationsVisibilityMap.get(id)) {
        Object.values(location.areas).forEach((area) => {
          if (['10', '20', '30', '107', '108', '109'].includes(area.type) && !gacs.includes(area.gac)) {
            gacs.push(area.gac)
          }
        })
      }
      return gacs
    }, [])
  }, [locations, locationsVisibilityMap])

  const locationCoordinates = useMemo(() => {
    return Object.entries(locations).reduce((coordinates, [id, location]) => {
      const addressLevel = parseInt(location.addressLevel)
      if (!isNaN(addressLevel) && addressLevel > 0 && addressLevel <= 4 && locationsVisibilityMap.get(id)) {
        coordinates.push(location.geometry.coordinates.join('|'))
      }
      return coordinates
    }, [])
  }, [locations, locationsVisibilityMap])

  // const [isOpen, toggleOpen] = useToggle(false)
  const [isOpen, setIsOpen] = useState(false)

  const Submenu = () => {
    if (route) {
      return (
        <AbsoluteContainer height={theme.mainMenu.width} alignItems="center" pl={2} pr={4} cursor="pointer">
          <ItemText>{title}</ItemText>
        </AbsoluteContainer>
      )
    }
    if (subitems)
      return (
        <AbsoluteContainer flexDirection="column" pl={2} pr={6} py={2}>
          <TextHighlight mt={1} mb={3}>
            {title}
          </TextHighlight>
          {subitems.map(({ label, Icon, route, visible = true }, index) => {
            if (visible) {
              if (route.startsWith('/download/') && route !== '/download/documents') {
                const href = route.startsWith('/download/raw-data')
                  ? 'https://www.riwis.de/riwis_online/abfrage/?gacs=' + gacs.join(',')
                  : 'https://www.riwis.de/riwis_online/sbrief/?locations=' + locationCoordinates.join(',')
                return (
                  <a key={index} href={href} target="_blank" rel="noopener noreferrer">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '0.5rem',
                        cursor: 'pointer',
                      }}
                    >
                      <ItemText ml={1}>{label}</ItemText>
                    </div>
                  </a>
                )
              } else {
                return (
                  <NavLink
                    key={index}
                    to={route}
                    style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}
                  >
                    <ItemText ml={1}>{label}</ItemText>
                  </NavLink>
                )
              }
            } else {
              return null
            }
          })}
        </AbsoluteContainer>
      )
    return (
      <AbsoluteContainer minHeight={theme.mainMenu.width} alignItems="center" pl={2} pr={4}>
        <ItemText>{title}</ItemText>
        {children}
      </AbsoluteContainer>
    )
  }

  return (
    <MainIconWrapper
      position="relative"
      my={2}
      isActive={routeIsActive}
      onClick={() => {
        if (onClickOverride) {
          onClickOverride()
        } else if (route) {
          navigate(route)
        }
        setIsOpen(false)
      }}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Icon strokeWidth="0.3" size="25px" color={routeIsActive ? theme.mainMenu.foreground : '#454545'} />
      {isOpen && <Submenu />}
    </MainIconWrapper>
  )
}

export default MainMenuItem
