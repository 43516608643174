/** Turns a geometry object into a GeoJSON Feature */
export function makeFeature(geometry, properties = null) {
  return { type: 'Feature', geometry, properties }
}

/** Turns coordinates for a Polygon into a GeoJSON Feature */
export function makePolygonFeature(coordinates, properties = null) {
  return makeFeature({ type: 'Polygon', coordinates }, properties)
}

/** Turns coordinates for a MultiPolygon into a GeoJSON Feature */
export function makeMultiPolygonFeature(coordinates, properties = null) {
  return makeFeature({ type: 'MultiPolygon', coordinates }, properties)
}

export function isEqualPoint(geometry1, geometry2) {
  const [x1, y1] = geometry1.coordinates
  const [x2, y2] = geometry2.coordinates
  return x1 === x2 && y1 === y2
}
