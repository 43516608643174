import React, { useMemo, useRef, useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import api from 'stores/api'
import useApi from 'stores/useApi'

import useDebounce from 'lib/hooks/useDebounce'
import useInitialFocus from 'lib/hooks/useInitialFocus'
import InputSuggestions from 'components/molecules/InputSuggestions'

import { sortFeatures, reduceFeatures } from './locationHelpers'

const ResultRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 8px 12px 7px;
  cursor: pointer;

  &:hover {
    background-color: rgb(244, 245, 247);
  }

  > div:nth-child(1) {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 1em;
    padding-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div:nth-child(2) {
    color: ${(props) => props.theme.colors.mediumGrey};
    font-size: 0.9em;
    font-weight: 100;
  }
`

export const LocationSearchInput = ({
  choosenLocation,
  setChoosenLocation,
  placeholder,
  isStandalone = false,
  onLocationSelect,
  locationPreset = false,
  country = 'DE',
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const [suggestions, setSuggestions] = useState([])
  const [value, setValue] = useState('_init')

  const debouncedValue = useDebounce(value, 400)
  const setFilterRef = useRef(null)

  const onChangeFilter = useCallback((filter) => {
    setValue(filter)
  }, [])

  let filterPreset = value === '_init' && choosenLocation?.displayedName ? choosenLocation.displayedName : ''

  const [fetchSuggestions, data, isLoading, isError] = useApi(api.Location.suggestions)

  const features = useMemo(
    () =>
      debouncedValue !== '_init' &&
      debouncedValue.length > 1 &&
      !isError &&
      !isLoading &&
      data &&
      data.features
        ? data.features.reduce(reduceFeatures, []).sort(sortFeatures)
        : [],
    [debouncedValue, data, isLoading, isError]
  )

  const onChange = useCallback(
    (evt) => {
      if (typeof evt.target.value?.value !== 'undefined') {
        setChoosenLocation?.({ ...features[evt.target.value.value], curIdx: evt.target.value.value })
      } else {
        setChoosenLocation?.(null)
      }
    },
    [features, setChoosenLocation]
  )

  const onSelect = useCallback(
    (evt) => {
      if (onLocationSelect) {
        if (evt.target.value) {
          evt.target.value.location = { ...features[evt.target.value.value] }
        }
        onLocationSelect(evt)
      }
    },
    [features, onLocationSelect]
  )

  const inputRef = useRef(null)
  useInitialFocus(inputRef)

  useEffect(() => {
    if (debouncedValue !== '_init' && debouncedValue.length > 1 && country !== null) {
      fetchSuggestions({ query: debouncedValue, countries: country })
    }
  }, [debouncedValue, fetchSuggestions, country])

  useEffect(() => {
    if (isError) {
      setSuggestions([
        {
          value: null,
          error: true,
          label: t('locationSearch.error'),
        },
      ])
    } else if (isLoading) {
      setSuggestions([{ value: null, label: t('locationSearch.loading') }])
    } else {
      setSuggestions(
        features.map((feature, idx) => {
          return {
            value: idx,
            label: feature.displayedName,
          }
        })
      )
    }
  }, [features, isError, isLoading, t])

  useEffect(() => {
    if (setFilterRef.current && country && !locationPreset) {
      setFilterRef.current('')
    }
  }, [country, setChoosenLocation, locationPreset])

  const renderCell = useCallback(
    ({ rowData, dataKey }) => {
      let style = { cursor: 'pointer' }

      const feature = rowData.value !== null && rowData?.error !== true ? features[rowData.value] : null

      if (
        choosenLocation &&
        choosenLocation.curIdx === rowData.value &&
        choosenLocation.displayedName === rowData.label
      ) {
        style = { ...style, fontWeight: 600 }
      }

      if (rowData?.error) {
        style.color = theme.colors.danger
        style.whiteSpace = 'normal'
      }

      return {
        style,
        ellipsis: false,
        value: feature ? (
          <ResultRow key={rowData.value}>
            <div>{feature.displayedName}</div>
            <div>
              {t(
                `locationSearch.input.addressLevels.${feature.properties.countryCode}.${feature.addressLevel}`
              )}
            </div>
          </ResultRow>
        ) : (
          <ResultRow key={rowData.label} dangerouslySetInnerHTML={{ __html: rowData.label }}></ResultRow>
        ),
      }
    },
    [choosenLocation, features, t, theme]
  )

  placeholder = placeholder || t('locationSearch.input.placeholder')

  if (isStandalone) {
    return (
      <InputSuggestions
        id="objectsMapSearch"
        placeholder={placeholder}
        appearance="borderless"
        renderCell={renderCell}
        showAll
        listWidth={380}
        entries={suggestions}
        value={value}
        onChange={onChange}
        onChangeFilter={onChangeFilter}
        onSelect={onSelect}
        ref={inputRef}
      />
    )
  } else {
    return (
      <InputSuggestions
        id="objectsMapSearch"
        placeholder={placeholder}
        renderCell={renderCell}
        showAll
        listWidth="auto"
        entries={suggestions}
        value={value}
        onChange={onChange}
        onChangeFilter={onChangeFilter}
        filterPreset={filterPreset}
        onSelect={onSelect}
        ignoreFirstExpand={locationPreset}
        ref={inputRef}
        refSetFilter={setFilterRef}
      />
    )
  }
}
