/** Icons generated by create-react-icons. Don't edit this file directly. **/
import React from 'react'
import PropTypes from 'prop-types'

const LogoRiwis = ({ size = '1em', color = undefined, part = 'icon', ...other }) => {
  other.fill = color || '#00487A'
  return (
    <svg
      viewBox={part === 'icon' ? '0 0 31 40' : part === 'text' ? '38 0 69 40' : '0 0 107 40'}
      height={size}
      // width={size}
      {...other}
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="1.41421"
    >
      {part !== 'text' && (
        <g fillRule="nonzero">
          <path d="M2.87 10.535v16.123l-.011 6.645 4.078-6.905c.507-.86 1.417-1.166 2.041-1.166h18.916V10.535L15.382 2.938 2.87 10.535zM1.509 39.684c-.395 0-.765-.154-1.045-.433C-.001 38.784 0 38.19 0 37.995l.018-11.339V10.272c0-.619.3-1.524 1.148-2.039L14.18.331C14.309.253 14.775 0 15.399 0c.417 0 .827.114 1.184.331l13.016 7.903c.529.321 1.147 1.047 1.147 2.038v15.847c0 .966-.735 1.966-1.966 1.966H9.253L2.927 38.798c-.333.563-.85.886-1.418.886z" />
        </g>
      )}
      {part !== 'icon' && (
        <g fillRule="nonzero">
          <path d="M99.67 17.048c-2.273-.541-4.235-1.009-4.235-2.586 0-1.574 1.567-2.674 3.812-2.674 1.631 0 3.264.539 4.476 1.477.226.189.498.285.808.285.67 0 1.215-.546 1.215-1.216 0-.353-.144-.67-.407-.895-1.663-1.425-3.656-2.119-6.092-2.119-3.957 0-6.721 2.145-6.721 5.216 0 3.722 3.423 4.554 6.442 5.288 2.431.591 4.531 1.102 4.531 2.931 0 1.808-1.559 2.931-4.069 2.931-1.764 0-3.584-.646-4.997-1.777-.267-.189-.544-.278-.874-.278-.765 0-1.363.566-1.363 1.289 0 .354.158.685.443.93 1.738 1.529 3.985 2.303 6.68 2.303 4.306 0 7.088-2.147 7.088-5.472 0-4.031-3.735-4.919-6.737-5.633z" />
          <path d="M87.242 9.54c-.852 0-1.546.644-1.546 1.436v15.485c0 .799.674 1.472 1.472 1.472.833 0 1.509-.661 1.509-1.472V10.976c0-.765-.671-1.436-1.435-1.436z" />
          <path d="M87.168 7.458c1.096 0 1.986-.874 1.986-1.949 0-1.095-.89-1.987-1.986-1.987-1.056 0-1.949.911-1.949 1.987 0 1.075.875 1.949 1.949 1.949z" />
          <path d="M80.857 9.54c-.646 0-1.234.41-1.43.999l-4.005 13.085-4.291-12.98c-.236-.67-.826-1.104-1.502-1.104-.754 0-1.329.402-1.577 1.106l-4.29 12.978-4.005-13.088c-.192-.577-.809-.996-1.467-.996-.846 0-1.435.757-1.435 1.436 0 .151.055.332.079.402l4.88 15.264c.243.773.936 1.291 1.724 1.291h.036c.84 0 1.594-.531 1.834-1.29l4.184-12.91 4.184 12.909c.24.76.964 1.291 1.761 1.291h.036c.831 0 1.554-.518 1.798-1.291l4.88-15.263c.023-.072.079-.254.079-.403 0-.792-.661-1.436-1.473-1.436z" />
          <path d="M51.869 9.54c-.838 0-1.545.658-1.545 1.436v15.485c0 .799.674 1.472 1.472 1.472.832 0 1.509-.661 1.509-1.472V10.976c0-.765-.671-1.436-1.436-1.436z" />
          <path d="M51.796 7.458c1.095 0 1.986-.874 1.986-1.949 0-1.095-.891-1.987-1.986-1.987-1.057 0-1.95.911-1.95 1.987 0 1.075.875 1.949 1.95 1.949z" />
          <path d="M45.778 9.467c-1.964 0-3.645 1.54-4.765 2.937v-1.428c0-.765-.671-1.436-1.436-1.436-.838 0-1.546.658-1.546 1.436v15.485c0 .786.628 1.432 1.436 1.472h.037c.832 0 1.509-.661 1.509-1.472V15.084c1.346-1.997 3.237-2.516 4.587-2.599.864-.04 1.541-.751 1.541-1.619 0-.771-.611-1.399-1.363-1.399z" />
        </g>
      )}
    </svg>
  )
}

LogoRiwis.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default LogoRiwis
