import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { zip } from 'lib/util'
import { rgbToHex } from 'lib/util/colors'
import { TextHeader } from 'components/atoms/Text'
import { ColorAllocator } from 'components/UtilHelper'
import Button from 'components/atoms/Button'
import Select from 'components/atoms/Select'
import { Flex } from 'components/atoms/Box'
import Checkbox from 'components/atoms/Checkbox'

import { useMethodStore } from './Store'
import { generateZoneName } from './generateZoneName'
import { useGeotoolsStore } from '../Zones/Store'

export const ControlNewSelection = () => {
  const { t } = useTranslation()
  const [opts, setOpts, close] = useMethodStore('select')
  const [{ collection }, { addZone }] = useGeotoolsStore()
  const [doUnion, setDoUnion] = useState(true)

  const addZones = () => {
    const creationMethod = 'select'
    const creationParams = opts
    const name = generateZoneName(t, creationMethod, opts)
    addZone({ creationMethod, creationParams, name })
    setOpts({ ids: [], names: [] })
    close()
  }

  const addMultipleZones = () => {
    let index = collection.meta.featureCount
    const { layer, ids, names } = opts
    for (const [id, name] of zip(ids, names)) {
      const creationMethod = 'select'
      const creationParams = { layer, ids: [id], names: [name] }
      const zoneName = generateZoneName(t, creationMethod, creationParams)
      const style = { color: rgbToHex(ColorAllocator(index)) }
      addZone({ creationMethod, creationParams, style, name: zoneName })
      index++
    }
    setOpts({ ids: [], names: [] })
    close()
  }

  return (
    <div style={{ width: '200px' }}>
      <TextHeader>{t('geotools.select.title')}</TextHeader>
      <Select
        id="geotools.select.layer"
        label={t('geotools.select.layersLabel')}
        value={opts.layer}
        options={LAYERS.map((layer) => ({ value: layer, label: t(`geotools.select.layers.${layer}`) }))}
        onChange={(evt) => {
          setOpts({ layer: evt.target.value, ids: [], names: [] })
        }}
      />
      {t(`geotools.select.featuresSelected`, { count: opts.ids.length })}
      <ul>
        {opts.names.filter(Boolean).map((name) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
      <Checkbox
        id="geotools.select.union"
        label={t('geotools.select.unionLabel')}
        checked={doUnion}
        onChange={(evt) => setDoUnion(evt.target.checked)}
      />
      <Flex justifyContent="center">
        <Button
          onClick={() => (doUnion ? addZones() : addMultipleZones())}
          disabled={opts.layer === '_init' || opts.ids.length === 0}
        >
          {t('actions.actionAdd')}
        </Button>
        <Button ml={2} onClick={close} appearance="secondary">
          {t('actions.actionCancel')}
        </Button>
      </Flex>
    </div>
  )
}

const LAYERS = [
  '_init',
  'residentialArea',
  'officeArea',
  'retailArea',
  'logisticsArea',
  'exurbs',
  'stadtbezirke',
  'ortsteile',
  'gemeinden',
  'landkreise',
  'postleitzahlen',
  'buildingBlock',
  'kgs12',
]
