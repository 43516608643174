import React from 'react'
import { useTranslation } from 'react-i18next'

import { BaseLayer, LayersControl } from 'lib/leaflet'
import {
  HereLayer,
  HereLayerBW,
  HereSatLayer,
  HereSatWithLabelsLayer,
  OSMMapnik,
  OSMMapnikBW,
} from './TileLayers'

/** Adds default base layers to the map
 * Needs to be wrapped by a LayersControl
 */
export const DefaultBaseLayers = ({ select = 'here' }) => {
  const { t } = useTranslation()
  return (
    <>
      <BaseLayer name={t('map.baseLayers.here')} checked={select === 'here' ? 'checked' : ''}>
        <HereLayer />
      </BaseLayer>
      <BaseLayer name={t('map.baseLayers.hereBW')} checked={select === 'hereBW' ? 'checked' : ''}>
        <HereLayerBW />
      </BaseLayer>
      <BaseLayer name={t('map.baseLayers.hereSat')} checked={select === 'hereSat' ? 'checked' : ''}>
        <HereSatLayer />
      </BaseLayer>
      <BaseLayer
        name={t('map.baseLayers.hereSatWithLabels')}
        checked={select === 'hereSatWithLabels' ? 'checked' : ''}
      >
        <HereSatWithLabelsLayer />
      </BaseLayer>
      <BaseLayer name={t('map.baseLayers.osmMapnik')} checked={select === 'osm' ? 'checked' : ''}>
        <OSMMapnik />
      </BaseLayer>
      <BaseLayer name={t('map.baseLayers.osmMapnikBW')} checked={select === 'osmBW' ? 'checked' : ''}>
        <OSMMapnikBW />
      </BaseLayer>
    </>
  )
}

export const ControlledBaseLayers = ({ children }) => (
  <LayersControl>
    <DefaultBaseLayers />
    {children}
  </LayersControl>
)
