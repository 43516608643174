import React from 'react'
import { Browser } from 'leaflet'

import { TileLayer } from 'lib/leaflet'

export const OSMDe = () => (
  <TileLayer
    url="https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png"
    options={{
      maxZoom: 18,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }}
  ></TileLayer>
)

export const OSMMapnik = () => (
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    options={{
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }}
  ></TileLayer>
)

export const OSMMapnikBW = () => (
  <TileLayer
    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    options={{
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      className: 'blackAndWhiteFilter',
    }}
  ></TileLayer>
)

export const HereLayer = () => (
  <TileLayer
    url={
      'https://{s}.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png?app_id=prspubU3RAsar34hajap&app_code=0lCwE2R4oo4Tc7xWs3mstg&lg=ger' +
      (Browser.retina ? '&ppi=320' : '')
    }
    options={{
      subdomains: ['1', '2', '3', '4'],
      minZoom: 5,
      maxZoom: 20,
      detectRetina: true,
      attribution:
        'Map &copy; 1987-' +
        new Date().getFullYear() +
        ' <a href="http://developer.here.com" target="_blank">HERE</a>',
      crossOrigin: true,
    }}
  ></TileLayer>
)

export const HereLayerBW = () => (
  <TileLayer
    url={
      'https://{s}.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png?app_id=prspubU3RAsar34hajap&app_code=0lCwE2R4oo4Tc7xWs3mstg&lg=ger' +
      (Browser.retina ? '&ppi=320' : '')
    }
    options={{
      subdomains: ['1', '2', '3', '4'],
      minZoom: 5,
      maxZoom: 20,
      detectRetina: true,
      attribution:
        'Map &copy; 1987-' +
        new Date().getFullYear() +
        ' <a href="http://developer.here.com" target="_blank">HERE</a>',
      crossOrigin: true,
      className: 'blackAndWhiteFilter',
    }}
  ></TileLayer>
)

export const HereSatLayer = () => (
  <TileLayer
    url="https://{s}.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/jpg?app_id=prspubU3RAsar34hajap&app_code=0lCwE2R4oo4Tc7xWs3mstg"
    options={{
      subdomains: ['1', '2', '3', '4'],
      minZoom: 5,
      maxZoom: 20,
      detectRetina: true,
      attribution:
        'Map &copy; 1987-' +
        new Date().getFullYear() +
        ' <a href="http://developer.here.com" target="_blank">HERE</a>',
      crossOrigin: true,
    }}
  ></TileLayer>
)

export const HereSatWithLabelsLayer = () => (
  <TileLayer
    url={
      'https://{s}.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/jpg?app_id=prspubU3RAsar34hajap&app_code=0lCwE2R4oo4Tc7xWs3mstg&lg=ger' +
      (Browser.retina ? '&ppi=320' : '')
    }
    options={{
      subdomains: ['1', '2', '3', '4'],
      minZoom: 5,
      maxZoom: 20,
      detectRetina: true,
      attribution:
        'Map &copy; 1987-' +
        new Date().getFullYear() +
        ' <a href="http://developer.here.com" target="_blank">HERE</a>',
      crossOrigin: true,
    }}
  ></TileLayer>
)
