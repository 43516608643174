import React, { useCallback, Fragment, isValidElement, cloneElement } from 'react'

import { isUndefined, isArray, isFunction } from 'lib/util'
import { useControllableState } from 'lib/hooks'

import { withInputGroup } from './InputGroup'

export const CheckboxComponent = ({ defaultValue, value, onChange, children, title, ...props }) => {
  const [checked, setChecked] = useControllableState(defaultValue || value || false, value, onChange)
  const handleChange = useCallback((evt) => setChecked(evt.target.checked), [setChecked])
  const childProps = { checked, disabled: props.disabled }

  if (isUndefined(children)) {
    return <input {...props} type="checkbox" checked={checked} onChange={handleChange} />
  }
  if (!isArray(children)) children = [children]
  return (
    <label
      style={{ cursor: props.disabled ? 'auto' : 'pointer', marginBottom: 0, display: 'inline' }}
      title={title}
    >
      <input
        {...props}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      {children.map((child, idx) => {
        return (
          <Fragment key={idx}>
            {isFunction(child)
              ? child(childProps)
              : isValidElement(child)
              ? cloneElement(child, childProps)
              : child}
          </Fragment>
        )
      })}
    </label>
  )
}
export const Checkbox = withInputGroup(CheckboxComponent)
