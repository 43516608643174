import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { createGlobalStyle } from 'styled-components'
import { DivIcon } from 'leaflet'

import typography from 'components/theming/shared/typography'
import { usePrevious } from 'lib/hooks'
import { VectorTileLayer, BgagVectorTileLayer, Pane } from 'lib/leaflet'
import { bgagServicesUrl } from 'config/appConfig'
import { zIndices } from 'config/zIndices'

import { useEditZoneStore } from './Store'

export const EditSelectionLayer = () => {
  const [{ zone }, { toggleSelectedId }] = useEditZoneStore()
  const { layer, ids } = zone.properties.creationParams
  const prevIds = usePrevious(ids)
  const [vectorLayer, setVectorLayer] = useState(null)

  // style selected layers according to store
  useEffect(() => {
    if (!vectorLayer) return
    if (prevIds) {
      for (const gid of prevIds) {
        vectorLayer.resetFeatureStyle(gid)
      }
    }
    for (const gid of ids) {
      vectorLayer.setFeatureStyle(gid, { ...defaultStyle, ...selectStyle })
    }
  }, [vectorLayer, ids, prevIds])

  const style = useMemo(() => {
    if (layer === '_init') {
      return {}
    }
    return { [layer]: defaultStyle }
  }, [layer])

  // event handlers
  const handleClick = useCallback(
    (evt) => {
      const { gid, name } = evt.layer.properties
      toggleSelectedId(gid, name)
    },
    [toggleSelectedId]
  )

  const handleMouseOver = useCallback(
    (evt) => {
      const id = evt.layer.properties.gid
      if (ids.includes(id)) return
      evt.target.setFeatureStyle(id, { ...defaultStyle, ...hoverStyle })
    },
    [ids]
  )

  const handleMouseOut = useCallback(
    (evt) => {
      if (evt.layer.selected) return
      const id = evt.layer.properties.gid
      if (ids.includes(id)) return
      evt.target.resetFeatureStyle(id)
    },
    [ids]
  )
  if (layer === '_init') return null
  return (
    <>
      <VectorTileLayer
        url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${layer}`}
        auth
        maxNativeZoom={14}
        style={style}
        interactive={true}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        callback={setVectorLayer}
      />
      {labelLayers[layer] && (
        <>
          <LabelsStyleCSS />
          <Pane name="newSelectionLabels" zIndex={zIndices.editZone + 1}>
            <BgagVectorTileLayer
              url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?layers[]=${labelLayers[layer]}`}
              auth
              maxNativeZoom={14}
              style={labelStyle}
              useMarker={true}
            />
          </Pane>
        </>
      )}
    </>
  )
}

const defaultStyle = {
  stroke: true,
  weight: 1,
  color: '#000000',
  opacity: 0.5,
  fill: true,
  fillColor: '#eeeeee',
  fillOpacity: 0.6,
}
const hoverStyle = {
  color: '#FF0000',
  weight: 2,
  fillColor: '#cccccc',
}
const selectStyle = {
  fillColor: '#ff0000',
}

const labelStyle = (layer) => {
  if (!layer.properties.name) {
    return null
  }
  return {
    icon: new DivIcon({
      html: `<span>${layer.properties.name}</span>`,
      className: 'geotools-new-selection-labels',
      iconSize: 'auto',
    }),
  }
}

const labelLayers = {
  residentialArea: 'residentialAreaLabels',
  officeArea: 'officeAreaLabels',
  logisticsArea: 'logisticsAreaLabels',
  retailArea: 'retailAreaLabels',
  exurbs: 'exurbsLabels',
  stadtteile: 'stadtteileLabels',
  stadtbezirke: 'stadtbezirkeLabels',
  ortsteile: 'ortsteileLabels',
  gemeinden: 'gemeindenLabels',
  landkreise: 'landkreiseLabels',
  postleitzahlen: 'postleitzahlenLabels',
}

const LabelsStyleCSS = createGlobalStyle`
.geotools-new-selection-labels{
  background: initial;
  pointer-events: none;
}

.geotools-new-selection-labels span {
  display:inline-block;
  transform:translate(-50%, -50%);

  padding: 2px 6px;

  font-weight: bold;
  font-family: ${typography.fonts.sansSerif};
  font-size: 10px;
  white-space: nowrap;
  color: #222;

  background: white;
  border: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  
  pointer-events: none;
}
`
