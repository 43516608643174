import escapeHtml from 'escape-html'
import { isUndefined } from 'lib/util'

const AUTOMAP_URL = 'http://padhraic.bgag/automap/upload_geojson.php'

/**
 * Open AutoMap for the given feature in a new browser tab
 * @param feature
 * @param options
 */
export const sendToAutomap = function (geojson) {
  geojson = JSON.parse(JSON.stringify(geojson))

  if (geojson.type === 'Feature') {
    fixProperties(geojson)
  } else if (geojson.type === 'FeatureCollection') {
    geojson.features.forEach((feature) => fixProperties(feature))
  }

  let serialized = escapeHtml(JSON.stringify(geojson))
  // automap requires float-only coordinates
  serialized = serialized.replace(/\[([0-9]+),/g, '[$1.00001,').replace(/,([0-9]+)\]/g, ',$1.00001]')

  const html = `
<form id="automap" method="POST" action="${AUTOMAP_URL}" target="_blank">
  <input type="hidden" name="geojson" value="${serialized}">
</form>
`.trim()

  const div = document.createElement('div')
  div.innerHTML = html

  document.body.appendChild(div)
  div.firstChild.submit()
  document.body.removeChild(div)
}

function fixProperties(feature) {
  const properties = feature.properties
  if (!isUndefined(properties.opacity)) {
    properties.opacity = properties.opacity * 100
  }
  if (!isUndefined(properties.color) && isUndefined(properties['stroke-color'])) {
    properties['stroke-color'] = properties.color
  }
  if (!isUndefined(properties.color) && isUndefined(properties['fill-color'])) {
    properties['fill-color'] = properties.color
  }
}
