import { TileLayer, Util, Evented } from 'leaflet'
import Pbf from 'pbf'
import { VectorTile } from '@mapbox/vector-tile'
import { TileMarker, TilePolygon, TilePolyline, TileCircleMarker } from './Layers'

export class Tile extends Evented {
  initialize(coords, tilePos, tileSize, options) {
    this.options = options
    this._coords = coords
    this._tileSize = tileSize
    this._tilePos = tilePos
    this._layers = []
  }

  getTileUrl() {
    return Util.template(this.options.url, {
      ...this.options,
      ...this._coords,
      s: TileLayer.prototype._getSubdomain(this._coords),
    })
  }

  async render(parentGrid) {
    const vectorTile = await fetchFeatures(this.getTileUrl(), this.options.fetchOptions)
    if (this._aborted) {
      return
    }
    for (const layerName in vectorTile) {
      if (this.options.hideLayers && this.options.hideLayers.includes(layerName)) {
        continue
      }
      if (this.options.layers && !this.options.layers.includes(layerName)) {
        continue
      }
      const vtLayer = vectorTile[layerName]
      const pxPerExtent = this._tileSize.divideBy(vtLayer.extent)

      const layerAsMarker =
        Array.isArray(this.options.useMarker) && this.options.useMarker.includes(layerName)
      const pointsAsMarker = this.options.useMarker === true || layerAsMarker

      for (let i = 0; i < vtLayer.length; i++) {
        const vtFeature = vtLayer.feature(i)

        const options = {
          interactive: this.options.interactive,
          pane: this.options.pane,
          pxPerExtent,
          tilePos: this._tilePos,
          gridLayer: parentGrid,
          coords: this._coords,
        }
        const geometry = vtFeature.loadGeometry()

        let layer
        switch (vtFeature.type) {
          case 1:
            if (pointsAsMarker) {
              layer = new TileMarker(geometry, options)
            } else {
              layer = new TileCircleMarker(geometry, options)
            }
            break
          case 2:
            layer = new TilePolyline(geometry, options)
            break
          case 3:
            layer = new TilePolygon(geometry, options)
            break
          default:
            throw Error('case not defined')
        }
        layer.properties = vtFeature.properties
        layer._vtLayerName = layerName
        layer.zoom = this._coords.z

        this._layers.push(layer)
        this.fire('layeradd', { layer, layerName })
      }
    }
  }

  remove() {
    this._aborted = true
    for (const layer of this._layers) {
      this.fire('layerremove', { layer, layerName: layer._vtLayerName })
    }
  }
}

async function fetchFeatures(url, fetchOptions) {
  const response = await fetch(url, fetchOptions)
  if (!response.ok) {
    return {}
  }

  const buffer = await response.arrayBuffer()
  var pbf = new Pbf(buffer)
  const vectorTile = new VectorTile(pbf)
  return vectorTile.layers
}
