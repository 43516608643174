import React, { useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import LoginForm from 'pages/HighstreetReport/Login/LoginForm'
import LoginCardWrapper from 'components/LoginCardWrapper'
import Layout from 'components/Layout'

import BMOLoginBg from 'assets/images/BMOLoginBg.jpg'

export const LoginModuleBMO = () => {
  const location = useLocation()
  const locationState = location.state || {}

  const showMessage = locationState.showMessage || ''

  const [redirectToReferrer, setRedirectToReferrer] = useState(false)

  // remove ssoConfig, to allow normal login after sso logout
  localStorage.removeItem('ssoConfig')

  if (redirectToReferrer === true) {
    return <Navigate replace to="/apps/highstreet-report/home" />
  }

  return (
    <Layout showMainMenu={false}>
      <LoginCardWrapper logoTop="riwis" BMOBg={BMOLoginBg}>
        <LoginForm setRedirectToReferrer={setRedirectToReferrer} showMessage={showMessage} />
      </LoginCardWrapper>
    </Layout>
  )
}
