import { useEffect, useState } from 'react'
import L from 'leaflet'

import { useMarkerEvents } from '../hooks/useEvents'
import { useLayerGroup } from './LayerGroup'
import { useAddToMap } from './LeafletMap'
import { usePaneOptions } from './Pane'

export const Marker = ({ position, options, draggable, children = null, ...props }) => {
  draggable = draggable ?? options.draggable
  const paneOptions = usePaneOptions()
  const [marker] = useState(() => L.marker(position, { ...options, ...paneOptions }))

  useLayerGroup(marker)
  useAddToMap(marker)
  useMarkerEvents(marker, props)

  useEffect(() => {
    marker.setLatLng(position)
  }, [marker, position])

  useEffect(() => {
    draggable ? marker.dragging.enable() : marker.dragging.disable()
  }, [marker, draggable])

  return children
}
