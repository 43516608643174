import React, { createContext, useContext, useMemo, useRef, useEffect } from 'react'
import appConfig from 'config/appConfig'

import { useDevtoolReducer } from './devTool'

const stores = {}
const storesMounted = {}
export const storeManager = {
  addStore: (name, callbacks = {}, initialState, reducers = []) => {
    stores[name] = { callbacks, initialState, reducers }
  },
  mount: (name, dispatcher) => {
    storesMounted[name] = { dispatcher }
  },
  unmount: (name) => {
    delete storesMounted[name]
  },
  list: () => {
    console.log({
      stores,
      storesMounted,
    })
  },
  reset: (name) => {
    if (typeof storesMounted[name] !== 'undefined') {
      return new Promise((resolve) => {
        if (stores[name].reducers.includes('reset')) {
          storesMounted[name].dispatcher({ type: 'reset' })
        } else if (stores[name].reducers.includes('setLoadedState')) {
          storesMounted[name].dispatcher({ type: 'setLoadedState', payload: stores[name].initialState })
        }
        resolve()
      })
    } else {
      if (typeof stores?.[name].callbacks.onReset === 'function') {
        return stores[name].callbacks.onReset()
      } else if (typeof stores?.[name].callbacks.onUpdate === 'function') {
        return stores[name].callbacks.onUpdate(stores[name].initialState)
      }
    }
  },
  resetAll: () => {
    const resets = []
    Object.keys(stores).forEach((name) => {
      resets.push(storeManager.reset(name))
    })
    return Promise.all(resets)
  },
  // resets all stores with method resetPartial is available
  // used e.g. by l3plus state store, on login
  partialReset: (name) => {
    if (typeof storesMounted[name] !== 'undefined') {
      return new Promise((resolve) => {
        if (stores[name].reducers.includes('partialReset')) {
          storesMounted[name].dispatcher({ type: 'partialReset' })
        }
        resolve()
      })
    } else {
      if (typeof stores?.[name].callbacks.onPartialReset === 'function') {
        return stores[name].callbacks.onPartialReset()
      }
    }
  },
  partialResetAll: () => {
    const resets = []
    Object.keys(stores).forEach((name) => {
      resets.push(storeManager.partialReset(name))
    })
    return Promise.all(resets)
  },
}

function createStore(reducer, actions, initialState, callbacks = {}, name) {
  if (typeof callbacks === 'function') {
    callbacks = { onUpdate: callbacks }
  }
  const context = createContext(null)
  const reducerFn = (prevState, action) => {
    logger(action.type, action)
    // redux devtool dispatches '@@INIT' actions
    if (action.type === '@@INIT') return prevState
    const defaultBranch = (state) => {
      console.warn(
        `You dispatched an action of type "${
          action.type
        }". There is no reducer registered with that type in store '${name}'. 
        
Are you sure you wanted to dispatch "${action.type}"? 
Possible reducers: 
${Object.keys(reducer)
  .map((type) => `- ${type}`)
  .join('\n')}

Make sure not to accidentally overwrite the "type" property of your action.

`
      )
      return state
    }
    const reducerBranch = reducer[action.type] ?? reducer.default ?? defaultBranch
    const newState = reducerBranch(prevState, action)
    if (typeof callbacks?.onUpdate === 'function') {
      callbacks.onUpdate(newState, prevState)
    }
    return newState
  }

  const StoreProvider = ({ children, ...props }) => {
    const store = useDevtoolReducer(reducerFn, initialState, { name })
    const [state, dispatch] = store
    const storeRef = useRef(store)
    const thunks = useMemo(() => createThunks(actions, storeRef), [])
    const providerValue = useMemo(() => [state, thunks], [state, thunks])

    useEffect(() => {
      storeRef.current = store
    }, [store])

    useEffect(() => {
      storeManager.mount(name, dispatch)
      return () => {
        storeManager.unmount(name)
      }
    }, [dispatch])
    return <context.Provider value={providerValue}>{children}</context.Provider>
  }

  const useStore = (selector) => {
    const store = useContext(context)
    if (store === null) throw Error('A useStore hook was used without the StoreProvider.')
    return typeof selector === 'function' ? selector(...store) : store
  }

  storeManager.addStore(name, callbacks, initialState, Object.keys(reducer))

  return [context, StoreProvider, useStore]
}

export default createStore

/** Returns a set of actions that are bound to the store */
function createThunks(actions, storeRef) {
  const thunks = {}
  for (let name of Object.keys(actions)) {
    thunks[name] = (...args) => {
      // this dispatch function will fill in a default type if none given
      const [prevState, dispatch] = storeRef.current
      const patchedDispatch = (action) => dispatch({ type: name, ...action })
      if (actions[name] === null) actions[name] = () => ({})
      const thunk = actions[name](...args)
      if (typeof thunk === 'function') return thunk(patchedDispatch, prevState, thunks)
      else return patchedDispatch(thunk)
    }
  }
  return thunks
}

function logger(type, payload) {
  if (appConfig.quietStoreLogger) {
    return
  }
  // To be extended...
  // Inspiration: https://github.com/LogRocket/redux-logger
  console.log('%c%s%c', 'color:#1b72bf;', type, 'color:inherit;', ':', payload)
}
