import React, { useState } from 'react'

import { isFunction } from 'lib/util'

import { InputGroup } from './InputGroup'

export const Form = ({ onSubmit, onChange, children, ...props }) => {
  const [state, setState] = useState(props.value ?? props.defaultValue ?? {})

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        isFunction(onSubmit) && onSubmit(state)
      }}
    >
      <InputGroup
        {...props}
        onChange={(value) => {
          setState(value)
          isFunction(onChange) && onChange(value)
        }}
      >
        {children}
      </InputGroup>
    </form>
  )
}
