import { useLeaflet } from 'lib/leaflet'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

import { useEffect } from 'react'

import { zIndices } from 'config/zIndices'

export const InitGeoman = () => {
  const map = useLeaflet()

  useEffect(() => {
    const pane = map.createPane('vertices')
    pane.style.zIndex = zIndices.vertices

    map.pm.setGlobalOptions({ panes: { vertexPane: 'vertices' } })
  }, [map])

  return null
}
