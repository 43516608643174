import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import api from 'stores/api'
import useApi from 'stores/useApi'

import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { storeManager } from 'lib/flux-store'
import { sortLocaleCompareAsc } from 'lib/helper/sorter'
import LocationSearchMap from './LocationSearchMap'
import { LocationSearchInput } from './LocationSearchInput'
import { LocationSearchDetails } from './LocationSearchDetails'

import { Form } from 'components/Forms/Form'
import Box, { Flex } from 'components/atoms/Box'
import Button from 'components/atoms/Button'
import { CountrySelection } from './CountrySelection'

const minHeight = '475px'

const getOfficeAreas = (gac) => {
  if (gac === null) {
    return Promise.resolve({ data: { data: [] } })
  }
  return api.Areas.byTypeAndGac({ type: 'office', gac, returnGeom: true })
}
const getResidentialAreas = (gac) => {
  if (gac === null) {
    return Promise.resolve({ data: { data: [] } })
  }
  return api.Areas.byTypeAndGac({ type: 'residential', gac, returnGeom: true })
}

const sortAreas = (a, b) => {
  return sortLocaleCompareAsc(a.name, b.name)
}

export const LocationSearch = ({
  mapId = 'locationSearchMap',
  hide,
  addLocation,
  location = null,
  country,
  setCountry,
  countries,
  showCountrySelection,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { hasRouteAccess } = useAuthzChecks()
  const { pathname } = useLocation()
  const resetSessionAndRedirect = pathname.startsWith('/home')
  const [choosenLocation, setChoosenLocation] = useState(null)
  const [additionalAreas, setAdditionalAreas] = useState({ residential: [], office: [] })
  const [prevLocation, setPrevLocation] = useState(null)
  const [selectedSubmarket, setSelectedSubmarket] = useState(null)

  const prevGacFetchedAreas = useRef(null)
  const locationName = useRef(null)

  const [fetchResidentialAreas, residentialAreas, loadingResidential] = useApi(getResidentialAreas, 'init')
  const [fetchOfficeAreas, officeAreas, loadingOffice] = useApi(getOfficeAreas, 'init')

  if (!Object.is(location, prevLocation)) {
    setPrevLocation(location)
    setChoosenLocation(location)
  }

  useEffect(() => {
    if (choosenLocation) {
      const addressLevel = parseInt(choosenLocation.addressLevel)
      let gac

      if (!isNaN(addressLevel) && addressLevel > 0 && addressLevel <= 4) {
        gac = null
      } else {
        gac = choosenLocation.areas?.['20']?.gac || choosenLocation.areas?.['10']?.gac || null
      }

      if (gac !== prevGacFetchedAreas.current) {
        locationName.current = choosenLocation.displayedName
        prevGacFetchedAreas.current = gac

        fetchResidentialAreas(gac)
        fetchOfficeAreas(gac)
      }
    } else {
      if (prevGacFetchedAreas.current !== null) {
        prevGacFetchedAreas.current = null
        fetchResidentialAreas(null)
        fetchOfficeAreas(null)
      }
    }
  }, [choosenLocation, fetchOfficeAreas, fetchResidentialAreas])

  useEffect(() => {
    const prepareArea = (area, type, index) => {
      area.displayedName =
        t('locationSearch.submarket.' + type) + ' ' + area.name + ', ' + locationName.current
      area.id = type + '-' + index
      return area
    }

    if (loadingResidential || loadingOffice || residentialAreas === 'init' || officeAreas === 'init') {
      return
    }
    setAdditionalAreas((prev) => {
      if (
        prev.residential.length === 0 &&
        residentialAreas.length === 0 &&
        prev.office.length === 0 &&
        officeAreas.length === 0
      ) {
        return prev
      }
      return {
        residential: residentialAreas
          .sort(sortAreas)
          .map((area, index) => prepareArea(area, 'residential', index)),
        office: officeAreas.sort(sortAreas).map((area, index) => prepareArea(area, 'office', index)),
      }
    })
  }, [residentialAreas, officeAreas, loadingOffice, loadingResidential, setAdditionalAreas, t])

  const coordinates = useMemo(() => {
    if (choosenLocation === null) {
      return null
    }
    const addressLevel = parseInt(choosenLocation.addressLevel)
    if (!isNaN(addressLevel) && addressLevel > 0 && addressLevel <= 4) {
      return choosenLocation.geometry.coordinates.join('|')
    } else {
      return null
    }
  }, [choosenLocation])

  const frmAddLocation = useCallback(() => {
    addLocation(choosenLocation)
    if (typeof hide === 'function') {
      hide()
    }
  }, [addLocation, hide, choosenLocation])

  const gotoMarketAnalysis = useCallback(() => {
    storeManager.resetAll().then(() => {
      addLocation(choosenLocation)
      navigate('/market-analysis/market-data')
    })
  }, [addLocation, choosenLocation, navigate])

  const gotoReport = useCallback(() => {
    storeManager.resetAll().then(() => {
      addLocation(choosenLocation)
      if (hasRouteAccess('/download/reports')) {
        window.open('https://www.riwis.de/riwis_online/sbrief/?locations=' + coordinates)
      } else {
        navigate('/market-analysis/market-data')
      }
    })
  }, [addLocation, choosenLocation, navigate, hasRouteAccess, coordinates])

  useEffect(() => {
    if (country && !location) {
      setChoosenLocation(null)
    }
  }, [country, location])

  return (
    <Form>
      <Flex>
        <Flex flex="0 0 50%" pr={2} minHeight={minHeight} flexDirection="column">
          {showCountrySelection && countries.length > 1 && (
            <CountrySelection country={country} setCountry={setCountry} countries={countries} />
          )}
          <LocationSearchInput
            choosenLocation={choosenLocation}
            setChoosenLocation={setChoosenLocation}
            locationPreset={location !== null}
            country={country}
          />
          <LocationSearchDetails
            choosenLocation={choosenLocation}
            setChoosenLocation={setChoosenLocation}
            additionalAreas={additionalAreas}
            setAdditionalAreas={setAdditionalAreas}
            selectedSubmarket={selectedSubmarket}
            setSelectedSubmarket={setSelectedSubmarket}
          />
          <Flex justifyContent="center" width="100%">
            {resetSessionAndRedirect ? (
              <Box>
                <Button
                  px={6}
                  mt={3}
                  mx={2}
                  appearance="secondary"
                  disabled={choosenLocation === null}
                  onClick={gotoMarketAnalysis}
                >
                  {t('locationSearch.input.gotoMarketAnalysis')}
                </Button>
                {hasRouteAccess('/download/reports') && (
                  <Button
                    px={6}
                    mt={3}
                    mx={2}
                    appearance="secondary"
                    disabled={choosenLocation === null || coordinates === null}
                    onClick={gotoReport}
                  >
                    {t('locationSearch.input.gotoReport')}
                  </Button>
                )}
              </Box>
            ) : (
              <Button
                px={6}
                mt={3}
                appearance="secondary"
                disabled={choosenLocation === null}
                onClick={frmAddLocation}
              >
                {t('controls.add')}
              </Button>
            )}
          </Flex>
        </Flex>
        <Box flex="0 0 50%" pl={2} minHeight={minHeight}>
          <LocationSearchMap
            mapId={mapId}
            choosenLocation={choosenLocation}
            setChoosenLocation={setChoosenLocation}
            setSelectedSubmarket={setSelectedSubmarket}
            additionalAreas={additionalAreas}
            country={location ? location?.properties?.countryCode : country}
          />
        </Box>
      </Flex>
    </Form>
  )
}
