import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Range } from 'rc-slider'

import { isEqualPoint } from 'lib/geojson'
import { useDebouncedUpdates } from 'lib/hooks'
import { useNumbersFormats } from 'config/numbersFormats'
import Button from 'components/atoms/Button'
import Checkbox from 'components/atoms/Checkbox'
import Select from 'components/atoms/Select'

import { useEditZoneStore } from './Store'
import { useGeotoolsStore } from '../Zones/Store'

export const EditFzrControl = () => {
  const { t } = useTranslation()
  const [{ collection }] = useGeotoolsStore()
  const numbersFormats = useNumbersFormats()
  const [{ zone, drawMode }, { setParams, setLoading, setDrawMode }] = useEditZoneStore()
  const { rangetype, mode, center, range } = zone.properties.creationParams

  const isOnProjectCenter = isEqualPoint(center, collection.meta.locationGeometry)

  const [localRange, setRange] = useDebouncedUpdates(
    range,
    useCallback((range) => setParams({ range }), [setParams]),
    500
  )

  const rangeMin = rangetype === 'time' ? 0 : 0
  const rangeMax = rangetype === 'time' ? 60 * 60 : 10_000
  const rangeStep = rangetype === 'time' ? 60 : 500
  return (
    <>
      <div>
        {t(`geotools.fzr.drivetime.${rangetype}`)}:{' '}
        {range
          .map((r) => numbersFormats.roundedFraction0.format(rangetype === 'time' ? parseInt(r / 60) : r))
          .join(', ')}{' '}
        {t(`geotools.fzr.units.${rangetype}`)}
      </div>
      <Range
        value={localRange}
        onChange={(range) => {
          setLoading(true)
          setRange(range)
        }}
        max={rangeMax}
        min={rangeMin}
        step={rangeStep}
      />
      <Select
        id="geotools.fzr.mode"
        label={t('geotools.fzr.mode')}
        value={mode}
        options={MODES.map((mode) => ({ value: mode, label: t(`geotools.fzr.modes.${mode}`) }))}
        onChange={(evt) => {
          setParams({ mode: evt.target.value })
        }}
      />
      <Select
        id="geotools.fzr.rangetype"
        label={t('geotools.fzr.rangetype')}
        value={rangetype}
        options={RANGETYPES.map((type) => ({ value: type, label: t(`geotools.fzr.rangetypes.${type}`) }))}
        onChange={(evt) => {
          const rangetype = evt.target.value
          setParams({ rangetype, range: rangetype === 'time' ? [300, 600] : [2000, 3000] })
        }}
      />
      <Checkbox
        checked={drawMode}
        onChange={(evt) => setDrawMode(evt.target.checked)}
        label={t('geotools.fzr.drawMode')}
      />
      <Button
        onClick={() => setParams({ center: collection.meta.locationGeometry })}
        disabled={isOnProjectCenter}
        title={isOnProjectCenter ? t('geotools.fzr.resetCenterDisabled') : t('geotools.fzr.resetCenterInfo')}
        mb={2}
      >
        {t('geotools.fzr.resetCenter')}
      </Button>
    </>
  )
}

const MODES = ['car', 'pedestrian']
const RANGETYPES = ['time', 'distance']
