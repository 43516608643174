import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import api from 'stores/api'
import useApi from 'stores/useApi'

import useDebounce from 'lib/hooks/useDebounce'
import Box from 'components/atoms/Box'
import { TextBanner, TextParagraph } from 'components/atoms/Text'
import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { BudiconPasswordLock } from 'bgag-budicons'

const PasswordCriteriumErrors = ({ criterias, config, children }) => {
  const { t } = useTranslation()
  return (
    <Box>
      {children}
      {criterias.map((criterium) => {
        if (criterium.passed) {
          return null
        } else {
          let textParams = {}
          if (criterium.key === 'minLength' || criterium.key === 'maxLength') {
            textParams[criterium.key] = config[criterium.key]
          }
          return (
            <TextParagraph mt={2} color="danger" key={criterium.key}>
              {t('setPassword.strength.' + criterium.key, textParams)}
            </TextParagraph>
          )
        }
      })}
    </Box>
  )
}
const SetPasswordForm = ({ token, userId, type, onSetPassword }) => {
  const { t } = useTranslation()

  const [validateToken, tokenIsValid, , failedValidatingToken] = useApi(api.Auth.validateToken, null)
  const [testPasswordStrength, passwordStrength] = useApi(api.Auth.testPasswordStrength, null)
  const [setPassword, passwordSaved, isSettingPassword, failedSettingPassword] = useApi(
    api.Auth.setPassword,
    null
  )

  const [passwords, setPasswords] = useState({
    new: '',
    repeat: '',
  })
  const debouncedPassword = useDebounce(passwords.new, 200)
  const newEqualsRepeat = passwords.new === passwords.repeat

  let showRequiredFailed = false
  let showOptionalFailed = false
  if (debouncedPassword.length > 0 && passwordStrength !== null && !passwordStrength.strong) {
    showRequiredFailed = passwordStrength.requiredFailed > 0
    showOptionalFailed =
      !passwordStrength.optionalRequired &&
      passwordStrength.optionalPassed < passwordStrength.config.minOptionalTestsToPass
  }

  useEffect(() => {
    validateToken({ userId, type, token })
  }, [validateToken, userId, type, token])

  useEffect(() => {
    if (debouncedPassword.length > 0) {
      testPasswordStrength(debouncedPassword)
    }
  }, [testPasswordStrength, debouncedPassword])

  const onSubmit = (evt) => {
    evt.preventDefault()
    setPassword({ password: passwords.new, userId, type, token })
  }

  useEffect(() => {
    if (
      failedSettingPassword &&
      typeof passwordSaved === 'object' &&
      Array.isArray(passwordSaved.messages) &&
      passwordSaved.messages.find((message) => message.msg === 'invalid token')
    ) {
      validateToken({ userId, type, token })
    }
  }, [failedSettingPassword, passwordSaved, validateToken, userId, type, token])

  useEffect(() => {
    if (passwordSaved === true && typeof onSetPassword === 'function') {
      onSetPassword()
    }
  }, [passwordSaved, onSetPassword])

  return (
    <Box width="100%" textAlign="center">
      {tokenIsValid === false && !failedValidatingToken && (
        <div>
          <TextParagraph mt={3} mb={6} color="danger">
            {t('setPassword.' + type + '.tokenInvalid')}
          </TextParagraph>
          {type === 'invite' && (
            <>
              <TextParagraph mt={3} mb={6}>
                {t('setPassword.invite.gotoLogin')}
              </TextParagraph>
              <Link style={{ marginLeft: '1.5rem' }} to="/">
                {t('setPassword.invite.btnLogin')}
              </Link>
            </>
          )}
          {type === 'reset' && (
            <Link to="/forgotPassword">{t('setPassword.reset.linkToForgotPassword')}</Link>
          )}
        </div>
      )}
      {failedValidatingToken === true && (
        <TextParagraph mt={3} mb={6} color="danger">
          {t('setPassword.failedValidatingToken')}
        </TextParagraph>
      )}
      {tokenIsValid === true && (
        <>
          <TextBanner mt={3}>{t('setPassword.' + type + '.header')}</TextBanner>
          <TextParagraph mt={3} mb={6}>
            {t('setPassword.' + type + '.intro')}
          </TextParagraph>
          <form onSubmit={onSubmit}>
            <Input
              id="password"
              type="password"
              label={t('setPassword.newPassword')}
              hideLabel
              placeholder={t('setPassword.newPassword')}
              autoComplete="off"
              disabled={!tokenIsValid || isSettingPassword}
              value={passwords.new}
              onChange={(e) => setPasswords({ ...passwords, new: e.target.value })}
              Icon={BudiconPasswordLock}
              appearance="default"
            />
            <Input
              id="password"
              type="password"
              label={t('setPassword.repeatPassword')}
              hideLabel
              placeholder={t('setPassword.repeatPassword')}
              autoComplete="off"
              disabled={!tokenIsValid || isSettingPassword}
              value={passwords.repeat}
              onChange={(e) => setPasswords({ ...passwords, repeat: e.target.value })}
              Icon={BudiconPasswordLock}
              appearance="default"
            />
            <Button
              type="submit"
              mt={6}
              mb={8}
              appearance="darker"
              isLoading={isSettingPassword}
              disabled={
                !tokenIsValid ||
                !passwordStrength ||
                !passwordStrength.strong ||
                isSettingPassword ||
                !newEqualsRepeat
              }
              isDisabled={
                !tokenIsValid ||
                !passwordStrength ||
                !passwordStrength.strong ||
                isSettingPassword ||
                !newEqualsRepeat
              }
            >
              {t('menu.login')}
            </Button>
          </form>
        </>
      )}
      <div>
        {showRequiredFailed && (
          <PasswordCriteriumErrors criterias={passwordStrength.required} config={passwordStrength.config}>
            <span>{t('setPassword.strength.preRequired')}</span>
          </PasswordCriteriumErrors>
        )}
        {showOptionalFailed && (
          <PasswordCriteriumErrors criterias={passwordStrength.optional} config={passwordStrength.config}>
            <span>
              {t('setPassword.strength.preOptional', {
                optionalRemaining: passwordStrength.optionalRemaining,
              })}
            </span>
          </PasswordCriteriumErrors>
        )}
      </div>
    </Box>
  )
}

export default SetPasswordForm
