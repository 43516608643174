import { useState, useEffect, useRef } from 'react'
import useDebounce from './useDebounce'

export const useDebouncedUpdates = (value, onChange, delay = 100) => {
  const firstRender = useRef(true)
  const [localValue, setLocalValue] = useState(value)
  const debouncedLocal = useDebounce(localValue, delay)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    typeof onChange === 'function' && onChange(debouncedLocal)
  }, [debouncedLocal, onChange, firstRender])

  useEffect(() => {
    setLocalValue(value)
  }, [value])

  return [localValue, setLocalValue]
}
