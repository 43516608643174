import { useEffect } from 'react'
import L from 'leaflet'

import { useMapMultiStore } from './index'

const defaultCenter = L.latLng([0, 0])

export const useSyncStore = (map, mapId) => {
  const [viewport, bounds] = useMapMultiStore((state, actions) => [state.viewport, state.bounds], mapId)

  // mapcenter change
  useEffect(() => {
    if (map === null) return

    const { center, zoom = 12, quick } = viewport
    let _quick = quick
    const newCenter = Array.isArray(center) ? L.latLng(center) : center
    // dont go to default center
    if (newCenter.equals(defaultCenter)) return
    // dont got to if map is already on correct center
    if (map.getCenter().equals(newCenter) && zoom === map.getZoom()) return
    // dont flyTo if first center-change
    if (map.getCenter().equals(defaultCenter)) {
      _quick = true
    }

    if (_quick) {
      map.setView(newCenter, zoom)
    } else {
      map.flyTo(newCenter, zoom, { duration: 2, maxZoom: 1 })
    }
  }, [map, viewport])

  // bounds change
  useEffect(() => {
    if (map === null || bounds === null) {
      return
    }
    map.stop()
    map.invalidateSize()
    map.fitBounds(bounds)
  }, [map, bounds])
}
