import React, { useEffect, useState } from 'react'
import { Navigate, useParams, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getAuthClient, ssoLogin } from 'lib/sso'

import { useAuthStore } from 'stores/AuthStore'
import { useAppStore } from 'stores/AppStore'

import Layout from 'components/Layout'
import LoginCardWrapper from 'components/LoginCardWrapper'
import { Flex } from 'components/atoms/Box'
import { TextParagraph } from 'components/atoms/Text'
import Signal from 'components/SignalLoader'

export const SingleSignOn = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const location = useLocation()

  const [currentUser, actions] = useAuthStore((state, actions) => [state.currentUser, actions])
  const [loadAppState] = useAppStore((state, actions) => [actions.loadState])

  const [redirectToReferrer, setRedirectToReferrer] = useState(false)
  const [currentTask, setCurrentTask] = useState('retrieveToken')
  const [loginError, setLoginError] = useState(null)

  const [authClient, setAuthClient] = useState(null)

  useEffect(() => {
    if (typeof currentUser === 'object') {
      let ssoRedirect = localStorage.getItem('ssoRedirect')
      let from
      if (typeof ssoRedirect === 'string') {
        from = ssoRedirect
        localStorage.removeItem('ssoRedirect')
      } else {
        const locationState = location.state || {}
        from = locationState.from && locationState.from.pathname ? locationState.from.pathname : '/home'
        if (locationState.from && typeof locationState.from.search === 'string') {
          from += locationState.from.search
        }
      }

      setRedirectToReferrer(from)
    }
  }, [currentUser, setRedirectToReferrer, location])

  useEffect(() => {
    getAuthClient(token).then((client) => {
      setAuthClient(client)
    })
  }, [token])

  useEffect(() => {
    if (authClient === null) {
      return
    }

    if (authClient.config.type === 'azure') {
      authClient.client.handleRedirectPromise().then((tokenResponse) => {
        if (tokenResponse === null) {
          authClient.client.acquireTokenRedirect({})
        } else {
          setCurrentTask('verifyToken')
          ssoLogin(tokenResponse.idToken, tokenResponse.accessToken)
            .then((valid) => {
              actions.pullCurrentUser()
              loadAppState()
            })
            .catch((err) => {
              console.error(err)
              setLoginError('errorOnLogin')
            })
        }
      })
    } else if (authClient.config.type === 'okta') {
      if (!authClient.client.token.isLoginRedirect()) {
        authClient.client.token.getWithRedirect()
      } else {
        authClient.client.token.parseFromUrl().then((data) => {
          authClient.client.authStateManager.subscribe((authState) => {
            // handle latest authState...needed to remove warning in console
            return true
          })
          const { idToken, accessToken } = data.tokens
          authClient.client.tokenManager.setTokens(data.tokens)
          setCurrentTask('verifyToken')
          ssoLogin(idToken.value, accessToken.value)
            .then((valid) => {
              actions.pullCurrentUser()
              loadAppState()
            })
            .catch((err) => {
              console.error(err)
              setLoginError('errorOnLogin')
            })
        })
      }
    } else if (authClient.config.type === 'architrave') {
      setCurrentTask('verifyToken')
      const [path, querystring] = window.location.href.split('?')
      const searchParams = new URLSearchParams(querystring)
      const href =
        path +
        '?uid=' +
        searchParams.get('uid') +
        '&ts=' +
        searchParams.get('ts') +
        '&chk=' +
        searchParams.get('chk')

      ssoLogin(null, null, href)
        .then((valid) => {
          actions.pullCurrentUser()
          loadAppState()
        })
        .catch((err) => {
          console.error(err)
          setLoginError('errorOnLogin')
        })
    }
  }, [authClient, actions, loadAppState])

  if (redirectToReferrer !== false) {
    return <Navigate replace to={redirectToReferrer} />
  }

  return (
    <Layout showMainMenu={false}>
      <LoginCardWrapper logoTop="riwis">
        <Flex width="100%" textAlign="center">
          {!loginError && <Signal />}
          {currentTask === 'retrieveToken' && (
            <TextParagraph mb={0} mt="0.188rem">
              {t('sso.retrieveToken')}
            </TextParagraph>
          )}
          {currentTask === 'verifyToken' && (
            <TextParagraph mb={0} mt="0.188rem">
              {t('sso.verifyToken')}
            </TextParagraph>
          )}
          {loginError && (
            <TextParagraph color="danger" mb={0} mt="0.188rem">
              {t('sso.loginError')}
            </TextParagraph>
          )}
        </Flex>
      </LoginCardWrapper>
    </Layout>
  )
}
