import React from 'react'
import { Link } from 'react-router-dom'
import Box, { Flex } from 'components/atoms/Box'
import styled from 'styled-components'
import { TextHighlight, TextParagraph } from 'components/atoms/Text'
import { Grid } from 'components/atoms/Grid'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'

export const TileGrid = ({ children, ...rest }) => {
  return <ExtendedGrid {...rest}>{children}</ExtendedGrid>
}

export const TileList = ({ children, ...rest }) => {
  return (
    <Grid overflow="hidden" flexDirection="column" borderRadius="0.75rem" {...rest}>
      {children}
    </Grid>
  )
}

export const Tile = ({ children, padding, linkto, minHeight = '10rem', ...rest }) => {
  const { hasRouteAccess } = useAuthzChecks()
  const route = linkto && hasRouteAccess(linkto) ? linkto : null
  // const hasAccess = !linkto ? true : hasRouteAccess(linkto)
  const defaultPadding = '1rem 1rem 0.25rem 0.25rem'
  return route ? (
    <Link to={route}>
      <Flex height="100%" padding={padding ? padding : defaultPadding} minHeight={minHeight} {...rest}>
        {children}
      </Flex>
    </Link>
  ) : (
    <Flex padding={padding ? padding : defaultPadding} minHeight={minHeight} {...rest}>
      {children}
    </Flex>
  )
}

Tile.Left = ({ children, minWidth = '6.5rem', ...rest }) => {
  return (
    <Flex
      width="6rem"
      flexDirection="column"
      mr={2}
      alignItems="center"
      minWidth={minWidth}
      justifyContent="flex-start"
      {...rest}
    >
      {children}
    </Flex>
  )
}

Tile.Right = ({ children, ...rest }) => {
  return <Flex {...rest}>{children}</Flex>
}

Tile.Icon = ({ Icon, color = '#002940', size, ...rest }) => {
  return (
    <Box {...rest}>
      <Icon size={size} strokeWidth="0.3" color={color} />{' '}
    </Box>
  )
}

Tile.Title = ({ children, color = '#002940', ...rest }) => {
  return (
    <TextHighlight textAlign="center" color={color} {...rest}>
      {children}
    </TextHighlight>
  )
}

Tile.Paragraph = ({ children, color = 'black', ...rest }) => {
  return (
    <TextParagraph color={color} {...rest}>
      {children}
    </TextParagraph>
  )
}

Tile.Logo = ({ logo, logoWidth, xPos = '-0.8125rem' }) => {
  return (
    <Box mt={'auto'}>
      <LogoContainer xPos={xPos}>
        <Logo width={logoWidth} logo={logo}></Logo>
      </LogoContainer>
      <Triangle xPos={xPos} />
    </Box>
  )
}

const LogoContainer = styled.div`
  display: flex;
  background-color: white;
  padding: 0.375rem 0.375rem;
  width: 6.375rem;
  height: 2.5rem;
  position: relative;
  left: ${(props) => props.xPos};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
`
const Logo = styled.div`
  background-image: url(${(props) => props.logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: ${(props) => (props.width ? props.width : '100%')};
`

const Triangle = styled.div`
  width: 0;
  height: 0;
  position: relative;
  border-top: 0.5rem solid #979797;
  border-left: 0.5rem solid transparent;
  left: ${(props) => props.xPos};
`
const ExtendedGrid = styled(Grid)`
  & > a:nth-child(2) > div,
  & > div:nth-child(2) {
    border-radius: 0 0.75rem 0 0;
  }
  & > a:last-child > div,
  & > div:last-child {
    border-radius: 0 0 0.75rem 0;
  }

  @media (max-width: 75rem) {
    & > a:nth-child(2) > div,
    & > div:nth-child(2) {
      border-radius: 0;
    }
    & > a:nth-last-child(2) > div,
    & > div:nth-last-child(2) {
      border-radius: 0 0 0 0.75rem;
    }
    & > a:last-child > div,
    & > div:last-child {
      border-radius: 0 0 0.75rem 0;
    }
  }
  @media (max-width: 768px) {
    & > a:nth-last-child(2) > div,
    & > div:nth-last-child(2) {
      border-radius: 0;
    }
    & > a:last-child > div,
    & > div:last-child {
      border-radius: 0 0 0.75rem 0.75rem;
    }
  }
`
