import React, { memo, useMemo } from 'react'

import { ColorAllocator } from 'components/UtilHelper'
import { LayerGroup, Marker, withPane } from 'lib/leaflet'

import { makeIcon } from '../MapHelpers'
import { zIndices } from 'config/zIndices'

export const LocationPins = memo(({ locations, draggable = false, ...props }) => {
  const pins = useMemo(
    () =>
      Object.entries(locations).map(([id, feature]) => ({
        id: id,
        point: feature.geometry.coordinates.slice().reverse(),
        name: feature.displayedName,
        color: ColorAllocator(id),
      })),
    [locations]
  )

  return (
    <LayerGroup>
      {pins.map(({ id, name, point, color }) => (
        <Marker
          key={id}
          position={point}
          options={{ title: name, icon: makeIcon(color), id }}
          draggable={draggable}
          {...props}
        />
      ))}
    </LayerGroup>
  )
})

export const LocationPinsPane = withPane(LocationPins, 'location-pins', zIndices.locationPins)
