import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { hexToRgb } from 'lib/util/colors'

import { useGeotoolsStore, useZoneProperties } from './Store'

export const ZoneIcon = ({ zoneId, disableHover, disableClick, width = 18 }) => {
  const { t } = useTranslation()
  const [props] = useZoneProperties(zoneId)
  const [, { setHoveredZone, setFlyTo }] = useGeotoolsStore()
  const { id, color, weight, opacity, active, name } = props

  return active ? (
    <ColorCircle
      color={color}
      weight={weight}
      opacity={opacity}
      width={width}
      onMouseEnter={disableHover ? null : () => setHoveredZone(id)}
      onMouseLeave={disableHover ? null : () => setHoveredZone(null)}
      onClick={disableClick ? null : () => setFlyTo(id)}
      title={disableClick ? null : t('geotools.control.flyToNamed', { name })}
      disableClick={disableClick}
    />
  ) : (
    <InactiveColorCircle width={width} />
  )
}

const InactiveColorCircle = styled.span`
  box-sizing: border-box;
  display: inline-block;
  background-color: #dddddd;
  width: ${({ width = 10 }) => width}px;
  height: ${({ width = 10 }) => width}px;
  border-radius: 2px;
  border: 1px solid #888888;
`

const ColorCircle = styled.span`
  box-sizing: border-box;
  display: inline-block;
  background-color: ${({ color, opacity }) => hexToRgb(color, opacity)};
  width: ${({ width = 10 }) => width}px;
  height: ${({ width = 10 }) => width}px;
  border-radius: 2px;
  border: ${({ weight }) => weight}px solid ${({ color }) => color};
  cursor: ${({ disableClick }) => (disableClick ? 'inherit' : 'pointer')};
`
