import React, { useEffect, useState } from 'react'

import { GeoJSON, withPane } from 'lib/leaflet'
import { usePrevious, usePromiseHandle, ignoreCanceled } from 'lib/hooks'
import { getGacFeature } from 'stores/GacFeatureCache'
import { MarketDataSelectors, useMarketDataStateStore } from 'pages/marketData/stateStore'
import { zIndices } from 'config/zIndices'

const DEFAULT_STYLE = { fill: false, color: '#000000', weight: 0.8 }

export const CurLocationLayer = ({ style = DEFAULT_STYLE }) => {
  const curGac = useMarketDataStateStore(MarketDataSelectors.$curGac)
  const feature = useGacFeature(curGac)

  return <GeoJSON data={feature} style={style} />
}

export const CurLocationPane = withPane(CurLocationLayer, 'currentLocation', zIndices.currentLocation)

/** Returns the feature to the gac or null while it hasn't been fetched */
const useGacFeature = (gac) => {
  const [feature, setFeature] = useState(null)
  const lastGac = usePrevious(gac)

  const [register, cancelAll] = usePromiseHandle()

  useEffect(() => {
    cancelAll()
    if (gac === null) {
      setFeature(null)
    } else {
      register(getGacFeature(gac)).then(setFeature).catch(ignoreCanceled)
    }
  }, [gac, register, cancelAll])

  return gac === lastGac ? feature : null
}
