import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { lighten } from 'polished'

import { useDragAndDropListItems } from 'lib/hooks'
import { useAuthzChecks } from 'lib/hooks/useAuthzChecks'
import { sendToAutomap } from 'lib/bgag-automap'
import Box, { Flex } from 'components/atoms/Box'
import { TextSectionHeader } from 'components/atoms/Text'
import {
  BudiconTrash,
  BudiconKebabUi,
  BudiconSedanFront,
  BudiconDesignCompass,
  BudiconVectorEditing,
  BudiconInternetNetwork,
  BudiconHidePassword,
  BudiconEyeSight,
  BudiconFilesPlus,
  BudiconCrop,
  BudiconMap,
} from 'bgag-budicons'

import ControlSection from 'components/controls/ControlSection'
import { AlignmentBox } from 'components/AlignmentBox'
import { CheckboxGroup, Checkbox } from 'components/Forms'
import { NoStyleButton } from 'components/atoms/NoStyleButton'

import { MenuIcon } from '../Drafts/MenuIcon'
import { EditZoneControl } from '../Edit/Control'
import {
  useGeotoolsStore,
  useZoneProperties,
  GeotoolsStoreSelectors,
  useCollectionPermissions,
} from './Store'
import { EditZoneProperties } from './EditZoneProperties'
import { ZoneIcon } from './ZoneIcon'

const DIFFERENT_STYLING_DROPZONE = true

export const ControlZones = () => {
  const { t } = useTranslation()
  const { hasLicence } = useAuthzChecks()
  const [editZone, setEditZone] = useState(null)
  const [{ collection, algebraMode }, { removeZone, setZonesOrder, setAlgebraMode, removeAllZones }] =
    useGeotoolsStore()
  const { hasWriteAccess } = useCollectionPermissions()
  const localZones = useGeotoolsStore(GeotoolsStoreSelectors.$localZones)
  const [dndZones, itemProperties, draggedTo] = useDragAndDropListItems(collection.features, setZonesOrder)

  const numZones = collection.features.length

  return (
    <>
      <ControlSection>
        <Flex>
          <Box flex="1">
            <TextSectionHeader mb={1}>{t('geotools.zones.title')}</TextSectionHeader>
          </Box>
          {hasLicence('bgagInternalLinks') && (
            <Box mr={2}>
              <NoStyleButton title={t('geotools.sendToAutomap')} onClick={() => sendToAutomap(localZones)}>
                <MenuIcon Icon={BudiconMap} iconSize="12px" padding="2px" />
              </NoStyleButton>
            </Box>
          )}
          <Box>
            <NoStyleButton title={t('geotools.control.deleteAll')} onClick={() => removeAllZones()}>
              <MenuIcon Icon={BudiconTrash} iconSize="12px" padding="2px" />
            </NoStyleButton>
          </Box>
        </Flex>
        {dndZones.map((zone, index) => {
          const id = zone.properties.id
          const propsIsOpen = editZone?.type === 'properties' && editZone?.id === id
          const geomIsOpen = editZone?.type === 'geometry' && editZone?.id === id
          const isDropZone = DIFFERENT_STYLING_DROPZONE && index === draggedTo
          return (
            <div key={zone.id}>
              <div data-position={index} {...itemProperties} draggable={hasWriteAccess}>
                <DropZone active={isDropZone}>
                  <div style={isDropZone ? { visibility: 'hidden' } : {}}>
                    <ZoneMenuItem
                      zoneId={id}
                      togglePropsEdit={() => setEditZone(propsIsOpen ? null : { type: 'properties', id })}
                      toggleGeomEdit={() => setEditZone(geomIsOpen ? null : { type: 'geometry', id })}
                      remove={() => removeZone(id)}
                    />
                  </div>
                </DropZone>
              </div>
              {propsIsOpen && (
                <AlignmentBox alignments={{ parentH: 'right' }} zIndex={1001}>
                  <PopupStyle>
                    <EditZoneProperties zoneId={editZone.id} close={() => setEditZone(null)} />
                  </PopupStyle>
                </AlignmentBox>
              )}
              {geomIsOpen && (
                <AlignmentBox alignments={{ parentH: 'right' }} zIndex={1001}>
                  <PopupStyle>
                    <EditZoneControl zoneId={editZone.id} close={() => setEditZone(null)} />
                  </PopupStyle>
                </AlignmentBox>
              )}
            </div>
          )
        })}
      </ControlSection>
      <ControlSection title={t('geotools.algebra.title')}>
        <CheckboxGroup
          id="geotools.algebra"
          allValues={['union', 'cut']}
          allowMultiple={false}
          value={algebraMode ? [algebraMode] : []}
          onChange={(checked) => setAlgebraMode(checked[0] ? checked[0] : null)}
        >
          <label style={{ cursor: 'pointer' }}>
            <Flex alignItems="center">
              <Box mr="2">
                <Checkbox
                  name="union"
                  title={t('geotools.algebra.union')}
                  disabled={!hasWriteAccess || numZones < 2}
                >
                  <MenuIcon Icon={BudiconFilesPlus} iconSize="20px" padding="6px" />
                </Checkbox>
              </Box>
              <span>{t('geotools.algebra.union')}</span>
            </Flex>
          </label>
          <label style={{ cursor: 'pointer' }}>
            <Flex alignItems="center">
              <Box mr="2">
                <Checkbox
                  name="cut"
                  title={t('geotools.algebra.cut')}
                  disabled={!hasWriteAccess || numZones < 2}
                >
                  <MenuIcon Icon={BudiconCrop} iconSize="20px" padding="6px" />
                </Checkbox>
              </Box>
              <span>{t('geotools.algebra.cut')}</span>
            </Flex>
          </label>
        </CheckboxGroup>
      </ControlSection>
    </>
  )
}

const ZoneMenuItem = ({ zoneId, togglePropsEdit, toggleGeomEdit, remove }) => {
  const { t } = useTranslation()
  const [zone] = useZoneProperties(zoneId)
  const [{ algebraMode, selectedZones }, { setHoveredZone, setFlyTo, setActive, selectZone }] =
    useGeotoolsStore()
  const { name, creationMethod, id, active } = zone
  const iconSize = '20px'
  const { hasWriteAccess } = useCollectionPermissions()
  return (
    <MenuItemStyle
      onMouseEnter={() => setHoveredZone(id)}
      onMouseLeave={() => setHoveredZone(null)}
      selected={selectedZones.includes(zoneId)}
      color={zone.color}
      title={active ? t('geotools.control.flyTo') : null}
    >
      <ZoneIcon zoneId={zoneId} disableClick disableHover />
      <ZoneName onClick={() => (algebraMode ? selectZone(zoneId) : setFlyTo(zoneId))}>{name}</ZoneName>
      <div style={{ width: '4px' }} />
      <ZoneButton
        onClick={() => setActive(zoneId, !active)}
        disabled={!hasWriteAccess}
        title={active ? t('geotools.control.setInactive') : t('geotools.control.setActive')}
      >
        {active ? <BudiconEyeSight size={iconSize} /> : <BudiconHidePassword size={iconSize} />}
      </ZoneButton>
      <div style={{ width: '4px' }} />
      <ZoneButton
        onClick={toggleGeomEdit}
        disabled={!hasWriteAccess}
        title={t('geotools.control.editGeometry')}
      >
        <GeomEditIcon type={creationMethod} size={iconSize} />
      </ZoneButton>
      <div style={{ width: '4px' }} />
      {hasWriteAccess && (
        <ZoneButton onClick={remove} disabled={!hasWriteAccess} title={t('geotools.control.delete')}>
          <BudiconTrash size={iconSize} />
        </ZoneButton>
      )}
      {hasWriteAccess && (
        <ZoneButton
          onClick={togglePropsEdit}
          disabled={!hasWriteAccess}
          title={t('geotools.control.properties')}
        >
          <BudiconKebabUi size={iconSize} />
        </ZoneButton>
      )}
    </MenuItemStyle>
  )
}

const GeomEditIcon = ({ type, size }) => {
  switch (type) {
    case 'draw':
      return <BudiconVectorEditing size={size} />
    case 'circle':
      return <BudiconDesignCompass size={size} />
    case 'fzr':
      return <BudiconSedanFront size={size} />
    case 'select':
      return <BudiconInternetNetwork size={size} />
    default:
      throw Error(`No icon for type "${type}" defined.`)
  }
}

const MenuItemStyle = styled.div`
  background-color: ${({ selected, color }) => (selected ? lighten(0.25, color) : 'white')};
  border-radius: 2px;
  display: flex;
  align-items: center;
  padding: 4px;
  &:hover {
    background-color: ${({ selected, color }) => (selected ? lighten(0.15, color) : 'lightgray')};
  }
  cursor: default;
`

const ZoneName = styled.span`
  flex: 1;
  color: #666;
  margin-left: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`

const ZoneButton = styled.button`
  font-size: 1.6em;
  opacity: 0.7;
  color: #495057;
  transition: all 0.3s linear;
  background: none;
  margin: 0;
  padding: 0;
  border: none;

  ${MenuItemStyle}:hover & {
    opacity: 1;
  }

  :disabled {
    color: #454545;
    transition: none;
  }
`

const DropZone = styled.div`
  box-sizing: border-box;
  border: ${({ active }) => (active ? '1px dashed gray' : '1px solid transparent')};
  border-radius: 2px;
  margin: 4px 0;
`

const PopupStyle = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 0px 8px 8px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
