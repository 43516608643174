import React, { useCallback } from 'react'
import L from 'leaflet'

import { GeoJSON, withPane } from 'lib/leaflet'

import riwisab from 'assets/geo/riwisab'
import { usePaneOptions } from 'lib/leaflet/hooks'
import { zIndices } from 'config/zIndices'
import { createGlobalStyle } from 'styled-components'

const riwisabStyle = {
  radius: 0.0,
  fillColor: '#ffffff',
  color: '#000000',
  weight: 0.0,
  opacity: 0.0,
  dashArray: '',
  lineCap: 'butt',
  lineJoin: 'miter',
  fillOpacity: 0.0,
}

export const CityLabels = ({ cities = null }) => {
  const paneOptions = usePaneOptions()
  const pointToLayer = useCallback(
    (feature, latlng) =>
      L.circleMarker(latlng, riwisabStyle).bindTooltip(feature.properties.name, {
        permanent: true,
        className: 'city-labels-tooltip',
        ...paneOptions,
      }),
    [paneOptions]
  )

  const displayCities = cities || riwisab

  return (
    <>
      <TooltipStyle />
      <GeoJSON data={displayCities} pointToLayer={pointToLayer} />
    </>
  )
}

const TooltipStyle = createGlobalStyle`
  .city-labels-tooltip {
    background-color: rgba(255, 255, 255, 0.4);
  }
`

export const CityLabelsPane = withPane(CityLabels, 'city-labels', zIndices.cityLabels)
