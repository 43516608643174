import React from 'react'
import { Icon } from 'leaflet'

import poiParkingPath from 'assets/poi-parking.svg'
import poiAirportPath from 'assets/poi-airport.svg'

import { bgagServicesUrl } from 'config/appConfig'
import { ALL_POI_LAYERS } from 'config/cityStructureConfig'
import { zIndices } from 'config/zIndices'
import { withPane, BgagVectorTileLayer } from 'lib/leaflet'

import { transportIcon, isBusOnly } from './PoiTransportUtils'

export const PoiLayer = ({ layers, countryCode = 'DE' }) => {
  if (layers.length === 0 || countryCode.length === 0) return null
  const query = ALL_POI_LAYERS.map((layer) => 'layers[]=' + layer).join('&') + `&countryCode=${countryCode}`
  return (
    <BgagVectorTileLayer
      url={`${bgagServicesUrl}/tiles/{z}/{x}/{y}.pbf?${query}`}
      auth
      maxNativeZoom={14}
      useMarker={true}
      layerStyles={layerStyles}
      layers={layers}
      layerFilters={layerFilters}
      onEachFeature={poiTooltip}
    />
  )
}

export const PoiPane = withPane(PoiLayer, 'poi', zIndices.poi)

const layerFilters = {
  poiTransport: (layer) => {
    if (layer.zoom < 12 && !layer.properties.train) return false
    if (layer.zoom < 14 && isBusOnly(layer)) return false
    return true
  },
}

const layerStyles = {
  poiParking: {
    icon: new Icon({
      iconUrl: poiParkingPath,
      iconSize: [10, 10],
      iconAnchor: [5, 5],
    }),
  },
  poiAirport: {
    icon: new Icon({
      iconUrl: poiAirportPath,
      iconSize: [10, 10],
      iconAnchor: [5, 5],
    }),
  },
  poiTransport: (layer) => ({
    icon: transportIcon(layer),
  }),
}

const poiTooltip = (layer) => {
  if (layer.properties.name && layer.properties.name.length) {
    layer.bindTooltip(layer.properties.name)
  }
}
