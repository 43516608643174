import React, { createContext, useState, useEffect } from 'react'
import L from 'leaflet'

import { useLeaflet } from './LeafletMap'

export const layersControlContext = createContext(null)

/** Show a Control for the display of layers
 * Overlays and BaseLayers wrapped in a LayersControl register themselves to it
 */
export const LayersControl = ({ children }) => {
  const map = useLeaflet()
  const [layerControl] = useState(L.control.layers())

  useEffect(() => {
    layerControl.addTo(map)
  }, [map, layerControl])
  return <layersControlContext.Provider value={layerControl}>{children}</layersControlContext.Provider>
}
