import Color from 'lib/color'

import bgagColors from 'config/colors/bgag-colors.json'

export const ColorAllocator = (index, type = 0) => {
  // console.log(index)
  const colorList = [
    bgagColors['bgag-blue'],
    bgagColors['bgag-yellow'],
    bgagColors['bgag-lightgreen'],
    bgagColors['bgag-violet'],
    bgagColors['bgag-darkgreen'],
  ]

  const typeKeys = {
    0: 'main',
    1: 'dark',
    2: 'bright',
  }

  return colorList[index % colorList.length][typeKeys[type] || 'main']
}

export const ColorCalculator = (baseColor, type = 0, colorIndex = 1, amountOfNeededColors = 2) => {
  // type 1 : darken
  // type 2 : lighten
  const newColor = Color(baseColor).hsl()
  const maxChange = 15

  if (type === 1) {
    const changeBy = colorIndex * ((5 + newColor.color[2]) / amountOfNeededColors)
    newColor.color[2] -= Math.min(changeBy, maxChange)
  } else if (type === 2) {
    const changeBy = colorIndex * ((95 - newColor.color[2]) / amountOfNeededColors)
    newColor.color[2] += Math.min(changeBy, maxChange)
  }

  return Color(newColor).hex()
}

export const DataColorAllocator = (index, source) => {
  const colorList = {
    default: [
      '#C84CB3',
      '#9B6EB1',
      '#7CD1C0',
      '#29AC9A',
      '#137764',
      '#B67E3A',
      '#D8811E',
      '#F8A03C',
      '#E2CD31',
      '#FCFE71',
      '#52C4F1',
      '#009CDA',
      '#1C6193',
      '#64417B',
    ],
    bws: ['#C84CB3', '#137764', '#29AC9A', '#7CD1C0', '#B67E3A', '#F8A03C', '#E2CD31', '#FCFE71'],
    sixColors: ['#9B6EB1', '#29AC9A', '#D8811E', '#E2CD31', '#009CDA', '#64417B'],
    takeUpIndustries: [
      '#7CD1C0',
      '#29AC9A',
      '#137764',
      '#FCFE71',
      '#52C4F1',
      '#009CDA',
      '#C84CB3',
      '#64417B',
    ],
  }
  return colorList[source][index]
}

export const MapColorScheme = (kpi) => {
  const schemes = {
    marke: ['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#f03b20', '#bd0026'],
    acces: ['#f2f0f7', '#dadaeb', '#bcbddc', '#9e9ac8', '#756bb1', '#54278f'],
    popul: ['#edf8e9', '#c7e9c0', '#a1d99b', '#74c476', '#31a354', '#006d2c'],
    suppl: ['#fee5d9', '#fcbba1', '#fc9272', '#fb6a4a', '#de2d26', '#a50f15'],
    econo: ['#eff3ff', '#c6dbef', '#9ecae1', '#6baed6', '#3182bd', '#08519c'],
    finan: ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#636363', '#252525'],
    total: ['#eff3ff', '#c6dbef', '#9ecae1', '#6baed6', '#3182bd', '#08519c'],
  }
  return schemes[kpi.slice(0, 5)] || schemes['econo']
}

export const isInTimeRange = (a, b) => (!a || !b ? false : b.from >= a.from && b.to <= a.to) // pre-check of existance

// ----- GENERIC JS ------
// -----------------------
export const timeout = async (delay) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

// Tutorial: https://bit.ly/2MfgN2C
export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

//  Helper function for reordering Array
export const repositionArrayItem = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const arraysAreSameShallow = (array1, array2) =>
  array1.length === array2.length && array1.every((val, i) => val === array2[i])

export const arraySum = (array) => {
  let sum = 0
  for (let i = 0, l = array.length; i < l; i++) {
    sum += array[i]
  }
  return sum
}

export const numbersRange = (start, stop, stepSize = 1) => {
  // from https://bit.ly/2KKMiSl
  if (stop === null) {
    stop = start
    start = 1
  }
  let steps = (stop - start) / stepSize
  let set = []
  for (let step = 0; step <= steps; step++) set.push(start + step * stepSize)
  return set
}

export const numbersRangeQuarter = (start, stop, maxMonth, maxYear) => {
  if (stop === null) {
    stop = start
    start = 1
  }
  maxMonth = maxYear && maxYear > stop ? 12 : maxMonth || 12
  const stopQuarter = getQuarterByMonth(maxMonth)
  const steps = stop - start
  const set = []
  for (let step = 0; step <= steps; step++) {
    let year = start + step
    for (let q = 1; q <= 4; q++) {
      if (step < steps || q <= stopQuarter) {
        set.push(year + '-' + q)
      }
    }
  }
  return set
}

export const formatQuarterTitle = (quarters, short = false) => {
  const formatted = quarters.map(
    (unit) =>
      'Q' + unit.split('-')[1] + '/' + (short ? unit.split('-')[0].substring(2, 4) : unit.split('-')[0])
  )
  return formatted
}

export const randomDataSet = (dataSetSize, minValue, maxValue) => {
  return new Array(dataSetSize).fill(0).map(function (n) {
    return Math.random() * (maxValue - minValue) + minValue
  })
}

export const randomSerie = (type) => {
  return {
    type: type || 'column',
    name: 'random#Serie',
    data: randomDataSet(10, 4, 8),
  }
}

export const findMapsKeysByValue = (map, value) =>
  [...map.entries()].filter(({ 1: v }) => v === value).map(([k]) => k)

export const getDistrictTypeByAssetClass = (assetClass) => {
  if (assetClass === 'office') return '107'
  if (assetClass === 'residential') return '108'
  return '109'
}

export const unflattenTree = (array, dataKey = 'key', parentKey = 'parentId') => {
  let tree = []
  let childrenMap = {}
  let parentMap = {}

  for (let i = 0; i < array.length; i++) {
    const item = { ...array[i] }
    const id = item[dataKey]
    const parentId = item[parentKey]

    parentMap[id] = item
    if (!childrenMap[id]) childrenMap[id] = []
    if (childrenMap[id].length > 0) item.children = childrenMap[id]

    if (parentId) {
      if (!childrenMap[parentId]) childrenMap[parentId] = []
      childrenMap[parentId].push(item)
      if (typeof parentMap[parentId] !== 'undefined' && typeof parentMap[parentId].children === 'undefined') {
        parentMap[parentId].children = childrenMap[parentId]
      }
    } else {
      tree.push(item)
    }
  }

  return tree
}
export const getQuarterByMonth = (month) => {
  return Math.ceil(month / 3)
}

export const getQuarter = (date) => {
  date = new Date(date)
  // if (!date instanceof Date) date = new Date(date)
  return getQuarterByMonth(date.getMonth() + 1)
}

export const getQuarterDisplay = (date) => {
  date = new Date(date)
  // if (!date instanceof Date) date = new Date(date)
  return 'Q' + getQuarterByMonth(date.getMonth() + 1) + '/' + date.getFullYear()
}
