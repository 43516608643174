import { useEffect } from 'react'

export default (onEnter) => {
  const testEnter = (evt) => {
    if (evt.key === 'Enter') {
      onEnter()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', testEnter, false)
    return () => {
      document.removeEventListener('keydown', testEnter, false)
    }
  })
}
