import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useNumbersFormats } from 'config/numbersFormats'
import { TextHeader } from 'components/atoms/Text'
import Button from 'components/atoms/Button'
import Box, { Flex } from 'components/atoms/Box'
import { NumberWithSlider } from 'components/molecules/NumberWithSlider'

import { useMethodStore } from './Store'
import { generateZoneName } from './generateZoneName'
import { useGeotoolsStore } from '../Zones/Store'

export const ControlNewCircle = () => {
  const { t } = useTranslation()
  const [{ radius, center, feature }, setOpts, close] = useMethodStore('circle')
  const [{ collection }, { addZone }] = useGeotoolsStore()
  const numbersFormats = useNumbersFormats()

  useEffect(() => {
    setOpts({ center: collection.meta.locationGeometry })
  }, [collection, setOpts])

  const addZones = () => {
    const creationMethod = 'circle'
    const creationParams = { center, radius, zoneNum: collection.meta.featureCount + 1 }
    const name = generateZoneName(t, creationMethod, creationParams)
    addZone({ creationMethod, name, creationParams, polygon: feature.geometry })
    close()
  }

  return (
    <div>
      <TextHeader mb={2}>{t('geotools.circle.title')}</TextHeader>
      <NumberWithSlider
        label="Radius"
        value={radius}
        onChange={(radius) => setOpts({ radius })}
        min={50}
        max={50_000}
        step={50}
      />
      <Box mt={2} mb={2} style={{ textAlign: 'center' }}>
        <span>{numbersFormats.roundedFraction0.format(radius)}m</span>
      </Box>
      <Flex justifyContent="center">
        <Button disabled={!feature} onClick={addZones}>
          {t('actions.actionAdd')}
        </Button>
        <Button ml={2} onClick={close} appearance="secondary">
          {t('actions.actionCancel')}
        </Button>
      </Flex>
    </div>
  )
}
