import React, { useEffect, useMemo } from 'react'
import styled, { useTheme } from 'styled-components'
import { Link, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'

import api from 'stores/api'
import useApi from 'stores/useApi'

import { useAppStore } from 'stores/AppStore'
import { useAuthStore } from 'stores/AuthStore'

import {
  BudiconPerformanceBoard,
  BudiconBubbleChart,
  BudiconTaskDownload,
  BudiconTwoGrids,
  BudiconLocationArea,
  BudiconWorldConnection,
  BudiconShoppingBags,
  BudiconHome,
  BudiconEqualizerA,
} from 'bgag-budicons'
import LogoRiwisOld from 'components/icons/LogoRiwisOld'
import landingPageHeader from 'assets/images/landingPageHeaderBg.jpg'
import riwisLogoWhite from 'assets/images/logos/riwis_logo_white.svg'
import luehrmannLogoUrl from 'assets/images/logos/luehrmann-logo.svg'
import bayernLBUrl from 'assets/images/logos/Bayernlb-logo.svg'

import { AddLocationModalPreselect } from 'components/controls/ControlLocations'

import Box, { Flex } from 'components/atoms/Box'
import { TextHighlight, TextSubHeader, TextParagraph } from 'components/atoms/Text'
// import Input from 'components/atoms/Input'
import Button from 'components/atoms/Button'
import { Tile, TileGrid } from 'components/molecules/Tile'
import { LandingPage } from 'components/LandingPage'
import { useMarketDataStateStore } from './marketData/stateStore'

const ObjectsIcon = (props) => {
  return <LogoRiwisOld {...props} />
}

const Rotation = styled.div`
  transform: rotate(90deg);
`
const prospectIcon = () => {
  return (
    <Box position="relative">
      <BudiconHome strokeWidth="0.3" size="3rem" color="#002940" />
      <Box position="absolute" top="14px" left="9px">
        <Rotation>
          <BudiconEqualizerA strokeWidth="0.3" size="1.75rem" color="#002940" />
        </Rotation>
      </Box>
    </Box>
  )
}

const LandingPageHome = (props) => {
  const location = useLocation()
  const locationState = location.state || {}

  const { t } = useTranslation(['landingPage'])
  const theme = useTheme()

  const [{ currentUser }] = useAuthStore()
  const [{ lastRoute }] = useAppStore()
  const [, { addLocation }] = useMarketDataStateStore()

  const [getAvailableAreas, availableAreas] = useApi(api.Auth.availableAreas, null)
  const isBulwiengesaTestAccount =
    currentUser && currentUser?.data?.systemGroups.includes('testAccountsBulwiengesa')

  useEffect(() => {
    if (isBulwiengesaTestAccount) {
      getAvailableAreas()
    }
  }, [isBulwiengesaTestAccount, getAvailableAreas])

  const optCountry = useMemo(() => {
    return currentUser.availableCountries.join(',')
  }, [currentUser])

  const userName = (currentUser.data?.firstname + ' ' + currentUser.data?.name).trim()

  const error = locationState.error || null

  return (
    <LandingPage p="3">
      <LandingPage.Header backgroundImage={landingPageHeader}>
        <LandingPage.Header.Overlay flexDirection="column" justifyContent="flex-end" p="1rem 2.375rem">
          {error && (
            <Box position="absolute" top={5} left={10}>
              <TextParagraph mb={5} color="danger">
                {t('error.' + error)}
              </TextParagraph>
            </Box>
          )}
          <Box position="absolute" top={4} right={4} width="120px">
            <img alt="RIWIS Logo" src={riwisLogoWhite} />
          </Box>
          {(userName.length && (
            <TextHighlight
              mb={2}
              color="white"
              dangerouslySetInnerHTML={{ __html: t('welcome', { userName }) }}
            />
          )) || (
            <TextHighlight mb={2} color="white" dangerouslySetInnerHTML={{ __html: t('welcomeNoName') }} />
          )}
          <TextSubHeader fontSize="1.1rem" color="white">
            {t('headerSubtitle')}
          </TextSubHeader>
        </LandingPage.Header.Overlay>
      </LandingPage.Header>
      <LandingPage.Section>
        <LandingPage.Section.Description
          flex={['1 1 100%', '1 1 100%', '1 1 100%', '1 1 100%', '1 1 0%']}
          backgroundColor={theme.bodyBackground}
        >
          <TextHighlight mb={2}>{t('modules.head')}</TextHighlight>
          <TextSubHeader fontSize="1.1rem">{t('modules.text')}</TextSubHeader>
          <Flex alignItems="center" mt={3} flexWrap="wrap">
            <Box flex="0 0 180px" mb={3} mr={4}>
              <AddLocationModalPreselect
                placeholder={t('resetSession')}
                country={optCountry}
                addLocation={addLocation}
              />
            </Box>
            {lastRoute && !lastRoute.startsWith('/logout') && (
              <Box mb={3}>
                <Link to={lastRoute}>
                  <Button sizeVariant="small" appearance="grey">
                    {t('continueLastSession')}
                  </Button>
                </Link>
              </Box>
            )}
          </Flex>
          {isBulwiengesaTestAccount && availableAreas !== null && availableAreas?.default?.length && (
            <>
              <TextSubHeader fontSize="1.1rem">{t('modules.bulwiengesaTestExtra')}</TextSubHeader>
              <TextSubHeader>
                {availableAreas.default.map((area) => (
                  <Box key={area.gac}>&nbsp;{area.name}</Box>
                ))}
              </TextSubHeader>
            </>
          )}
        </LandingPage.Section.Description>

        <TileGrid flex="2" gridTemplateColumns={['100%', '100%', '50% 50%']}>
          <Tile backgroundColor="#CFDE40" linkto="/market-analysis/market-data">
            <Tile.Left>
              <Tile.Icon Icon={BudiconPerformanceBoard} size={'3rem'} mb="1" />
              <Tile.Title>{t('modules.marketAnalysis.title')}</Tile.Title>
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('modules.marketAnalysis.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
          <Tile backgroundColor="#F7FFA9" linkto="/objects/comparables">
            <Tile.Left>
              <Tile.Icon Icon={ObjectsIcon} size={'3rem'} mb="1" />
              <Tile.Title>{t('modules.objects.title')}</Tile.Title>
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('modules.objects.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
          <Tile backgroundColor="#EFFC70" linkto="/makro/economy">
            <Tile.Left>
              <Tile.Icon Icon={BudiconBubbleChart} size={'3rem'} mb="1" />
              <Tile.Title>{t('modules.makro.title')}</Tile.Title>
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('modules.makro.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
          <Tile backgroundColor="#FDFFE9" linkto="/download/documents">
            <Tile.Left>
              <Tile.Icon Icon={BudiconTaskDownload} size={'3rem'} mb="1" />
              <Tile.Title>{t('modules.download.title')}</Tile.Title>
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('modules.download.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
        </TileGrid>
      </LandingPage.Section>

      <LandingPage.Section>
        <LandingPage.Section.Description
          flex={['1 1 100%', '1 1 100%', '1 1 100%', '1 1 100%', '1 1 0%']}
          backgroundColor={theme.bodyBackground}
        >
          <Flex mb={2} alignItems="center">
            <BudiconTwoGrids size="24px" strokeWidth="0.3" />{' '}
            <TextHighlight ml={2}>{t('apps.head')}</TextHighlight>
          </Flex>
          <TextSubHeader fontSize="1.1rem">{t('apps.text')}</TextSubHeader>
        </LandingPage.Section.Description>

        <TileGrid flex="2" gridTemplateColumns={['100%', '100%', '50% 50%']}>
          <Tile backgroundColor="#00697C" linkto="/apps/disco">
            <Tile.Left>
              <Tile.Icon Icon={BudiconWorldConnection} size={'3rem'} mb="1" />
              <Tile.Title>{t('apps.disco.title')}</Tile.Title>
              <Tile.Logo logo={bayernLBUrl} />
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('apps.disco.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
          <Tile backgroundColor="#41B59E" linkto="/apps/l3plus">
            <Tile.Left>
              <Tile.Icon Icon={BudiconShoppingBags} size={'3rem'} mb="1" />
              <Tile.Title>{t('apps.l3plus.title')}</Tile.Title>
              <Tile.Logo logo={luehrmannLogoUrl} />
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('apps.l3plus.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
          <Tile backgroundColor="#0680C5" linkto="/apps/hedonic">
            <Tile.Left>
              <Tile.Icon Icon={prospectIcon} size={'3rem'} mb="1" />
              <Tile.Title>{t('apps.prospect.title')}</Tile.Title>
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('apps.prospect.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
          <Tile backgroundColor="#009CDA" linkto="/apps/retail-markets">
            <Tile.Left>
              <Tile.Icon Icon={BudiconLocationArea} size={'3rem'} mb="1" />
              <Tile.Title>{t('apps.retailMarkets.title')}</Tile.Title>
            </Tile.Left>
            <Tile.Right>
              <Tile.Paragraph fontSize="1rem">{t('apps.retailMarkets.text')}</Tile.Paragraph>
            </Tile.Right>
          </Tile>
        </TileGrid>
      </LandingPage.Section>
    </LandingPage>
  )
}

export default LandingPageHome
