import React from 'react'
import styled from 'styled-components'

import { CheckboxGroup, Checkbox } from 'components/Forms'
import Box, { Flex } from 'components/atoms/Box'
import { AlignmentBox } from 'components/AlignmentBox'

import { useGeotoolsDraftStore } from './Store'
import { useCollectionPermissions } from '../Zones/Store'
import { MenuIcon } from './MenuIcon'
import { ControlNewFzr } from './NewFzrControl'
import { ControlNewSelection } from './NewSelectionControl'
import { ControlNewCircle } from './NewCircleControl'

import {
  BudiconSedanFront,
  BudiconDesignCompass,
  BudiconVectorEditing,
  BudiconInternetNetwork,
} from 'bgag-budicons'

export const DraftControls = () => {
  const [{ popupsOpen }, { setPopups }] = useGeotoolsDraftStore()
  const { hasWriteAccess } = useCollectionPermissions()
  return (
    <div>
      <CheckboxGroup
        id="geotools-create-zone"
        allValues={['circle', 'fzr', 'draw', 'select']}
        value={popupsOpen}
        onChange={setPopups}
        allowMultiple={false}
      >
        <Flex margin={'-4px'}>
          <MenuItem name="circle" Icon={BudiconDesignCompass} disabled={!hasWriteAccess} />
          <MenuItem name="fzr" Icon={BudiconSedanFront} disabled={!hasWriteAccess} />
          <MenuItem name="draw" Icon={BudiconVectorEditing} disabled={!hasWriteAccess} />
          <MenuItem name="select" Icon={BudiconInternetNetwork} disabled={!hasWriteAccess} />
        </Flex>
      </CheckboxGroup>

      {popupsOpen.length > 0 && (
        <AlignmentBox alignments={{ parentH: 'right' }} zIndex={1001}>
          {popupsOpen.includes('fzr') && (
            <PopupStyle>
              <ControlNewFzr />
            </PopupStyle>
          )}
          {popupsOpen.includes('select') && (
            <PopupStyle>
              <ControlNewSelection />
            </PopupStyle>
          )}
          {popupsOpen.includes('circle') && (
            <PopupStyle>
              <ControlNewCircle />
            </PopupStyle>
          )}
        </AlignmentBox>
      )}
    </div>
  )
}

const MenuItem = ({ name, Icon, disabled }) => {
  return (
    <Box flex={1} margin={'4px'}>
      <Checkbox name={name} disabled={disabled}>
        <MenuIcon Icon={Icon} />
      </Checkbox>
    </Box>
  )
}

const PopupStyle = styled.div`
  background-color: white;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 1px 2px 6px 0px rgba(0, 0, 0, 0.2);
  margin: 0px 8px 8px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
`
